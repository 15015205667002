import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

import { BookingStep } from '@/utils/constants.js';

import { useBookingStore, usePersistedPrismicStore } from '@/store.js';

import OutlinedButton from '../OutlinedButton';

const useStyles = makeStyles(() => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '18rem',
        borderRadius: '0.5rem',
        border: '1px solid #D5D5D5',
        flex: 1,
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1rem',
        gap: '1rem',
        textAlign: 'center',
        justifyContent: 'space-between',
        height: '100%',
        boxSizing: 'border-box',
    },
}));

const SecondaryLocationCard = ({ key, locationData }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { mainData: bookAppointmentPrismicData },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);

    const coordinates = {
        lat: locationData.coordinates.latitude,
        lng: locationData.coordinates.longitude,
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    });

    const selectLocation = () => {
        useBookingStore.setState({
            secondaryLocation: locationData,
            currentStep: BookingStep.TIMESLOT_SELECTION,
        });
    };

    return (
        <div className={classes.card} key={key}>
            {isLoaded && (
                <GoogleMap
                    options={{ streetViewControl: false }}
                    mapContainerStyle={{ height: '10rem', minHeight: '10rem' }}
                    center={coordinates}
                    zoom={14}
                >
                    <Marker position={coordinates} />
                </GoogleMap>
            )}
            <div className={classes.cardContent}>
                <Typography>{locationData.address[0].text}</Typography>
                <OutlinedButton
                    onPress={selectLocation}
                    text={bookAppointmentPrismicData.location_cta_external_address[0].text}
                />
            </div>
        </div>
    );
};

export default SecondaryLocationCard;
