import { MenuItem, useTheme } from '@material-ui/core';
import { memo } from 'react';

import { useStyles } from './DefaultDropDownStyles';

const MenuItemDropDown = ({ validateForm, handleCloseDefault, valu, dropDownValue, compulsory }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <MenuItem
            className={classes.menuItem}
            onClick={() => {
                handleCloseDefault(valu.api_key);
                validateForm && validateForm(compulsory, valu.api_key);
            }}
            style={{
                backgroundColor:
                    valu.api_key === dropDownValue
                        ? theme.palette.common.lightBrownSelected
                        : theme.palette.common.lightBrownDropdown,
                whiteSpace: 'break-spaces',
            }}
        >
            {valu.api_value[0].text.substring(0, 70)}
            {valu.api_value[0].text > 70 && '...'}
        </MenuItem>
    );
};

export default memo(MenuItemDropDown);
