import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePersistedPrismicStore } from '@/store.js';

import usePrismicData from './usePrismicData';

/**
 * Prismic content for all forms check-in page.
 *
 * @returns loading
 * @returns mainContent
 */
const useAllFormsPrismic = () => {
    const heraklesFormsCheckin = usePersistedPrismicStore((state) => state.heraklesFormsCheckin);

    const { i18n } = useTranslation();

    const [mainContent, setMainContent] = useState(null);

    const { loading, content: wielandContent } = usePrismicData(heraklesFormsCheckin, 'wieland_forms_welcome_page');

    useEffect(() => {
        const { [i18n.language]: heraklesFormsCheckinArray } = heraklesFormsCheckin;
        if (heraklesFormsCheckinArray && wielandContent) {
            const extendedTreatmentContractEntries = heraklesFormsCheckinArray.filter(
                (item) => item.type === 'extended_treatment_contract'
            );

            const dataArray = wielandContent.select_forms_group
                ?.map(
                    (form) => extendedTreatmentContractEntries?.find((data) => data.id === form.form_linked?.id)?.data
                )
                .filter(Boolean);

            if (dataArray?.length) {
                setMainContent(dataArray);
            }
        }
    }, [heraklesFormsCheckin, wielandContent]);

    return {
        loading: !mainContent || loading,
        content: mainContent,
        wielandContent: wielandContent,
    };
};

export default useAllFormsPrismic;
