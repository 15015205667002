import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import CountryFlag from '@/components/Common/CountryFlag';

import { Context as BookAppointmentContext } from '@/context/BookAppointmentContext';
import { Context as DoctorsContext } from '@/context/DoctorsContext';
import { ContextProvider } from '@/context/ProjectContext';

import { useConfigStore, useSearchStore } from '@/store.js';

import { useStyles } from './DoctorClasses';

const Card = ({ myDoctor, allDoctors, mainData }) => {
    const history = useHistory();
    const classes = useStyles();
    const {
        state: { mainDataArrActive },
    } = useContext(DoctorsContext);

    const { setMyAppointmentsData, setMyAppointmentsDataStatus, setIsDoctorsPage, setForBackState, setTypeId } =
        useContext(ContextProvider);

    const {
        state: { selectedDoctor },
    } = useContext(BookAppointmentContext);
    console.debug('🚀 ~ file: Card.jsx ~ line 43 ~ Card ~ selectedDoctor', selectedDoctor);

    console.debug('myDoctor: myDoctor', myDoctor);

    const forDoctorDetails = (myDoctor) => {
        const uniqueKey = myDoctor.key[0].text;
        const data = [];
        const pracEng = allDoctors['en-us'].find((fil) => fil.key[0].text === uniqueKey);
        const pracGer = allDoctors['de-de'].find((fil) => fil.key[0].text === uniqueKey);
        data['en-us'] = pracEng;
        data['de-de'] = pracGer;
        console.debug('myDoctorID: data', data);

        setMyAppointmentsData(data);
        setMyAppointmentsDataStatus(mainDataArrActive);
        setIsDoctorsPage(false);
        history.push(`/professional/${uniqueKey}`);
    };

    const forFindAppointments = (currDoc) => {
        console.debug('🚀 ~ file: Card.jsx ~ line 72 ~ forFindAppointments ~ currDoc', currDoc.key[0].text);
        console.debug('🚀 ~ file: Card.jsx ~ line 72 ~ forFindAppointments ~ currDoc', currDoc);
        setForBackState(currDoc.key[0].text);

        const { currentCustomer } = useConfigStore.getState();
        if (currentCustomer.is_accordion_view_active) history.push('/');
        else {
            useSearchStore.setState({ queryValuesSet: false });
            history.push(`/find-appointment/${currDoc.key[0].text}`);
        }

        console.debug('currDoc', currDoc);
        setTypeId(currDoc?.type.id);
    };
    console.debug('myDoctor: languages', myDoctor?.languages);

    const handleButtonClick = (event) => {
        event.stopPropagation();
        if (!myDoctor?.is_bookable && myDoctor?.external_booking_link) {
            window.open(myDoctor.external_booking_link[0].text, 'SingleSecondaryWindowName', 'noreferrer');
        } else {
            forFindAppointments(myDoctor);
        }
    };

    return (
        <div
            onClick={() => {
                forDoctorDetails(myDoctor);
            }}
            style={{ cursor: 'pointer' }}
            className={classes.cardContentClasses}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 0,
                    height: '100%',
                }}
            >
                <img alt="Doctor's Profile" src={myDoctor?.profile_picture.url} className={classes.doctorImg} />
                <div className={classes.doctorDataContainer}>
                    <div className={classes.titleContainer}>
                        <Typography className={classes.classTitle}>{myDoctor?.display_name[0].text}</Typography>
                        {myDoctor?.category && (
                            <Typography className={classes.classTeacher}>{myDoctor?.category}</Typography>
                        )}
                        <div className={classes.flagsContainer}>
                            {myDoctor?.languages.map((lang) => (
                                <CountryFlag
                                    key={lang.languages1}
                                    countryCode={lang.languages1 === 'en' ? 'gb' : lang.languages1}
                                    alt={lang.languages1}
                                />
                            ))}
                        </div>
                        <Typography className={classes.classTeacher} style={{ marginBottom: 10, textAlign: 'center' }}>
                            {myDoctor?.practice_name[0].text}
                        </Typography>
                    </div>

                    <div
                        style={{
                            boxSizing: 'border-box',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                        }}
                    >
                        <Button
                            variant="contained"
                            disableElevation
                            fullWidth
                            classes={{
                                root: classes.submitButton,
                                label: classes.buttonText,
                            }}
                            onClick={handleButtonClick}
                            disabled={
                                !myDoctor?.is_bookable &&
                                (!myDoctor?.external_booking_link || !myDoctor?.external_booking_link[0].text)
                            }
                        >
                            {mainData
                                ? !myDoctor?.is_bookable && myDoctor?.external_booking_link
                                    ? mainData?.external_booking_cta[0].text
                                    : mainData?.book_now_cta[0].text
                                : 'Book Now'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
