import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FilledButton from '@/components/FilledButton';
import OutlinedButton from '@/components/OutlinedButton';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

import useFormSteps from '@/hooks/useFormSteps';

import { yourBodyAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';

import { menuItems, trackStormlyAnamnesisSection } from '@/utils/helpers.js';

import { useTrackingStore } from '@/store.js';

import { useStyles } from './FamilyComponents/FamilyClasses';
import FamilyFollowingDisease from './FamilyComponents/FamilyFollowingDisease';

export default function Family({ mainData, allData, user, usersession, isFromHealthData = false }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [checkBoxObj, setCheckBoxObj] = useState({});
    const [errOthers, setErrOthers] = useState('');
    const haveProperty = checkBoxObj.hasOwnProperty(0);
    const total = mainData.diseases_responses;
    const [counter, setCounter] = useState(0);

    const {
        state: { step, families, save_changes_option, is_open_state, is_edit_overview },
        BackBtn,
        UpdateSteps,
        setFamilies,
        onSaveButton,
        onCancelButton,
        setIsOpenState,
        setReviewOption,
    } = useContext(HealthProfileContextMain);

    const {
        relativeDisease,
        whichRelative,
        relativeAge,
        allDisease,
        data2_,
        newArr,
        setRelativeDisease,
        setWhichRelative,
        setRelativeAge,
        setAllDisease,
        setData2_,
        setBody6,
        body6,
        allDataApi,
        setHasAccount,
        other,
        setOther,
        newFromTrue,
        setNewFromTrue,
    } = useContext(ContextProviderTwo);

    const { isConsentAllForms, isLastStep } = useFormSteps();

    const [allDisease1, setAllDisease1] = useState([]);

    const messageRefTwo = useRef();
    useEffect(() => {
        console.debug('sergeriesArr12', counter);
        if (messageRefTwo.current) {
            messageRefTwo.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }, [counter]);

    useEffect(() => {
        useTrackingStore.setState({
            anamnesisSectionStarted: Date.now(),
            currentAnamnesisSection: menuItems()[step - 1],
        });
    }, []);

    const familyData = () => {
        const allDieseasLocal = [];

        if (disabledFunction()) {
            setCounter(counter + 1);
            setNewFromTrue(true);
            return;
        }

        setNewFromTrue(false);

        if (allDisease1.length > 0) {
            allDisease1.forEach((m) => {
                allDieseasLocal[m.whichRelative] = allDisease1.filter((b) => b.whichRelative === m.whichRelative);
            });
        }
        setAllDisease(allDieseasLocal);

        console.debug('Question9: datas ', relativeDisease, relativeAge, whichRelative, data2_, newArr, allDisease);
        setData2_(allDisease1);
        setFamilies({
            relativeDisease: relativeDisease,
            whichRelative: whichRelative,
            relativeAge: relativeAge,
            allDisease: allDieseasLocal,
            newArray: newArr,
            dataFamily: allDisease1,
        });
        familyAPI();

        if (!save_changes_option) {
            trackStormlyAnamnesisSection();
            UpdateSteps(step, history, user, isFromHealthData, isConsentAllForms, isLastStep);
        }
        if (save_changes_option) {
            setHasAccount(false);
            setReviewOption(false);
            onSaveButton(history, is_edit_overview);
        }
    };

    const CancelFunction = () => {
        if (isFromHealthData) {
            history.push('/health-profile-overview');
            return;
        }
        setRelativeDisease(families.relativeDisease);
        setWhichRelative(families.whichRelative);
        setRelativeAge(families.relativeAge);
        setAllDisease(families.allDisease);
        setData2_(families.dataFamily);

        onCancelButton(history);
    };

    console.debug(
        'Question921:',
        data2_,
        '\n All Dieseas',
        allDisease1,
        '\n new Arr',
        allDisease1,
        '\n relativeDisease',
        relativeDisease
    );

    const familyAPI = () => {
        const user_id = user ? usersession?.idToken.payload.sub : '';

        const familyAPIArr = [];
        allDisease1.forEach((m) => {
            familyAPIArr.push({
                name: m.relativeDisease,
                family_member: m.whichRelative,
                since_age: m.relativeAge,
                condition: m.whichOther,
            });
        });
        const body = {
            user_id: user_id,
            category: 'family-history',
            data: {
                history: familyAPIArr,
            },
        };
        console.debug('Body1', body.data);
        setBody6(body);
        user && yourBodyAPI(usersession, body);
    };
    const familyHistoryIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'family-history');
    useEffect(() => {
        if (user && allDataApi !== null && allDataApi !== undefined && body6 === null) {
            if (
                allDataApi?.category_data[familyHistoryIndex] &&
                allDataApi?.category_data[familyHistoryIndex].category === 'family-history'
            ) {
                const category2 = allDataApi?.category_data[familyHistoryIndex].data;
                const arr = [];
                const arr2 = [];
                const allDieseasLocal = [];
                if (category2?.history.length === 0) {
                    setRelativeDisease('NONE OF THE ABOVE');
                } else if (category2?.history.length > 0) {
                    category2?.history.forEach((m) => {
                        arr.push({
                            relativeDisease: m.name,
                            whichRelative: m.family_member,
                            relativeAge: m.since_age,
                            whichOther: m.condition,
                        });
                    });
                    arr.forEach((m) => {
                        allDieseasLocal[m.whichRelative] = arr.filter((b) => b.whichRelative === m.whichRelative);
                    });
                    arr2.push(allDieseasLocal);

                    console.debug('welcome123', arr, arr2);
                }
                console.debug('🚀 ~ file: Family.jsx:424 ~ arr', arr);
                setData2_(arr);
                setAllDisease(arr2[0]);

                console.debug('welcome123:', arr, arr2);

                if (category2?.history.length > 0) {
                    const a = arr;
                    const b = [];
                    const p = {};
                    for (let i = 0; i < a.length; i++) {
                        const c = a[i];
                        console.debug('🚀 ~ file: Family.jsx:457 ~ c', c);
                        b.push({
                            relativeDisease: c.relativeDisease,
                            whichRelative: c.whichRelative,
                            relativeAge: c.relativeAge,
                            whichOther: c.whichOther,
                        });
                        for (let k = 0; k < total.length; k++) {
                            if (total[k].desease_response_api_value === c.relativeDisease) {
                                p[k] = true;
                            }
                            setCheckBoxObj(p);
                        }
                    }
                    console.debug('🚀 ~ file: Family.jsx:475 ~ b', b);
                    setAllDisease1(b);
                } else {
                    console.debug('index1: clicked');
                    setCheckBoxObj({ 0: true });
                }
            }
        }
    }, [allDataApi]);

    const onlyIsOther = allDisease1.some((m) => m.relativeDisease === 'OTHER');
    const isOther = allDisease1.some(
        (m) => m.relativeDisease === 'OTHER' && m.whichOther.length === 0 && m.whichRelative.length === 0
    );
    const isTrue = Object.values(checkBoxObj).some((val) => val === true);
    const disabled = allDisease1.every((m) => m.whichRelative.length > 0);
    const disabledOther = allDisease1.some((m) => m.relativeDisease === 'OTHER' && m.whichRelative.length > 0);
    useMemo(() => {
        if (isOther) {
            setOther(true);
        }
    }, [allDisease1]);

    const disabledFunction = () => {
        if (onlyIsOther && other) {
            console.debug('isOtherALl 1');
            return true;
        }
        if (onlyIsOther && !disabledOther) {
            console.debug('isOtherALl 2');
            return true;
        }
        if (!isOther && !disabled) {
            console.debug('isOtherALl 3');
            return true;
        }
        if (allDisease1.length === 0 && relativeDisease !== 'NONE OF THE ABOVE') {
            console.debug('isOtherALl 4');
            return true;
        }
        console.debug('isOtherALl 5');
        return false;
    };

    return (
        <div className={classes.contentContainerBody}>
            {/* main Part */}
            <div
                className={
                    allDisease1.length === 0 && disabledFunction() && (isFromHealthData || newFromTrue)
                        ? classes.highlight
                        : classes.main
                }
                ref={!isTrue && disabledFunction() ? messageRefTwo : null}
            >
                <FamilyFollowingDisease
                    mainData={mainData}
                    /* ----- NEW PROPS ----- */
                    allDisease1={allDisease1}
                    setAllDisease1={setAllDisease1}
                    allData={allData}
                    checkBoxObj={checkBoxObj}
                    setCheckBoxObj={setCheckBoxObj}
                    haveProperty={haveProperty}
                    user={user}
                    errOthers={errOthers}
                    setErrOthers={setErrOthers}
                    isFromHealthData={isFromHealthData}
                    disabledFunction={disabledFunction}
                    isTrue={isTrue}
                    messageRefTwo={messageRefTwo}
                />
            </div>
            <div
                className=""
                style={{
                    marginTop: 30,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: matches && 'column-reverse',
                }}
            >
                {!save_changes_option ? (
                    <OutlinedButton
                        text={mainData?.back_cta[0].text}
                        onPress={() => {
                            BackBtn(step, history, user, isFromHealthData);
                            setNewFromTrue(false);
                        }}
                        fullWidth={matches}
                    />
                ) : (
                    <OutlinedButton
                        text={!isFromHealthData ? mainData?.cancel[0].text : mainData?.back_cta[0].text}
                        onPress={() => {
                            if (is_open_state) {
                                setIsOpenState(false);
                            }
                            setNewFromTrue(false);
                            CancelFunction();
                        }}
                        fullWidth={matches}
                    />
                )}
                {!save_changes_option ? (
                    <FilledButton
                        text={mainData?.continue_cta[0].text}
                        onPress={() => {
                            familyData();
                        }}
                        fullWidth={matches}
                        marginBottom={matches && 20}
                    />
                ) : (
                    <FilledButton
                        text={mainData?.save_cta[0].text}
                        onPress={() => {
                            familyData();
                        }}
                        fullWidth={matches}
                        marginBottom={matches && 20}
                    />
                )}
            </div>
        </div>
    );
}
