import axios from 'axios';

import { useConfigStore } from '@/store.js';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;
const BASE_API_URL_FOR_PRACTITIONERS = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_PRAC_API_DOMAIN}`;

export async function yourBodyAPI(usersession, body) {
    const user_id = usersession?.idToken.payload.sub;
    const token = usersession?.idToken.jwtToken;

    const { currentCustomer } = useConfigStore.getState();
    const customer_id = currentCustomer?.customer_id?.[0].text;
    // main work starts from here
    try {
        const resp = await axios.post(`${BASE_API_URL}/secure/user/health-profiles/${user_id}`, body, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                customer_id: customer_id,
            },
        });
        console.debug('YourBody1: ', resp);
        return resp;
    } catch (error) {
        console.debug('YourBody1 error is ', error);
        return new Error(error.message);
    }
}

export async function yourBodyGetAPI(usersession) {
    const user_id = usersession?.idToken.payload.sub;
    const token = usersession?.idToken.jwtToken;

    const { currentCustomer } = useConfigStore.getState();
    const customer_id = currentCustomer?.customer_id?.[0].text;
    try {
        const resp = await axios.get(`${BASE_API_URL}/secure/user/health-profiles/${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                customer_id: customer_id,
            },
        });
        console.debug('Bo1dy1 get from YourBodyAPI page', resp);
        return resp;
    } catch (error) {
        console.debug('YourBody1 error is ', error);
        return new Error(error.message);
    }
}
