import { useContext, useEffect } from 'react';

import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { ContextProvider } from '@/context/ProjectContext';

import { formatUserRawDate } from '@/utils/helpers.js';

/**
 * Set User Data In ConsentForms Context
 */
const useUserData = () => {
    const { formData, setFormData, setAllFormsPatientName } = useContext(ConsentFormsContext);

    const {
        state: { user },
    } = useContext(AuthContext);
    const { languageGlobal } = useContext(ContextProvider);

    const {
        firstName: firstNameForm,
        middleName: middleNameForm,
        lastName: lastNameForm,
        dob: dobForm,
        title: titleForm,
        email: emailForm,
        phoneNo: phoneNoForm,
        gender: genderForm,
    } = formData;

    useEffect(() => {
        if (user) {
            setFormData((prev) => ({
                ...prev,
                email: user?.email || '' /** Override prev context email with current user email */,
                ...(!firstNameForm && { firstName: user?.name?.first || '' }),
                ...(!middleNameForm && { middleName: user?.name?.middle || '' }),
                ...(!lastNameForm && { lastName: user?.name?.last || '' }),
                ...(!dobForm && { dob: formatUserRawDate(user?.date_of_birth, languageGlobal) || '' }),
                ...(!titleForm && { title: user?.title || '' }),
                ...(!phoneNoForm && { phoneNo: user?.phone || '' }),
                ...(!genderForm && { gender: user?.gender || '' }),
            }));
            const name = `${user?.name?.first || ''} ${user?.name?.middle || ''}${user?.name?.middle ? ' ' : ''}${
                user?.name?.last || ''
            }`;
            setAllFormsPatientName(name);
        }
    }, [user]);
};

export default useUserData;
