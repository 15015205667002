import clsx from 'clsx';
import { Fragment, useState } from 'react';

import { useStyles } from '@/pages/HealthProfileAnamnesis/AccountCommponents/AccountsStyles';

import { colorStyles } from './BasicStyles';

const PrivacyPolicyText = ({
    data = [
        {
            id: '',
            text: '',
            link: '',
            href: '',
        },
    ],
    seeLess = '',
    seeMore = '',
    hasMarginLeft = true,
}) => {
    const basicStyles = colorStyles();
    const classes = useStyles();
    const [isShowMore, setIsShowMore] = useState(false);
    return (
        <div className={clsx(classes.privacyPolicyText, hasMarginLeft && basicStyles.ml10)}>
            <div className={!isShowMore && basicStyles.paragraphClamp}>
                {data.map(({ id, text, link, href }) => {
                    return (
                        <Fragment key={id}>
                            <span>{text} </span>
                            <a className={classes.contentTextLink} href={href} target="_blank" rel="noreferrer">
                                {link}
                            </a>
                        </Fragment>
                    );
                })}
                .
            </div>
            <div onClick={() => setIsShowMore(!isShowMore)} className={classes.pointer}>
                {!isShowMore ? seeMore : seeLess}
            </div>
        </div>
    );
};

export default PrivacyPolicyText;
