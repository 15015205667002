import axios from 'axios';

import { processAPIWithHeaders } from '@/utils/helpers.js';

import createDataContext from './createDataContext';

const initialState = {
    isDocumentUploading: false,
    isDocumentUploaded: false,
    uploadedDocument: null,
};

const documentReducer = (state, action) => {
    switch (action.type) {
        case 'set_document_uploading_status':
            return {
                ...state,
                isDocumentUploading: action.payload,
            };
        case 'set_uploaded_document':
            return {
                ...state,
                isDocumentUploading: action.payload.isDocumentUploading,
                isDocumentUploaded: action.payload.isDocumentUploaded,
                uploadedDocument: action.payload.uploadedDocument,
            };
        case 'delete_document':
            return {
                ...state,
                initialState: initialState,
            };
        default:
            return state;
    }
};

const setDocumentUploadingStatus = (dispatch) => async (status) => {
    dispatch({
        type: 'set_document_uploading_status',
        payload: status,
    });
};

const deleteDocument = (dispatch) => async () => {
    dispatch({
        type: 'delete_document',
    });
};

const beginFileUpload = (dispatch) => async (data, file, usersession) => {
    // Start Loading
    dispatch({
        type: 'set_document_uploading_status',
        payload: true,
    });

    const token = usersession.idToken.jwtToken;

    // Prepare upload link
    const uploadUrlResponse = await processAPIWithHeaders(`secure/user/documents/?user_id=${data.user_id}`, 'POST', {
        body: data,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    });
    console.debug('fileUpload: File Upload Url Response:', uploadUrlResponse);
    const { document, upload_url } = uploadUrlResponse;
    if (upload_url !== undefined) {
        // Start uploading file
        console.debug('fileUpload: Starting File Upload');

        const uploadFileResult = await axios.put(upload_url, file);
        console.debug('fileUpload: File Upload Result:', uploadFileResult);

        if (uploadFileResult.status === 200) {
            console.debug('fileUpload: File Uploaded:', uploadFileResult);

            dispatch({
                type: 'set_uploaded_document',
                payload: {
                    isDocumentUploading: false,
                    isDocumentUploaded: true,
                    uploadedDocument: document,
                },
            });
        } else {
            dispatch({
                type: 'set_document_uploading_status',
                payload: false,
            });
        }
    } else {
        dispatch({
            type: 'set_document_uploading_status',
            payload: false,
        });
    }
};

// login end
export const { Provider, Context } = createDataContext(
    documentReducer,
    {
        setDocumentUploadingStatus: setDocumentUploadingStatus,
        beginFileUpload: beginFileUpload,
        deleteDocument: deleteDocument,
    },
    initialState
);
