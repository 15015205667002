import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

import Tooltip from '@/components/Tooltip';
import UnderlinedButton from '@/components/UnderlinedButton';

const useStyles = makeStyles(() => ({
    cancelButtonContainer: {
        display: 'inline-block',
        position: 'relative',
    },
}));

const UnderlinedButtonWithTooltip = ({
    buttonText,
    onPress,
    underlineColor,
    tooltipText,
    isDisabled,
    isMobile,
    marginRight,
}) => {
    const classes = useStyles();
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            className={classes.cancelButtonContainer}
            style={{ cursor: !isDisabled ? 'pointer' : 'not-allowed', marginRight: marginRight || 0 }}
            onContextMenu={(e) => e.preventDefault()}
            onMouseEnter={() => {
                if (!isMobile) setShowTooltip(true);
            }}
            onMouseLeave={() => {
                if (!isMobile) setShowTooltip(false);
            }}
            onTouchStart={() => {
                if (isMobile) setShowTooltip(true);
            }}
            onTouchEnd={() => {
                if (isMobile) setShowTooltip(false);
            }}
        >
            {/* UnderlinedButton that shows the tooltip on hover */}
            <UnderlinedButton
                text={buttonText}
                onPress={onPress}
                underlineColor={underlineColor}
                noMarginTop
                disabled={isDisabled}
            />
            <Tooltip text={tooltipText} show={showTooltip} />
        </div>
    );
};

export default UnderlinedButtonWithTooltip;
