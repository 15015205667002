import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { AntSwitch } from '../AntSwitchCommon';
import { colorStyles } from '../BasicStyles';
import InputFieldQuestions, { compType } from './InputFieldQuestions';

const useStyles = makeStyles((theme) => ({
    BtncontentText: {
        textTransform: 'none',
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        margin: '20px 5px 0 0',
    },
    userData: {
        margin: '0 5px 0 0',
    },
}));

const FamilyFollowingSwitch = ({
    mainData,
    switchName,
    savedValue,
    index,
    sergeriesArr1,
    setSergeriesArr1,
    handleFunction,
    checkBoxObj,
    errOthers,
    setErrOthers,
}) => {
    const classes = useStyles();
    const basicStyle = colorStyles();
    return (
        <>
            <div className={clsx(classes.mainContainer, index === 0 && basicStyle.mb30)}>
                <AntSwitch
                    checked={checkBoxObj[index] || false}
                    onChange={() => {
                        handleFunction(index);
                    }}
                    name={switchName}
                    className={classes.userData}
                />
                <Typography className={classes.BtncontentText}>{switchName}</Typography>
            </div>

            {checkBoxObj[index] && (
                <>
                    {sergeriesArr1.map(
                        (m, i) =>
                            m.relativeDisease === savedValue && (
                                <div>
                                    <InputFieldQuestions
                                        componentType={compType.FAMILY_HISTORY}
                                        mainData={mainData}
                                        idOfComponent="relativeDisease"
                                        dateQuestion={m.whenPastCondition}
                                        getter={m.specific}
                                        isCompulsory={m.relativeDisease}
                                        getterRelative={m.whichRelative}
                                        getterAge={m.relativeAge}
                                        getterOther={m?.whichOther}
                                        callBack={(e) => {
                                            setSergeriesArr1((prev) => {
                                                const a = prev[i];
                                                a.relativeDisease = e;
                                                return prev;
                                            });
                                            console.debug('hello12345', e);
                                        }}
                                        callBackRelative={(e) => {
                                            setSergeriesArr1((prev) => {
                                                const a = prev[i];
                                                a.whichRelative = e;
                                                return prev;
                                            });
                                            console.debug('hello12345', e);
                                        }}
                                        callBackAge={(e) => {
                                            setSergeriesArr1((prev) => {
                                                const a = prev[i];
                                                a.relativeAge = e;
                                                return prev;
                                            });
                                        }}
                                        callBackOther={(e) => {
                                            setSergeriesArr1((prev) => {
                                                const a = prev[i];
                                                a.whichOther = e;
                                                return prev;
                                            });
                                        }}
                                        errOthers={errOthers}
                                        setErrOthers={setErrOthers}
                                        isFamilyOther={index === 11}
                                    />
                                </div>
                            )
                    )}
                </>
            )}
        </>
    );
};

export default FamilyFollowingSwitch;
