import { ThemeProvider } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import { useEffect, useLayoutEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import DevVersionLabel from '@/components/DevVersionLabel';
import HtmlHead from '@/components/HtmlHead';
import Router from '@/components/Router';

import { Provider as AuthProvider } from '@/context/AuthContext';
import { Provider as BookAppointmentProvider } from '@/context/BookAppointmentContext';
import { ConsentFormsContextMain } from '@/context/ConsentFormsContext';
import { Provider as ConsentManagerProvider } from '@/context/ConsentManagerContext';
import { Provider as DoctorsContext } from '@/context/DoctorsContext';
import { Provider as DocumentProvider } from '@/context/DocumentContext';
import { Provider as HealthProfileProvider } from '@/context/HealthProfileContext';
import { Provider as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { Provider as HealthProfileOverviewProvider } from '@/context/HealthProfileOverviewContext';
import { Provider as ProductProvider } from '@/context/ProductContext';
import { Provider as ResetPaswdContext } from '@/context/ResetPaswdContext';

import useAnalytics from '@/hooks/useAnalytics';
import usePrismic from '@/hooks/usePrismic';

import { dayjs } from '@/utils/dayjsSetup.js';
import { isProdEnvironment, trackStormlyUserProperty } from '@/utils/helpers.js';

import { useConfigStore, useCookiesStore } from '@/store.js';

import { createTheme } from './theme';

const App = () => {
    usePrismic();
    useAnalytics();
    const { i18n } = useTranslation();
    const analyticsAllowed = useCookiesStore((state) => state.analyticsCookies);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    useLayoutEffect(() => {
        if (isProdEnvironment()) {
            console.debug = function () {};
        }

        if (import.meta.env.VITE_ENVIRONMENT === 'dev') {
            console.warn = (...args) => {
                console.error('[Warning as Error]', ...args);
            };
        }
    }, []);

    const clearCookies = () => {
        const allCookies = Cookies.get(); // Get all cookies
        for (const cookie in allCookies) {
            Cookies.remove(cookie); // Clear each cookie
        }
    };

    // check localstorage
    useEffect(() => {
        const storedAppVersion = localStorage.getItem('appVersion');

        if (!storedAppVersion || storedAppVersion !== import.meta.env.VITE_VERSION) {
            localStorage.clear();
            localStorage.setItem('appVersion', import.meta.env.VITE_VERSION);
            clearCookies();
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        if (i18n.language) trackStormlyUserProperty({ language: i18n.language });
    }, [analyticsAllowed, i18n.language]);

    useEffect(() => {
        trackStormlyUserProperty({ environment: import.meta.env.VITE_ENVIRONMENT });
    }, [analyticsAllowed]);

    useEffect(() => {
        dayjs.locale(i18n.language);
    }, [i18n.language]);

    return (
        <ThemeProvider theme={createTheme(currentCustomer?.primary_color, currentCustomer?.secondary_color)}>
            <DocumentProvider>
                <ResetPaswdContext>
                    <ConsentFormsContextMain>
                        <HealthProfileContextMain>
                            <HealthProfileOverviewProvider>
                                <ConsentManagerProvider>
                                    <DoctorsContext>
                                        <BookAppointmentProvider>
                                            <HealthProfileProvider>
                                                <ProductProvider>
                                                    <AuthProvider>
                                                        <HtmlHead />
                                                        {/* <Elements stripe={stripePromise}> */}
                                                        <Router />
                                                        {/* </Elements> */}
                                                        <DevVersionLabel />
                                                        <Toaster
                                                            toastOptions={{
                                                                style: { fontFamily: 'MessinaSans-Regular' },
                                                            }}
                                                        />
                                                    </AuthProvider>
                                                </ProductProvider>
                                            </HealthProfileProvider>
                                        </BookAppointmentProvider>
                                    </DoctorsContext>
                                </ConsentManagerProvider>
                            </HealthProfileOverviewProvider>
                        </HealthProfileContextMain>
                    </ConsentFormsContextMain>
                </ResetPaswdContext>
            </DocumentProvider>
        </ThemeProvider>
    );
};

export default App;
