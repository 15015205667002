import createDataContext from './createDataContext';

const productReducer = (state, action) => {
    switch (action.type) {
        case 'set_dialog_product_details':
            return {
                ...state,
                showProductDetails: action.payload,
            };
        case 'set_product_details':
            return {
                ...state,
                detailsProduct: action.payload,
            };
        default:
            return state;
    }
};

const setDialogProductDetails = (dispatch) => async (open) => {
    dispatch({
        type: 'set_dialog_product_details',
        payload: open,
    });
};

const setProductDetails = (dispatch) => async (item) => {
    dispatch({
        type: 'set_product_details',
        payload: item,
    });
};

export const { Provider, Context } = createDataContext(
    productReducer,
    { setDialogProductDetails: setDialogProductDetails, setProductDetails: setProductDetails },
    { showProductDetails: false, detailsProduct: null }
);
