/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:edab10a8-2307-4b19-962d-906431019809",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_i9SDpR6AA",
    "aws_user_pools_web_client_id": "6ldohm7a858eh1kne242pkjagp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "eternoweb-20210511122855-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://eternoweb-20210511122855-hostingbucket-dev.s3-website.eu-central-1.amazonaws.com"
};


export default awsmobile;
