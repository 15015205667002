import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AntSwitch } from '@/components/Common/AntSwitchCommon.jsx';

import { usePersistedPrismicStore } from '@/store.js';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2rem',
        gap: '2rem',
    },
    consentContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: '1rem',
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    switchContainer: {
        marginTop: '3px',
    },
}));

const RegistrationConsent = ({
    isConsentConfirmed,
    isAnalyticsConfirmed,
    setIsConsentConfirmed,
    setIsAnalyticsConfirmed,
}) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { benefits: prismicData, dataSet },
    } = usePersistedPrismicStore((state) => state.registerData);

    const { privacyPolicyTextParts, moreInfoTextParts } = useMemo(() => {
        if (!dataSet) return null;

        return {
            privacyPolicyTextParts: prismicData.privacy_policy.split('{placeholder}'),
            moreInfoTextParts: prismicData.more_info.split('{placeholder}'),
        };
    }, [dataSet, prismicData]);

    return (
        <div className={classes.container}>
            <div className={classes.consentContainer}>
                <div className={classes.switchContainer}>
                    <AntSwitch
                        checked={isConsentConfirmed}
                        onChange={() => setIsConsentConfirmed(!isConsentConfirmed)}
                    />
                </div>
                <Typography>
                    {privacyPolicyTextParts[0]}
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        className={classes.contentTextLink}
                        href={prismicData.link_one}
                    >
                        {prismicData.term_and_condition_one}
                    </a>
                    {privacyPolicyTextParts[1]}
                </Typography>
            </div>
            <div className={classes.consentContainer}>
                <div className={classes.switchContainer}>
                    <AntSwitch
                        checked={isAnalyticsConfirmed}
                        onChange={() => setIsAnalyticsConfirmed(!isAnalyticsConfirmed)}
                    />
                </div>
                <Typography>{prismicData.analytics_consent}</Typography>
            </div>
            <Typography>
                {moreInfoTextParts[0]}
                <a
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.contentTextLink}
                    href={prismicData.privacy_policy_link}
                >
                    {prismicData.privacy_policy_link_text}
                </a>
                {moreInfoTextParts[1]}
            </Typography>
        </div>
    );
};

export default RegistrationConsent;
