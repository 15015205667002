import { InputBase, MenuItem, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'react';

import countryCode from '@/components/PhoneInput/countryCode.json';

const useStyles = makeStyles((theme) => ({
    menuItem: {
        fontSize: 12,
        height: 55,
    },
    textFields: {
        position: 'fixed',
        zIndex: 1,
        padding: '0 17px',
        height: 55,
        backgroundColor: theme.palette.common.lightBrownDropdown,
        marginTop: -8,
    },
    inputMainItem: {
        backgroundColor: theme.palette.common.lightBrownDropdown,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        marginTop: 1,
    },
    mainContainer: {
        backgroundColor: theme.palette.common.lightBrownDropdown,
        minHeight: 'auto',
    },
    text: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
    },
    imageInner: {
        height: 11,
    },
}));

const CountrySearch = ({ mainDataPlaceHolder, data, setData, widthLocal, closeDropDown }) => {
    const messageRef = useRef();
    const classes = useStyles();
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const [filterdWord, setFilterdWord] = useState('');
    const [filter, setFilter] = useState([]);
    const [wordEnter, setWordEnter] = useState([]);
    const [wordEnterr, setWordEnterr] = useState([]);

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    });

    const onFilter = () => {
        console.debug('allTreatments1: name', filterdWord);
        const newFilter = countryCode.filter((value) => {
            return (
                value.name.toLocaleLowerCase().includes(filterdWord.toLocaleLowerCase()) &&
                !wordEnterr.includes(value.name)
            );
        });
        if (filterdWord === '') {
            setFilter(newFilter);
        } else {
            setFilter(newFilter);
        }
        console.debug(filterdWord);
        setWordEnter(filterdWord);
    };

    const testing = (item) => {
        console.debug('first12:', item.dialCode);
        setWordEnter(item.dialCode);
        setWordEnterr(item.dialCode);
        setData(item.dialCode);
        setWordEnter('');
        setFilterdWord('');
        closeDropDown(null);
    };

    useEffect(() => {
        onFilter();
    }, [filterdWord]);

    return (
        <div className={classes.mainContainer}>
            <InputBase
                placeholder={mainDataPlaceHolder}
                variant="filled"
                color="primary"
                autoComplete="off"
                value={wordEnter}
                fullWidth
                autoFocus="on"
                disabledRipple
                // onFocus={onFilter}
                onChange={(e) => {
                    setFilterdWord(e.target.value);
                    onFilter();
                }}
                classes={{
                    root: classes.textFields,
                }}
                style={{
                    width: matchesMd ? parseFloat(widthLocal) + 5 : parseFloat(widthLocal) + 8, //
                }}
            />
            <div className="">
                {filter.map((code) => (
                    <div
                        onClick={() => {
                            testing(code);
                        }}
                        ref={code.dialCode === data ? messageRef : null}
                    >
                        <MenuItem
                            className={classes.menuItem}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                top: 55,
                                backgroundColor:
                                    code.dialCode === data
                                        ? theme.palette.common.lightBrownSelected
                                        : theme.palette.common.lightBrownDropdown,
                            }}
                        >
                            <div className="" style={{ paddingRight: 10 }}>
                                <img className={classes.imageInner} src={code.flag.toLowerCase()} />
                            </div>
                            <Typography className={classes.text}>{code.name}</Typography>
                        </MenuItem>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CountrySearch;
