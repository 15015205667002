import { DialogContentText } from '@material-ui/core';

import UnderlinedButton from '../../UnderlinedButton';

const RequestCode = ({ classes, handleRequestCode, isResendDisabled, error, content }) => (
    <>
        <DialogContentText
            id="alert-dialog-description"
            classes={{
                root: classes.text,
            }}
        >
            {content?.otp_description[0]?.text}
        </DialogContentText>
        {error && <p className={classes.errorText}>{error}</p>}
        <UnderlinedButton
            text={content?.request_otp_button[0]?.text}
            onPress={handleRequestCode}
            disabled={isResendDisabled}
        />
    </>
);

export default RequestCode;
