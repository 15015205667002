import { clone, cloneDeep, debounce, uniqBy } from 'es-toolkit';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import isEmail from 'validator/es/lib/isEmail';

import { emailExists } from '@/utils/helpers.js';

export const ConsentFormsContext = createContext();

export const ConsentFormsContextMain = (props) => {
    //* * Constants */
    const stepsEnum = {
        'personal-information': 'personal-information',
        'contract-details': 'contract-details',
        'summary-and-consent': 'summary-and-consent',
    };

    const whichEnum = {
        NEXT: 'next',
        PREVIOUS: 'previous',
    };

    //* * States */
    const [consentFormsContent, setConsentFormsContent] = useState(null);
    const [doctor, setDoctor] = useState('Herakles Therapiezentrum.');
    const [signState, setSignState] = useState('');
    const [openSignModal, setOpenSignModal] = useState(false);
    const [signature, setSignature] = useState(null);
    const [confirmSummary, setConfirmSummary] = useState(false); //* * By Default Not Confirmed */

    //* * Herakles Course */
    const [courseStartDate, setCourseStartDate] = useState('');
    const [courseStartDateError, setCourseStartDateError] = useState('');

    //* * Professional */
    const [professional, setProfessional] = useState(null);
    const [professionalAddress, setProfessionalAddress] = useState(null);
    const [contractContent, setContractContent] = useState(null);

    //* * Form Template Id */
    const [formTemplateId, setFormTemplateId] = useState('');

    //* * Eterno Account States */
    const [password, setPassword] = useState(''); //* * For Account Page/Step */
    const [confirmPassword, setConfirmPassword] = useState(''); //* * For Account Page/Step */
    const [passwordError, setPasswordError] = useState(''); //* * For Account Page/Step */
    const [confirmPasswordError, setConfirmPasswordError] = useState(''); //* * For Account Page/Step */
    const [createAccount, setCreateAccount] = useState(''); //* * For Account Page/Step */
    const [showVerification, setShowVerification] = useState(verificationStatus.NOT_VERIFIED); //* * For Account Page/Step */
    const [verificationCode, setVerificationCode] = useState(''); //* * For Account Page/Step */
    const [isVerificationError, setIsVerificationError] = useState(false); //* * For Account Page/Step */
    const [policyAccepted, setPolicyAccepted] = useState(false); //* * For Account Page/Step */
    const [analyticsAccepted, setAnalyticsAccepted] = useState(false); //* * For Account Page/Step */
    const [userType, setUserType] = useState(null); //* * For Account Page/Step */

    /** Personal Information Form State */
    const [formData, setFormData] = useState({
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        insuranceType: '',
        insuranceName: '',
        phoneNo: '',
        email: '',
        street: '',
        zipCode: '',
        city: '',
        country: {},
        comment: '',
        gender: '',
    });

    /** GuardianData Errors */
    const [guardianErrors, setGuardianErrors] = useState(INITIAL_STATE_GUARDIAN_ERROR);
    /** Reset Errors */
    const resetGuardianErrors = () => setGuardianErrors(INITIAL_STATE_GUARDIAN_ERROR);

    /** FormData Errors */
    const [errors, setErrors] = useState(INITIAL_STATE_ERROR);
    /** Reset Errors */
    const resetErrors = () => setErrors(INITIAL_STATE_ERROR);

    // Set Email Error Message
    const setEmailError = (err) => {
        const copyErrors = { ...errors };
        copyErrors.emailError = err;
        setErrors(copyErrors);
    };

    const callback = (...params) => {
        const [email, callback, errorMessage, fetchObj] = params;
        const { isFetching, setIsFetching } = fetchObj;

        if (isFetching) return;

        setIsFetching(true);
        emailExists(email, callback, errorMessage)
            .then(() => {
                setIsFetching(false);
            })
            .catch((error) => {
                console.error(error);
                setIsFetching(false);
            });
    };

    const debounceFn = (callback) => debounce(() => callback, 500);

    /** Validation Function */
    const validateForm = (type = '', errors = {}, setErrors = () => {}, formData = {}) => {
        const { email } = formData;

        const copyErrors = { ...errors };

        if (type === 'email') {
            setErrors((prev) => ({ ...prev, emailError: '' }));

            if (formData.email === '') {
                copyErrors.emailError = 'requiredError';
                setErrors(copyErrors);
            } else if (isEmail(email)) {
                emailExists(formData.email, setEmailError, 'emailAlreadyExistError');
            } else {
                copyErrors.emailError = 'invalidEmailError';
                setErrors(copyErrors);
            }
        } else {
            Object.keys(formData).forEach((form) => {
                if (form === type) {
                    // Empty Error Message
                    copyErrors[`${form}Error`] = '';
                    setErrors(copyErrors);

                    // Checking Null Error Message
                    if (formData[form] === '') {
                        copyErrors[`${form}Error`] = 'requiredError';
                        setErrors(copyErrors);
                    }
                }
            });
        }
    };

    /** Validate Password */
    const validatePassword = () => {
        setPasswordError('');
        setConfirmPasswordError('');

        if (password === '') {
            setPasswordError('requiredError');
        }

        if (confirmPassword === '') {
            setConfirmPasswordError('requiredError');
        } else if (password !== confirmPassword) {
            setConfirmPasswordError('passwordsNotMatched');
        }
    };

    /** Personal Information Form onChange Function */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /** Personal Information Form State */
    const [guardianData, setGuardianData] = useState(INITIAL_STATE_GUARDIAN);

    /** Reset Guardian Data */
    const resetGuardianData = () => setGuardianData(INITIAL_STATE_GUARDIAN);

    /** Personal Information Form onChange Function */
    const handleGuardianChange = (e) => {
        const { name, value } = e.target;
        setGuardianData({
            ...guardianData,
            [name]: value,
        });
    };

    /** Personal Information Form State */
    const [communicationData, setCommunicationData] = useState(INITIAL_STATE_COMMUNICATION);

    /** Personal Information Form onChange Function */
    const handleCommunicationChange = (e) => {
        const { name, value } = e.target;
        setCommunicationData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    /** Communication Toggles */
    const [communicationToggles, setCommunicationToggles] = useState(INITIAL_STATE_COMMUNICATION_TOGGLES);

    //* * Handle Communication Toggle For Switch */
    const handleCommunicationToggle = (name) => {
        setCommunicationToggles((prev) => ({
            ...prev,
            [name]: !communicationToggles[name],
        }));
    };

    //* * Handle Toggle For Switch */
    const handleToggle = () => {
        setConfirmSummary(!confirmSummary);
    };

    /** EMPTY STATES */
    const emptyStates = () => {
        setConsentFormsContent(null);
        setDoctor('');
        setPassword('');
    };

    /** All Selected Forms */
    const [allFormsPatientName, setAllFormsPatientName] = useState('');

    /** All Selected Forms */
    const [allSelectedForms, setAllSelectedForms] = useState({});

    //* * All Forms Flow States */
    const [isAllForms, setIsAllForms] = useState(false);

    //* * All Pages/Forms */
    const [allFormsPages, setAllFormsPages] = useState([
        { name: 'Welcome', route: '/all-forms-welcome', active: true, welcome: true },
    ]);

    const [isFromOwnWelcome, setIsFromOwnWelcome] = useState(false);

    const allFormsStepCounter = useMemo(() => {
        if (!isAllForms || allFormsPages.length <= 0) return '';

        const copyAllForms = cloneDeep(allFormsPages);
        const allFormsPagesLocal = copyAllForms.slice(1, -1);
        const uniqueForms = uniqBy(allFormsPagesLocal, (page) => page.name);

        const formNames = uniqueForms.map((unique) => unique?.route?.replace('/', '').split('?')[0]);
        const currentFormName = window.location.pathname.replace('/', '');
        const currentFormIndex = formNames.findIndex((form) => form === currentFormName);

        const totalForms = formNames.length;
        const currentFormNo = currentFormIndex >= 0 ? currentFormIndex + 1 : 0;

        return {
            isNotLastForm: currentFormNo !== totalForms,
            formCount: `${currentFormNo}/${totalForms}`,
        };
    }, [isAllForms, allFormsPages]);

    const [consentFormsLoading, setConsentFormsLoading] = useState(false);

    const pagesStepsNo = useCallback(
        ({ currentStepNo = 1, pages = {}, user = null, isNotLastForm = false }) => {
            const isInitialForm = !isAllForms;
            const totalPageCount = Object.keys(pages).length;
            const nextPageNo = currentStepNo + 1;

            if (isInitialForm) {
                return `${nextPageNo}/${totalPageCount}`;
            }

            const isThirdStep = nextPageNo === 3;
            const isFourthStep = nextPageNo === 4;
            const isFifthStep = nextPageNo === 5;

            const shouldMoveToThird = isFourthStep && createAccount === toggleValues.YES;
            const shouldMoveToFourth = isFifthStep;

            const calculatedNextPageNo = isThirdStep ? 3 : shouldMoveToThird ? 3 : shouldMoveToFourth ? 4 : nextPageNo;

            const finalPageCount = user || isNotLastForm ? 3 : 4;

            return `${calculatedNextPageNo}/${finalPageCount}`;
        },
        [createAccount, isAllForms]
    );

    const [consentAllForms, setConsentAllForms] = useState({
        isConsentAllForms: false,
        currentStepIndex: 0,
        initialRoute: '',
        forms: [],
        consentFormsBody: [],
        formTemplates: [],
        formsBody: [],
        updateFormBody: [],
    });

    //* * AllForms SignUp User */
    const [signUpUser, setSignUpUser] = useState(null);

    //* * Summary Page Edit State */
    const [summaryEdit, setSummaryEdit] = useState(false);

    //* * Helpers */
    const currentPage = allFormsPages.find((page) => page.active === true);
    const nextPageIndex = allFormsPages.findIndex((page) => page.active === true) + 1;
    const nextPage = allFormsPages.find((p, i) => i === nextPageIndex);
    const previousPageIndex = allFormsPages.findIndex((page) => page.active === true) - 1;
    const previousPage = allFormsPages.find((p, i) => i === previousPageIndex);
    const isLastPage = currentPage === allFormsPages[allFormsPages.length - 1];

    //* * Setting Next or Previous Page/Form Active */
    const movePage = (move = whichEnum.NEXT, history, allForms = null, isAuthenticated = false) => {
        let index = 0;
        const forms = allForms ?? allFormsPages;
        const userForms = isAuthenticated
            ? forms.filter((f) => !f.route.includes('create-account') && !f.route.includes('verification'))
            : forms; // if isAuthenticated remove 'create-account' route
        const clonePages = clone(userForms);

        const pageIndex = (index) => {
            const operator = move === whichEnum.NEXT ? '+' : '-';
            return operator === '+' ? index + 1 : index ? index - 1 : index;
        };

        clonePages.forEach((page, i) => {
            if (page?.active) {
                index = i;
                clonePages[i].active = false;
            }

            const compareIndex = move === whichEnum.NEXT ? i : i - 1;
            if (pageIndex(index) === compareIndex) {
                clonePages[pageIndex(index)].active = true;
                history.push(clonePages[pageIndex(index)].route);
            }
        });

        setAllFormsPages(clonePages);
    };

    return (
        <ConsentFormsContext.Provider
            value={{
                emptyStates: emptyStates,
                consentFormsContent: consentFormsContent,
                setConsentFormsContent: setConsentFormsContent,

                //* * Current Doctor */
                doctor: doctor,
                setDoctor: setDoctor,

                //* * Sign State */
                signState: signState,
                setSignState: setSignState,
                openSignModal: openSignModal,
                setOpenSignModal: setOpenSignModal,
                signature: signature,
                setSignature: setSignature,

                //* * Constants */
                stepsEnum: stepsEnum,
                whichEnum: whichEnum,
                signEnum: signEnum,

                //* * Form States */
                formData: formData,
                setFormData: setFormData,
                handleChange: handleChange,

                //* * Form Error States */
                errors: errors,
                setErrors: setErrors,
                resetErrors: resetErrors,

                //* * Form Validation */
                validateForm: validateForm,

                //* * Guardian States */
                guardianData: guardianData,
                setGuardianData: setGuardianData,
                handleGuardianChange: handleGuardianChange,
                resetGuardianData: resetGuardianData,

                //* * Guardian Error States */
                guardianErrors: guardianErrors,
                setGuardianErrors: setGuardianErrors,
                resetGuardianErrors: resetGuardianErrors,

                //* * Communication States */
                communicationData: communicationData,
                setCommunicationData: setCommunicationData,
                handleCommunicationChange: handleCommunicationChange,

                //* * Communication Toggle States */
                togglesEnum: togglesEnum,
                communicationToggles: communicationToggles,
                setCommunicationToggles: setCommunicationToggles,
                handleCommunicationToggle: handleCommunicationToggle,

                //* * Confirm Summary States */
                confirmSummary: confirmSummary,
                setConfirmSummary: setConfirmSummary,
                handleToggle: handleToggle,

                //* * Summary Page Edit States */
                summaryEdit: summaryEdit,
                setSummaryEdit: setSummaryEdit,

                //* * Own Welcome Page Check */
                isFromOwnWelcome: isFromOwnWelcome,
                setIsFromOwnWelcome: setIsFromOwnWelcome,

                //* * All Forms Flow (Pages/Forms) */
                isAllForms: isAllForms,
                setIsAllForms: setIsAllForms,
                allFormsPages: allFormsPages,
                setAllFormsPages: setAllFormsPages,
                allSelectedForms: allSelectedForms,
                setAllSelectedForms: setAllSelectedForms,
                allFormsPatientName: allFormsPatientName,
                setAllFormsPatientName: setAllFormsPatientName,
                allFormsStepCounter: allFormsStepCounter,
                pagesStepsNo: pagesStepsNo,
                /** Child Variables */
                currentPage: currentPage,
                nextPageIndex: nextPageIndex,
                nextPage: nextPage,
                previousPageIndex: previousPageIndex,
                previousPage: previousPage,
                isLastPage: isLastPage,
                //* * Set Next/Previous Page Accordingly */
                movePage: movePage,
                //* * AllForms SignUp User Object */
                signUpUser: signUpUser,
                setSignUpUser: setSignUpUser,

                //* * Password States */
                password: password,
                setPassword: setPassword,
                confirmPassword: confirmPassword,
                setConfirmPassword: setConfirmPassword,
                passwordError: passwordError,
                setPasswordError: setPasswordError,
                confirmPasswordError: confirmPasswordError,
                setConfirmPasswordError: setConfirmPasswordError,
                validatePassword: validatePassword,
                debounceFn: debounceFn,
                setEmailError: setEmailError,
                callback: callback,

                //* * Eterno Account States */
                createAccount: createAccount,
                setCreateAccount: setCreateAccount,
                showVerification: showVerification,
                setShowVerification: setShowVerification,
                verificationCode: verificationCode,
                setVerificationCode: setVerificationCode,
                isVerificationError: isVerificationError,
                setIsVerificationError: setIsVerificationError,
                policyAccepted: policyAccepted,
                setPolicyAccepted: setPolicyAccepted,
                analyticsAccepted: analyticsAccepted,
                setAnalyticsAccepted: setAnalyticsAccepted,
                userType: userType,
                setUserType: setUserType,

                //* * Herakles Course */
                courseStartDate: courseStartDate,
                setCourseStartDate: setCourseStartDate,
                courseStartDateError: courseStartDateError,
                setCourseStartDateError: setCourseStartDateError,

                //* * Professional */
                professional: professional,
                setProfessional: setProfessional,
                professionalAddress: professionalAddress,
                setProfessionalAddress: setProfessionalAddress,
                contractContent: contractContent,
                setContractContent: setContractContent,
                formTemplateId: formTemplateId,
                setFormTemplateId: setFormTemplateId,

                //* * Consent All Forms */
                consentAllForms: consentAllForms,
                setConsentAllForms: setConsentAllForms,
                consentFormsLoading: consentFormsLoading,
                setConsentFormsLoading: setConsentFormsLoading,
            }}
        >
            {props.children}
        </ConsentFormsContext.Provider>
    );
};

export const steps = {
    PERSONAL_INFO: 'personal-information',
    CONTRACT_DETAILS: 'contract-details',
    COMMUNICATION_CONTRACT: 'communication-and-contract',
    SUMMARY: 'summary-and-consent',
};

export const guardianValue = {
    YES: 'Yes',
    NO: 'No',
};

export const toggleValues = {
    YES: 'Yes',
    NO: 'No',
};

export const verificationStatus = {
    NOT_VERIFIED: 'Not verified',
    VERIFYING: 'Verifying',
    VERIFIED: 'Verified',
};

export const signEnum = {
    SIGNED: 'signed',
    DIGITAL_SIGN: 'digital',
    DIRECT_SIGN: 'paper',
};

export const SignatureType = {
    digital: signEnum.DIGITAL_SIGN,
    paper: signEnum.DIRECT_SIGN,
    unknown: signEnum.DIGITAL_SIGN,
};

/** Communication Toggles Enum */
export const togglesEnum = {
    EMAIL_TOGGLE: 'emailToggle',
    SMS_TOGGLE: 'smsToggle',
    OTHER_TOGGLE: 'otherToggle',
};

export const signTypes = {
    IMAGE: 'image',
    TEXT: 'text',
};

export const INITIAL_STATE_GUARDIAN = {
    guardian: '',
    guardianFirstName: '',
    guardianLastName: '',
    guardianStreet: '',
    guardianZipCode: '',
    guardianCity: '',
};

export const INITIAL_STATE_GUARDIAN_ERROR = {
    guardianFirstNameError: '',
    guardianLastNameError: '',
    guardianStreetError: '',
    guardianZipCodeError: '',
    guardianCityError: '',
};

export const INITIAL_STATE_ERROR = {
    titleError: '',
    firstNameError: '',
    middleNameError: '',
    lastNameError: '',
    dobError: '',
    insuranceTypeError: '',
    insuranceNameError: '',
    phoneNoError: '',
    emailError: '',
    streetError: '',
    zipCodeError: '',
    cityError: '',
    countryError: '',
    commentError: '',
    genderError: '',
};

export const INITIAL_STATE_ALL_FORMS = [{ name: 'Welcome', route: '/all-forms-welcome', active: true, welcome: true }];

export const INITIAL_STATE_COMMUNICATION = {
    email: '',
    sms: '',
    other: '',
};

export const INITIAL_STATE_COMMUNICATION_TOGGLES = {
    emailToggle: true,
    smsToggle: false,
    otherToggle: false,
};

export const FormTypes = {
    CONSENT_BASE: 'treatment-contract-short',
    CONSENT_LG: 'treatment-contract-extended',
    CONSENT_PRIVACY: 'privacy-herakles',
    CONSENT_ADMISSION: 'admission-herakles',
    CONSENT_COMMUNICATION: 'communication-channels-herakles',
    CONSENT_BACK_COURSE: 'course-enrollment-herakles',
    ANAMNESIS: 'anamnesis',
    CUSTOM: 'custom',
};

export const ALL_FORM_PATHS = {
    'admission-herakles': [
        { path: `/consent-admission?step=${steps.PERSONAL_INFO}` },
        { path: `/consent-admission?step=${steps.CONTRACT_DETAILS}` },
        { path: `/consent-admission?step=${steps.SUMMARY}` },
    ],
    'privacy-herakles': [
        { path: `/consent-privacy?step=${steps.PERSONAL_INFO}` },
        { path: `/consent-privacy?step=${steps.CONTRACT_DETAILS}` },
        { path: `/consent-privacy?step=${steps.SUMMARY}` },
    ],
    'communication-channels-herakles': [
        { path: `/consent-communication?step=${steps.PERSONAL_INFO}` },
        { path: `/consent-communication?step=${steps.COMMUNICATION_CONTRACT}` },
        { path: `/consent-communication?step=${steps.SUMMARY}` },
    ],
    'course-enrollment-herakles': [
        { path: `/herakles-course?step=${steps.PERSONAL_INFO}` },
        { path: `/herakles-course?step=${steps.CONTRACT_DETAILS}` },
        { path: `/herakles-course?step=${steps.SUMMARY}` },
    ],
};

export const ALL_FORM_BASE_URLS = {
    ADMISSION: 'consent-admission',
    PRIVACY: 'consent-privacy',
    COMMUNICATION: 'consent-communication',
    COURSE: 'herakles-course',
};

export const UserType = {
    REGISTERED: 'registered',
    UN_REGISTERED: 'unregistered',
};

export const FormDataCategory = {
    SIGNATURE: 'signature',
    LEGAL_GUARDIAN: 'legal-guardian',
};

export const ConsentAllFormsInitialRoutes = {
    MY_APPOINTMENT: '/appointments',
    CONFIRM_APPOINTMENT: '/confirm-appointment',
    ALL_FORMS_CODE: '/all-forms-code',
};

export const useConsentFormsContext = () => useContext(ConsentFormsContext);
