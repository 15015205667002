import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './MedicationClasses';

export default function MedicationSleepingTablets({ mainData, isFromHealthData }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { setSleepingPills, sleepingPills, whichSleepingPills, setWhichSleepingPills } =
        useContext(ContextProviderTwo);
    console.debug('isFromHealthData123', isFromHealthData);

    return (
        <div className={classes.contentContainerBody}>
            {/* sleeping tablets start */}
            <div
                className={classes.fixedQuestion}
                style={{
                    marginTop: isFromHealthData ? sleepingPills && (matches ? 20 : 40) : 40,
                }}
            >
                <Typography className={classes.mainHeading}>{mainData.sleeping_pills_question[0].text}</Typography>
                <Grid
                    style={{
                        marginTop: 15,
                    }}
                    container
                >
                    {mainData &&
                        mainData.sleeping_pills_responses.map((parts) => (
                            <Grid lg="auto" sm="auto" xs={6}>
                                <Button
                                    onClick={() => {
                                        console.debug(parts.sleeping_pill_yes_no_selector);
                                        setSleepingPills(parts.sleeping_pill_yes_no_selector);
                                    }}
                                    disableRipple
                                    className={
                                        sleepingPills === parts.sleeping_pill_yes_no_selector
                                            ? clsx(classes.selectItemCard, classes.selectedCard)
                                            : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                    }
                                    style={{
                                        marginBottom: matches && 10,
                                        width: matches && '90%',
                                        display: matches ? null : 'flex',
                                        marginRight: 15,
                                    }}
                                >
                                    <div className="">
                                        <Typography className={classes.BtncontentText}>
                                            {parts.sleeping_pills_yes_no_responses[0].text}
                                        </Typography>
                                    </div>
                                </Button>
                            </Grid>
                        ))}
                </Grid>
            </div>
            {sleepingPills.toLocaleLowerCase() === 'yes' && (
                <TextField
                    id="sleepingPills"
                    label={mainData ? mainData.sleeping__pills_subquestion[0].text : t('TransferSlip')}
                    variant="filled"
                    autoComplete="off"
                    color="primary"
                    value={whichSleepingPills}
                    onChange={(event) => {
                        setWhichSleepingPills(event.target.value);
                    }}
                    classes={{
                        root: classes.textFields,
                    }}
                    style={{
                        width: matches ? '100%' : '100%',
                        flex: matches ? 1 : 0.5,
                        marginTop: !matches && 20,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                            shrink: 'shrink',
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: classes.filledInputRoot,
                            focused: classes.fieldFocused,
                            shrink: 'shrink',
                        },
                    }}
                />
            )}
        </div>
    );
}
