import { CircularProgress, Typography, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, rgbToHex, useTheme } from '@material-ui/core/styles';
import { useMemo, useRef, useState } from 'react';

import close from '@/assets/Icons/close_grey.svg';

import UnderlinedButton from '@/components/UnderlinedButton';

import Calender from '@/pages/MyAppointments/Calender';

const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        dialogRoot: {
            backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
        },
        text: {
            ...theme.typography.content,
        },
        title: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            position: 'absolute',
            marginTop: 40,
        },
        titleHeading: {
            ...theme.typography.HL1,
            [theme.breakpoints.down('xs')]: {
                fontSize: '1rem !important',
            },
        },
        progressBar: {
            color: theme.palette.common.yellow,
        },
        loadingProgress: {
            width: '100%',
            minHeight: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        submitButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.yellow,
            borderRadius: '2em',
            '&:hover': {
                backgroundColor: theme.palette.common.yellow,
            },
        },
        disabledButton: {
            backgroundColor: theme.palette.common.yellow,
        },
        buttonText: {
            ...theme.typography.buttonText,
            [theme.breakpoints.down('xs')]: {
                lineHeight: '15px',
                fontSize: '12px',
            },
        },
    };
});

export default function DialogBox({
    open,
    onClose,
    activeResponseDataCalendar,
    prepareCalendarData,
    onPress,
    startDate,
    localLoading,
    dataSet,
    mainData,
}) {
    const classes = useStyles();
    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
    const [dateOut, setDateOut] = useState('');

    const continueButtonRef = useRef();

    const isDataLoaded = useMemo(() => activeResponseDataCalendar?.status === 200, [activeResponseDataCalendar]);

    const scrollToContinueButton = () => {
        if (!dateOut) {
            setTimeout(() => {
                continueButtonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 200);
        } else continueButtonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    // calendar ends
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.dialogRoot,
            }}
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                    minHeight: 170,
                    width: '100%',
                    maxWidth: 620,
                    borderRadius: 8,
                    paddingLeft: matches ? 20 : matchesMd ? 30 : matchesLg ? 40 : matchesXl && 40,
                    paddingRight: matches ? 20 : matchesMd ? 30 : matchesLg ? 40 : matchesXl && 40,
                },
            }}
        >
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        justifyContent: 'end',
                    }}
                >
                    <img
                        alt="Close"
                        src={close}
                        style={{
                            width: 30,
                            height: 50,
                            objectFit: 'contain',
                            paddingLeft: 0,

                            paddingRight: 0,
                        }}
                    />
                </Button>
            </DialogActions>
            <DialogTitle
                id="alert-dialog-title"
                classes={{
                    root: classes.title,
                }}
            >
                <Typography className={classes.titleHeading}>
                    {dataSet ? mainData?.timeslot_heading[0].text : 'Select a new time slot'}
                </Typography>
            </DialogTitle>
            <DialogContent
                style={{
                    overflowY: 'visible',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    paddingBottom: 20,
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
            >
                <div>
                    {isDataLoaded ? (
                        <Calender
                            prepareCalendarData={prepareCalendarData}
                            data={activeResponseDataCalendar?.data}
                            setDateOut={setDateOut}
                            startDate={startDate}
                            scrollToContinueButton={scrollToContinueButton}
                            mainData={mainData}
                            dataSet={dataSet}
                        />
                    ) : (
                        <div className={classes.loadingProgress}>
                            <CircularProgress className={classes.progressBar} size={50} />
                        </div>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 20,
                        }}
                    >
                        <div
                            style={{
                                marginLeft: 20,
                                marginRight: 10,
                            }}
                        >
                            <UnderlinedButton
                                text={dataSet ? mainData?.timeslot_cancel_cta[0].text : 'Cancel'}
                                onPress={onClose}
                                noMarginTop
                            />
                        </div>

                        <Button
                            ref={continueButtonRef}
                            variant="contained"
                            type="submit"
                            disableElevation
                            disabled={localLoading || !dateOut}
                            onClick={() => {
                                onPress();
                            }}
                            classes={{
                                root: classes.submitButton,
                                label: classes.buttonText,
                            }}
                        >
                            {localLoading ? (
                                <CircularProgress />
                            ) : dataSet ? (
                                mainData?.confirm_timeslot_cta[0].text
                            ) : (
                                'Confirm New Date'
                            )}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
