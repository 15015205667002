import { useEffect } from 'react';

/**
 *  useScrollToTop scrolls to top on component mount
 */
const useScrollToTop = () => {
    useEffect(() => {
        // scroll back to top on mount
        window.scrollTo(0, 0);
    }, []);
};

export default useScrollToTop;
