import i18nCountries from 'i18n-iso-countries';
import deLocale from 'i18n-iso-countries/langs/de.json';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { useTranslation } from 'react-i18next';

i18nCountries.registerLocale(enLocale);
i18nCountries.registerLocale(deLocale);

const useI18nCountries = () => {
    const { i18n } = useTranslation();

    return i18nCountries.getNames(i18n.language, {
        select: 'official',
    });
};

export default useI18nCountries;
