import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    contentContainerHeader: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingBottom: '370px',
        marginTop: '-550px',
        [theme.breakpoints.down('lg')]: {
            marginTop: '-450px',
            paddingBottom: '-270px',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '-400px',
            paddingBottom: '220px',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            paddingBottom: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '10px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingBottom: '20px',
        },
    },

    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: '50px',
        paddingRight: '50px',

        marginTop: '40px',
        minHeight: '600px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
            marginTop: 0,
        },
    },

    bgContainer: {
        backgroundColor: theme.palette.common.lightBrown,
    },

    mainbg: {
        width: '100%',
        height: '650px',
        objectFit: 'cover',
        [theme.breakpoints.down('lg')]: {
            height: '500px',
        },
        [theme.breakpoints.down('md')]: {
            height: '450px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '300px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '200px',
        },
    },

    mainHeading: {
        ...theme.typography.BGHL1,
    },

    sectionHeading: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1.5rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },

    contentText: {
        ...theme.typography.content,
    },

    contentMainText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.125rem',
        textTransform: 'none',
        lineHeight: '1.75rem',
        color: theme.palette.common.darkGrey,
    },

    contentTextHeader: {
        ...theme.typography.content,
        maxWidth: '366px',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },

    findClassbutton: {
        height: '3.75em',
        backgroundColor: 'transparent',
        border: `solid 2px ${theme.palette.common.yellow}`,
        borderRadius: '2em',
        marginTop: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    findClassText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },

    buttonText: {
        ...theme.typography.buttonText,
    },

    submitButton: {
        ...theme.button,
    },

    buttonHeader: {
        maxWidth: '150px',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },

    titleHeading: {
        ...theme.typography.HL1,
    },

    expertHeading: {
        ...theme.typography.HL1,
        textAlign: 'center',
    },

    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: '8px',
        display: 'block',
        paddingTop: '34px',
        paddingBottom: '27px',
        paddingLeft: '30px',
        paddingRight: '30px',
    },

    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: '8px',
        display: 'block',

        paddingBottom: '20px',
        height: '100%',
    },

    doctorDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: 1,
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        padding: '10px',
    },

    titleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '15px',
        gap: '8px',
    },

    classTitle: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },

    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },

    classIcon: {
        width: '20px',
        height: '20px',
        objectFit: 'contain',
    },

    detailsButton: {
        background: 'transparent',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    buttonLabel: {
        padding: 0,
        justifyContent: 'left',
    },

    detailsLinks: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        color: theme.palette.common.darkGrey,
        marginTop: '30px',
    },

    appointmentBG: {
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        borderRadius: '50%',
        backgroundColor: theme.palette.common.lightBrown,
    },

    appointmentImg: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            width: '200px',
            height: '200px',
        },
    },

    emptyClassesImg: {
        width: '300px',
        height: '300px',
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            width: '200px',
            height: '200px',
        },
    },

    classesImg: {
        width: '50%',
        height: '50%',
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            width: '200px',
            height: '200px',
        },
    },

    doctorImg: {
        width: '100%',
        height: '220px',
        objectFit: 'cover',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
    },

    flagsContainer: {
        display: 'flex',
        gap: '15px',
        justifyContent: 'center',
    },

    progressBar: {
        color: theme.palette.common.yellow,
    },

    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
