import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    findButton: {
        ...theme.button,
    },
    buttonText: {
        ...theme.typography.buttonText,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    whiteText: {
        ...theme.typography.buttonText,
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    mediumBrown: {
        backgroundColor: theme.palette.common.mediumBrown,
        '&:hover': {
            backgroundColor: theme.palette.common.mediumBrown,
        },
    },
    yellow: {
        backgroundColor: theme.palette.common.yellow,
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    red: {
        backgroundColor: theme.palette.common.red,
        '&:hover': {
            backgroundColor: theme.palette.common.red,
        },
    },
    black: {
        backgroundColor: theme.palette.common.darkGrey,
        '&:hover': {
            backgroundColor: theme.palette.common.darkGrey,
        },
    },
    disabledButton: {
        backgroundColor: '#F9F8F4',
    },
    progressBar: {
        color: theme.palette.common.white,
    },
    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paddingClass: {
        paddingLeft: '20px',
        paddingRight: '20px',
    },
}));

const FilledButton = (
    {
        text,
        color,
        onPress,
        fullWidth,
        height,
        marginBottom,
        loading = false,
        disabled = false,
        marginTop,
        labelStyle = false,
        classNameProp,
        type = 'button',
    },
    ref
) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Button
            ref={ref}
            variant="contained"
            type={type}
            disabled={disabled}
            disableElevation
            fullWidth={fullWidth === true}
            onClick={() => {
                if (!loading) {
                    onPress();
                }
            }}
            classes={{
                root: clsx(
                    classes.findButton,
                    color === 'mediumBrown'
                        ? classes.mediumBrown
                        : color === 'black'
                          ? classes.black
                          : color === 'red'
                            ? classes.red
                            : classes.yellow
                ),
                label: clsx(
                    color === 'black' || color === 'red' ? classes.whiteText : classes.buttonText,
                    labelStyle ? classes.paddingClass : null,
                    classNameProp || null
                ),
                disabled: classes.disabledButton,
            }}
            style={{
                height: height || null,
                opacity: !disabled ? 1 : 0.4,

                marginBottom: marginBottom,
                marginTop: marginTop,
            }}
        >
            {loading === false ? (
                text
            ) : (
                <div className={classes.loadingProgress}>
                    <CircularProgress className={classes.progressBar} size={40} />
                </div>
            )}
        </Button>
    );
};

export default forwardRef(FilledButton);
