import { createContext, useState } from 'react';

export const ContextProviderTwo = createContext();

export const AuthContextTwo = (props) => {
    const [error, setError] = useState(false);
    const [errorDate2, setErrorDate2] = useState(false);
    const [errorDate3, setErrorDate3] = useState(false);
    const [errorDate4, setErrorDate4] = useState(false);
    const [mainDataArray, setMainDataArray] = useState('hello99');
    // existing condition start
    const [pastCondition, setPastCondition] = useState('');
    const [surgeries, setSurgeries] = useState('');
    const [specific, setSpecific] = useState('');
    const [surgeriesArr, setSergeriesArr] = useState([]);
    const [sergeriesArr1, setSergeriesArr1] = useState([]);

    const [coditionStatus, setConditionStatus] = useState('YES');
    const [sergeriesQuestion, setSergeriesQuestion] = useState('');
    const [sergerieDate, setSergerieDate] = useState('');
    const [sergeryDataAll, setSergeryDataAll] = useState([]);
    const [newCondition, setNewCondition] = useState([]);
    const [editState, setEditState] = useState(false);
    const [cancelEdit, setCancelEdit] = useState({});
    const [editPencil, setEditPencil] = useState(false);
    const [surgeryData, setSeugeryData] = useState({});
    const [whenPastCondition, setWhenPastCondition] = useState('');
    const [imaging, setImaging] = useState('');
    const [imagingQuestion, setImagingQuestion] = useState('');
    const [imagingDate, setImagingDate] = useState('');
    const [oldData, setOldData] = useState([]);
    const [compulsoryOther, setCompulsoryOther] = useState(false);
    const [newFromTrue, setNewFromTrue] = useState(false);
    // existing condition end
    const [errPhoneNumber, setErrPhoneNumber] = useState('');
    const [errorDate, setErrorDate] = useState(false);
    // professional Info
    const [nameOfDoctor, setNameOfDoctor] = useState('');

    // personal information start
    const [efirstName, seteFirstName] = useState('');
    const [elastName, seteLastName] = useState('');
    const [emiddleName, seteMiddleName] = useState('');
    const [eemail, seteEmail] = useState('');
    const [estreetMainAdress, seteSreetMainAdress] = useState('');
    const [ezipMainAdress, seteZipMainAdress] = useState('');
    const [ecityMainAdress, seteCityMainAdress] = useState('');
    const [dob, setDOB] = useState('');
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');
    const [mobileCode, setMobileCode] = useState('+49');
    const [anchorE7, setanchorE7] = useState(null);
    const [mobileNumber, setMobileNumber] = useState('');
    const [privateTitle, setPrivateTitle] = useState('0');
    const [anchorE6, setAnchorE6] = useState(null);
    const [privateGender, setPrivateGender] = useState('0');
    const [anchorE5, setAnchorE5] = useState(null);
    const [pcountry, setPcountry] = useState('');
    const [epcountry, setePcountry] = useState('');
    const [egender, seteGender] = useState('');
    const [etitle, seteTitle] = useState('');
    const [pcode, setPcode] = useState('DE');
    const [epcode, setePcode] = useState('DE');
    const [occuption, setOccuption] = useState('');
    const [countrySelect, setCountrySelect] = useState({});
    const [testPerfomed, setTestPerfomed] = useState('');
    const [testPerfomedDate, setTestPerfomedDate] = useState('');
    const [testPerfomedArr, setTestPerfomedArr] = useState([]);
    const [testPerfomedArrEasy, setTestPerfomedArrEasy] = useState([]);
    const [testEdit, setTestEdit] = useState(false);
    const [cancelTestEdit, setCancelTestEdit] = useState({});
    const [imagingPerfomed, setImagingPerfomed] = useState('');
    const [imagingPerfomedDate, setImagingPerfomedDate] = useState('');
    const [imagingPerfomedArr, setImagingPerfomedArr] = useState([]);
    const [imagingPerfomedArrEasy, setImagingPerfomedArrEasy] = useState([]);
    const [imagingEdit, setImagingEdit] = useState(false);
    const [cancelImagingEdit, setCancelImagingEdit] = useState({});
    const [otherFieldError, setOtherFieldError] = useState('');
    const [other, setOther] = useState(false);

    // personal information ends

    // personal information health-data
    const [occupation, setOccupation] = useState('');

    // your body
    const [genderQuestion, setGenderQuestion] = useState('');
    const [gainedWeight, setGainedWeight] = useState('');
    const [pregnancy, setPregnancy] = useState('');
    const [pregnancyCount, setPregnancyCount] = useState('');
    const [KGs, setKGs] = useState();
    const [weeks, setWeeks] = useState();
    const [value__, setValue__] = useState('');
    const [valueHeight, setValueHeight] = useState('');
    const [bloodType, setBloodType] = useState('');
    const [disability, setDisability] = useState('');
    const [disabilityValue, setDisabilityValue] = useState('');
    const [cholesterol, setCholesterol] = useState('');
    const [bloodPressure, setBloodPressure] = useState('');
    const [bloodPressureValue, setBloodPressureValue] = useState('');
    const [sBP, setSBP] = useState('');
    const [dBP, setDBP] = useState('');
    // your body ends
    // current problem
    const [anyProblem, setAnyProblem] = useState('');
    const [whichOne, setWhichOne] = useState('');
    // current ends
    // medication starts
    const [bloodThinner, setBloodThinner] = useState('');
    const [whichBloodThinner, setWhichBloodThinner] = useState('');
    const [sleepingPills, setSleepingPills] = useState('');
    const [whichSleepingPills, setWhichSleepingPills] = useState('');
    const [medicationType, setMedicationType] = useState('');
    const [whichMedication, setWhichMedication] = useState('');
    const [allData, setAllData] = useState([]);
    const [editOptions, setEditOptions] = useState(false);
    // medication ends
    // allergy starts
    const [anyAllergy, setAnyAllergy] = useState('');
    const [type, setType] = useState('');
    const [reaction, setReaction] = useState('');
    const [value, setValue] = useState('');
    const [allergyArrCon, setAllergyArr] = useState({});
    const [data2Con, setData2] = useState([]);
    const [continueEdit, setContinueEdit] = useState(false);
    const [allergiesTwo, setAllergiesTwo] = useState({
        anyAllergy: '',
        allergyType: '',
        allergyArr: {},
        data2: [],
    });
    // allergy ends
    // family states
    const [relativeDisease, setRelativeDisease] = useState('');
    const [whichRelative, setWhichRelative] = useState('');
    const [relativeAge, setRelativeAge] = useState('');
    const [allDisease, setAllDisease] = useState([]);
    const [currentResponse, setCurrentResponse] = useState('YES');
    const [data2_, setData2_] = useState([]);
    const [allDisease1, setAllDisease1] = useState([]);
    const [newArr, setNewArr] = useState([]);
    // const [editOptions, setEditOptions] = useState(false);
    const [whichOther, setWhichOther] = useState('');
    const [addingData, setAddingData] = useState({});
    // family end
    // life style states
    const [whichOtherDietSubQuestion, setWhichOtherDietSubQuestion] = useState('');
    const [multipleDiet, setMultipleDiet] = useState([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [whichDiet, setWhichDiet] = useState('');
    const [sports, setSports] = useState('');
    const [value_, setValue_] = useState('');
    const [exercise, setExercise] = useState('');
    const [smoke, setSmoke] = useState('');
    const [smokePerDay, setSmokePerDay] = useState('');
    const [smokeHowLong, setSmokeHowLong] = useState('');
    const [alcohol, setAlcohol] = useState('');
    const [alcoholKind, setAlcoholKind] = useState('');
    const [alcoholPerWeek, setAlcoholPerWeek] = useState('');
    const [drugs, setDrugs] = useState('');
    const [rSelect, setRSelect] = useState('');
    const [nightSleep, setNightSleep] = useState('');
    const [snoreSelect, setSnoreSelect] = useState('');
    const [grindSelect, setGrindSelect] = useState('');
    const [restedSelect, setRestedSelect] = useState('');
    const [otherInfo, setOtherInfo] = useState('');
    const [sleepHours, setSleepHours] = useState('');
    const [stressSelect, setStressSelect] = useState('');
    const [moodSelect, setMoodSelect] = useState('');
    const [energySelect, setEnergySelect] = useState('');
    const [whichOneDrugsLocal, setWhichOneDrugsLocal] = useState([{ whichOne: '', howOftenDrugs: '' }]);

    // life style end
    // account page starts
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [wantAccount, setWantAccount] = useState('');
    const [passwordValid, setPasswordValid] = useState({
        characters: false,
        number: false,
        special: false,
        password: '',
    });
    const [validUntil, setValidUntil] = useState('');
    const [insuranceCompany, setInsuranceCompany] = useState('');
    const [insuranceId, setInsuranceId] = useState('');
    const [insuranceNumber, setInsuranceNumber] = useState('');
    const [insuranceStatus, setInsuranceStatus] = useState('');
    const [privateText, setPrivateText] = useState('0');
    const [insuranceType, setInsuraceType] = useState('');
    const [checked, setchecked] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [verifiactionPortion, setVerificationPortion] = useState(false);
    const [user, setUser] = useState(null);

    // account page ends

    const [body1, setBody1] = useState(null);
    const [body2, setBody2] = useState(null);
    const [body3, setBody3] = useState(null);
    const [body4, setBody4] = useState(null);
    const [body5, setBody5] = useState(null);
    const [body6, setBody6] = useState(null);
    const [body7, setBody7] = useState(null);
    const [body8, setBody8] = useState(null);
    const [allDataApi, setAllDataApi] = useState(null);
    const [hasAccount, setHasAccount] = useState(false);
    const [fromAppointment, setFromAppointment] = useState(false);
    const [allDoctorsDataApi, setAllDoctorsDataApi] = useState([]);
    const [selectedDoctorGlobal, setSelectedDoctorGlobal] = useState([]);
    const [apppointmentDoctorInfo, setAppointmentDoctorInfo] = useState(null);
    const [isConcent, setIsConcent] = useState(false);
    const [apiData, setApiData] = useState({});
    const [isSaveLifeStyle, setIsSaveLifeStyle] = useState(false);
    const [errEmail, setErrEmail] = useState('');
    const [errGender, setErrGender] = useState('');
    const [errFirstName, setErrFirstName] = useState('');
    const [errLastName, setErrLastName] = useState('');
    const [errDOB, setErrDOB] = useState('');
    const [errStreet, setErrorStreet] = useState('');
    const [errCity, setErrorCity] = useState('');
    const [errZip, setErrZip] = useState('');
    const [fromLocation, setFromLocation] = useState(false);
    const emptyStates = () => {
        setBody5(null);
        setBody6(null);
        setNameOfDoctor('');
        seteFirstName('');
        seteLastName('');
        seteMiddleName('');
        seteEmail('');
        seteSreetMainAdress('');
        seteZipMainAdress('');
        seteCityMainAdress('');
        setDOB('');
        setMobileCode('+49');
        setMobileNumber('');
        setPrivateTitle('');
        setPrivateGender('');
        setPcountry('');
        setePcountry('');
        seteGender('');
        seteTitle('');
        setOccuption('');
        setCountrySelect('');
        setGenderQuestion('');
        setGainedWeight('');
        setPregnancy('');
        setPregnancyCount('');
        setKGs('');
        setWeeks('');
        setValue__('');
        setValueHeight('');
        setAnyProblem('');
        setWhichOne('');
        setBloodThinner('');
        setWhichBloodThinner('');
        setMedicationType('');
        setWhichMedication('');
        setAllData([]);
        setEditOptions(false);
        setAnyAllergy('');
        setType('');
        setReaction('');
        setValue('');
        setData2([]);
        setAllergyArr({});
        setPastCondition('');
        setSurgeries('');
        setSergeriesArr([]);
        setSpecific('');
        setConditionStatus('YES');
        setSergeriesQuestion('');
        setSergerieDate('');
        setSergeryDataAll([]);
        setNewCondition([]);
        setEditState(false);
        setCancelEdit({});
        setEditPencil(false);
        setSeugeryData({});
        setWhenPastCondition('');
        setTestPerfomed('');
        setTestPerfomedDate('');
        setTestPerfomedArr([]);
        setTestPerfomedArrEasy([]);
        setTestEdit(false);
        setCancelTestEdit({});
        setImagingPerfomed('');
        setImagingPerfomedDate('');
        setImagingPerfomedArr([]);
        setImagingPerfomedArrEasy([]);
        setImagingEdit('');
        setCancelImagingEdit('');
        setAddingData({});
        setRelativeDisease('');
        setWhichRelative('');
        setRelativeAge('');
        setAllDisease([]);
        setCurrentResponse('YES');
        setNewArr([]);
        setWhichOther('');
        setData2_([]);
        setAllDisease1([]);
        setMultipleDiet('');
        setSelectedCount(0);
        setWhichDiet('');
        setSports('');
        setValue_('');
        setExercise('');
        setSmoke('');
        setSmokePerDay('');
        setSmokeHowLong('');
        setAlcohol('');
        setAlcoholKind('');
        setAlcoholPerWeek('');
        setDrugs('');
        setWantAccount('');
        setchecked(false);
        setLocalLoading(false);
        setVerificationPortion(false);
        setShowPassword(false);
        setConfirmPassword('');
        setPasswordValid({
            characters: false,
            number: false,
            special: false,
            password: '',
        });
        setValidUntil('');
        setInsuranceCompany('');
        setInsuranceId('');
        setInsuranceNumber('');
        setInsuranceStatus('');
        setPrivateText('0');
        setInsuraceType('');
        setWhichSleepingPills('');
        setSleepingPills('');
        setWhichOtherDietSubQuestion('');

        // YOUR BODY STATES
        setGenderQuestion('');
        setGainedWeight('');
        setPregnancy('');
        setPregnancyCount('');
        setKGs('');
        setWeeks('');
        setValue__('');
        setValueHeight('');
        setBloodType('');
        setDisability('');
        setDisabilityValue('');
        setCholesterol('');
        setBloodPressure('');
        setBloodPressureValue('');
        setSBP('');
        setDBP('');
        // YOUR BODY STATES

        // current problem
        setAnyProblem('');
        setWhichOne('');
        // current ends
        // medication starts
        setBloodThinner('');
        setWhichBloodThinner('');
        setSleepingPills('');
        setWhichSleepingPills('');
        setMedicationType('');
        setWhichMedication('');
        setAllData('');
        setEditOptions('');
        // medication ends
        // allergy starts
        setAnyAllergy('');
        setType('');
        setReaction('');
        setValue('');
        setAllergyArr('');
        setData2('');
        setContinueEdit('');
        setAllergiesTwo({
            anyAllergy: '',
            allergyType: '',
            allergyArr: {},
            data2: [],
        });
        // allergy ends
        // family states
        setRelativeDisease('');
        setWhichRelative('');
        setRelativeAge('');
        setAllDisease('');
        setCurrentResponse('');
        setData2_('');
        setNewArr('');

        setWhichOther('');
        setAddingData({});
        // family end
        // life style states
        setWhichOtherDietSubQuestion('');
        setMultipleDiet([]);
        setSelectedCount(0);
        setWhichDiet('');
        setSports('');
        setValue_('');
        setExercise('');
        setSmoke('');
        setSmokePerDay('');
        setSmokeHowLong('');
        setAlcohol('');
        setAlcoholKind('');
        setAlcoholPerWeek('');
        setDrugs('');
        setRSelect('');
        setNightSleep('');
        setSnoreSelect('');
        setGrindSelect('');
        setRestedSelect('');
        setOtherInfo('');
        setSleepHours('');
        setStressSelect('');
        setMoodSelect('');
        setEnergySelect('');
        setWhichOneDrugsLocal([{ whichOne: '', howOftenDrugs: '' }]);

        // life style end
    };

    // edited

    return (
        <ContextProviderTwo.Provider
            value={{
                errZip: errZip,
                setErrZip: setErrZip,
                errCity: errCity,
                setErrorCity: setErrorCity,
                errStreet: errStreet,
                setErrorStreet: setErrorStreet,
                errDOB: errDOB,
                setErrDOB: setErrDOB,
                errLastName: errLastName,
                setErrLastName: setErrLastName,
                errFirstName: errFirstName,
                setErrFirstName: setErrFirstName,
                errGender: errGender,
                setErrGender: setErrGender,
                errEmail: errEmail,
                setErrEmail: setErrEmail,
                compulsoryOther: compulsoryOther,
                setCompulsoryOther: setCompulsoryOther,
                other: other,
                setOther: setOther,
                otherFieldError: otherFieldError,
                setOtherFieldError: setOtherFieldError,
                setApiData: setApiData,
                apiData: apiData,
                setWhichOneDrugsLocal: setWhichOneDrugsLocal,
                whichOneDrugsLocal: whichOneDrugsLocal,
                isSaveLifeStyle: isSaveLifeStyle,
                setIsSaveLifeStyle: setIsSaveLifeStyle,
                isConcent: isConcent,
                setIsConcent: setIsConcent,
                setError: setError,
                error: error,
                errorDate2: errorDate2,
                setErrorDate2: setErrorDate2,
                errorDate3: errorDate3,
                setErrorDate3: setErrorDate3,
                errorDate4: errorDate4,
                setErrorDate4: setErrorDate4,
                whichOtherDietSubQuestion: whichOtherDietSubQuestion,
                setWhichOtherDietSubQuestion: setWhichOtherDietSubQuestion,
                apppointmentDoctorInfo: apppointmentDoctorInfo,
                setAppointmentDoctorInfo: setAppointmentDoctorInfo,
                selectedDoctorGlobal: selectedDoctorGlobal,
                setSelectedDoctorGlobal: setSelectedDoctorGlobal,
                allDoctorsDataApi: allDoctorsDataApi,
                setAllDoctorsDataApi: setAllDoctorsDataApi,
                emptyStates: emptyStates,
                fromAppointment: fromAppointment,
                setFromAppointment: setFromAppointment,
                setAllDataApi: setAllDataApi,
                allDataApi: allDataApi,
                setMainDataArray: setMainDataArray,
                mainDataArray: mainDataArray,
                // existing condition
                setPastCondition: setPastCondition,
                pastCondition: pastCondition,
                setSurgeries: setSurgeries,
                setSergeriesArr: setSergeriesArr,
                setSpecific: setSpecific,
                sergeriesArr1: sergeriesArr1,
                setSergeriesArr1: setSergeriesArr1,
                setConditionStatus: setConditionStatus,
                setSergeriesQuestion: setSergeriesQuestion,
                setSergerieDate: setSergerieDate,
                setSergeryDataAll: setSergeryDataAll,
                setNewCondition: setNewCondition,
                setEditState: setEditState,
                setCancelEdit: setCancelEdit,
                setEditPencil: setEditPencil,
                setSeugeryData: setSeugeryData,
                setImaging: setImaging,
                setImagingQuestion: setImagingQuestion,
                setImagingDate: setImagingDate,
                setOldData: setOldData,
                oldData: oldData,
                surgeries: surgeries,
                specific: specific,
                surgeriesArr: surgeriesArr,
                coditionStatus: coditionStatus,
                sergeriesQuestion: sergeriesQuestion,
                sergerieDate: sergerieDate,
                sergeryDataAll: sergeryDataAll,
                newCondition: newCondition,
                editState: editState,
                cancelEdit: cancelEdit,
                editPencil: editPencil,
                surgeryData: surgeryData,
                imaging: imaging,
                imagingQuestion: imagingQuestion,
                imagingDate: imagingDate,
                setWhenPastCondition: setWhenPastCondition,
                whenPastCondition: whenPastCondition,
                // existing condition ends
                // personal Info
                efirstName: efirstName,
                seteFirstName: seteFirstName,
                elastName: elastName,
                seteLastName: seteLastName,
                emiddleName: emiddleName,
                seteMiddleName: seteMiddleName,
                eemail: eemail,
                seteEmail: seteEmail,
                estreetMainAdress: estreetMainAdress,
                seteSreetMainAdress: seteSreetMainAdress,
                ezipMainAdress: ezipMainAdress,
                seteZipMainAdress: seteZipMainAdress,
                ecityMainAdress: ecityMainAdress,
                seteCityMainAdress: seteCityMainAdress,
                dob: dob,
                setDOB: setDOB,
                mobileCodeWidth: mobileCodeWidth,
                setMobileCodeWidth: setMobileCodeWidth,
                mobileCode: mobileCode,
                setMobileCode: setMobileCode,
                anchorE7: anchorE7,
                setanchorE7: setanchorE7,
                mobileNumber: mobileNumber,
                setMobileNumber: setMobileNumber,
                privateTitle: privateTitle,
                setPrivateTitle: setPrivateTitle,
                anchorE6: anchorE6,
                setAnchorE6: setAnchorE6,
                privateGender: privateGender,
                setPrivateGender: setPrivateGender,
                anchorE5: anchorE5,
                setAnchorE5: setAnchorE5,
                pcountry: pcountry,
                setPcountry: setPcountry,
                epcountry: epcountry,
                setePcountry: setePcountry,
                egender: egender,
                seteGender: seteGender,
                etitle: etitle,
                seteTitle: seteTitle,
                pcode: pcode,
                setPcode: setPcode,
                epcode: epcode,
                setePcode: setePcode,
                occuption: occuption,
                setOccuption: setOccuption,
                countrySelect: countrySelect,
                setCountrySelect: setCountrySelect,
                setTestPerfomed: setTestPerfomed,
                setTestPerfomedDate: setTestPerfomedDate,
                setTestPerfomedArr: setTestPerfomedArr,
                setTestPerfomedArrEasy: setTestPerfomedArrEasy,
                setTestEdit: setTestEdit,
                setCancelTestEdit: setCancelTestEdit,
                setImagingPerfomed: setImagingPerfomed,
                setImagingPerfomedDate: setImagingPerfomedDate,
                setImagingPerfomedArr: setImagingPerfomedArr,
                setImagingPerfomedArrEasy: setImagingPerfomedArrEasy,
                setImagingEdit: setImagingEdit,
                setCancelImagingEdit: setCancelImagingEdit,
                imagingPerfomedDate: imagingPerfomedDate,
                imagingPerfomedArr: imagingPerfomedArr,
                imagingPerfomedArrEasy: imagingPerfomedArrEasy,
                imagingEdit: imagingEdit,
                cancelImagingEdit: cancelImagingEdit,
                imagingPerfomed: imagingPerfomed,
                testEdit: testEdit,
                cancelTestEdit: cancelTestEdit,
                testPerfomed: testPerfomed,
                testPerfomedDate: testPerfomedDate,
                testPerfomedArr: testPerfomedArr,
                testPerfomedArrEasy: testPerfomedArrEasy,
                // personal info ends
                // your body starts
                genderQuestion: genderQuestion,
                setGenderQuestion: setGenderQuestion,
                gainedWeight: gainedWeight,
                setGainedWeight: setGainedWeight,
                pregnancy: pregnancy,
                setPregnancy: setPregnancy,
                pregnancyCount: pregnancyCount,
                setPregnancyCount: setPregnancyCount,
                KGs: KGs,
                setKGs: setKGs,
                weeks: weeks,
                setWeeks: setWeeks,
                value__: value__,
                setValue__: setValue__,
                valueHeight: valueHeight,
                setValueHeight: setValueHeight,
                bloodType: bloodType,
                setBloodType: setBloodType,
                disability: disability,
                setDisability: setDisability,
                disabilityValue: disabilityValue,
                setDisabilityValue: setDisabilityValue,
                cholesterol: cholesterol,
                setCholesterol: setCholesterol,
                bloodPressure: bloodPressure,
                setBloodPressure: setBloodPressure,
                bloodPressureValue: bloodPressureValue,
                setBloodPressureValue: setBloodPressureValue,
                sBP: sBP,
                setSBP: setSBP,
                dBP: dBP,
                setDBP: setDBP,
                // your body ends
                // current problem
                anyProblem: anyProblem,
                setAnyProblem: setAnyProblem,
                whichOne: whichOne,
                setWhichOne: setWhichOne,
                // current ends
                // medication starts
                bloodThinner: bloodThinner,
                setBloodThinner: setBloodThinner,
                whichBloodThinner: whichBloodThinner,
                setSleepingPills: setSleepingPills,
                sleepingPills: sleepingPills,
                whichSleepingPills: whichSleepingPills,
                setWhichSleepingPills: setWhichSleepingPills,
                setWhichBloodThinner: setWhichBloodThinner,
                medicationType: medicationType,
                setMedicationType: setMedicationType,
                whichMedication: whichMedication,
                setWhichMedication: setWhichMedication,
                allData: allData,
                setAllData: setAllData,
                editOptions: editOptions,
                setEditOptions: setEditOptions,
                // medication ends
                // allergy
                anyAllergy: anyAllergy,
                setAnyAllergy: setAnyAllergy,
                type: type,
                setType: setType,
                reaction: reaction,
                setReaction: setReaction,
                value: value,
                setValue: setValue,
                allergyArrCon: allergyArrCon,
                setAllergyArr: setAllergyArr,
                data2Con: data2Con,
                setData2: setData2,
                continueEdit: continueEdit,
                setContinueEdit: setContinueEdit,
                setAllergiesTwo: setAllergiesTwo,
                allergiesTwo: allergiesTwo,
                // allergy ends
                // family style starts
                relativeDisease: relativeDisease,
                setRelativeDisease: setRelativeDisease,
                whichRelative: whichRelative,
                setWhichRelative: setWhichRelative,
                relativeAge: relativeAge,
                setRelativeAge: setRelativeAge,
                allDisease: allDisease,
                setAllDisease: setAllDisease,
                currentResponse: currentResponse,
                setCurrentResponse: setCurrentResponse,
                data2_: data2_,
                setData2_: setData2_,
                allDisease1: allDisease1,
                setAllDisease1: setAllDisease1,
                newArr: newArr,
                setNewArr: setNewArr,
                setAddingData: setAddingData,
                addingData: addingData,
                setWhichOther: setWhichOther,
                whichOther: whichOther,
                // family ends
                // life starts
                multipleDiet: multipleDiet,
                setMultipleDiet: setMultipleDiet,
                selectedCount: selectedCount,
                setSelectedCount: setSelectedCount,
                whichDiet: whichDiet,
                setWhichDiet: setWhichDiet,
                sports: sports,
                setSports: setSports,
                value_: value_,
                setValue_: setValue_,
                exercise: exercise,
                setExercise: setExercise,
                smoke: smoke,
                setSmoke: setSmoke,
                smokePerDay: smokePerDay,
                setSmokePerDay: setSmokePerDay,
                smokeHowLong: smokeHowLong,
                setSmokeHowLong: setSmokeHowLong,
                alcohol: alcohol,
                setAlcohol: setAlcohol,
                alcoholKind: alcoholKind,
                setAlcoholKind: setAlcoholKind,
                alcoholPerWeek: alcoholPerWeek,
                setAlcoholPerWeek: setAlcoholPerWeek,
                drugs: drugs,
                setDrugs: setDrugs,
                rSelect: rSelect,
                setRSelect: setRSelect,
                nightSleep: nightSleep,
                setNightSleep: setNightSleep,
                snoreSelect: snoreSelect,
                setSnoreSelect: setSnoreSelect,
                grindSelect: grindSelect,
                setGrindSelect: setGrindSelect,
                restedSelect: restedSelect,
                setRestedSelect: setRestedSelect,
                otherInfo: otherInfo,
                setOtherInfo: setOtherInfo,
                sleepHours: sleepHours,
                setSleepHours: setSleepHours,
                setStressSelect: setStressSelect,
                stressSelect: stressSelect,
                setMoodSelect: setMoodSelect,
                moodSelect: moodSelect,
                setEnergySelect: setEnergySelect,
                energySelect: energySelect,
                // life ends
                // account starts
                showPassword: showPassword,
                setShowPassword: setShowPassword,
                confirmPassword: confirmPassword,
                setConfirmPassword: setConfirmPassword,
                wantAccount: wantAccount,
                setWantAccount: setWantAccount,
                passwordValid: passwordValid,
                setPasswordValid: setPasswordValid,
                validUntil: validUntil,
                setValidUntil: setValidUntil,
                insuranceCompany: insuranceCompany,
                setInsuranceCompany: setInsuranceCompany,
                insuranceId: insuranceId,
                setInsuranceId: setInsuranceId,
                insuranceNumber: insuranceNumber,
                setInsuranceNumber: setInsuranceNumber,
                insuranceStatus: insuranceStatus,
                setInsuranceStatus: setInsuranceStatus,
                privateText: privateText,
                setPrivateText: setPrivateText,
                checked: checked,
                setInsuraceType: setInsuraceType,
                insuranceType: insuranceType,
                setchecked: setchecked,
                localLoading: localLoading,
                setLocalLoading: setLocalLoading,
                verifiactionPortion: verifiactionPortion,
                setVerificationPortion: setVerificationPortion,
                setUser: setUser,
                user: user,
                // account ends
                setNameOfDoctor: setNameOfDoctor,
                nameOfDoctor: nameOfDoctor,
                // summrary API
                body1: body1,
                body2: body2,
                body3: body3,
                body4: body4,
                body5: body5,
                body6: body6,
                body7: body7,
                body8: body8,
                setBody1: setBody1,
                setBody2: setBody2,
                setBody3: setBody3,
                setBody4: setBody4,
                setBody5: setBody5,
                setBody6: setBody6,
                setBody7: setBody7,
                setBody8: setBody8,
                setHasAccount: setHasAccount,
                hasAccount: hasAccount,
                occupation: occupation,
                setOccupation: setOccupation,
                newFromTrue: newFromTrue,
                setNewFromTrue: setNewFromTrue,
                errorDate: errorDate,
                setErrorDate: setErrorDate,
                fromLocation: fromLocation,
                setFromLocation: setFromLocation,
                errPhoneNumber: errPhoneNumber,
                setErrPhoneNumber: setErrPhoneNumber,
            }}
        >
            {props.children}
        </ContextProviderTwo.Provider>
    );
};
