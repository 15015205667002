import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingLeft: 30,
    },

    contentMainText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.125rem',
        textTransform: 'none',
        lineHeight: '1.75rem',
        color: theme.palette.common.darkGrey,
        paddingTop: 30,

        paddingRight: 30,
    },
    emptyClassesImg: {
        width: 248,
        // height: 211,
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            width: 252,
            height: 252,
        },
        [theme.breakpoints.down('sm')]: {
            width: 252,
            height: 252,
        },
        [theme.breakpoints.down('xs')]: {
            width: 150,
            height: 150,
        },
    },
}));

const RenderEmpty = ({ mainData }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div
            className={classes.cardContent}
            style={{
                marginTop: 23,
            }}
        >
            <Grid container direction={matches ? 'column-reverse' : 'row'}>
                <Grid item md={6} xs={12}>
                    <div style={{ width: '100%' }}>
                        <Typography className={classes.contentMainText} style={{ marginBottom: 13 }}>
                            {mainData
                                ? mainData?.no_active_professionals[0].text
                                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
                        </Typography>
                    </div>
                </Grid>
                <Grid item md={6} xs={12}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: matches ? 'flex-start' : 'end',
                        }}
                    >
                        <img src={mainData?.discover_others_image1.url} className={classes.emptyClassesImg} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default RenderEmpty;
