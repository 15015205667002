import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const Tooltip = ({ text, show }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.tooltip, show && classes.showTooltip)}>
            <Typography className={classes.text}>{text}</Typography>
            <svg className={classes.triangle} width="16" height="10" viewBox="0 0 16 10" fill="none">
                <path
                    d="M7.25671 9.17412C7.65395 9.6155 8.34605 9.6155 8.74329 9.17412L15.4979 1.66897C16.0771 1.02544 15.6204 0 14.7546 0H1.24536C0.379591 0 -0.0771019 1.02544 0.502068 1.66896L7.25671 9.17412Z"
                    fill="#2E2E2E"
                />
            </svg>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    tooltip: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '11px 15px',
        width: '250px',
        left: '50%',
        top: '-1rem',
        transform: 'translate(-50%, -100%)',
        background: '#2E2E2E',
        borderRadius: '5px',
        zIndex: 3,
        opacity: 0,
        transition: 'opacity 0.2s ease',
        pointerEvents: 'none',
    },
    triangle: {
        position: 'absolute',
        width: '18px',
        height: '10px',
        left: '50%',
        bottom: '-8px',
        transform: 'translate(-50%, 0)',
    },
    text: {
        ...theme.typography.subheading,
        fontFamily: 'MessinaSans-Regular',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#FFFFFF',
        textAlign: 'start',
    },
    showTooltip: {
        opacity: 1,
    },
}));

export default Tooltip;
