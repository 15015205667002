import { useCallback, useContext, useEffect } from 'react';

import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { ContextProvider } from '@/context/ProjectContext';

import { getUserInsurance } from '@/service/AnamnesisSectionAPI/AccountHealthAPI';

import { formatUserRawDate } from '@/utils/helpers.js';

/**
 * Set User Health Insurance In ConsentForms Context
 */
const useHealthInsurance = () => {
    const { formData, setFormData } = useContext(ConsentFormsContext);
    const {
        state: { userAccount, usersession },
    } = useContext(AuthContext);
    const {
        // Getters
        validUntil,
        insuranceCompany,
        insuranceId,
        insuranceNumber,
        insuranceStatus,
        insuraceType,
        // Setters
        setValidUntil,
        setInsuranceCompany,
        setInsuranceId,
        setInsuranceNumber,
        setInsuranceStatus,
        setInsuraceType,
    } = useContext(ContextProviderTwo);
    const { languageGlobal } = useContext(ContextProvider);

    const { insuranceName: insuranceNameForm, insuranceType: insuranceTypeForm } = formData;

    const setInsurance = useCallback(
        (insuranceName, insuranceType) => {
            setFormData((prev) => ({
                ...prev,
                ...(!insuranceNameForm && { insuranceName: insuranceName }),
                ...(!insuranceTypeForm && { insuranceType: insuranceType }),
            }));
        },
        [insuranceNameForm, insuranceTypeForm, setFormData]
    );

    const getInsurance = async () => {
        try {
            const res = await getUserInsurance(userAccount, usersession);

            if (typeof res === 'object' && 'data' in res) {
                setInsurance(res.data?.insurance_company, res?.data?.insurance_type);
                setInsuranceCompany(res?.data?.insurance_company);
                setInsuranceNumber(res?.data?.insurance_id);
                setInsuraceType(res?.data?.insurance_type);
                setInsuranceId(res?.data?.insurance_id);
                setInsuranceStatus(res?.data?.insurance_status);
                setValidUntil(formatUserRawDate(res?.data?.insurance_valid_until), languageGlobal);
            }
        } catch (error) {
            console.debug(error);
        }
    };

    useEffect(() => {
        if (validUntil || insuranceCompany || insuranceId || insuranceNumber || insuranceStatus || insuraceType) {
            return;
        }

        getInsurance();
    }, [userAccount, usersession]);
};

export default useHealthInsurance;
