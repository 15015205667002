import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import leftAngle from '@/assets/Icons/leftAngle.png';
import rightAngle from '@/assets/Icons/rightAngle.png';

import MobileCalender from './Calender/MobileCalender';
import './Calender/NoScrollBar.css';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        position: 'relative',
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
    },
    heading: {
        ...theme.typography.HL1,
        fontWeight: 'bold',
    },
    subheading: {
        ...theme.typography.HL4,
        color: '#000',
        fontSize: 14,
    },
    categoryHeading: {
        ...theme.typography.HL5,
    },
    contentText: {
        ...theme.typography.HL5,
        textAlign: 'center',
    },
    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            height: 370,
        },
    },
    cardContentBottomClasses: {
        position: 'relative',
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        paddingBottom: 10,
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    doctorImg: {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    findClassbutton: {
        height: '3.75em',
        width: '100%',
        backgroundColor: 'transparent',
        border: `solid 2px ${theme.palette.common.brown}`,
        marginTop: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    findClassText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    priceText: {
        ...theme.typography.HL1,
    },
    formControl: {
        width: '100%',
        backgroundColor: 'white',
        height: '4.3em',
        justifyContent: 'space-between',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    downArrow: {
        height: '0.75em',
        marginRight: 15,
    },
    menuItem: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
    },
    textFields: {
        height: '3.75em',
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
    },
    blackCircle: {
        backgroundColor: theme.palette.common.darkGrey,
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    greenCircle: {
        backgroundColor: '#5ba668',
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    passwordText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    passwordValidText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },

    selectionPopup: {
        height: 240,
        width: '100%',
        background: 'white',
        top: '2.5em',
        paddingTop: 1,
        zIndex: 9999,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            height: 280,
        },
        [theme.breakpoints.down('xs')]: {
            height: 100,
        },
    },
    closeButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    selectionContainer: {
        marginTop: 28,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            height: 18,
        },
        [theme.breakpoints.down('xs')]: {
            height: 18,
        },
    },
    selectionTitle: {
        ...theme.typography.content,

        textDecoration: 'underline',
    },
    userData: {
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',

        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    progressInner: {
        width: 300,
        [theme.breakpoints.down('sm')]: {
            width: 150,
        },
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 18,
        borderRadius: '2em',
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    disabledButton: {
        backgroundColor: theme.palette.common.yellow,
    },
    buttonText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'uppercase',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    icon: {
        borderRadius: '50%',
        width: 22,
        height: 22,
        backgroundColor: theme.palette.common.lightBrown,

        'input:hover ~ &': {
            backgroundColor: theme.palette.common.lightBrown,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.common.yellow,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 22,
            height: 22,
            backgroundImage: 'radial-gradient(#000,#000 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    radioRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    mainCenterCircle: {
        display: 'flex',
        height: 270,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    buttonContent: {
        marginTop: 2,
        marginLeft: 10,
    },
    calenderContainer: {
        display: 'flex',
    },
    calenderContainerMain: {
        display: 'contents',
        backgroundColor: 'purple',
    },
}));

const Calender = ({ data, prepareCalendarData, setDateOut, startDate, scrollToContinueButton, mainData, dataSet }) => {
    const classes = useStyles();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div className={classes.mainContainer}>
            <div>
                <div className={classes.calenderContainer}>
                    <ButtonBase
                        disableRipple
                        onClick={() => {
                            let dateOffset;
                            if (!matchesMd) dateOffset = 24 * 60 * 60 * 1000 * 5;
                            else dateOffset = 24 * 60 * 60 * 1000 * 3;
                            const myDate = startDate;
                            myDate.setTime(myDate.getTime() - dateOffset);
                            prepareCalendarData('nothing', myDate);
                        }}
                        className={classes.calenderContainerMain}
                        style={{
                            marginLeft: matchesXs ? -25 : matches ? -30 : null,
                            marginTop: 50,
                            // position: 'absolute',
                            alignSelf: 'baseline',
                        }}
                    >
                        <img
                            src={leftAngle}
                            style={{ height: 18, marginTop: 35, marginRight: matchesXs && 8 }}
                            alt=""
                        />
                    </ButtonBase>

                    <div className={classes.calenderContainerMain}>
                        <MobileCalender
                            setDateOut={setDateOut}
                            data={data?.data}
                            scrollToContinueButton={scrollToContinueButton}
                            startDate={startDate}
                            setPatientDetails={prepareCalendarData}
                            mainData={mainData}
                            dataSet={dataSet}
                        />
                    </div>

                    <ButtonBase
                        disableRipple
                        onClick={() => {
                            let dateOffset;
                            if (!matchesMd) dateOffset = 24 * 60 * 60 * 1000 * 5;
                            else dateOffset = 24 * 60 * 60 * 1000 * 3;
                            const myDate = startDate;
                            myDate.setTime(myDate.getTime() + dateOffset);
                            prepareCalendarData('nothing', myDate);
                        }}
                        className={classes.calenderContainerMain}
                        style={{
                            right: matchesXs ? 30 : matches ? 60 : 40,
                            marginTop: 50,
                            // position: 'absolute',
                            alignSelf: 'baseline',
                        }}
                    >
                        <img
                            src={rightAngle}
                            style={{ height: 18, marginTop: 35, marginLeft: matchesXs && 8 }}
                            alt=""
                        />
                    </ButtonBase>
                </div>
                {/* {dateOut !== "" ? (
          <div
            className=""
            style={{ marginTop: 30, display: "flex", justifyContent: "end" }}
          >
            <FilledButton
              onPress={() => {}}
              // text={ dataSet ? mainData.continue_button[0].text :t("Continue")}
              text={"Continue"}
            />
          </div>
        ) : null} */}
            </div>
        </div>
    );
};

export default Calender;
