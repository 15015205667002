import { Button, Grid, Menu, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { delay } from 'es-toolkit';
import { Fragment, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';
import ic_arrow_top from '@/assets/Icons/ic_arrow_top.svg';

import CountrySearch from './CountrySeach/CountrySearch';
import { useStyles } from './TelephoneDropDownClass';

const TelephoneDropDown = ({
    setData,
    mainDataHeading,
    data,
    compulsory,
    errorText,
    validateForm = () => {},
    error,
    isScroll,
    setError,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');
    const [mobileCode, setMobileCode] = useState('+49');
    const [anchorE1, setAnchorE1] = useState(null);
    const [mobileNumber, setMobileNumber] = useState('');

    useEffect(() => {
        if (!data) return;

        const numberParts = data?.split(' ');
        if (numberParts.length === 2) {
            setMobileCode(numberParts[0]);
            setMobileNumber(numberParts[1]);
            return;
        }

        setMobileCode('+49');
        setMobileNumber(numberParts[0]);
    }, [data]);

    const messageRef = useRef();

    const handleDivClicked = () => {
        if (messageRef.current) {
            messageRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    };

    const handleClick = (event) => {
        const e = event.currentTarget;
        delay(
            function () {
                const w =
                    document.getElementById('mobileNumber').clientWidth +
                    document.getElementById('mobileCodeButton').clientWidth;
                console.debug(w);
                setMobileCodeWidth(w.toString());
                setAnchorE1(e);
                console.debug('hello1234', mobileCodeWidth, anchorE1, e);
            },
            600,
            e
        );
        console.debug('hello1234', mobileCodeWidth, anchorE1, e);
    };

    const handleClose = (mobileCodee = '') => {
        setAnchorE1(null);
        if (mobileCodee !== '') {
            if (mobileNumber) setData(mobileCodee.concat(` ${mobileNumber}`));
            setMobileCode(mobileCodee);
        }
    };

    const validateForm1 = (text) => {
        const number = text ?? mobileNumber;
        if (number?.length === 0) {
            setError(true);
        } else {
            setError(false);
        }
    };

    return (
        <>
            <div
                ref={isScroll ? messageRef : null}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                }}
                className={error && classes.redBorder}
            >
                <Grid item lg={2} md={3} sm={3} xs={3}>
                    <Button
                        id="mobileCodeButton"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                            handleClick(e);
                            if (isScroll) {
                                handleDivClicked();
                            }
                        }}
                        disableRipple
                        className={classes.formControl}
                        classes={{ label: classes.menuLabel }}
                        style={{
                            borderBottom: anchorE1 ? 'none' : null,
                            borderBottomLeftRadius: anchorE1 ? 0 : 5,
                            border: !anchorE1 && '1px solid #ABABAB ',
                            borderRight: 'none !important',
                            backgroundColor: anchorE1 ? theme.palette.common.lightBrownDropdown : 'white',
                            borderLeft: anchorE1 ? `3px solid ${theme.palette.common.yellow}` : '1px solid #ABABAB',
                            borderTop: anchorE1 ? `3px solid ${theme.palette.common.yellow}` : '1px solid #ABABAB',
                        }}
                    >
                        <div>
                            <Typography className={classes.ButtonTextClassMain}>{mobileCode}</Typography>
                        </div>
                        <img
                            src={anchorE1 ? ic_arrow_top : ic_arrow_bottom}
                            alt="select"
                            className={classes.downArrow}
                        />
                    </Button>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorE1}
                        keepMounted
                        elevation={0}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                        }}
                        open={Boolean(anchorE1)}
                        onClose={() => {
                            handleClose();
                        }}
                        PaperProps={{
                            style: {
                                width: matchesMd ? parseFloat(mobileCodeWidth) + 7 : parseFloat(mobileCodeWidth) + 8,
                                maxHeight: 610,
                                minHeight: 119,
                                borderBottom: `3px solid ${theme.palette.common.yellow}`,
                                borderLeft: `3px solid ${theme.palette.common.yellow}`,
                                borderRight: `3px solid ${theme.palette.common.yellow}`,
                                backgroundColor: theme.palette.common.lightBrownDropdown,

                                marginTop: '-2px',
                            },
                        }}
                    >
                        <CountrySearch
                            data={mobileCode}
                            setData={setMobileCode}
                            mainDataPlaceHolder={t('typeYourCountry')}
                            openProp={anchorE1}
                            closeDropDown={setAnchorE1}
                            widthLocal={mobileCodeWidth}
                        />
                    </Menu>
                </Grid>
                {/* Text field */}
                <Grid item md={10} sm={9} xs={9}>
                    <TextField
                        id="mobileNumber"
                        autoComplete="off"
                        label={mainDataHeading}
                        variant="filled"
                        color="primary"
                        type="number"
                        value={mobileNumber}
                        onBlur={() => validateForm1()}
                        onChange={(event) => {
                            const text = event.target.value;
                            setMobileNumber(text);
                            setData(mobileCode.concat(` ${text}`));
                            validateForm1(text);
                        }}
                        fullWidth
                        classes={classes.textFields}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: anchorE1
                                    ? clsx(classes.coloredRoot, classes.filledInputRoot)
                                    : classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                </Grid>
            </div>
            {error && <Typography className={classes.errorText}>{errorText}</Typography>}
        </>
    );
};

export default memo(TelephoneDropDown);
