import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        position: 'relative',
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingTop: 34,
        paddingBottom: 27,
        paddingLeft: 30,
        paddingRight: 30,
    },
    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        // height: 310,
        paddingBottom: 20,

        // [theme.breakpoints.down("sm")]: {
        //   height: 340,
        // },
        // [theme.breakpoints.down("xs")]: {
        //   height: 370,
        // },
    },
    cardContentClassDetail: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
        // height: 170,
        [theme.breakpoints.down('sm')]: {
            height: 300,
        },
        [theme.breakpoints.down('xs')]: {
            height: 270,
        },
    },
    heading: {
        ...theme.typography.HL1,
    },
    subheading: {
        ...theme.typography.HL4,
    },
    contentText: {
        ...theme.typography.content,
    },
    categoryHeading: {
        ...theme.typography.HL5,
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    doctorImg: {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        textAlign: 'center',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    findClassbutton: {
        height: '3.75em',
        width: '100%',
        backgroundColor: 'transparent',
        border: `solid 2px ${theme.palette.common.brown}`,
        marginTop: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    findClassText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    textFields: {
        height: '3.75em',
        marginTop: 10,
    },

    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    selectionPopup: {
        width: '100%',
        height: 260,
        background: 'white',
        position: 'absolute',
        top: 0,
        zIndex: 9999,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
    },
    closeButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));
