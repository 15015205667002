import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from '@/components/Loader.jsx';

import useProfessionalMappings from '@/hooks/useProfessionalMappings.js';
import useSortDocuments, { sortDirections } from '@/hooks/useSortDocuments.js';

import { usePersistedPrismicStore } from '@/store.js';

import DocumentItem from './DocumentItem.jsx';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '1rem',
    },
    sorting: {
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        padding: '0 1.5rem 1rem 1.5rem',
        borderBottom: '3px solid #F5F5F5',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            gap: '1rem',
            alignItems: 'start',
        },
    },
    sortingOption: {
        width: '25%',
        cursor: 'pointer',
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.8rem',
        fontStyle: 'normal',
        textTransform: 'uppercase',
        color: theme.palette.common.brown,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            width: '33%',
        },
    },
    sortName: {
        width: '45%',
        marginRight: ({ displayDoctor }) => (displayDoctor ? '1.5rem' : '1rem'),
        [theme.breakpoints.down('xs')]: {
            width: '33%',
        },
    },
}));

const DocumentList = ({ documents, displayDoctor = false }) => {
    const classes = useStyles({ displayDoctor });
    const { i18n } = useTranslation();
    useProfessionalMappings();

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const [visibleDocuments, setVisibleDocuments] = useState(10);

    const sortingItems = useMemo(() => {
        if (!content) return {};

        if (displayDoctor) {
            return {
                DATE: content?.date_label?.[0]?.text ?? 'Date',
                DOCTOR: content?.doctor_label?.[0]?.text ?? 'Doctor',
                NAME: content?.document_name_label?.[0]?.text ?? 'Document name',
            };
        }

        return {
            NAME: content?.document_name_label[0].text,
            TYPE: content?.type_label[0].text,
            DATE: content?.date_label[0].text,
        };
    }, [displayDoctor, content]);

    const { sortedDocuments, sortField, sortDirection, handleSort } = useSortDocuments(documents, sortingItems);

    const getSortClassName = (sortingOption) => {
        return sortingOption === sortingItems.NAME ? classes.sortName : classes.sortOption;
    };

    const showMoreDocuments = () => {
        if (sortedDocuments.length > visibleDocuments) {
            setVisibleDocuments((prevVisibleDocuments) => prevVisibleDocuments + 10);
        }
    };

    const getSortIcon = (field) => {
        if (field === sortField) {
            return sortDirection === sortDirections.ASC ? ' ▲' : ' ▼';
        }
        return '';
    };

    const isSortedField = (field) => sortField === sortingItems[field.toUpperCase()];

    const visibleDocumentsList = useMemo(() => {
        return sortedDocuments.slice(0, visibleDocuments);
    }, [sortedDocuments, visibleDocuments]);

    return (
        <div className={classes.container}>
            <div className={classes.sorting}>
                {Object.entries(sortingItems).map(([key, sortingOption]) => (
                    <Typography
                        key={key}
                        className={clsx(classes.sortingOption, getSortClassName(sortingOption))}
                        onClick={() => handleSort(sortingOption)}
                        role="button"
                    >
                        {sortingOption}
                        {getSortIcon(sortingOption)}
                    </Typography>
                ))}
            </div>
            <InfiniteScroll
                dataLength={visibleDocuments}
                next={showMoreDocuments}
                hasMore={sortedDocuments.length > visibleDocuments}
                loader={<Loader />}
            >
                {visibleDocumentsList.map((document) => (
                    <DocumentItem
                        key={document.id}
                        document={document}
                        content={content}
                        isSortedField={isSortedField}
                        displayDoctor={displayDoctor}
                    />
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default DocumentList;
