import { Switch, Typography, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, rgbToHex, useTheme, withStyles } from '@material-ui/core/styles';
import { useState } from 'react';

import close from '@/assets/Icons/close_grey.svg';
import ic_arrow_down_black from '@/assets/Icons/ic_down_arrow_black.svg';
import blackArrowUp from '@/assets/images/blackArrowUp.png';

import FilledButton from '../FilledButton';
import UnderlinedButton from '../UnderlinedButton';
import { AntSwitch } from './AntSwitchCommon';

const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        dialogRoot: {
            backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
        },
        scrollRoot: {
            alignItems: 'stretch',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {},
        },
        text: {
            ...theme.typography.content,
        },
        title: {
            paddingLeft: 0,
            paddingRight: 0,
            ...theme.typography.HL1,
            paddingTop: 0,
            marginTop: '-2rem',
        },
        line: {
            height: 1,
            width: '100%',
            backgroundColor: theme.palette.common.mediumBrown,
            marginBottom: 20,
            marginTop: 20,
        },
        shortHeading: {
            ...theme.typography.HL4,
            textTransform: 'uppercase',
        },
        userData: {
            fontFamily: 'MessinaSerif-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        necessaryText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: 16,
            marginBottom: 10,
        },
        mainHeading: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: 16,
        },
        showMoreText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: 15,
        },
        showMoreTextHeading: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: 16,
        },
        arrowImg: {
            display: 'flex',
        },
        contentTextLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',

            lineHeight: '1.5rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            textDecorationColor: theme.palette.common.yellow,
            textDecorationThickness: 2,
            color: theme.palette.common.darkGrey,
            textUnderlineOffset: '0.25em',
            '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
        },
    };
});

const AntSwitchTrueDisable = withStyles((theme) => ({
    root: {
        width: 34,
        height: 20,
        padding: 0,
        display: 'flex',
    },

    switchBase: {
        padding: 2,
        color: theme.palette.common.yellow,
        '&:hover + $track': {
            backgroundColor: '#898989',
            borderColor: '#898989',
        },
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.common.yellow,
                borderColor: theme.palette.common.yellow,
            },
            '&:hover + $track': {
                backgroundColor: '#CCA05F',
                borderColor: '#CCA05F',
            },
        },
    },
    thumb: {
        width: 15,
        height: 15,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.common.yellow}`,
        borderRadius: 18 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        height: 16,
    },
    checked: {},
}))(Switch);

const CookieConstantMain = ({ open, onClose, mainData, acceptCookies }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
    const [isStatistics, setIsStatistics] = useState(false);

    const [showMoreFirst, setShowMoreFirst] = useState(false);
    const [showMoreSecond, setShowMoreSecond] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.dialogRoot,
                scrollPaper: classes.scrollRoot,
            }}
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                    minHeight: 250,
                    width: '100%',
                    maxWidth: 570,
                    borderRadius: 8,
                    paddingLeft: matches ? 20 : matchesMd ? 30 : matchesLg ? 40 : matchesXl && 40,
                    paddingRight: matches ? 20 : matchesMd ? 30 : matchesLg ? 40 : matchesXl && 40,
                    paddingTop: matches ? 20 : matchesMd ? 30 : matchesLg ? 40 : matchesXl && 40,
                    paddingBottom: matches ? 20 : matchesMd ? 30 : matchesLg ? 40 : matchesXl && 40,
                },
            }}
        >
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        justifyContent: 'end',
                        paddingTop: 0,
                        paddingBottom: 0,
                    }}
                >
                    <img src={close} alt="Close" />
                </Button>
            </DialogActions>
            <DialogTitle
                id="alert-dialog-title"
                classes={{
                    root: classes.title,
                }}
            >
                {mainData?.title1[0].text}
            </DialogTitle>

            <DialogContent
                style={{
                    paddingBottom: 20,
                    paddingLeft: 0,
                    paddingRight: 0,
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}
            >
                <div className="">
                    <DialogContentText
                        id="alert-dialog-description"
                        classes={{
                            root: classes.text,
                        }}
                    >
                        {mainData?.description[0].text}
                        <div className="" style={{ marginTop: 30 }}>
                            <a
                                className={classes.contentTextLink}
                                href={mainData?.privacy_policy_link_[0]?.data?.path[0].text}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {' '}
                                {mainData?.privacy_policy_link_[0].data.title[0].text}
                            </a>
                        </div>
                    </DialogContentText>

                    <div className={classes.line} />

                    <div>
                        <div className={classes.shortHeading} style={{ marginBottom: 13 }}>
                            {mainData?.settings_subtitle[0].text}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: 20,
                            }}
                        >
                            <AntSwitchTrueDisable checked name="notifications" disabled className={classes.userData} />
                            <Typography className={classes.mainHeading} style={{ marginLeft: 10, marginTop: 7 }}>
                                {mainData?.cookie_category_title_1[0].text}
                            </Typography>
                        </div>
                        <div className={classes.necessaryText}>
                            <Typography>{mainData?.category_1_description[0].text}</Typography>
                        </div>

                        {!showMoreFirst && (
                            <div
                                className={classes.arrowImg}
                                style={{ marginBottom: 20, cursor: 'pointer' }}
                                onClick={() => {
                                    setShowMoreFirst(true);
                                }}
                            >
                                <Typography
                                    className={classes.necessaryText}
                                    style={{ marginBottom: 0, marginRight: 10 }}
                                >
                                    {mainData?.show_more[0].text}
                                </Typography>
                                <img src={ic_arrow_down_black} alt="" />
                            </div>
                        )}
                        {showMoreFirst && (
                            <div className="">
                                <ul>
                                    <li>
                                        {mainData?.category_1_cookies.length === 0 ? (
                                            <Typography className={classes.showMoreText}>
                                                {mainData?.fallback_text_if_no_cookies[0].text}
                                            </Typography>
                                        ) : (
                                            mainData?.category_1_cookies.map((cate) => (
                                                <div className="">
                                                    <Typography
                                                        className={classes.showMoreTextHeading}
                                                        style={{
                                                            marginRight: 3,
                                                        }}
                                                    >
                                                        {cate.cookie_name[0].text}:
                                                    </Typography>
                                                    <Typography className={classes.showMoreText}>
                                                        {cate.cookie_description[0].text}
                                                    </Typography>
                                                </div>
                                            ))
                                        )}
                                    </li>
                                </ul>
                                <div
                                    className={classes.arrowImg}
                                    style={{
                                        marginBottom: 20,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setShowMoreFirst(false);
                                    }}
                                >
                                    <Typography
                                        className={classes.necessaryText}
                                        style={{
                                            marginBottom: 0,
                                            marginRight: 10,
                                        }}
                                    >
                                        {mainData?.show_less[0].text}
                                    </Typography>
                                    <img src={blackArrowUp} alt="" />
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Statistics */}
                    <div className="">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: 20,
                            }}
                        >
                            <AntSwitch
                                checked={isStatistics}
                                onChange={() => {
                                    setIsStatistics(!isStatistics);
                                }}
                                name="notifications"
                                className={classes.userData}
                            />
                            <Typography className={classes.mainHeading} style={{ marginLeft: 10, marginTop: 7 }}>
                                {mainData?.cookie_category_3_title[0].text}
                            </Typography>
                        </div>
                        <div className={classes.necessaryText}>
                            <Typography>{mainData['category_3_-_description'][0].text}</Typography>
                        </div>

                        {!showMoreSecond && (
                            <div
                                className={classes.arrowImg}
                                style={{ marginBottom: 20, cursor: 'pointer' }}
                                onClick={() => {
                                    setShowMoreSecond(true);
                                }}
                            >
                                <Typography
                                    className={classes.necessaryText}
                                    style={{ marginBottom: 0, marginRight: 10 }}
                                >
                                    {mainData?.show_more[0].text}
                                </Typography>
                                <img src={ic_arrow_down_black} alt="" />
                            </div>
                        )}
                        {showMoreSecond && (
                            <div className="">
                                <ul>
                                    <li>
                                        {mainData?.category_3_cookies.length === 0 ? (
                                            <Typography className={classes.showMoreText}>
                                                {mainData?.fallback_text_if_no_cookies2[0].text}
                                            </Typography>
                                        ) : (
                                            mainData?.category_3_cookies.map((cate) => (
                                                <div className="">
                                                    <Typography
                                                        className={classes.showMoreTextHeading}
                                                        style={{
                                                            marginRight: 3,
                                                        }}
                                                    >
                                                        {cate.cookie_name[0].text}:
                                                    </Typography>
                                                    <Typography className={classes.showMoreText}>
                                                        {cate.cookie_desciption[0].text}
                                                    </Typography>
                                                </div>
                                            ))
                                        )}
                                    </li>
                                </ul>
                                <div
                                    className={classes.arrowImg}
                                    style={{
                                        marginBottom: 20,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setShowMoreSecond(false);
                                    }}
                                >
                                    <Typography
                                        className={classes.necessaryText}
                                        style={{
                                            marginBottom: 0,
                                            marginRight: 10,
                                        }}
                                    >
                                        {mainData?.show_less[0].text}
                                    </Typography>
                                    <img src={blackArrowUp} alt="" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="">
                    <div className={classes.line} />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: matches ? 'column' : 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <FilledButton
                            text={mainData?.allow_selection_cta[0].text}
                            onPress={() => acceptCookies(true, isStatistics)}
                            fullWidth={matches && true}
                        />
                        <div>
                            <UnderlinedButton
                                text={mainData?.cancel_cta[0].text}
                                onPress={onClose}
                                noMarginTop={!matches && true}
                                marginLeft={!matches && 30}
                                fullWidth={matches && true}
                                noJSLeft
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default CookieConstantMain;
