import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AntSwitch } from '@/components/Common/AntSwitchCommon';
import TextInputField from '@/components/Common/TextInputField';
import { ConsentStyles, ContractDetailsWithLogo, TranslationAlert } from '@/components/ConsentForms';
import PhoneInput from '@/components/PhoneInput/PhoneInput';

import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext, togglesEnum } from '@/context/ConsentFormsContext';

import useScrollToTop from '@/hooks/useScrollToTop';

import { languageMap } from '@/utils/helpers.js';

const defaultText = `This document can only be signed in original form in german. For your convenience, we provide you with a suggested english translation `;

const CommunicationAndContract = () => {
    /** Styles */
    const classes = ConsentStyles();

    const { i18n } = useTranslation();
    const lan = languageMap[i18n.language];

    useScrollToTop();

    /** Context */
    const {
        consentFormsContent: content,
        communicationData,
        setCommunicationData,
        handleCommunicationChange,
        //* * CommunicationAndContract Toggle States */
        communicationToggles,
        setCommunicationToggles,
        handleCommunicationToggle,
        setFormData,
        formData,
    } = useContext(ConsentFormsContext);

    const { email, sms, other } = communicationData;
    const { emailToggle, smsToggle, otherToggle } = communicationToggles;

    const {
        state: { user },
    } = useContext(AuthContext);

    useEffect(() => {
        // On toggle state change
        setCommunicationData((prev) => ({
            ...prev,
            email: emailToggle ? (user?.email ? user?.email : formData?.email ? formData?.email : email) : '',
            sms: smsToggle ? sms || formData?.phoneNo?.number : '',
            other: otherToggle ? other : '',
        }));
    }, [communicationToggles]);

    useEffect(() => {
        setCommunicationData((prev) => ({
            ...prev,
            email: emailToggle ? (user?.email ? user?.email : formData?.email) : '',
            sms: formData?.phoneNo?.number || sms || '',
            other: other || '',
        }));
        setCommunicationToggles((prev) => ({
            ...prev,
            emailToggle: !emailToggle ? false : Boolean(user?.email) || !!formData?.email || !!email,
            smsToggle: !smsToggle ? false : !!formData?.phoneNo?.number || !!sms,
            otherToggle: !!other,
        }));
    }, []);

    /** Set Phone No in context */
    const setPhoneNo = useCallback(
        (sms) => {
            setCommunicationData((prev) => ({ ...prev, sms: sms }));
            setFormData((prev) => ({ ...prev, phoneNo: { number: sms } }));
        },
        [sms]
    );

    return (
        <div>
            <Typography variant="h2" className={clsx(classes.secondaryHeading, classes.mb20)}>
                {content?.heraklesContent?.communicationSectionTitle}
            </Typography>
            <Grid container>
                {/* Row */}
                <>
                    <Grid item sm={3} xs={12} className={classes.my10}>
                        <div className={clsx(classes.dFlex, classes.gap10, classes.h100, classes.itemsCenter)}>
                            <AntSwitch
                                checked={emailToggle}
                                onChange={() => handleCommunicationToggle(togglesEnum.EMAIL_TOGGLE)}
                            />
                            <Typography variant="body1" className={clsx(classes.paragraph, classes.mb5)}>
                                {content?.heraklesContent?.emailToggle}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sm={9} xs={12} className={classes.my10}>
                        <TextInputField
                            disabled
                            label={content?.heraklesContent?.emailAddress}
                            className={classes.mt0}
                            name="email"
                            value={email || ''}
                            onChange={handleCommunicationChange}
                        />
                    </Grid>
                </>
                {/* Row */}
                <>
                    <Grid item sm={3} xs={12} className={classes.my10}>
                        <div className={clsx(classes.dFlex, classes.gap10, classes.h100, classes.itemsCenter)}>
                            <AntSwitch
                                checked={smsToggle}
                                onChange={() => handleCommunicationToggle(togglesEnum.SMS_TOGGLE)}
                            />
                            <Typography variant="body1" className={clsx(classes.paragraph, classes.mb5)}>
                                {content?.heraklesContent?.smsToggle}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sm={9} xs={12} className={classes.my10}>
                        <PhoneInput
                            disabled={!smsToggle}
                            phone={sms}
                            setPhone={setPhoneNo}
                            label={content?.heraklesContent?.mobileNumber}
                            mt="0px"
                        />
                    </Grid>
                </>
                {/* Row */}
                <>
                    <Grid item sm={3} xs={12} className={classes.my10}>
                        <div className={clsx(classes.dFlex, classes.gap10, classes.h100, classes.itemsCenter)}>
                            <AntSwitch
                                checked={otherToggle}
                                onChange={() => handleCommunicationToggle(togglesEnum.OTHER_TOGGLE)}
                            />
                            <Typography variant="body1" className={clsx(classes.paragraph, classes.mb5)}>
                                {content?.heraklesContent?.otherToggle}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sm={9} xs={12} className={classes.my10}>
                        <TextInputField
                            disabled={!otherToggle}
                            label={content?.heraklesContent?.specifyHere}
                            className={classes.mt0}
                            name="other"
                            value={other || ''}
                            onChange={handleCommunicationChange}
                        />
                    </Grid>
                </>
            </Grid>

            {/* Contract Details */}
            <div className={classes.mt30}>
                {lan === languageMap.en ? (
                    <TranslationAlert
                        text={
                            content && content?.heraklesContent?.translationMessage
                                ? content?.heraklesContent?.translationMessage
                                : defaultText
                        }
                        underlineText={
                            content && content?.contractDetails.herePopup ? content?.contractDetails.herePopup : `here.`
                        }
                        modalContent={content?.heraklesContent?.popupTranslation}
                    />
                ) : null}
                <ContractDetailsWithLogo
                    heading={
                        <Typography className={clsx(classes.shortHeading)}>
                            {content?.heraklesContent?.textProvidedHeading}
                        </Typography>
                    }
                    content={content?.heraklesContent?.contractDetailsText}
                    logo={content?.heraklesContent?.contractLogo?.url}
                    logoHeight={content?.heraklesContent?.contractLogo?.dimensions.height}
                    logoWidth={content?.heraklesContent?.contractLogo?.dimensions.width}
                />
            </div>
        </div>
    );
};

export default CommunicationAndContract;
