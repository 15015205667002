import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { useContext, useEffect } from 'react';

import { Context as AuthContext } from '@/context/AuthContext.js';

import { dayjs } from '@/utils/dayjsSetup.js';
import { createQuestionObj, isDevEnvironment } from '@/utils/helpers.js';

import { useCustomAnamnesisStore } from '@/store.js';

import OutlinedButton from '../OutlinedButton.jsx';
import DatefieldQuestion from './QuestionTypes/DatefieldQuestion.jsx';
import TextfieldQuestion, { TextValidation } from './QuestionTypes/TextfieldQuestion.jsx';

export const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '1rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

const PersonalInformationCategory = () => {
    const classes = useStyles();
    const {
        state: { user },
    } = useContext(AuthContext);
    const prismicData = useCustomAnamnesisStore((state) => state.prismicData);
    const currentCategory = useCustomAnamnesisStore((state) => state.currentCategory);
    const answers = useCustomAnamnesisStore((state) => state.answers);

    useEffect(() => {
        if (!user || answers[currentCategory.position]?.['first-name']) return;
        useCustomAnamnesisStore.setState({
            answers: {
                ...answers,
                [currentCategory.position]: {
                    ...answers[currentCategory.position],
                    'first-name': user.name.first,
                    'last-name': user.name.last,
                    email: user.email,
                    'date-of-birth': user.date_of_birth,
                },
            },
        });
    }, [user]);

    const updateAnswer = (value, questionKey) => {
        useCustomAnamnesisStore.setState({
            answers: {
                ...answers,
                [currentCategory.position]: {
                    ...answers[currentCategory.position],
                    [questionKey]: value,
                },
            },
        });
    };

    const getFakeData = async () => {
        if (!isDevEnvironment() || user) return;

        try {
            const response = await axios.get('https://randomuser.me/api/?nat=de&inc=name,email,dob');

            const fakeUser = response.data?.results?.[0];
            if (!fakeUser) return;

            useCustomAnamnesisStore.setState({
                answers: {
                    ...answers,
                    [currentCategory.position]: {
                        ...answers[currentCategory.position],
                        'first-name': fakeUser.name?.first,
                        'last-name': fakeUser.name?.last,
                        email: fakeUser.email,
                        'date-of-birth': fakeUser.dob?.date ? dayjs(fakeUser.dob.date).format('YYYY-MM-DD') : null,
                    },
                },
            });
        } catch (error) {
            console.debug(error);
        }
    };

    return (
        <div className={classes.container}>
            {isDevEnvironment() && !user && (
                <div>
                    <OutlinedButton text="Add Fake Data (Dev only)" fullWidth={false} onPress={getFakeData} />
                </div>
            )}
            <Typography style={{ marginBottom: '1rem' }} className={classes.title}>
                {prismicData.personal_details_question[0].text}
            </Typography>
            <div className={classes.nameContainer}>
                <TextfieldQuestion
                    question={createQuestionObj('', prismicData.first_name_label[0].text, 'first-name')}
                    answer={answers[currentCategory.position]?.['first-name']}
                    setAnswer={(value) => updateAnswer(value, 'first-name')}
                    hideTitle
                />
                <TextfieldQuestion
                    question={createQuestionObj('', prismicData.last_name_label[0].text, 'last-name')}
                    answer={answers[currentCategory.position]?.['last-name']}
                    setAnswer={(value) => updateAnswer(value, 'last-name')}
                    hideTitle
                />
            </div>
            <div className={classes.nameContainer}>
                <DatefieldQuestion
                    question={createQuestionObj('', prismicData.date_of_birth_label[0].text, 'date-of-birth')}
                    answer={answers[currentCategory.position]?.['date-of-birth']}
                    setAnswer={(value) => updateAnswer(value, 'date-of-birth')}
                    hideTitle
                />
                <TextfieldQuestion
                    question={createQuestionObj('', prismicData.email_label[0].text, 'email', {
                        textfield_validation: TextValidation.EMAIL,
                    })}
                    answer={answers[currentCategory.position]?.email}
                    setAnswer={(value) => updateAnswer(value, 'email')}
                    hideTitle
                    disabled={!!user}
                />
            </div>
        </div>
    );
};

export default PersonalInformationCategory;
