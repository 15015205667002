import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getTextColor } from '@/utils/colorHelpers';

const useStyles = makeStyles((theme) => ({
    findButton: {
        ...theme.button,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: `${theme.palette.common.yellow} !important`,
            color: getTextColor(theme.palette.common.yellow, theme.palette.common.darkGrey, 'white'),
        },
    },
    findButtonSm: {
        ...theme.button,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
            color: getTextColor(theme.palette.common.yellow, theme.palette.common.darkGrey, 'white'),
        },
    },
    buttonText: {
        ...theme.typography.buttonTextNoColor,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    mediumBrown: {
        border: `solid 2px ${theme.palette.common.mediumBrown}`,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    yellow: {
        border: `solid 2px ${theme.palette.common.yellow}`,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
}));

export default function OutlinedButton({
    text,
    color,
    onPress,
    fullWidth,
    height,
    disabled = false,
    disableRipple,
    classNameProp,
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Button
            variant="outlined"
            disableElevation
            disabled={disabled}
            disableRipple={disableRipple}
            fullWidth={fullWidth === true}
            onClick={() => {
                onPress();
            }}
            classes={{
                root: clsx(
                    matchesSm ? classes.findButtonSm : classes.findButton,
                    color === 'mediumBrown' ? classes.mediumBrown : classes.yellow
                ),
                label: clsx(classes.buttonText, classNameProp || null),
            }}
            style={{ height: height || null }}
        >
            {text}
        </Button>
    );
}
