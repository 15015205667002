import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    smallText: {
        fontSize: 12,
    },
    underLinedText: {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textUnderlineOffset: '0.45em',
        textDecorationThickness: 3,
    },
    marginTop: {
        marginTop: 20,
    },
}));
