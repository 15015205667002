import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ConsentAllFormsInitialRoutes, useConsentFormsContext } from '@/context/ConsentFormsContext.jsx';

const useHandleFormsFlowRefresh = () => {
    const history = useHistory();
    const {
        consentAllForms: { initialRoute },
    } = useConsentFormsContext();

    useEffect(() => {
        const redirectRoute = sessionStorage.getItem('redirectAfterReload');
        if (!redirectRoute) return;

        sessionStorage.removeItem('redirectAfterReload');
        history.replace('/all-forms-code');
    }, []);

    useEffect(() => {
        if (initialRoute !== ConsentAllFormsInitialRoutes.ALL_FORMS_CODE) return undefined;

        const handleUnload = () => {
            sessionStorage.setItem('redirectAfterReload', '/all-forms-code');
        };

        window.addEventListener('unload', handleUnload);

        const handleBeforeUnload = (event) => {
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [initialRoute]);
};

export default useHandleFormsFlowRefresh;
