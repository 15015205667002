import { useMemo, useState } from 'react';

import { dayjs } from '@/utils/dayjsSetup.js';

export const sortDirections = {
    ASC: 'asc',
    DESC: 'desc',
};

const useSortDocuments = (documents, sortFields) => {
    const [sortField, setSortField] = useState(sortFields.DATE);
    const [sortDirection, setSortDirection] = useState(sortDirections.DESC);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortDirection(sortDirection === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC);
        } else {
            setSortField(field);
            setSortDirection(sortDirections.ASC);
        }
    };

    const sortedDocuments = useMemo(() => {
        return [...documents].sort((a, b) => {
            let aValue;
            let bValue;

            switch (sortField) {
                case sortFields.DATE:
                    aValue = dayjs(a.meta.created_at);
                    bValue = dayjs(b.meta.created_at);
                    break;
                case sortFields.TYPE:
                    aValue = a.type;
                    bValue = b.type;
                    break;
                case sortFields.NAME:
                    aValue = a.name;
                    bValue = b.name;
                    break;
            }

            if (aValue < bValue) return sortDirection === sortDirections.ASC ? -1 : 1;
            if (aValue > bValue) return sortDirection === sortDirections.ASC ? 1 : -1;
            return 0;
        });
    }, [documents, sortField, sortDirection]);

    return {
        sortedDocuments: sortedDocuments,
        sortField: sortField,
        sortDirection: sortDirection,
        handleSort: handleSort,
    };
};

export default useSortDocuments;
