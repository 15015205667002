import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { BookingStep } from '@/utils/constants.js';

import { useBookingStore, usePersistedPrismicStore } from '@/store.js';

import FilledButton from '../FilledButton';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 0',
        gap: '1.5rem',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const InvalidTimeslot = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const {
        [i18n.language]: { mainData: bookAppointmentPrismicData },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);

    return (
        <div className={classes.container}>
            <Typography>{bookAppointmentPrismicData.invalid_timeslot_info_text[0].text}</Typography>
            <div className={classes.buttonContainer}>
                <FilledButton
                    text={bookAppointmentPrismicData.invalid_timeslot_cta[0].text}
                    fullWidth={matchesXs}
                    onPress={() => useBookingStore.setState({ currentStep: BookingStep.TIMESLOT_SELECTION })}
                />
            </div>
        </div>
    );
};

export default InvalidTimeslot;
