import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './YourBodyStyles';

export default function YourBodyGainedORLoss({ mainData, isFromHealthData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const { gainedWeight, KGs, weeks, setGainedWeight, setKGs, setWeeks } = useContext(ContextProviderTwo);

    return (
        <div className={classes.contentContainerBody}>
            <div className="" style={{ marginTop: gainedWeight || !isFromHealthData ? 40 : null }}>
                <Typography className={classes.mainHeading}>
                    {mainData.changed_weight_question_title[0].text}
                </Typography>
                <Grid
                    style={{
                        marginTop: 20,
                    }}
                    container
                >
                    <div
                        style={{
                            width: '100%',
                            display: !matches && 'flex',
                            gap: 15,
                        }}
                    >
                        {mainData &&
                            mainData.changed_weight_responses.map((parts, index) => (
                                <Button
                                    onClick={() => {
                                        console.debug();
                                        setGainedWeight(parts.changed_weight_response_api_value);
                                        if (parts.changed_weight_response_api_value === 'NEITHER') {
                                            setKGs('');
                                            setWeeks('');
                                        }
                                    }}
                                    disableRipple
                                    className={
                                        gainedWeight === parts.changed_weight_response_api_value
                                            ? clsx(classes.selectItemCard, classes.selectedCard)
                                            : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                    }
                                    style={{
                                        marginBottom: matchesMD && 20,
                                        width: matches && index > 1 ? '100%' : matches ? '47.5%' : null,
                                        marginRight: matches && index === 0 ? '4%' : null,
                                    }}
                                >
                                    <Typography className={classes.BtncontentText}>
                                        {parts.changed_weight_response_display_value[0].text}
                                    </Typography>
                                </Button>
                            ))}
                    </div>
                </Grid>

                {/* optional */}
                {(gainedWeight.toLocaleLowerCase() === 'gained' || gainedWeight.toLocaleLowerCase() === 'lost') && (
                    <div
                        className=""
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: matches ? 'column' : 'row',
                            gap: 12,
                            marginTop: !matchesMD && 20,
                        }}
                    >
                        <TextField
                            id="yourBodyChangedWeightAmount"
                            label={mainData ? mainData.changed_weight_amount_subquestion[0].text : t('TransferSlip')}
                            variant="filled"
                            autoComplete="off"
                            color="primary"
                            value={KGs ? String(KGs)?.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '') : ''}
                            onChange={(event) => {
                                setKGs(event.target.value);
                            }}
                            classes={{
                                root: classes.textFields,
                            }}
                            style={{
                                width: matches ? '100%' : '40%',
                                flex: matches ? 1 : 0.5,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />

                        <TextField
                            id="yourBodyChangedWeightSubquestion"
                            label={mainData ? mainData.changed_weight_time_subquestion[0].text : t('TransferSlip')}
                            variant="filled"
                            autoComplete="off"
                            // type="number"
                            // onKeyDown={onKeyDown}
                            color="primary"
                            value={weeks ? weeks?.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '') : ''}
                            onChange={(event) => {
                                setWeeks(event.target.value);
                            }}
                            classes={{
                                root: classes.textFields,
                            }}
                            style={{
                                flex: matches ? 1 : 0.5,
                                width: matches ? '100%' : '40%',
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
