import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.3em',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 9,
        },
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    formControlDisable: {
        width: '100%',
        backgroundColor: '#F9F8F4',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.3em',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 15,
        },
        opacity: 0.75,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    downArrow: {
        height: '0.75em',
        marginRight: 15,
    },
    menuItem: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
    },
    ButtonMainClass: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    ButtonTextMainClass: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
        padding: 0,
        marginLeft: 3,
    },
    dropDownTextClass: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
    },
    MenuButtonMainClass: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 15,
        justifyContent: 'space-between',
        paddingRight: 15,
        marginBottom: 10,
    },
    OpendownArrow: {
        marginTop: 2,
        height: '0.75em',
        marginRight: 15,
    },
    errorBorder: {
        boxShadow: '0 0 0 1px #da3030',
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
        marginBottom: 5,
        marginTop: '0.3em',

        [theme.breakpoints.down('xs')]: {
            marginTop: '-0.4em',
        },
    },
}));
