import { Auth } from 'aws-amplify';

import { processAPIWithHeaders } from '@/utils/helpers.js';

export const getCurrentUser = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const response = await processAPIWithHeaders(`secure/users/${user.username}`, 'GET', {
            headers: {
                Authorization: user.signInUserSession.idToken.jwtToken,
            },
        });
        return response;
    } catch (err) {
        console.debug(err);
    }
};
