import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './YourBodyStyles';

export default function YourBodyPregnant({ mainData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { pregnancy, pregnancyCount, setPregnancy, setPregnancyCount } = useContext(ContextProviderTwo);

    useEffect(() => {
        if (pregnancy !== 'YES') {
            setPregnancyCount('');
        }
    }, [pregnancy]);

    // drop down
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');
    const [anchorE1, setAnchorE1] = useState(null);

    const handleClick = (event) => {
        const w = document.getElementById('proBTN').clientWidth;
        console.debug(w);
        setMobileCodeWidth(w.toString());
        setAnchorE1(event.currentTarget);
    };

    const handleClose = (selectedPracc = '') => {
        setAnchorE1(null);
        if (selectedPracc !== '') {
            setPregnancyCount(selectedPracc);
        }
    };

    const healthData = [
        { data: '1' },
        { data: '2' },
        { data: '3' },
        { data: '4' },
        { data: '5' },
        { data: '6' },
        { data: '7' },
        { data: '8' },
        { data: '9' },
        { data: '10' },
    ];

    return (
        <div className={classes.contentContainerBody}>
            <div className="" style={{ marginTop: 40 }}>
                <div className={classes.fixedQuestion}>
                    <Typography className={classes.mainHeading}>
                        {mainData.pregnancy_yes_no_question_title[0].text}
                    </Typography>
                    <Grid
                        style={{
                            marginTop: 20,
                        }}
                        container
                    >
                        <div style={{ width: '100%', display: !matches && 'flex', gap: 15 }}>
                            {mainData &&
                                mainData.pregnancy_yes_no_responses.map((parts, index) => (
                                    <Button
                                        onClick={() => {
                                            console.debug(parts.pregnancy_yes_no_response_api_value);
                                            setPregnancy(parts.pregnancy_yes_no_response_api_value);
                                        }}
                                        disableRipple
                                        className={
                                            pregnancy === parts.pregnancy_yes_no_response_api_value
                                                ? clsx(classes.selectItemCard, classes.selectedCard)
                                                : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                        }
                                        style={{
                                            marginBottom: matches && 20,
                                            display: matches && index > 1 ? 'block' : null,
                                            width: matches && index > 1 ? '100%' : matches ? '47.5%' : null,
                                            marginRight: matches && index === 0 ? '4%' : null,
                                        }}
                                    >
                                        <Typography className={classes.BtncontentText}>
                                            {parts.pregnancy_response_lables[0].text}
                                        </Typography>
                                    </Button>
                                ))}
                        </div>
                    </Grid>
                </div>

                {/* mounths */}
                {pregnancy.toLocaleLowerCase() === 'yes' && (
                    <div className="">
                        <Button
                            id="proBTN"
                            style={{ marginTop: 20 }}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            disableRipple
                            className={classes.formControl}
                            classes={{ label: classes.menuLabel }}
                        >
                            <div>
                                <Typography
                                    style={{
                                        fontFamily: 'MessinaSans-Regular',
                                        fontSize: '0.875rem',
                                        color: theme.palette.common.darkGrey,
                                        textTransform: 'none',
                                        padding: 0,
                                        marginLeft: 3,
                                        textAlign: 'left',
                                    }}
                                >
                                    {mainData?.pregnancy_answer_textbox_placeholder[0].text}
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'MessinaSans-SemiBold',
                                        fontSize: '1rem',
                                        color: theme.palette.common.darkGrey,
                                        textTransform: 'none',
                                        marginLeft: 3,
                                        textAlign: 'left',
                                    }}
                                >
                                    {pregnancyCount}
                                </Typography>
                            </div>
                            <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
                        </Button>

                        <Menu
                            id="simple-menu"
                            anchorEl={anchorE1}
                            keepMounted
                            open={Boolean(anchorE1)}
                            onClose={() => {
                                handleClose();
                            }}
                            PaperProps={{
                                style: {
                                    width: parseFloat(mobileCodeWidth),
                                    backgroundColor: 'white',
                                    border: `2px solid ${theme.palette.common.yellow}`,
                                    height: '25%',
                                },
                            }}
                        >
                            {healthData.map((data) => (
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={() => {
                                        handleClose(data.data);
                                    }}
                                >
                                    <div
                                        className=""
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        {data.data}
                                    </div>
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                )}
            </div>
        </div>
    );
}
