import { debounce } from 'es-toolkit';
import { useCallback } from 'react';

import { callbackEmail } from '@/utils/helpers.js';

export const useDebounce = () => {
    const callback = useCallback((params) => callbackEmail(...params), []);
    const debounceEmail = debounce(callback, 500);
    return { debounceEmail: debounceEmail };
};
