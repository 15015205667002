import { useHistory } from 'react-router-dom';

import { FormTypes, useConsentFormsContext } from '@/context/ConsentFormsContext';

const useFormSteps = () => {
    const { consentAllForms, setConsentAllForms } = useConsentFormsContext();
    const { isConsentAllForms, currentStepIndex, forms } = consentAllForms;
    console.debug('🚀 ~ file: useFormSteps.js:7 ~ forms:', forms);

    const history = useHistory();

    const ALL_FORMS = {
        [FormTypes.ANAMNESIS]: { route: '/anamnesis-health-profile', name: 'Anamnesis' },
        [FormTypes.CONSENT_BASE]: { route: '/consent-treatment', name: 'Treatment Contract' },
        [FormTypes.CONSENT_LG]: { route: '/consent-treatment-extended', name: 'Patient declaration' },
    };

    // all form names
    const pages = forms?.map((form) => {
        console.debug('🚀 ~ file: useFormSteps.js:19 ~ form:', form);
        return { name: form?.name || '' };
    });
    console.debug('🚀 ~ file: useFormSteps.js:21 ~ pages:', forms, pages);

    // create formSteps array containing all form components
    const steps = forms?.map((form) => ALL_FORMS[form?.key?.toLowerCase()]?.route ?? '');

    // Helper function to change in context state
    const setCurrentStepIndex = (getSetStep) => {
        setConsentAllForms((prev) => ({
            ...prev,
            currentStepIndex: getSetStep(prev.currentStepIndex),
        }));
    };

    // Hook functions
    const next = (step = '') => {
        setCurrentStepIndex((i) => {
            if (i >= steps.length - 1) {
                // Ensure we don't exceed the last step index
                return i;
            }
            return i + 1;
        });

        const nextStepIndex = currentStepIndex + 1;
        if (nextStepIndex >= 0 && nextStepIndex < steps.length) {
            // Only navigate to the next step if it's within a valid range
            const nextStep = steps[nextStepIndex];
            history.push(`${nextStep}${step ? '?step=' : ''}${step}`);
        } else {
            // The next step index is out of bounds, meaning all steps are completed
            // Navigate to the thank you page
            history.push('/consent-all-forms-thankyou');
        }
    };

    const back = () => {
        setCurrentStepIndex((i) => {
            if (i <= 0) return i;
            return i - 1;
        });
    };

    const goTo = (index) => {
        setCurrentStepIndex(index);
        history.push(steps[index]);
    };

    return {
        isConsentAllForms: isConsentAllForms,
        currentStepIndex: currentStepIndex,
        step: steps[currentStepIndex],
        steps: steps,
        currentForm: forms[currentStepIndex],
        isFirstStep: currentStepIndex === 0,
        isLastStep: currentStepIndex === steps.length - 1,
        pages: pages,
        goTo: goTo,
        next: next,
        back: back,
    };
};

export default useFormSteps;
