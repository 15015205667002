import { useContext } from 'react';
import useSWRImmutable from 'swr/immutable';

import { Context as AuthContext } from '@/context/AuthContext.js';

import { getRequiredTemplates, getSingleFormTemplate } from '@/service/ConsentForms/consentFormService.js';

// TODO delete old hook and rename this
const useRequiredTemplatesAndAnamnesisNew = ({
    professionalId = '',
    appointmentTypeKeys,
    insuranceType,
    enrichMissingFormsWithTemplateAndName = false,
    customerId = '',
}) => {
    const {
        state: { user, usersession },
    } = useContext(AuthContext);

    const fetchRequiredTemplatesAndAnamnesis = async () => {
        try {
            // Fetch all forms and anamnesis by appointment params
            const { templates } = await getRequiredTemplates({
                userId: user?.id ?? '',
                appointment_type_keys: appointmentTypeKeys,
                insurance_type: insuranceType,
                professional_id: professionalId,
                customerId: customerId,
            });

            // Select only not filled templates
            let requiredTemplates = templates.filter((form) => !form.is_completed);

            // Enrich forms and anamnesis with their template info and name
            if (enrichMissingFormsWithTemplateAndName && requiredTemplates?.length > 0) {
                requiredTemplates = await Promise.all(
                    requiredTemplates.map(async (form) => {
                        const template = await getSingleFormTemplate(professionalId, form.id);
                        return {
                            ...form,
                            ...template,
                            name: {
                                de: template?.form_name_de ?? template?.content?.name,
                                en: template?.form_name_en ?? template?.content?.name,
                            },
                        };
                    })
                );
            }

            const missingConsentForms = []; // Required Forms of this appointment
            const missingCustomAnamnesisForms = []; // Optional Anamnesis of this appointment
            requiredTemplates.forEach((form) => {
                if (form.key) missingConsentForms.push(form);
                else missingCustomAnamnesisForms.push(form);
            });

            return {
                missingConsentForms, // missing consent forms
                missingCustomAnamnesisForms, // missing anamnesis
            };
        } catch (err) {
            console.error(err);
        }

        return null;
    };

    const appointmentParamsUniqueKey = `appointment-required-forms-${appointmentTypeKeys}-${insuranceType}-${professionalId}`;
    const { data, isLoading } = useSWRImmutable(
        usersession ? { usersession: usersession, key: appointmentParamsUniqueKey } : null,
        fetchRequiredTemplatesAndAnamnesis
    );

    return {
        isLoading,
        data,
    };
};

export default useRequiredTemplatesAndAnamnesisNew;
