import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './YourBodyStyles';

const YourBodyBloodPressure = ({ mainData }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { bloodPressure, setBloodPressure, sBP, setSBP, dBP, setDBP } = useContext(ContextProviderTwo);

    const regex = /[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/;

    useEffect(() => {
        if (bloodPressure !== null && bloodPressure === 'NO') {
            setSBP('');
            setDBP('');
        }
    }, [bloodPressure]);

    return (
        <div className={classes.contentContainerBody}>
            <div className="" style={{ marginTop: bloodPressure && 40 }}>
                <div className={classes.fixedQuestion}>
                    <Typography className={classes.mainHeading}>
                        {mainData.blood_pressure_question_title[0].text}
                    </Typography>
                    <Grid
                        style={{
                            marginTop: 20,
                        }}
                        container
                    >
                        <div style={{ display: 'flex', width: '100%' }}>
                            {mainData &&
                                mainData.blood_pressure_answer_yes_no_group.map((answer) => (
                                    <Button
                                        onClick={() => {
                                            console.debug(answer.blood_pressure_answer_selector);
                                            setBloodPressure(answer.blood_pressure_answer_selector);
                                        }}
                                        disableRipple
                                        className={
                                            bloodPressure === answer.blood_pressure_answer_selector
                                                ? clsx(classes.selectItemCard, classes.selectedCard)
                                                : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                        }
                                        style={{
                                            marginBottom: matches && 20,
                                            marginRight: 15,
                                            flex: matches && 1,
                                            display: matches ? 'null' : 'flex',
                                        }}
                                    >
                                        <div className="">
                                            <Typography className={classes.BtncontentText}>
                                                {answer.blood_pressure_yes_no_answer[0].text}
                                            </Typography>
                                        </div>
                                    </Button>
                                ))}
                        </div>
                    </Grid>
                </div>

                {/* mounths */}
                {bloodPressure && bloodPressure.toLocaleLowerCase() === 'yes' && (
                    <div
                        className=""
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: matches ? 'column' : 'row',
                            gap: 12,
                            marginTop: 20,
                        }}
                    >
                        <TextField
                            id="yourBodyChangedWeightAmount"
                            label={mainData ? mainData.systolic_answer_title[0].text : t('TransferSlip')}
                            variant="filled"
                            autoComplete="off"
                            color="primary"
                            value={sBP.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '')}
                            onChange={(event) => {
                                if (regex.test(event.target.value)) return;
                                setSBP(event.target.value);
                            }}
                            classes={{
                                root: classes.textFields,
                            }}
                            style={{
                                width: matches ? '100%' : '40%',
                                flex: matches ? 1 : 0.5,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />

                        <TextField
                            id="yourBodyChangedWeightSubquestion"
                            label={mainData ? mainData.diastolic_blood_pressure[0].text : t('TransferSlip')}
                            variant="filled"
                            autoComplete="off"
                            color="primary"
                            value={dBP.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '')}
                            onChange={(event) => {
                                if (regex.test(event.target.value)) return;
                                setDBP(event.target.value);
                            }}
                            classes={{
                                root: classes.textFields,
                            }}
                            style={{
                                flex: matches ? 1 : 0.5,
                                width: matches ? '100%' : '40%',
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(YourBodyBloodPressure);
