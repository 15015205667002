import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import account_yellow from '@/assets/Illustrations/Yellow/Eterno-Account_Yellow.svg';

import { Context as AuthContext } from '@/context/AuthContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { hexToRgb } from '@/utils/colorHelpers';

import { useConfigStore, useLoggingStore, usePersistedPrismicStore } from '@/store.js';

import LoginDialogBox from './Common/LoginDialogBox';
import FilledButton from './FilledButton';
import Icon from './Icon/Icon';

// TODO finish design implementation
const useStyles = makeStyles((theme) => {
    const [r, g, b] = hexToRgb(theme.palette.common.yellow);

    return {
        wrapper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'MessinaSans-Regular',
            maxWidth: theme.layout.contentWidth,
            padding: '40px 50px 20px 50px',
            width: '100%',
            justifyContent: 'space-between',

            [theme.breakpoints.down('xs')]: {
                padding: '40px 20px 20px 20px',
            },
        },
        customerIcon: {
            width: '100%',
            maxHeight: '8rem',
            [theme.breakpoints.down('xs')]: {
                width: '8rem',
                maxHeight: '6rem',
            },
        },
        headerNav: {
            display: 'flex',
            position: 'relative',
            userSelect: 'none',
        },
        nameLabel: {
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem 0 0.5rem 1rem',
            boxShadow: '0px 3px 12px 0px rgba(120, 120, 120, 0.21)',
            background: 'white',
            borderRadius: '8px 40px 40px 8px',
            justifyContent: 'space-between',
            minWidth: '10rem',
        },
        profilePictureWrapper: {
            background: 'white',
            position: 'absolute',
            right: 0,
            top: 0,
            transform: 'translate(0, -20%)',
            boxShadow: '0 0 0 2px white',
            borderRadius: '50%',
            zIndex: 4,
        },
        profilePicture: {
            objectFit: 'contain',
            backgroundColor: `rgba(${r},${g},${b},0.2)`,
            width: '4rem',
            height: '4rem',
            borderRadius: '50%',
        },
        menu: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            background: 'white',
            transform: 'translate(0, 100%)',
            display: 'flex',
            flexDirection: 'column',
            padding: '1.5rem 0',
            gap: '0.25rem',
            zIndex: 3,
            boxShadow: '0px 5px 12px 0px rgba(120, 120, 120, 0.22)',
            borderRadius: '0 0 0.5rem 0.5rem',
        },
        menuLink: {
            textDecoration: 'none',
            color: theme.palette.common.darkGrey,
            cursor: 'pointer',
            padding: '0.5rem 1rem',
            textAlign: 'right',
            fontWeight: 'lighter',
            borderRight: `4px solid transparent`,
            transition: 'padding 0.3s ease',
            '&:hover': {
                fontWeight: 'bolder',
                padding: '0.5rem 1.25rem',
            },
        },
        currentRoute: {
            borderRight: `4px solid ${theme.palette.common.secondary}`,
            fontWeight: 'bolder',
        },
    };
});

const routesWithoutHeader = ['/all-forms-code'];

const Header = () => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const {
        state: { user },
        setuser,
        setUserData,
    } = useContext(AuthContext);
    const { emptyStates } = useContext(ContextProviderTwo);
    const theme = useTheme();
    const location = useLocation();
    const history = useHistory();
    const [openLogin, setOpenLogin] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const {
        [i18n.language]: { contentHeading: prismicData, dataSet },
    } = usePersistedPrismicStore((state) => state.headerData);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    const logout = async (e) => {
        e.preventDefault();
        await Auth.signOut();
        setuser(null);
        emptyStates();
        setUserData(null);
        history.replace('/');
        window.location.reload();
    };

    useEffect(() => {
        if (!prismicData || !dataSet) return;
        useLoggingStore.setState({
            largeText: prismicData?.error_fallback_large_text[0]?.text,
            smallText: prismicData?.error_fallback_small_text[0]?.text,
            ctaText: prismicData?.error_fallback_cta[0]?.text,
        });
    }, [prismicData, dataSet]);

    if (!dataSet || routesWithoutHeader.includes(location.pathname)) return null;

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <Link to="/">
                    <img
                        className={classes.customerIcon}
                        src={currentCustomer.customer_icon_large.url}
                        alt={currentCustomer.customer_name[0].text}
                    />
                </Link>
                {!user ? (
                    <FilledButton onPress={() => setOpenLogin(true)} text={prismicData.login[0].text} />
                ) : (
                    <div
                        onMouseEnter={() => setShowMenu(true)}
                        onMouseLeave={() => setShowMenu(false)}
                        className={classes.headerNav}
                    >
                        <div className={classes.nameLabel}>
                            <Typography>{user?.name?.first ?? ''}</Typography>
                            <div style={{ width: '5rem' }} />
                        </div>
                        <div className={classes.profilePictureWrapper}>
                            <div className={classes.profilePicture}>
                                <Icon
                                    color={theme.palette.common.yellow}
                                    src={account_yellow}
                                    description="account"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>
                        {showMenu && (
                            <div className={classes.menu}>
                                {currentCustomer.header_links.map((headerLink) => (
                                    <Link
                                        key={headerLink.link + headerLink.link_label[0].text}
                                        className={clsx(
                                            classes.menuLink,
                                            location.pathname === headerLink.link && classes.currentRoute
                                        )}
                                        to={headerLink.link}
                                        onClick={() => setShowMenu(false)}
                                    >
                                        {headerLink.link_label[0].text}
                                    </Link>
                                ))}
                                <a className={classes.menuLink} href="" onClick={logout}>
                                    Logout
                                </a>
                            </div>
                        )}
                    </div>
                )}

                <LoginDialogBox openPopUp={openLogin} onClosePopUp={() => setOpenLogin(false)} />
            </div>
        </div>
    );
};

export default Header;
