import { Typography } from '@material-ui/core';

import TextInputField from './Common/TextInputField';
import consentFormsStyles from './ConsentForms/ConsentFormsStyles';

const CommentField = ({ heading = '', label = 'Comment', onChange = () => {}, value = '', name = '' }) => {
    const classes = consentFormsStyles();
    return (
        <>
            {heading && <Typography className={classes.secondaryHeading}>{heading}</Typography>}
            {/* Comment Field */}
            <TextInputField
                label={label}
                multiline
                rows={4}
                style={{ height: 'auto' }}
                onChange={onChange}
                name={name}
                value={value}
            />
        </>
    );
};

export default CommentField;
