import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconBox from '@/components/IconBox';
import Loader from '@/components/Loader';
import RecommendedClasses from '@/components/RecommendedClasses';
import SearchTool from '@/components/Search/SearchTool';

import { ContextProvider } from '@/context/ProjectContext';

import { getTextColor } from '@/utils/colorHelpers';
import { isDevEnvironment } from '@/utils/helpers.js';

import { useConfigStore, usePersistedPrismicStore } from '@/store.js';

import MyAppointments from './MyAppointments';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    searchToolWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'MessinaSans-Regular',
        flexDirection: 'column',
        maxWidth: theme.layout.contentWidth,
        padding: '0 50px',
        width: '100%',
        margin: '2rem 0 4rem 0',

        [theme.breakpoints.down('xs')]: {
            padding: '0 20px',
        },
    },
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 0,
        },
    },
    bgContainer: {},
    mainHeading: {
        ...theme.typography.BGHL1,
    },
    contentText: {
        ...theme.typography.content,
    },
    contentTextHeader: {
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '1rem',
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 0,
        '&:hover': {
            backgroundColor: theme.palette.common.hoverYellow,
        },
        borderRadius: '2em',
    },
    buttonText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.9rem',
        textTransform: 'uppercase',
        lineHeight: '1.5rem',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 36,
        paddingRight: 36,
        color: getTextColor(theme.palette.common.yellow, theme.palette.common.darkGrey, 'white'),
    },
    buttonHeader: {
        maxWidth: 230,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    titleHeading: {
        ...theme.typography.HL1,
    },
    subTitleHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.5rem',
        textTransform: 'none',
        lineHeight: '1.875rem',
        color: '#3c3c3c',
        marginBottom: 15,
    },
    expertHeading: {
        ...theme.typography.HL1,
        textAlign: 'center',
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingTop: 34,
        paddingBottom: 27,
        paddingLeft: 30,
        paddingRight: 30,
    },
    cardBottomContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingTop: 34,
        paddingBottom: 27,
        paddingLeft: 30,
        paddingRight: 30,
        marginLeft: 40,
        marginRight: 40,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.31rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.125rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    detailsButton: {
        background: 'transparent',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    buttonLabel: {
        padding: 0,
        justifyContent: 'left',
    },
    detailsLinks: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        color: theme.palette.common.darkGrey,
        marginTop: 30,
    },
    appointmentBG: {
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        borderRadius: '50%',
        backgroundColor: theme.palette.common.lightBrown,
    },
    appointmentImg: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            width: 190,
            height: 190,
        },
        [theme.breakpoints.down('xs')]: {
            width: 150,
            height: 150,
        },
    },
    textClassMain: {
        padding: '50px 0px',
        paddingRight: 20,

        [theme.breakpoints.down('xs')]: {
            padding: 0,
            paddingTop: 20,
        },
    },
    mainImgBg: {
        display: 'flex',
        justifyContent: 'end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    mainbg: {
        height: 330,
        marginRight: 0,
        objectFit: 'contain',
        [theme.breakpoints.down('md')]: {},
        [theme.breakpoints.down('sm')]: {
            height: 270,
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            height: 220,
        },
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const { openLogin, setOpenLogin } = useContext(ContextProvider);
    const [crash, setCrash] = useState(false);

    const {
        [i18n.language]: { content, dataSet },
    } = usePersistedPrismicStore((state) => state.dashboardData);

    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    let crashValue;
    if (crash) crashValue = content.nonExistingValue.blabliblub;
    console.debug(crashValue);

    console.debug('prismicDataDash:  Dashboard', content);
    console.debug('prismicDataDash:  type_id', content?.icons);

    useEffect(() => {
        if (openLogin) setOpenLogin(false);

        // returning back to top on mount
        window.scrollTo(0, 0);
    }, []);

    if (!dataSet) return <Loader />;

    return (
        <>
            <div className={classes.container}>
                <div className={classes.searchToolWrapper}>
                    <SearchTool />
                </div>
            </div>

            <div className={classes.contentContainerBody}>
                <IconBox background="white" icons={content?.icons} />

                {/* TO BE REMOVED */}
                {isDevEnvironment() && (
                    <div className={classes.buttonHeader}>
                        <Button
                            variant="contained"
                            fullWidth={!!matches}
                            disableElevation
                            classes={{
                                root: classes.submitButton,
                                label: classes.buttonText,
                            }}
                            onClick={() => setCrash(true)}
                        >
                            Force Crash
                        </Button>
                    </div>
                )}

                <MyAppointments fromDashboard />

                {currentCustomer?.is_eterno_customer && (
                    <div className="" style={{ marginTop: 40 }}>
                        <RecommendedClasses data={content} />
                    </div>
                )}
            </div>
        </>
    );
}
