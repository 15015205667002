import { Box, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { orderBy } from 'es-toolkit';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@/assets/Icons/search_icon.svg';

import HistoryEmpty from '@/components/ConsentManagement/HistoryComponents/HistoryEmpty';
import Table from '@/components/ConsentManagement/HistoryComponents/HistoryTable/Table';
import TableTabs from '@/components/ConsentManagement/OverviewComponents/OverviewTable/TableTabs';
import CardWrapper from '@/components/HealthProfileOverview/HealthData/CardWrapper';
import Loader from '@/components/Loader';

import { Context as ConsentManagerContext, showBtnStatus } from '@/context/ConsentManagerContext';

import useDoctorDocuments from '@/hooks/useDoctorDocuments';

import { formatDateForTable, languageMap } from '@/utils/helpers.js';

import { usePersistedPrismicStore } from '@/store.js';

import HealthContainer from '../HealthProfileOverview/HealthContainer';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        fontFamily: 'MessinaSans-Regular',
        color: theme.palette.common.darkGrey,
        fontSize: 24,
        fontWeight: 'normal',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '.6rem',
        },
    },
    heading: {
        ...theme.typography.HL1,
    },
    paragraph: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '1rem',
        width: '100%',
    },
    mt10: { marginTop: 10 },
    searchBox: { position: 'relative' },
    searchIcon: {
        width: 18,
        height: 18,
        position: 'absolute',
        top: '50%',
        right: 20,
        transform: 'translateY(-50%)',
        cursor: 'pointer',
    },
    textFields: {
        height: '3.75em',
    },
    inputLabel: {
        color: '#ABABAB',
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    pX15: {
        paddingInline: 15,
    },
    pl30: {
        paddingLeft: 30,
    },
    pr30: {
        paddingRight: 30,
    },
}));

let isData = false;
let showSearchBox = false;
const ConsentHistory = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { i18n } = useTranslation();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

    // current language
    const lan = languageMap[i18n.language];

    // prismic data
    const { [i18n.language]: consentPrismicData } = usePersistedPrismicStore((state) => state.consentData);
    const { loading, content } = consentPrismicData;
    console.debug('🚀 ~ file: History.jsx:104 ~ ConsentHistory ~ content:', loading, content);

    // shared documents
    const { doctorDocuments } = useDoctorDocuments();

    const {
        state: { mainDocuments, allDocuments, sortType, sortDoctor, sortDate, searchStarted },
        setShowBtn,
        setMainDocuments,
        setSearchStarted,
    } = useContext(ConsentManagerContext);

    isData = !!content;

    const isLoaded = isData && Object.keys(content).length;

    const [DocTypes, setDocTypes] = useState(null);

    useEffect(() => {
        if (!loading) {
            setDocTypes(content.data_operations_table_document_types);
        }
    }, [loading]);

    const setDocumentTypeToPrismic = useCallback(
        (type) => {
            if (!DocTypes) return;
            const filteredType = DocTypes.filter((prismicType) => {
                return prismicType.data_operations_table_document_types_backend === type;
            });
            return filteredType[0];
        },
        [DocTypes]
    );

    const search = useCallback(
        (e) => {
            const searchQuery = e.target.value;
            setSearchStarted(!!e.target.value.length);
            if (mainDocuments) {
                const searchedDocuments = allDocuments?.filter((doc) => {
                    let containsSearch = true;
                    searchQuery.split(' ').forEach((value) => {
                        if (
                            !(
                                doc.display_name[0].text.toLowerCase().includes(value.toLowerCase()) ||
                                formatDateForTable(doc.meta.created_at).toLowerCase().includes(value.toLowerCase()) ||
                                doc.doc_type.toLowerCase().includes(value.toLowerCase()) ||
                                setDocumentTypeToPrismic(doc.doc_type)
                                    ?.data_operations_table_document_types_frontend[0].text.toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                doc.name.toLowerCase().includes(value.toLowerCase())
                            )
                        )
                            containsSearch = false;
                    });

                    return containsSearch;
                });

                const tempColOrders = [sortType, sortDoctor, sortDate];
                const tempColNames = ['type', 'doctor_name', 'created_date'];
                const colOrders = [];
                const colNames = [];
                const tempArr = searchedDocuments.map((doc) => ({
                    id: doc.id,
                    name: doc.name,
                    doctor_name: doc.doctor_name,
                    type: doc.doc_type,
                    created_date: doc.created_date,
                }));
                tempColOrders.forEach((colName, index) => {
                    if (colName) {
                        colOrders.push(colName);
                        colNames.push(tempColNames[index]);
                    }
                });

                setMainDocuments(orderBy(tempArr, colNames, colOrders));
                if (tempArr.length > 5) setShowBtn(showBtnStatus.SHOW);
            }
        },
        [allDocuments, sortType, sortDoctor, sortDate]
    );

    const RenderComponent = useCallback(() => {
        showSearchBox = Array.isArray(doctorDocuments) ? doctorDocuments.length > 0 : !!doctorDocuments; // toggle search box
        // check if user documents available
        if (Array.isArray(doctorDocuments) && doctorDocuments.length > 0) {
            return (
                <>
                    <div className={matchesXS ? classes.pX15 : null}>
                        <TableTabs />
                    </div>
                    <Table doctorDocuments={doctorDocuments} />
                </>
            );
        }
        if (Array.isArray(doctorDocuments) && doctorDocuments.length === 0) return <HistoryEmpty />;
        return <Loader />;
    }, [doctorDocuments, matchesXS]);

    if (loading || !isLoaded) return <Loader />;

    return (
        <HealthContainer>
            <CardWrapper pInlineXs={Array.isArray(doctorDocuments) && doctorDocuments.length > 0 && 0}>
                <Grid container style={{ padding: 0 }}>
                    <Grid item sm={8} xs={6} className={matchesXS ? classes.pl30 : null}>
                        <Typography className={classes.mainHeading}>
                            {isLoaded ? content?.history_page_title[0].text : 'History'}
                        </Typography>
                    </Grid>
                    {showSearchBox ? (
                        <Grid item sm={4} xs={6} className={matchesXS ? classes.pr30 : null}>
                            <Box className={classes.searchBox}>
                                <TextField
                                    id="search"
                                    label={lan === 'en-us' ? 'Search' : 'Suche'}
                                    variant="filled"
                                    color="primary"
                                    onChange={search}
                                    fullWidth
                                    classes={{
                                        root: classes.textFields,
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRoot,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                    }}
                                />
                                <img src={SearchIcon} className={classes.searchIcon} />
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
                {RenderComponent()}
            </CardWrapper>
        </HealthContainer>
    );
};

export default ConsentHistory;
