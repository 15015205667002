import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, rgbToHex, useTheme } from '@material-ui/core/styles';

import close from '@/assets/Icons/close_grey.svg';

import FilledButton from '../FilledButton';
import UnderlinedButton from '../UnderlinedButton';

const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        dialogRoot: {
            backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
        },
        text: {
            ...theme.typography.content,
            marginBottom: 40,
        },
        title: {
            paddingLeft: 0,
            paddingRight: 0,
            ...theme.typography.HL1,
            paddingTop: 0,
            position: 'absolute',
            marginTop: 40,
        },
    };
});

export default function DialogBox({
    text,
    open,
    onClose,
    title,
    cancelButton,
    mainButton,
    mainButtonText,
    onPress,
    cancelText,
    marginBottom = false,
}) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesmd = useMediaQuery(theme.breakpoints.down('md'));
    const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesxl = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.dialogRoot,
            }}
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                    width: '90%',
                    maxWidth: 500,
                    borderRadius: 8,
                    paddingLeft: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                    paddingRight: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                },
            }}
        >
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        justifyContent: 'end',
                    }}
                >
                    <img
                        src={close}
                        style={{
                            width: 30,
                            height: 50,
                            objectFit: 'contain',
                            paddingLeft: 0,

                            paddingRight: 0,
                        }}
                    />
                </Button>
            </DialogActions>
            <DialogTitle
                id="alert-dialog-title"
                classes={{
                    root: classes.title,
                }}
            >
                {title}
            </DialogTitle>
            <DialogContent
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    paddingBottom: 20,
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
            >
                <DialogContentText
                    id="alert-dialog-description"
                    classes={{
                        root: classes.text,
                    }}
                    style={{ marginTop: marginBottom && 20 }}
                >
                    {text}
                </DialogContentText>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {mainButton === true && <FilledButton text={mainButtonText} onPress={onPress} />}
                    {cancelButton === true && (
                        <div style={{ marginLeft: 20 }}>
                            <UnderlinedButton text={cancelText} onPress={onClose} noMarginTop />
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}
