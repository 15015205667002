import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import { usePersistedPrismicStore } from '@/store.js';

import consentFormsStyles from '../../ConsentForms/ConsentFormsStyles';
import PhoneInput from '../../PhoneInput/PhoneInput';
import { DateTextField } from '../Date/DateTextField';
import { DropDown } from '../DropDown/DropDown';
import GrayTextBox from '../GrayTextBox';
import TextInputField from '../TextInputField';

const PersonalInformation = ({
    heading,
    /** Form States */
    formData,
    setFormData,
    haveGap = false,
    isDOBLast = false,
    isDOBFullWidth = false,
    isOnlyGender = false,
    handleChange,
    /** Form Labels */
    labels,
}) => {
    /** Styles */
    const classes = consentFormsStyles();
    const { i18n } = useTranslation();

    const {
        state: { user },
    } = useContext(AuthContext);
    const { consentFormsContent: mainContent, errors, setErrors, validateForm } = useContext(ConsentFormsContext);

    /** Destructured labels */
    const {
        titleLabel,
        firstNameLabel,
        middleNameLabel,
        lastNameLabel,
        dobLabel,
        phoneLabel,
        emailLabel,
        genderLabel,
        infoLabel,
    } = labels;

    /** Destructured context form states */
    const { title, firstName, middleName, lastName, dob, phoneNo, email, gender } = formData;

    const {
        [i18n.language]: { benefits: accountData },
    } = usePersistedPrismicStore((state) => state.registerData);

    /** Set Phone No in context */
    const setPhone = useCallback((phoneNo) => {
        setFormData((prev) => ({
            ...prev,
            phoneNo: {
                number: phoneNo,
            },
        }));
    }, []);

    const handleDataChange = (prop) => {
        return (value) => {
            setFormData((prev) => ({
                ...prev,
                [prop]: value,
            }));
        };
    };

    const setDobError = (err) => {
        setErrors((error) => ({
            ...error,
            dobError: err ? 'dobError' : '',
        }));
    };

    const validateGender = (_, value = '-') => {
        setErrors((prev) => ({ ...prev, genderError: value === '-' ? 'requiredError' : '' }));
    };

    const dobField = () => (
        <Grid item md={isDOBFullWidth ? 12 : 6} xs={12}>
            <DateTextField
                type="dateOfBirth"
                separator="."
                mainHeading={dobLabel || 'DOB'}
                setDOB={handleDataChange('dob')}
                dob={dob}
                setError={setDobError}
                error={errors.dobError}
                errorMessage={mainContent?.heraklesContent?.[errors.dobError]}
                placeHolderMonth="MM"
                placeHolderYear="YYYY"
                placeHolderDay="DD"
                mt={10}
            />
        </Grid>
    );

    const emailField = () => (
        <Grid item md={12} xs={12}>
            <TextInputField
                label={emailLabel || 'Email'}
                name="email"
                value={email}
                onChange={handleChange}
                disabled={!!user} /** Disabled when user logged in */
                validateForm={validateForm}
                errorName="email"
                errorMessage={mainContent?.heraklesContent?.[errors?.emailError]}
                formData={formData}
                errors={errors}
                setErrors={setErrors}
            />
        </Grid>
    );

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1} direction="row">
                {infoLabel && (
                    <Grid className={classes.mb30} item md={12} xs={12} direction="row">
                        <GrayTextBox text={infoLabel} />
                    </Grid>
                )}
                <Grid item md={12} xs={12} direction="row">
                    {heading ? (
                        <Typography className={clsx(classes.secondaryHeading, classes.my10)}>{heading}</Typography>
                    ) : null}
                </Grid>
                {titleLabel && (
                    <Grid item md={6} xs={12} direction="row">
                        <DropDown
                            type="default"
                            mainDataHeading={accountData ? titleLabel : 'Title'}
                            mainDataArray={accountData ? accountData?.title_values : []}
                            setData={handleDataChange('title')}
                            data={title || ''}
                            compulsory=""
                            validateForm={null}
                        />
                    </Grid>
                )}
                {genderLabel && (
                    <>
                        <Grid item md={6} xs={12} direction="row">
                            <DropDown
                                type="default"
                                mainDataHeading={accountData ? genderLabel : 'Gender'}
                                mainDataArray={accountData ? accountData?.gender_values : []}
                                setData={handleDataChange('gender')}
                                data={gender || ''}
                                compulsory="gender"
                                widthID="gender"
                                validateForm={validateGender}
                                error={errors.genderError}
                                errorMessage={mainContent?.heraklesContent?.[errors.genderError]}
                            />
                        </Grid>
                        {isOnlyGender && <Grid item md={6} xs={12} direction="row" />}
                    </>
                )}
                {haveGap ? <Grid item md={6} xs={12} direction="row" /> : null}
                <Grid item md={6} xs={12}>
                    <TextInputField
                        label={firstNameLabel || 'First Name'}
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        validateForm={validateForm}
                        errorName="firstName"
                        errorMessage={mainContent?.heraklesContent?.[errors?.firstNameError]}
                        formData={formData}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>
                {middleNameLabel && (
                    <Grid item md={6} xs={12}>
                        <TextInputField
                            label={middleNameLabel || 'Middle Name'}
                            name="middleName"
                            value={middleName}
                            onChange={handleChange}
                        />
                    </Grid>
                )}
                <Grid item md={6} xs={12}>
                    <TextInputField
                        label={lastNameLabel || 'Last Name'}
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        validateForm={validateForm}
                        errorName="lastName"
                        errorMessage={mainContent?.heraklesContent?.[errors?.lastNameError]}
                        formData={formData}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>

                {isDOBLast ? emailField() : dobField()}

                {phoneLabel && (
                    <Grid item md={6} xs={12}>
                        <PhoneInput
                            phone={phoneNo?.number}
                            setPhone={setPhone}
                            label={phoneLabel || 'Mobile Number (optional)'}
                            mt={10}
                            validateForm={validateForm}
                            errorName="phoneNo"
                            errorMessage={mainContent?.heraklesContent?.[errors?.phoneNoError]}
                            formData={formData}
                            errors={errors}
                            setErrors={setErrors}
                        />
                    </Grid>
                )}

                {isDOBLast ? dobField() : emailLabel ? emailField() : null}
            </Grid>
        </Grid>
    );
};

export default PersonalInformation;
