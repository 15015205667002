import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    textField: {
        height: '3.75em',
        marginTop: 10,
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
        marginTop: 10,
    },
    inputLabel: {
        overflow: 'visible',
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    filledInputRoot: {
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
}));

/**
 *
 * @param fullWidth
 * @param validateForm
 * @param errorName
 * @param errorMessage
 * @param setErrors
 * @param formData
 * @param ...props
 * @returns TextInput With Error Message
 */
const TextInputField = ({
    fullWidth = true,
    validateForm = () => {},
    errorName = '',
    errorMessage = '',
    errors = {},
    setErrors = () => {},
    formData = {},
    ...props
}) => {
    const classes = useStyles();
    return (
        <>
            <TextField
                variant="filled"
                color="primary"
                fullWidth={fullWidth}
                classes={{
                    root: errorMessage ? classes.textFieldsError : classes.textField,
                }}
                onBlur={() => validateForm(errorName, errors, setErrors, formData)}
                InputLabelProps={{
                    classes: {
                        root: classes.inputLabel,
                        focused: 'focused',
                        shrink: 'shrink',
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.filledInputRoot,
                        focused: classes.fieldFocused,
                        shrink: 'shrink',
                    },
                }}
                {...props}
            />
            <Grid xs="12" sm="12">
                {errorMessage ? <Typography className={classes.errorText}>{errorMessage}</Typography> : null}
            </Grid>
        </>
    );
};

export default TextInputField;
