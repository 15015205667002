import { Button, Menu, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import i18next from 'i18next';
import { memo, useEffect, useState } from 'react';

import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';

import { useStyles } from './DefaultDropDownStyles';
import MenuItemDropDown from './MenuItemDropDown';

const DefaultDropDown = ({
    mainDataHeading,
    mainDataArray,
    setData,
    data,
    compulsory,
    validateForm = null,
    widthID = '',
    disabled = false,
    value,
    fullWidth = true,
    error = '',
    errorMessage,
    noMarginTop = false,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const [privateCountryWidth, setPrivateCountryWidth] = useState('0');
    const [anchorEl, setAnchorEl] = useState(null);
    const [dropDownValue, setDropDownValue] = useState('');
    const [dropDownValueLocal, setDropDownValueLocal] = useState([]);
    console.debug('error112233', error);
    useEffect(() => {
        if (data !== undefined && data !== null && data.length > 0) {
            if (value !== 'insuranceType') setData(data);
            setDropDownValue(data);
        } else {
            setDropDownValue('');
        }
    }, [data]);

    useEffect(() => {
        const newArray = [];
        mainDataArray.forEach((e) => {
            const newObj = {};
            for (const key of Object.keys(e)) {
                if (typeof e[key] === 'string' || e[key] instanceof String) {
                    newObj.api_key = e[key];
                } else {
                    newObj.api_value = e[key];
                }
            }
            if (!newObj.api_key) newObj.api_key = newObj.api_value[0].text;
            newArray.push(newObj);
        });
        setDropDownValueLocal(newArray);
    }, [mainDataArray]);

    const handleCloseDefault = (defaultValue = '') => {
        setAnchorEl(null);
        console.debug('defaultValue', defaultValue);
        if (defaultValue !== '') {
            setDropDownValue(defaultValue);
            setData(defaultValue);
        }
        console.debug(defaultValue);
    };

    const selectedWidth = () => {
        if (widthID !== undefined && widthID !== null && widthID.length > 0) {
            return widthID;
        }
        return 'defaultDropDown';
    };

    const handleClickOpenDefault = (event) => {
        const w = document.getElementById(
            widthID !== undefined && widthID !== null && widthID.length > 0 ? widthID : 'defaultDropDown'
        ).clientWidth;
        setPrivateCountryWidth(w.toString());
        setAnchorEl(event.currentTarget);
    };

    return (
        <div>
            <Button
                aria-controls={selectedWidth()}
                aria-haspopup="true"
                onClick={handleClickOpenDefault}
                id={selectedWidth()}
                disableRipple
                className={clsx(
                    disabled ? classes.formControlDisable : classes.formControl,
                    widthID === 'gender' && error !== '' && classes.errorBorder
                )}
                classes={{ label: classes.menuLabel }}
                style={{ marginTop: noMarginTop ? 0 : 8 }}
                disabled={disabled}
            >
                <div className={classes.ButtonMainClass}>
                    <Typography className={classes.ButtonTextMainClass}>{mainDataHeading}</Typography>
                    <Typography
                        style={{
                            fontFamily: 'MessinaSans-SemiBold',
                            fontSize: '1rem',
                            color: theme.palette.common.darkGrey,
                            textTransform: 'none',
                            marginLeft: 3,
                            textAlign: 'left',
                        }}
                    >
                        {dropDownValueLocal.map((value) =>
                            dropDownValue === value.api_key ? (
                                <Typography
                                    className={classes.dropDownTextClass}
                                    style={{
                                        marginLeft: i18next.language === 'en' ? 0 : 0,
                                    }}
                                >
                                    {value.api_value[0]?.text?.length > 20
                                        ? !matchesXs &&
                                          !matches &&
                                          !matchesMd &&
                                          fullWidth &&
                                          value.api_value[0]?.text.length < 50
                                            ? value.api_value[0]?.text
                                            : matchesXs
                                              ? value.api_value[0]?.text?.slice(0, 25).concat('...')
                                              : matches
                                                ? value.api_value[0]?.text?.slice(0, 32).concat('...')
                                                : matchesMd
                                                  ? value.api_value[0]?.text?.slice(0, 27).concat('...')
                                                  : fullWidth
                                                    ? value.api_value[0]?.text?.slice(0, 56).concat('...')
                                                    : value.api_value[0]?.text?.slice(0, 23).concat('...')
                                        : value.api_value[0]?.text}
                                </Typography>
                            ) : null
                        )}
                    </Typography>
                </div>
                <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
            </Button>
            <Menu
                id={selectedWidth()}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    handleCloseDefault();
                    validateForm && validateForm(compulsory);
                }}
                PaperProps={{
                    style: {
                        width: parseFloat(privateCountryWidth),
                        paddingTop: 10,
                        height: 220,
                        backgroundColor: theme.palette.common.lightBrownDropdown,
                        border: `2px solid ${theme.palette.common.yellow}`,
                    },
                }}
            >
                <div
                    onClick={() => {
                        handleCloseDefault();
                        validateForm && validateForm(compulsory);
                    }}
                    className={classes.MenuButtonMainClass}
                >
                    <div>
                        <Typography
                            style={{
                                fontFamily: 'MessinaSans-Regular',
                                fontSize: '0.875rem',
                                color: theme.palette.common.darkGrey,
                                textTransform: 'none',
                                padding: 0,
                                height: 42,
                            }}
                        >
                            {mainDataHeading}
                        </Typography>
                    </div>
                    <img src={ic_arrow_bottom} alt="select" className={classes.OpendownArrow} />
                </div>

                {(widthID === 'gender' ? dropDownValueLocal.slice(1) : dropDownValueLocal).map((valu) => (
                    <MenuItemDropDown
                        validateForm={validateForm}
                        handleCloseDefault={handleCloseDefault}
                        valu={valu}
                        compulsory={compulsory}
                        dropDownValue={dropDownValue}
                    />
                ))}
            </Menu>
            {widthID === 'gender' && error !== '' && (
                <Typography className={classes.errorText}>{errorMessage}</Typography>
            )}
        </div>
    );
};
export default memo(DefaultDropDown);
