import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './UnderLinedAddButtonClasses';

const UnderLinedAddButton = (props) => {
    const { onClick, underLineText, marginTop = false } = props;
    const classes = useStyles();
    return (
        <div className={marginTop ? clsx(classes.flex, classes.marginTop) : classes.flex} onClick={onClick}>
            <Typography className={classes.smallText}> + &nbsp; </Typography>
            <Typography className={classes.underLinedText}> {underLineText}</Typography>
        </div>
    );
};

export default UnderLinedAddButton;
