import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';

/** Child Components */
import { ThankYou } from '@/components/ConsentForms';
import Loader from '@/components/Loader';

import { Context as AuthContext } from '@/context/AuthContext';

import useAutoLogoutAndRedirect from '@/hooks/useAutoLogoutAndRedirect';

import { getText } from '@/utils/helpers.js';

import { usePersistedPrismicStore } from '@/store.js';

const WELCOME_PAGE = '/extended-treatment-welcome';

// TODO unify with duplicate pages/ConsentForms/TreatmentContract/Pages/ThankYouPage.jsx
const ThankYouPage = () => {
    const { i18n } = useTranslation();
    //* * Prismic Content */
    const { [i18n.language]: content } = usePersistedPrismicStore((state) => state.treatmentContractWelcomeData);

    const {
        state: { user },
    } = useContext(AuthContext);

    const getUserText = (userText, guestText) => {
        return getText(content?.[user ? userText : guestText]);
    };

    // Autologout hook
    const { forceRedirect } = useAutoLogoutAndRedirect(WELCOME_PAGE);

    if (!content) return <Loader />;

    return (
        <ThankYou
            /** ICON */
            icon={content?.[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_guest']?.url || WelcomeIcon}
            iconHeight={content?.[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_guest']?.dimensions?.height}
            iconWidth={content?.[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_guest']?.dimensions?.width}
            title={getUserText('eterno_user_thank_you_title', 'guest_thank_you_title') || 'Thank You'}
            centerParagraph={
                getText(content?.thank_you_subtitle) ||
                'Your health profile is now synced and updated accordingly in the Eterno App. You can simply share it in the future with a selected professional or export it as a pdf.'
            }
            paragraph={getText(content?.thank_you_subtitle) || 'You can bring now the iPad to the Front Desk.'}
            completed
            buttonText={getText(content?.[user ? 'autologout_cta' : 'all_forms_thankyou_continue'])}
            onButtonClick={forceRedirect}
        />
    );
};

export default ThankYouPage;
