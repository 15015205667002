const OnlineAppointmentIcon = () => {
    return (
        <svg
            style={{ width: '1rem', height: '1rem', minHeight: '1rem', minWidth: '1rem' }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="3 6 18 12"
        >
            <path
                d="M15 7.5V16.5C15 17.3438 14.3125 18 13.5 18H4.5C3.65625 18 3 17.3438 3 16.5V7.5C3 6.6875 3.65625 6 4.5 6H13.5C14.3125 6 15 6.6875 15 7.5ZM21 8V16.0312C21 16.8125 20.0625 17.2812 19.4062 16.8438L16 14.4688V9.5625L19.4062 7.1875C20.0625 6.75 21 7.21875 21 8Z"
                fill="#242424"
            />
        </svg>
    );
};

export default OnlineAppointmentIcon;
