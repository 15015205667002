import { Helmet } from 'react-helmet';

import { useConfigStore } from '@/store.js';

const HtmlHead = () => {
    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    if (!currentCustomer) return null;

    return (
        <Helmet>
            <meta name="description" content={currentCustomer.html_description[0].text} />
            <title>{currentCustomer.html_title[0].text}</title>
            <link rel="icon" type="image/png" href={currentCustomer.favicon.url} />
        </Helmet>
    );
};

export default HtmlHead;
