import { useContext, useEffect } from 'react';

/** Re-usable user components */
import ContactInformation from '@/components/Common/User/ContactInformation';
import { ConsentStyles } from '@/components/ConsentForms';
import AllFormsPersonalInfo from '@/components/ConsentForms/Common/AllFormsPersonalInfo';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { ContextProvider } from '@/context/ProjectContext';

import useFormLabels, { pageNameLabels } from '@/hooks/useFormLabels';
import useI18nCountries from '@/hooks/useI18nCountries';
import useScrollToTop from '@/hooks/useScrollToTop';

const PersonalInformationPage = () => {
    /** Styles */
    const classes = ConsentStyles();

    useScrollToTop();

    /** Context */
    const { consentFormsContent: content, formData, setFormData, handleChange } = useContext(ConsentFormsContext);
    const { languageGlobal } = useContext(ContextProvider);

    /** Form Labels */
    const labels = useFormLabels(content);

    const countryList = useI18nCountries();

    const setCountry = (country) => {
        setFormData((prev) => ({
            ...prev,
            country: {
                code: country,
                name: countryList[country?.toLocaleUpperCase()],
            },
        }));
    };

    useEffect(() => {
        if (!Object.keys(formData?.country).length > 0 && Object.keys(countryList).length > 0) {
            setFormData((prev) => ({
                ...prev,
                country: {
                    code: 'de',
                    name: countryList.DE,
                },
            }));
        }
    }, [countryList]);

    return (
        <>
            <AllFormsPersonalInfo
                /** Form States */
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                /** Form Labels/Heading */
                heading={content?.heraklesContent?.introText || ''}
                labels={labels[pageNameLabels.PERSONAL_INFO]}
            />
            <div className={classes.mt30}>
                <ContactInformation
                    /** Form States */
                    formData={formData}
                    handleChange={handleChange}
                    /** Form Labels/Heading */
                    heading={content?.heraklesContent?.contactInfoTitle || ''}
                    labels={labels[pageNameLabels.CONTACT_INFO]}
                    /** Country */
                    isCountry
                    setCountry={setCountry}
                />
            </div>
        </>
    );
};

export default PersonalInformationPage;
