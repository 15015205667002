import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createQuestionObj } from '@/utils/helpers.js';

import { useBookingStore, usePersistedPrismicStore } from '@/store.js';

import NumberfieldQuestion, { NumberValidation } from '../CustomAnamnesis/QuestionTypes/NumberfieldQuestion';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import UnderlinedButton from '../UnderlinedButton';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        position: 'relative',
    },
    inputFieldWrapper: {
        width: '50%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    resendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '0.5rem',
        },
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
    },
    errorMessage: {
        color: '#da3030',
        fontSize: '0.75rem',
        marginTop: '0.5rem',
    },
}));

const VerificationCode = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const [isResendCtaDisabled, setIsResendCtaDisabled] = useState(false);
    const {
        [i18n.language]: { benefits: prismicData },
    } = usePersistedPrismicStore((state) => state.registerData);
    const { email, verificationCode } = useBookingStore((state) => state.registrationFormData);
    const setVerificationCode = useBookingStore((state) => state.setVerificationCode);
    const loading = useBookingStore((state) => state.loading);
    const isCodeIncorrect = useBookingStore((state) => state.isCodeIncorrect);
    const previouslyTriedCode = useBookingStore((state) => state.previouslyTriedCode);

    const resendCode = async () => {
        try {
            setIsResendCtaDisabled(true);

            await Auth.resendSignUp(email);

            const timeOut = 1000 * 60 * 5; // 5 minutes

            setTimeout(() => {
                setIsResendCtaDisabled(false);
            }, timeOut);
        } catch (err) {
            console.debug('error resending code: ', err);
        }
    };

    return (
        <div className={classes.container}>
            {loading && (
                <div className={classes.loadingOverlay}>
                    <EternoSpinner />
                </div>
            )}
            <div>
                <Typography>{prismicData.verify_code_textfield_placeholder}</Typography>
                <Typography>{email}</Typography>
            </div>
            <div className={classes.inputFieldWrapper}>
                <div style={{ width: '100%' }}>
                    <NumberfieldQuestion
                        question={createQuestionObj(
                            '',
                            prismicData.verification_code_input_label,
                            'verification-code',
                            {
                                number_field_validation: NumberValidation.WHOLE_NUMBER,
                            }
                        )}
                        answer={verificationCode}
                        setAnswer={(value) => setVerificationCode(value)}
                        hideTitle
                        customValidation={(value) =>
                            value.length === 6 && isCodeIncorrect ? value !== previouslyTriedCode : true
                        }
                    />
                    {isCodeIncorrect && verificationCode === previouslyTriedCode && (
                        <Typography className={classes.errorMessage}>{prismicData.invalid_code_message}</Typography>
                    )}
                </div>
            </div>
            <div className={classes.resendContainer}>
                <Typography>{prismicData.resend_code_prompt}</Typography>
                <UnderlinedButton
                    disabled={isResendCtaDisabled}
                    onPress={resendCode}
                    text={prismicData.resend_code_cta}
                    noMarginTop
                />
            </div>
        </div>
    );
};

export default VerificationCode;
