import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import my_appointment from '@/assets/images/Side-Nav-Icon.svg';

import Icon from '@/components/Icon/Icon';

import { dayjs } from '@/utils/dayjsSetup.js';

import AppointmentDetailsDocuments from './AppointmentDetailsDocuments';

const useStyles = makeStyles((theme) => ({
    mainCardHeading: {
        ...theme.typography.HL5,
        fontFamily: 'MessinaSans-SemiBold',
    },
    lightText: {
        fontFamily: 'MessinaSans-Regular',
    },
    buttoncontentText: {
        ...theme.typography.subheading,
    },
    innerMainClass: {
        margin: '1rem 0 1.3rem 0',
        borderRadius: '.7rem',
        padding: '1rem',
        backgroundColor: theme.palette.common.lightBrown,
        textAlign: 'left',
        display: 'flex',
        marginRight: '1rem',
        width: '95%',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            marginRight: '.67rem', // classes overrides
            display: 'inherit',
        },
    },
    innerIMG: {
        width: '3.5rem',
        height: '3.5rem',
        [theme.breakpoints.down('xs')]: {
            width: '2rem',
            height: '2rem',
            marginBottom: '.7rem',
        },
        marginRight: '1.3rem',
    },
    line: {
        height: '.07rem',
        width: '100%',
        backgroundColor: theme.palette.common.mediumBrown,
        marginBottom: '1.3rem',
    },
    wFull: {
        width: '100%',
    },
}));

const PastAppointmentDetails = ({ appointment, dataSet, mainData, dataSetDoctos, doctorLocation }) => {
    const classes = useStyles();

    return (
        <div className={classes.wFull}>
            <Typography className={classes.mainCardHeading}>
                {dataSet ? mainData.details_heading[0].text : 'Appointment Details'}
            </Typography>
            <div className={classes.innerMainClass}>
                <div className={classes.innerIMG}>
                    <Icon src={my_appointment} description="handwritten" width="100%" height="100%" />
                </div>
                <div>
                    <Typography className={classes.buttoncontentText} style={{ marginBottom: 5 }}>
                        {dataSetDoctos
                            ? dayjs(appointment?.start).format('dddd, MMMM D YYYY [at] HH:mm').replace(/,/g, ' ')
                            : ''}
                    </Typography>
                    <Typography className={classes.lightText} style={{ marginBottom: 15 }}>
                        {dataSetDoctos && doctorLocation?.address?.[0].text}
                    </Typography>
                </div>
            </div>
            <div className={classes.line} />
            <AppointmentDetailsDocuments
                dataSet={dataSet}
                mainData={mainData}
                appointment={appointment}
                enableFillDigitalDocument={false}
            />
        </div>
    );
};

export default PastAppointmentDetails;
