import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export const useStyles = makeStyles((theme) => ({
    selectItemCard: {
        backgroundColor: theme.palette.common.lightGray,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'inline-block',

        '&:hover': {
            backgroundColor: theme.palette.common.lightGray,
            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        },
        [theme.breakpoints.down('sm')]: {
            addingTop: 18,
            paddingBottom: 18,
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    selectedCard: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    selectItemCardBorder: {
        boxShadow: `0 0 0 1px ${theme.palette.common.mediumBrown}`,
    },
    BtncontentText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        textAlign: 'center',
    },
}));

const SelectableButton = ({ text, onClick = () => {}, selected = false, ...props }) => {
    const classes = useStyles();
    return (
        <Button
            onClick={onClick}
            disableRipple
            className={clsx(classes.selectItemCard, selected ? classes.selectedCard : classes.selectItemCardBorder)}
            {...props}
        >
            <Typography className={classes.BtncontentText}>{text}</Typography>
        </Button>
    );
};

export default SelectableButton;
