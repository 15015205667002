import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import DetailedIcon from './DetailedIcon';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingTop: 34,
        paddingBottom: 27,
        paddingLeft: 30,
        paddingRight: 30,
    },
    cardContentTransparent: {
        maxWidth: theme.layout.contentWidth,
        display: 'block',

        paddingLeft: 30,
        paddingRight: 30,
    },
}));

export default function IconBox({ background, icons, size, disabled }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    console.debug('prismicDataDash: icons', icons);

    return (
        <div className={background === 'transparent' ? classes.cardContentTransparent : classes.cardContent}>
            <Grid container spacing={2}>
                {icons?.length > 0 &&
                    icons.map((item) => {
                        {
                            console.debug('item-', item);
                        }
                        return (
                            <Grid key={item?.icon?.id} item md={12 / icons.length} xs={icons.length < 4 ? 12 : 6}>
                                <DetailedIcon disabled={item.disabled} item={item} size={size} />
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
}
