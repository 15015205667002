/** MUI */
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useContext } from 'react';

import tabTwoIconInactive from '@/assets/images/tabOneIconActive.svg';
import tabOneIconInactive from '@/assets/images/tabOneIconInactive.svg';
import tabTwoIconActive from '@/assets/images/tabTwoIconActive.svg';

/** Tabs Icons */
import tabOneIconActive from '@/assets/images/tabTwoIconInactive.svg';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import { useBookingStore } from '@/store.js';

/** Styles */
import consentFormsStyles from '../../ConsentFormsStyles';

/** Signature Canvas */
import SignaturePad from './SignaturePad';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
};

const SignatureTabs = ({ signCanvas, tabNumber, setTabNumber }) => {
    const classes = consentFormsStyles();

    /** Context */
    const {
        consentFormsContent: content,
        formData: { firstName, lastName },
    } = useContext(ConsentFormsContext);

    const handleChange = (event, newValue) => {
        setTabNumber(newValue);
        useBookingStore.setState({ isSignatureEmpty: newValue === 1 });
    };

    return (
        <div className={classes.tabsRoot}>
            <AppBar position="static" color="default" className={classes.tabsHeader}>
                <Tabs
                    value={tabNumber}
                    onChange={handleChange}
                    indicatorColor="#F5F5F5"
                    textColor="primary"
                    variant="standard"
                    aria-label="full width tabs example"
                    className={classes.tabs}
                >
                    <Tab
                        label={content ? content?.signaturePopup.generatedSignature : 'Generated signature'}
                        {...a11yProps(0)}
                        className={classes.tab}
                        disableRipple
                        icon={
                            <img src={!tabNumber ? tabOneIconActive : tabOneIconInactive} className={classes.tabIcon} />
                        }
                    />
                    <Tab
                        label={content ? content?.signaturePopup.ownSign : 'Own signature'}
                        {...a11yProps(1)}
                        className={classes.tab}
                        disableRipple
                        icon={
                            <img
                                alt="selection_tab"
                                src={tabNumber ? tabTwoIconActive : tabTwoIconInactive}
                                className={classes.tabIcon}
                            />
                        }
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={tabNumber} index={0} className={classes.tabPanel}>
                <Typography className={clsx(classes.digitalSignature, classes.textUnSelect, classes.translateCenter)}>
                    {`${firstName || ''} ${lastName || ''}`}
                </Typography>
            </TabPanel>
            <TabPanel value={tabNumber} index={1} className={classes.tabPanel}>
                {/* Signature Pad */}
                <SignaturePad ref={signCanvas} />
            </TabPanel>
        </div>
    );
};

export default SignatureTabs;
