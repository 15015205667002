import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { useCallback, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import close from '@/assets/Icons/close_grey.svg';

import { Context as AuthContext } from '@/context/AuthContext';
import { Context as DocumentContext } from '@/context/DocumentContext';
// Contexts
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

import { usePersistedPrismicStore, useUploadDocumentModalStore } from '../../store';
import FilledButton from '../FilledButton';
import UnderlinedButton from '../UnderlinedButton';
import FileDrop from './UploadDocumentComponents/FileDrop';
import FileUploaded from './UploadDocumentComponents/FileUploaded';

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        backgroundColor: 'transparent',
    },
    scrollRoot: {
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    text: {
        ...theme.typography.content,
        marginTop: 10,
    },
    title: {
        ...theme.typography.HL1,
        marginTop: 10,
        width: '90%',
        padding: 0,
    },
    btnClose: {
        position: 'absolute',
        right: 10,
    },
}));

const UploadDocumentModal = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesmd = useMediaQuery(theme.breakpoints.down('md'));
    const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesxl = useMediaQuery(theme.breakpoints.down('xl'));

    const { appointmentId, isModalOpen, closeModal, isDocumentUploaded } = useUploadDocumentModalStore(
        useShallow(({ appointmentId, isModalOpen, closeModal, isDocumentUploaded }) => ({
            appointmentId: appointmentId,
            isModalOpen: isModalOpen,
            closeModal: closeModal,
            isDocumentUploaded: isDocumentUploaded,
        }))
    );

    // context
    const {
        state: { acceptedFileName },
        resetFile,
        setIsDocumentAvailable,
        setAcceptedFileName,
    } = useContext(HealthProfileContextMain);

    const {
        state: { user, usersession, userDocuments },
        fetchUserAllDocuments,
    } = useContext(AuthContext);

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    // document type (will set by child component - TypeOfDoc.jsx)
    const [docType, setDocType] = useState('');

    const isUploadButtonDisabled = !acceptedFileName || !docType || !appointmentId;

    // ---- Document Context ----
    const {
        state: { isDocumentUploading },
        beginFileUpload,
    } = useContext(DocumentContext);

    // ---- document state ----
    const [file, setFile] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
    }, []);

    useEffect(() => {
        console.debug('files', file);

        setAcceptedFileName(file ? file.name : '');
    }, [file]);

    // ---- upload document ----
    const prepareToUpload = async (file = null) => {
        if (file && user && user.id !== undefined && usersession) {
            const data = {
                user_id: user.id,
                type: docType.toUpperCase(),
                mime_type: file.type,
                name: acceptedFileName,
                appointment_id: appointmentId,
                content_length: file.size,
            };
            console.debug('fileUpload: Preparing to process this data:', data);
            await beginFileUpload(data, file, usersession);
            const authUser = await Auth.currentAuthenticatedUser();
            await fetchUserAllDocuments(authUser.signInUserSession);
            useUploadDocumentModalStore.setState({ isDocumentUploaded: true });
            toast.success(content?.successful_upload_notification[0].text);
        }
    };

    const removeFile = () => {
        resetFile();
        setFile(null);
    };

    useEffect(() => {
        if (userDocuments?.data.length > 0) {
            setIsDocumentAvailable(true);
        }
    }, [userDocuments]);

    useEffect(() => {
        if (isDocumentUploaded) {
            removeFile();
            closeModal();
        }
    }, [isDocumentUploaded]);

    if (!content) return null;

    return (
        <Dialog
            open={isModalOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.dialogRoot,
            }}
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                    width: '609px',
                    borderRadius: 8,
                    paddingLeft: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                    paddingRight: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                    paddingTop: matches ? 20 : matchesmd ? 20 : matcheslg ? 20 : matchesxl && 20,
                    paddingBottom: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                },
            }}
        >
            <Button color="primary" className={classes.btnClose} onClick={closeModal}>
                <img
                    src={close}
                    style={{
                        width: 20,
                        height: 20,
                    }}
                />
            </Button>
            <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
                {content?.popup_title_upload[0].text ?? 'Upload your document'}
            </DialogTitle>

            <DialogContent
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    paddingBottom: 20,
                    paddingLeft: 0,
                    paddingRight: 0,
                    padding: 0,
                }}
            >
                <DialogContentText
                    id="alert-dialog-description"
                    classes={{
                        root: classes.text,
                    }}
                >
                    {content?.subtitle_upload[0].text}{' '}
                </DialogContentText>

                {file ? (
                    <FileUploaded docType={docType} setDocType={setDocType} removeFile={removeFile} />
                ) : (
                    <FileDrop onDrop={onDrop} />
                )}

                <div
                    style={{
                        display: 'flex',
                        flexDirection: matches ? 'column' : 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        marginTop: 20,
                    }}
                >
                    <FilledButton
                        disabled={isUploadButtonDisabled || isDocumentUploading}
                        text={isDocumentUploading ? <CircularProgress /> : content?.save_cta[0].text || 'Save'}
                        onPress={() => {
                            // upload file
                            prepareToUpload(file);
                        }}
                        fullWidth={matches && true}
                    />

                    <div
                        style={{
                            display: !matches ? 'flex' : 'contents',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <UnderlinedButton
                            text={content?.cancel_cta[0].text ?? 'Cancel'}
                            onPress={() => {
                                closeModal();
                                removeFile();
                            }}
                            noMarginTop={!matches && true}
                            marginLeft={!matches && 30}
                            fullWidth={matches && true}
                            noJSLeft
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UploadDocumentModal;
