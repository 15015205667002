import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import clsx from 'clsx';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';

import ArrowDown from '@/assets/images/ArrowDown.png';

// consent manager context
import { Context as ConsentManagerContext } from '@/context/ConsentManagerContext';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useUserDocumentDownload } from '@/hooks/useUserDocumentDownload';

import UnderlinedButton from '../../../UnderlinedButton';
// custom styles
import tableStyles from './tableStyles';

const TableRow = ({
    id,
    fileName = 'Labresults.jpg',
    type = 'Laboratory',
    date = '08 Aug 22',
    doctorName = 'doctor',
}) => {
    const classes = tableStyles();
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const downloadButtonRef = useRef();
    const [downloadUrl, setDownloadUrl] = useState('');
    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');
    const { handleDocumentDownload } = useUserDocumentDownload();

    const {
        state: { tableType },
    } = useContext(ConsentManagerContext);

    const getDocumentData = async (user, documentId) => {
        const response = await axios.get(
            `${import.meta.env.VITE_API_PROTOCOL}://${
                import.meta.env.VITE_API_DOMAIN
            }/secure/user/documents/${documentId}/?user_id=${user.signInUserSession.idToken.payload.sub}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.signInUserSession.idToken.jwtToken,
                },
            }
        );

        return response.data;
    };

    // link click handler
    const handleDocumentClick = async (documentId) => {
        if (isDocumentExchangeFeatureFlagActivated) {
            await handleDocumentDownload(documentId);
        } else {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const documentData = await getDocumentData(user, documentId);
                setDownloadUrl(documentData.download_url);
            } catch (error) {
                console.debug('🚀 Error: ', error);
            }
        }
    };

    useEffect(() => {
        if (downloadButtonRef.current && downloadUrl && downloadUrl !== '') downloadButtonRef.current?.click();
    }, [downloadUrl]);

    const fileNameCol = useCallback(
        () => (
            <Typography className={classes.rowTypeText}>
                {type} <span className={classes.fileName}>({fileName})</span>
            </Typography>
        ),
        [type, fileName]
    );

    const TextWrapper = ({ children }) => <Typography className={clsx(classes.rowText)}>{children}</Typography>;

    const renderColumnsByType = tableType ? <TextWrapper>{doctorName}</TextWrapper> : fileNameCol();
    const renderColumnsByDoctor = tableType ? fileNameCol() : <TextWrapper>{doctorName}</TextWrapper>;

    const mobileRow = () => {
        return (
            <Grid
                container
                className={classes.row}
                style={{ gap: 5, flexDirection: tableType ? 'column-reverse' : 'column' }}
            >
                <div className={classes.rowItem}>
                    <div className={classes.rowType}>{fileNameCol()}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <Grid item xs={6} className={classes.rowItem}>
                        <Typography className={clsx(classes.rowText, classes.rowItemHeight)}>
                            <TextWrapper>{doctorName}</TextWrapper>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={clsx(classes.rowItem, classes.jcEnd)}>
                        <Typography className={clsx(classes.rowText, classes.mt10, classes.textLeft)}>
                            {date}
                        </Typography>
                    </Grid>
                </div>
            </Grid>
        );
    };

    return (
        <Accordion
            className={classes.rowAccordian}
            expanded={expanded}
            onChange={() => setExpanded((prev) => !prev)}
            style={{ background: expanded ? '#FAFAFA' : null }}
        >
            <AccordionSummary
                disableRipple
                disableTouchRipple
                expandIcon={<img src={ArrowDown} className={classes.icon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {matchesXs ? (
                    mobileRow()
                ) : (
                    <Grid container className={classes.row}>
                        <Grid item sm={4} xs={6} className={classes.rowItem}>
                            <div className={classes.rowType}>{renderColumnsByType}</div>
                        </Grid>
                        <Grid
                            item
                            sm={5}
                            xs={6}
                            className={classes.rowItem}
                            style={{ paddingLeft: matches ? '1.5rem' : null }}
                        >
                            <Typography
                                className={clsx(
                                    classes.rowText,
                                    matchesXs ? classes.textRight : null,
                                    matchesXs ? classes.rowItemHeight : null
                                )}
                            >
                                {renderColumnsByDoctor}
                            </Typography>
                        </Grid>
                        <Grid item sm={3} xs={6} className={clsx(classes.rowItem, classes.textCenter)}>
                            <Typography
                                className={clsx(
                                    classes.rowText,
                                    matchesXs ? classes.mt10 : null,
                                    matchesXs ? classes.textLeft : null
                                )}
                            >
                                {date}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </AccordionSummary>
            <AccordionDetails className={classes.rowExpanded}>
                <Box className={classes.rowExpandedBox}>
                    <UnderlinedButton
                        text="Link"
                        onPress={() => {
                            handleDocumentClick(id);
                        }}
                        color={theme.palette.common.darkGrey}
                        fullWidth={false}
                        noMarginTop
                    />
                </Box>
                <button
                    onClick={() => window.open(downloadUrl, 'SingleSecondaryWindowName', 'noreferrer')}
                    className={classes.downloadButton}
                    ref={downloadButtonRef}
                >
                    Download
                </button>
            </AccordionDetails>
        </Accordion>
    );
};

export default memo(TableRow);
