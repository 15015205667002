import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import leftAngle from '@/assets/Icons/leftAngle.png';
import rightAngle from '@/assets/Icons/rightAngle.png';

import CancelDialogBox from '@/components/Common/CancelDialogBox';
import FilledButton from '@/components/FilledButton';
import UnderlinedButton from '@/components/UnderlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';
import { Context as BookAppointmentContext } from '@/context/BookAppointmentContext';
import { Context as DoctorsContext } from '@/context/DoctorsContext';
import { ContextProvider } from '@/context/ProjectContext';

import { getUserAppointments } from '@/service/Appointments/appointmentService';

import { trackStormlyEvent } from '@/utils/helpers.js';

import { useTrackingStore } from '@/store.js';

import MobileCalender from './Calender/MobileCalender';
import SecondaryLocationSelection from './SecondaryLocationSelection';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        position: 'relative',
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
    },
    heading: {
        ...theme.typography.HL1,
        fontWeight: 'bold',
    },
    subheading: {
        ...theme.typography.HL4,
        color: '#000',
        fontSize: 14,
    },
    categoryHeading: {
        ...theme.typography.HL5,
    },
    contentText: {
        ...theme.typography.HL5,
        textAlign: 'center',
    },
    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            height: 370,
        },
    },
    cardContentBottomClasses: {
        position: 'relative',
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        paddingBottom: 10,
        [theme.breakpoints.down('xs')]: {},
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    doctorImg: {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    findClassbutton: {
        height: '3.75em',
        width: '100%',
        backgroundColor: 'transparent',
        border: `solid 2px ${theme.palette.common.brown}`,
        marginTop: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    findClassText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    priceText: {
        ...theme.typography.HL1,
    },
    formControl: {
        width: '100%',

        backgroundColor: 'white',
        height: '4.3em',

        justifyContent: 'space-between',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    downArrow: {
        height: '0.75em',
        marginRight: 15,
    },
    menuItem: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
    },
    textFields: {
        height: '3.75em',
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
    },
    blackCircle: {
        backgroundColor: theme.palette.common.darkGrey,
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    greenCircle: {
        backgroundColor: '#5ba668',
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    passwordText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    passwordValidText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },

    selectionPopup: {
        height: 240,
        width: '100%',
        background: 'white',
        top: '2.5em',
        paddingTop: 1,
        zIndex: 9999,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            height: 280,
        },
        [theme.breakpoints.down('xs')]: {
            height: 100,
        },
    },
    closeButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    selectionContainer: {
        marginTop: 28,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            height: 18,
        },
        [theme.breakpoints.down('xs')]: {
            height: 18,
        },
    },
    selectionTitle: {
        ...theme.typography.content,

        textDecoration: 'underline',
    },
    userData: {
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',

        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    progressInner: {
        width: 300,
        [theme.breakpoints.down('sm')]: {
            width: 150,
        },
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 18,
        borderRadius: '2em',
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    disabledButton: {
        backgroundColor: theme.palette.common.yellow,
    },
    buttonText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'uppercase',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    icon: {
        borderRadius: '50%',
        width: 22,
        height: 22,
        backgroundColor: theme.palette.common.lightBrown,

        'input:hover ~ &': {
            backgroundColor: theme.palette.common.lightBrown,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.common.yellow,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 22,
            height: 22,
            backgroundImage: 'radial-gradient(#000,#000 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    radioRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    mainCenterCircle: {
        display: 'flex',
        height: 270,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    buttonContent: {
        marginTop: 2,
        marginLeft: 10,
    },
    calenderContainer: {
        display: 'flex',
    },
    calenderContainerMain: {
        display: 'contents',
        backgroundColor: 'purple',
    },
}));

const Calendar = ({
    setFormStage,
    data,
    setPatientDetails,
    selectedDoctor,
    setDetails,
    mainData,
    dataSet,
    mainBenefits,
    dataSetReg,
    history,
    useriner,
    timeSlots,
    getTime,
    doctor,
    isReturning,
    secondaryLocations,
    selectedSecondaryLocation,
    setSelectedSecondaryLocation,
    updateAppointmentData,
    appointmentSearch,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const [dateOut, setDateOut] = useState('');
    const [startDate] = useState(new Date());
    const [showAll, setShowAll] = useState(false);
    const continueButtonRef = useRef();
    console.debug('selectedDoctor123', selectedDoctor);
    const scrollToContinueButton = () => {
        if (!dateOut) {
            setTimeout(() => {
                continueButtonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 200);
        } else continueButtonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };
    const { activeResp } = useContext(ContextProvider);

    const {
        state: { appointmentSelection },
    } = useContext(BookAppointmentContext);

    const {
        state: { mainDataMyDoctorsID },
    } = useContext(DoctorsContext);
    const {
        state: { user, usersession },
    } = useContext(AuthContext);
    const [activeResponseData, setActiveResponseData] = useState([]);
    const [openDoubleBookingPopUp, setOpenDoubleBookingPopUp] = useState(false);
    const [doubleBookingAnyWay, setDoubleBookingAnyWay] = useState(false);
    // calendar double logic
    useEffect(() => {
        if (user) {
            getUserAppointments(usersession).then((response) => {
                const data = response?.data?.data?.active;
                setActiveResponseData(data);
            });
        }
    }, []);

    const dayAlreadyBooked = (doctorKey) => {
        const appointmentExists = activeResponseData?.find(
            (activeAppointments) =>
                activeAppointments.professional.key === doctorKey &&
                activeAppointments?.start?.split('T')[0] === appointmentSelection?.selected_appointment?.split('T')[0]
        );
        console.debug('inner', !!appointmentExists);
        return !!appointmentExists;
    };

    // calender logic ends

    function isNotCurrentUser() {
        if (
            user &&
            doctor !== selectedDoctor &&
            activeResp &&
            activeResp.length > 0 &&
            mainDataMyDoctorsID &&
            mainDataMyDoctorsID.length > 0 &&
            doctor &&
            !mainDataMyDoctorsID.includes(doctor?.key[0].text)
        ) {
            return true;
        }
        if (user && activeResp && activeResp.length === 0 && doctor !== selectedDoctor) {
            return true;
        }
        return !user && doctor !== selectedDoctor;
    }

    console.debug('isNotCurrentUser123', isNotCurrentUser());
    // else if (doctor !== selectedDoctor) {
    //     ;
    // }

    return (
        <div className={classes.mainContainer}>
            {!mainDataMyDoctorsID.includes(doctor?.key[0].text) && (
                <div
                    style={{
                        marginRight: matches ? 10 : 20,
                        borderRadius: 4,
                        paddingTop: 18,
                        paddingBottom: 18,
                        paddingLeft: 20,
                        paddingRight: 20,
                        backgroundColor: theme.palette.common.lightBrown,
                        textAlign: 'left',
                        border: `1px solid ${theme.palette.common.mediumBrown}`,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            className={classes.buttoncontentText}
                            style={{
                                fontFamily: 'MessinaSans-Regular',
                                fontSize: '1.125rem',
                                color: theme.palette.common.darkGrey,
                            }}
                        >
                            {dataSet ? mainData.available_slots_heading[0].text : 'Available slots'}{' '}
                            {dataSet ? mainData.for_cta[0].text : 'for'}{' '}
                            {isReturning
                                ? dataSet
                                    ? mainData.current_cta[0].text
                                    : 'current'
                                : dataSet
                                  ? mainData.new_cta[0].text
                                  : 'new'}{' '}
                            {dataSet ? mainData.patients_cta[0].text : 'patients'}
                        </Typography>
                        <div className={classes.buttonContent}>
                            <UnderlinedButton
                                noMarginTop
                                text={dataSet ? mainData.change_button_text[0].text : t('change')}
                                onPress={() => {
                                    setFormStage(1, doctor);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}

            {appointmentSearch.location_key !== 'online' && secondaryLocations.length > 1 && (
                <SecondaryLocationSelection
                    selectedSecondaryLocation={selectedSecondaryLocation}
                    setSelectedSecondaryLocation={setSelectedSecondaryLocation}
                    secondaryLocations={secondaryLocations}
                    setPatientDetails={setPatientDetails}
                    startDate={startDate}
                    dataSet={dataSet}
                    mainData={mainData}
                />
            )}

            {/* SIMPLE TEXT */}
            <Typography
                className={classes.contentText}
                style={{
                    marginTop: !mainDataMyDoctorsID.includes(doctor?.key[0].text) ? 20 : 0,
                    fontFamily: 'MessinaSans-Regular',
                    fontSize: '1.125rem',
                    color: theme.palette.common.darkGrey,
                }}
            >
                {dataSet ? mainData.select_time_slot[0].text : 'Select a time slot to continue'}
            </Typography>
            <div className="">
                <div className={classes.calenderContainer}>
                    <ButtonBase
                        disableRipple
                        onClick={() => {
                            useTrackingStore.setState({
                                calendarMoves: [...useTrackingStore.getState().calendarMoves, 'backward'],
                            });
                            let dateOffset;
                            if (!matchesMd) dateOffset = 24 * 60 * 60 * 1000 * 5;
                            else dateOffset = 24 * 60 * 60 * 1000 * 3;
                            const myDate = startDate;
                            myDate.setTime(myDate.getTime() - dateOffset);
                            setPatientDetails('nothing', myDate);
                            setShowAll(false);
                        }}
                        style={{
                            marginLeft: matchesXs ? -25 : matches ? -30 : null,
                            marginTop: 50,
                            position: 'absolute',
                            zIndex: '2',
                        }}
                    >
                        <img src={leftAngle} alt="" />
                    </ButtonBase>

                    <div className={classes.calenderContainerMain}>
                        <MobileCalender
                            setDateOut={setDateOut}
                            data={data}
                            setPatientDetails={setPatientDetails}
                            setDetails={setDetails}
                            mainData={mainData}
                            dataSet={dataSet}
                            mainBenefits={mainBenefits}
                            dataSetReg={dataSetReg}
                            timeSlots={timeSlots}
                            getTime={getTime}
                            showAll={showAll}
                            setShowAll={setShowAll}
                            scrollToContinueButton={scrollToContinueButton}
                            startDate={startDate}
                            doctor={doctor}
                        />
                    </div>

                    <ButtonBase
                        disableRipple
                        onClick={() => {
                            useTrackingStore.setState({
                                calendarMoves: [...useTrackingStore.getState().calendarMoves, 'forward'],
                            });
                            let dateOffset;
                            if (!matchesMd) dateOffset = 24 * 60 * 60 * 1000 * 5;
                            else dateOffset = 24 * 60 * 60 * 1000 * 3;
                            const myDate = startDate;
                            myDate.setTime(myDate.getTime() + dateOffset);
                            setPatientDetails('nothing', myDate);
                            setShowAll(false);
                        }}
                        style={{
                            right: matchesXs ? 30 : matches ? 60 : 60,
                            marginTop: 50,
                            position: 'absolute',
                            zIndex: '2',
                        }}
                    >
                        <img src={rightAngle} alt="" />
                    </ButtonBase>
                </div>
                {dateOut !== '' ? (
                    <div
                        className=""
                        style={{
                            marginTop: 30,
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <FilledButton
                            ref={continueButtonRef}
                            onPress={() => {
                                updateAppointmentData();
                                if (!doubleBookingAnyWay && dayAlreadyBooked(doctor?.key[0].text)) {
                                    setOpenDoubleBookingPopUp(true);
                                    return;
                                }
                                console.debug('userineruserineruseriner', useriner);
                                trackStormlyEvent('doctorSelected', {
                                    selectedDoctor: selectedDoctor.display_name[0].text,
                                });
                                trackStormlyEvent('calendarMoved', {
                                    calendarMoves: useTrackingStore.getState().calendarMoves,
                                });
                                if (useriner === null) {
                                    // alert("if")
                                    setFormStage(3, selectedDoctor, false);
                                } else {
                                    // alert("else")
                                    setFormStage(4, selectedDoctor, false);
                                }
                            }}
                            text={dataSet ? mainData.continue_button[0].text : t('Continue')}
                        />
                    </div>
                ) : null}
            </div>

            <CancelDialogBox
                open={openDoubleBookingPopUp}
                onClose={() => {
                    console.debug('closing dialog');
                    setOpenDoubleBookingPopUp(false);
                }}
                onPress={() => {
                    setDoubleBookingAnyWay(true);
                    setOpenDoubleBookingPopUp(false);
                    // copied data
                    trackStormlyEvent('doctorSelected', {
                        selectedDoctor: selectedDoctor.display_name[0].text,
                    });
                    trackStormlyEvent('calendarMoved', {
                        calendarMoves: useTrackingStore.getState().calendarMoves,
                    });
                    if (useriner === null) {
                        setFormStage(3, selectedDoctor, false);
                    } else {
                        setFormStage(4, selectedDoctor, false);
                    }
                    // copied data ends
                }}
                title={mainData?.double_booking_pop_up_title[0]?.text}
                text={mainData?.double_booking_pop_up_text[0]?.text}
                cancelButton
                cancelButtonText={mainData?.double_booking_pop_up_cancel_cta[0]?.text}
                mainButton
                mainButtonText={mainData?.double_booking_pop_up_continue_cta[0]?.text}
                onCancelClick={() => {
                    history.replace('/appointments');
                }}
                onClickCustom
                filledButtonColor="yellow"
            />
        </div>
    );
};

export default Calendar;
