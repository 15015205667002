import { Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
//* * Icons */
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    textField: {
        height: '3.75em',
        marginTop: 10,
    },
    inputLabel: {
        overflow: 'visible',
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    filledInputRoot: {
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    blackCircle: {
        backgroundColor: theme.palette.common.darkGrey,
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    greenCircle: {
        backgroundColor: '#5ba668',
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    passwordText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    passwordValidText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
}));

const hasNumber = (myString) => {
    return /\d/.test(myString);
};

const hasSpecial = (myString) => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(myString);
};

/**
 *
 * @param {fullWidth, errorState, errorMessage, ...rest} RestProps (type, label, value, onChange etc)
 * @returns Reusable TextInput With Error Message
 */
const PasswordField = ({
    fullWidth = true,
    errorMessage = '',
    hideValidMessage = false,
    value = '',
    onChange = () => {},
    validatePassword = () => {},
    //* * Password Options *//
    passwordOptionHeading = '',
    optionOne = '',
    optionTwo = '',
    optionThree = '',
    ...props
}) => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    //* * Error States */
    const [passwordValid, setPasswordValid] = useState({
        characters: false,
        number: false,
        special: false,
        password: '',
    });

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (
        <>
            <TextField
                variant="filled"
                color="primary"
                fullWidth={fullWidth}
                type={showPassword ? 'text' : 'password'}
                classes={{
                    root: classes.textField,
                }}
                value={value}
                onBlur={validatePassword}
                onChange={(event) => {
                    const password = event.target.value;

                    //* * Setting password to prop setter */
                    onChange(password);

                    let characters = false;

                    if (password.length > 7) {
                        characters = true;
                    }

                    const number = hasNumber(password);
                    const special = hasSpecial(password);

                    setPasswordValid({
                        characters: characters,
                        number: number,
                        special: special,
                        password: password,
                    });
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.inputLabel,
                        focused: 'focused',
                        shrink: 'shrink',
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.filledInputRoot,
                        focused: classes.fieldFocused,
                        shrink: 'shrink',
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                {...props}
            />
            {/* Required Error Message */}
            {errorMessage || value === '' ? (
                <Typography className={classes.errorText}>{errorMessage}</Typography>
            ) : null}

            {/* Valid Password Message */}
            {!hideValidMessage && passwordValid.password !== '' ? (
                <div>
                    <Typography className={classes.passwordText} style={{ marginTop: 8 }}>
                        {passwordOptionHeading || 'Your password must contain:'}
                    </Typography>
                    {/* Password Options */}
                    <Grid container alignItems="center">
                        <Grid item>
                            <div className={passwordValid.characters ? classes.greenCircle : classes.blackCircle} />
                        </Grid>
                        <Grid item>
                            <Typography
                                className={passwordValid.characters ? classes.passwordValidText : classes.passwordText}
                            >
                                {optionOne || 'At least 8 characters'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item>
                            <div className={passwordValid.number ? classes.greenCircle : classes.blackCircle} />
                        </Grid>
                        <Grid item>
                            <Typography
                                className={passwordValid.number ? classes.passwordValidText : classes.passwordText}
                            >
                                {optionTwo || 'A number'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center">
                        <Grid item>
                            <div className={passwordValid.special ? classes.greenCircle : classes.blackCircle} />
                        </Grid>
                        <Grid item>
                            <Typography
                                className={passwordValid.special ? classes.passwordValidText : classes.passwordText}
                            >
                                {optionThree || 'A special character'}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            ) : null}
        </>
    );
};

export default PasswordField;
