import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

import { menuItems } from '@/utils/helpers.js';

import { usePersistedPrismicStore } from '@/store.js';

import CardHeading from './CardHeading';
import CardWrapper from './CardWrapper';

const useStyles = makeStyles((theme) => ({
    pt: { paddingTop: '1.5rem' },
    pb: { paddingBottom: '1.5rem' },
    bTop: { borderTop: '3px solid #F5F5F5' },
    bBottom: { borderBottom: '3px solid #F5F5F5' },
    italicHeading: {
        fontFamily: 'MessinaSerif-Regular',
        fontStyle: 'italic',
        fontSize: '24px',
    },
    bottomContainer: {
        marginTop: 15,
    },
    icons: {
        display: 'flex',
        gap: '.8rem',
    },
    habitIcon: theme.icons.secondary,
}));

const nthTimes = [1, 2, 3, 4, 5, 6, 7];
const menuList = menuItems();
const lastMenuIndex = menuList.length - 1;

const HealthLifestyle = ({ dietAnswers = [], noTimes, smoke = false, drink = false }) => {
    const classes = useStyles();
    const history = useHistory();
    const { i18n } = useTranslation();
    const {
        state: {},
        setIsEditOverview,
    } = useContext(HealthProfileContextMain);

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.healthProfileData);

    // Won't re-renders if another state changes
    const activeDays = useMemo(() => {
        return nthTimes.indexOf(parseInt(noTimes)) + 1;
    }, [noTimes]);

    const handleEdit = () => {
        history.push(`/health-data?step=${menuList[lastMenuIndex]}`);
        setIsEditOverview(true);
    };

    const showBottom = activeDays !== 0 || smoke || drink;

    return (
        <>
            {Object.keys(content).length !== 0 && (
                <CardWrapper isEditable onEdit={handleEdit}>
                    <CardHeading>{content?.lifestyle_title[0].text}</CardHeading>
                    {dietAnswers.length > 0 && (
                        <div
                            className={clsx(
                                classes.pt,
                                showBottom ? classes.pb : null,
                                classes.bTop,
                                showBottom ? classes.bBottom : null
                            )}
                        >
                            <CardHeading style={{ marginBottom: 10 }}>
                                {content?.your_diet_subtitle[0].text}
                            </CardHeading>
                            <Typography className={classes.italicHeading}>{dietAnswers.join(', ')}</Typography>
                        </div>
                    )}
                    {showBottom && (
                        <Grid container spacing={2} className={classes.bottomContainer}>
                            <Grid item md={6} xs={12}>
                                <CardHeading>{content?.sports_physical_activity_title[0].text}</CardHeading>
                                <div className={classes.icons}>
                                    {/* Icons */}
                                    {nthTimes.map((i, index) => {
                                        return (
                                            <img
                                                key={index}
                                                src={content?.sports_physical_activity_icon_inactive.url}
                                                width={content?.sports_physical_activity_icon_inactive.dimensions.width}
                                                height={
                                                    content?.sports_physical_activity_icon_inactive.dimensions.width
                                                }
                                                className={i <= activeDays ? classes.habitIcon : ''}
                                                alt={
                                                    content?.[
                                                        i <= activeDays
                                                            ? 'sports_physical_activity_icon_active'
                                                            : 'sports_physical_activity_icon_inactive'
                                                    ].alt
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <CardHeading>{content?.other_habits_subtitle[0].text}</CardHeading>
                                <div className={classes.icons}>
                                    {/* Icons */}
                                    {smoke && (
                                        <img
                                            src={content?.smoking_habit_icon.url}
                                            width={content?.smoking_habit_icon.dimensions.width}
                                            height={content?.smoking_habit_icon.dimensions.width}
                                            alt={content?.smoking_habit_icon.alt}
                                            className={classes.habitIcon}
                                        />
                                    )}
                                    {drink && (
                                        <img
                                            src={content?.drinking_habit_icon.url}
                                            width={content?.drinking_habit_icon.dimensions.width}
                                            height={content?.smoking_habit_icon.dimensions.width}
                                            alt={content?.drinking_habit_icon.alt}
                                            className={classes.habitIcon}
                                        />
                                    )}
                                    {!smoke && !drink && '-'}
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </CardWrapper>
            )}
        </>
    );
};

export default memo(HealthLifestyle);
