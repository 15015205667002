export const insurance_list_public = [
    {
        api_value: 'AOK - Die Gesundheitskasse für Niedersachsen',
        display_value: [{ text: 'AOK - Die Gesundheitskasse für Niedersachsen' }],
    },
    {
        api_value: 'AOK - Die Gesundheitskasse in Hessen',
        display_value: [{ text: 'AOK - Die Gesundheitskasse in Hessen' }],
    },
    { api_value: 'AOK Baden-Württemberg', display_value: [{ text: 'AOK Baden-Württemberg' }] },
    { api_value: 'AOK Bayern - Die Gesundheitskasse', display_value: [{ text: 'AOK Bayern - Die Gesundheitskasse' }] },
    { api_value: 'AOK Bremen / Bremerhaven', display_value: [{ text: 'AOK Bremen / Bremerhaven' }] },
    {
        api_value: 'AOK Nordost - Die Gesundheitskasse',
        display_value: [{ text: 'AOK Nordost - Die Gesundheitskasse' }],
    },
    {
        api_value: 'AOK NordWest - Die Gesundheitskasse',
        display_value: [{ text: 'AOK NordWest - Die Gesundheitskasse' }],
    },
    {
        api_value: 'AOK PLUS - Die Gesundheitskasse für Sachsen und Thüringen',
        display_value: [{ text: 'AOK PLUS - Die Gesundheitskasse für Sachsen und T]hüringen' }],
    },
    {
        api_value: 'AOK Rheinland/Hamburg - Die Gesundheitskasse',
        display_value: [{ text: 'AOK Rheinland/Hamburg - Die Gesundheitskasse' }],
    },
    {
        api_value: 'AOK Rheinland-Pfalz/Saarland-Die Gesundheitskasse',
        display_value: [{ text: 'AOK Rheinland-Pfalz/Saarland-Die Gesundheitskasse' }],
    },
    {
        api_value: 'AOK Sachsen-Anhalt - Die Gesundheitskasse',
        display_value: [{ text: 'AOK Sachsen-Anhalt - Die Gesundheitskasse' }],
    },
    { api_value: 'Audi BKK', display_value: [{ text: 'Audi BKK' }] },
    { api_value: 'BAHN-BKK', display_value: [{ text: 'BAHN-BKK' }] },
    { api_value: 'BARMER', display_value: [{ text: 'BARMER' }] },
    { api_value: 'BERGISCHE KRANKENKASSE', display_value: [{ text: 'BERGISCHE KRANKENKASSE' }] },
    { api_value: 'Bertelsmann BKK', display_value: [{ text: 'Bertelsmann BKK' }] },
    { api_value: 'Betriebskrankenkasse Mobil', display_value: [{ text: 'Betriebskrankenkasse Mobil' }] },
    {
        api_value: 'Betriebskrankenkasse PricewaterhouseCoopers',
        display_value: [{ text: 'Betriebskrankenkasse P]ricewaterhouseCoopers' }],
    },
    { api_value: 'BIG direkt gesund', display_value: [{ text: 'BIG direkt gesund' }] },
    { api_value: 'BKK Akzo Nobel Bayern', display_value: [{ text: 'BKK Akzo Nobel Bayern' }] },
    { api_value: 'BKK B. Braun Aesculap', display_value: [{ text: 'BKK B. Braun Aesculap' }] },
    { api_value: 'BKK BPW Bergische Achsen KG', display_value: [{ text: 'BKK BPW Bergische Achsen KG' }] },
    { api_value: 'BKK Deutsche Bank AG', display_value: [{ text: 'BKK Deutsche Bank AG' }] },
    { api_value: 'BKK Diakonie', display_value: [{ text: 'BKK Diakonie' }] },
    { api_value: 'BKK EUREGIO', display_value: [{ text: 'BKK EUREGIO' }] },
    { api_value: 'BKK EVM', display_value: [{ text: 'BKK EVM' }] },
    { api_value: 'BKK EWE', display_value: [{ text: 'BKK EWE' }] },
    { api_value: 'BKK exklusiv', display_value: [{ text: 'BKK exklusiv' }] },
    { api_value: 'BKK Faber-Castell & Partner', display_value: [{ text: 'BKK Faber-Castell & Partner' }] },
    { api_value: 'BKK firmus', display_value: [{ text: 'BKK firmus' }] },
    { api_value: 'BKK Freudenberg', display_value: [{ text: 'BKK Freudenberg' }] },
    { api_value: 'BKK GILDEMEISTER SEIDENSTICKER', display_value: [{ text: 'BKK GILDEMEISTER SEIDENSTICKER' }] },
    { api_value: 'BKK Groz-Beckert', display_value: [{ text: 'BKK Groz-Beckert' }] },
    { api_value: 'BKK Herkules', display_value: [{ text: 'BKK Herkules' }] },
    { api_value: 'BKK KARL MAYER', display_value: [{ text: 'BKK KARL MAYER' }] },
    { api_value: 'BKK Linde', display_value: [{ text: 'BKK Linde' }] },
    { api_value: 'BKK MAHLE', display_value: [{ text: 'BKK MAHLE' }] },
    { api_value: 'BKK Melitta Hmr', display_value: [{ text: 'BKK Melitta Hmr' }] },
    { api_value: 'BKK Miele', display_value: [{ text: 'BKK Miele' }] },
    { api_value: 'BKK MTU', display_value: [{ text: 'BKK MTU' }] },
    { api_value: 'BKK PFAFF', display_value: [{ text: 'BKK PFAFF' }] },
    { api_value: 'BKK Pfalz', display_value: [{ text: 'BKK Pfalz' }] },
    { api_value: 'BKK ProVita', display_value: [{ text: 'BKK ProVita' }] },
    { api_value: 'BKK Public', display_value: [{ text: 'BKK Public' }] },
    { api_value: 'BKK Rieker.RICOSTA.Weisser', display_value: [{ text: 'BKK Rieker.RICOSTA.Weisser' }] },
    { api_value: 'BKK Salzgitter', display_value: [{ text: 'BKK Salzgitter' }] },
    { api_value: 'BKK Scheufelen', display_value: [{ text: 'BKK Scheufelen' }] },
    { api_value: 'BKK Schwarzwald-Baar-Heuberg', display_value: [{ text: 'BKK Schwarzwald-Baar-Heuberg' }] },
    { api_value: 'BKK STADT AUGSBURG', display_value: [{ text: 'BKK STADT AUGSBURG' }] },
    { api_value: 'BKK Technoform', display_value: [{ text: 'BKK Technoform' }] },
    { api_value: 'BKK Textilgruppe Hof', display_value: [{ text: 'BKK Textilgruppe Hof' }] },
    { api_value: 'BKK VDN', display_value: [{ text: 'BKK VDN' }] },
    { api_value: 'BKK VerbundPlus', display_value: [{ text: 'BKK VerbundPlus' }] },
    { api_value: 'BKK Verkehrsbau Union (BKK VBU)', display_value: [{ text: 'BKK Verkehrsbau Union (BKK VBU)' }] },
    { api_value: 'BKK Voralb HELLER*INDEX*LEUZE', display_value: [{ text: 'BKK Voralb HELLER*INDEX*LEUZE' }] },
    { api_value: 'BKK Werra-Meissner', display_value: [{ text: 'BKK Werra-Meissner' }] },
    { api_value: 'BKK Wirtschaft & Finanzen', display_value: [{ text: 'BKK Wirtschaft & Finanzen' }] },
    { api_value: 'BKK Würth', display_value: [{ text: 'BKK Würth' }] },
    { api_value: 'BKK ZF & Partner', display_value: [{ text: 'BKK ZF & Partner' }] },
    { api_value: 'BKK DürkoppAdler', display_value: [{ text: 'BKK DürkoppAdler' }] },
    { api_value: 'BKK 24', display_value: [{ text: 'BKK 24' }] },
    { api_value: 'BMW BKK', display_value: [{ text: 'BMW BKK' }] },
    { api_value: 'Bosch BKK', display_value: [{ text: 'Bosch BKK' }] },
    { api_value: 'Continentale Betriebskrankenkasse', display_value: [{ text: 'Continentale Betriebskrankenkasse' }] },
    { api_value: 'Daimler Betriebskrankenkasse', display_value: [{ text: 'Daimler Betriebskrankenkasse' }] },
    { api_value: 'DAK-Gesundheit', display_value: [{ text: 'DAK-Gesundheit' }] },
    { api_value: 'Debeka BKK', display_value: [{ text: 'Debeka BKK' }] },
    { api_value: 'Energie-Betriebskrankenkasse', display_value: [{ text: 'Energie-Betriebskrankenkasse' }] },
    { api_value: 'Ernst & Young BKK', display_value: [{ text: 'Ernst & Young BKK' }] },
    { api_value: 'Handelskrankenkasse (hkk)', display_value: [{ text: 'Handelskrankenkasse (hkk)' }] },
    { api_value: 'Heimat Krankenkasse', display_value: [{ text: 'Heimat Krankenkasse' }] },
    { api_value: 'HEK - Hanseatische Krankenkasse', display_value: [{ text: 'HEK - Hanseatische Krankenkasse' }] },
    { api_value: 'IKK - Die Innovationskasse', display_value: [{ text: 'IKK - Die Innovationskasse' }] },
    { api_value: 'IKK Brandenburg und Berlin', display_value: [{ text: 'IKK Brandenburg und Berlin' }] },
    { api_value: 'IKK classic', display_value: [{ text: 'IKK classic' }] },
    { api_value: 'IKK gesund plus', display_value: [{ text: 'IKK gesund plus' }] },
    { api_value: 'IKK Südwest', display_value: [{ text: 'IKK Südwest' }] },
    { api_value: 'Kaufmännische Krankenkasse - KKH', display_value: [{ text: 'Kaufmännische Krankenkasse - KKH' }] },
    { api_value: 'KNAPPSCHAFT', display_value: [{ text: 'KNAPPSCHAFT' }] },
    { api_value: 'Koenig & Bauer BKK', display_value: [{ text: 'Koenig & Bauer BKK' }] },
    { api_value: 'Krones BKK', display_value: [{ text: 'Krones BKK' }] },
    { api_value: 'Merck BKK', display_value: [{ text: 'Merck BKK' }] },
    { api_value: 'Mhplus Betriebskrankenkasse', display_value: [{ text: 'Mhplus Betriebskrankenkasse' }] },
    { api_value: 'Novitas BKK', display_value: [{ text: 'Novitas BKK' }] },
    { api_value: 'Pronova BKK', display_value: [{ text: 'Pronova BKK' }] },
    { api_value: 'R+V Betriebskrankenkasse', display_value: [{ text: 'R+V Betriebskrankenkasse' }] },
    { api_value: 'Salus BKK', display_value: [{ text: 'Salus BKK' }] },
    { api_value: 'SECURVITA BKK', display_value: [{ text: 'SECURVITA BKK' }] },
    {
        api_value: 'Siemens-Betriebskrankenkasse (SBK)',
        display_value: [{ text: 'Siemens-Betriebskrankenkasse (SBK)' }],
    },
    { api_value: 'SKD BKK', display_value: [{ text: 'SKD BKK' }] },
    {
        api_value: 'Sozialversicherung für Landwirtschaft, Forsten und Gartenbau (SVLFG)',
        display_value: [{ text: 'Sozialversicherung für Landwirtschaft, Forsten und Gartenbau (SVLFG)]' }],
    },
    { api_value: 'Südzucker BKK', display_value: [{ text: 'Südzucker BKK' }] },
    { api_value: 'Techniker Krankenkasse', display_value: [{ text: 'Techniker Krankenkasse' }] },
    { api_value: 'TUI BKK', display_value: [{ text: 'TUI BKK' }] },
    { api_value: 'VIACTIV Krankenkasse', display_value: [{ text: 'VIACTIV Krankenkasse' }] },
    { api_value: 'Vivida bkk', display_value: [{ text: 'Vivida bkk' }] },
    { api_value: 'WMF Betriebskrankenkasse', display_value: [{ text: 'WMF Betriebskrankenkasse' }] },
];

export const insurance_list_private = [
    { api_value: 'ALTE OLDENBURGER', display_value: [{ text: 'ALTE OLDENBURGER' }] },
    { api_value: 'ARAG', display_value: [{ text: 'ARAG' }] },
    { api_value: 'ASSTEL', display_value: [{ text: 'ASSTEL' }] },
    { api_value: 'AXA', display_value: [{ text: 'AXA' }] },
    { api_value: 'Allianz', display_value: [{ text: 'Allianz' }] },
    { api_value: 'BBV-Krankenversicherung', display_value: [{ text: 'BBV-Krankenversicherung' }] },
    { api_value: 'Barmenia', display_value: [{ text: 'Barmenia' }] },
    {
        api_value: 'Bayerische Beamten Versicherung AG',
        display_value: [{ text: 'Bayerische Beamten Versicherung AG' }],
    },
    {
        api_value: 'Bayerische Beamtenkrankenkasse (BK/VKB)',
        display_value: [{ text: 'Bayerische Beamtenkrankenkasse (BK/VKB)' }],
    },
    { api_value: 'Berlin-Kölnische', display_value: [{ text: 'Berlin-Kölnische' }] },
    { api_value: 'CENTRAL', display_value: [{ text: 'CENTRAL' }] },
    { api_value: 'CONCORDIA', display_value: [{ text: 'CONCORDIA' }] },
    { api_value: 'Continentale', display_value: [{ text: 'Continentale' }] },
    { api_value: 'DBV-Winterthur', display_value: [{ text: 'DBV-Winterthur' }] },
    { api_value: 'DEUTSCHER RING', display_value: [{ text: 'DEUTSCHER RING' }] },
    { api_value: 'DEVK', display_value: [{ text: 'DEVK' }] },
    {
        api_value: 'DKV (Deutsche Krankenversicherung)',
        display_value: [{ text: 'DKV (Deutsche Krankenversicherung)' }],
    },
    { api_value: 'Debeka', display_value: [{ text: 'Debeka' }] },
    {
        api_value: 'ERGO Direkt Krankenversicherung AG',
        display_value: [{ text: 'ERGO Direkt Krankenversicherung AG' }],
    },
    { api_value: 'EUROPA', display_value: [{ text: 'EUROPA' }] },
    { api_value: 'Envivas Krankenversicherung AG', display_value: [{ text: 'Envivas Krankenversicherung AG' }] },
    { api_value: 'Evang. Pfarrverein Württemberg', display_value: [{ text: 'Evang. Pfarrverein Württemberg' }] },
    {
        api_value: 'FREIE ARZT- UND MEDIZINKASSE (FAMK)',
        display_value: [{ text: 'FREIE ARZT- UND MEDIZINKASSE (FAMK)' }],
    },
    {
        api_value: 'Generali Deutschland Krankenversicherung AG',
        display_value: [{ text: 'Generali Deutschland Krankenversicherung AG' }],
    },
    { api_value: 'Globale', display_value: [{ text: 'Globale' }] },
    { api_value: 'Gothaer', display_value: [{ text: 'Gothaer' }] },
    { api_value: 'HALLESCHE', display_value: [{ text: 'HALLESCHE' }] },
    { api_value: 'HUK-Coburg', display_value: [{ text: 'HUK-Coburg' }] },
    {
        api_value: 'HanseMerkur Speziale Krankenversicherung AG',
        display_value: [{ text: 'HanseMerkur Speziale Krankenversicherung AG' }],
    },
    { api_value: 'INTER Krankenversicherung AG', display_value: [{ text: 'INTER Krankenversicherung AG' }] },
    { api_value: 'Kranken- und Sterbekasse', display_value: [{ text: 'Kranken- und Sterbekasse' }] },
    {
        api_value: 'Krankenunterstützungskasse Berufsfeuerwehr Hannover (KUK)',
        display_value: [{ text: 'Krankenunterstützungskasse Berufsfeuerwehr Hannover (KUK)' }],
    },
    {
        api_value: 'Krankenversorgung der Bundesbahnbeamten (KVB I - III)',
        display_value: [{ text: 'Krankenversorgung der Bundesbahnbeamten (KVB I - III)' }],
    },
    {
        api_value: 'Krankenversorgung der Bundesbahnbeamten (KVB IV)',
        display_value: [{ text: 'Krankenversorgung der Bundesbahnbeamten (KVB IV)' }],
    },
    {
        api_value: 'Krankenversorgung der Bundesbahnbeamten (KVB)',
        display_value: [{ text: 'Krankenversorgung der Bundesbahnbeamten (KVB)' }],
    },
    {
        api_value: 'LIGA Krankenversicherung katholischer Priester',
        display_value: [{ text: 'LIGA Krankenversicherung katholischer Priester' }],
    },
    {
        api_value: 'LVM (Landwirtschaftlicher Versicherungsverein Münster)',
        display_value: [{ text: 'LVM (Landwirtschaftlicher Versicherungsverein Münster)' }],
    },
    { api_value: 'Landeskrankenhilfe (LKH)', display_value: [{ text: 'Landeskrankenhilfe (LKH)' }] },
    { api_value: 'Mannheimer', display_value: [{ text: 'Mannheimer' }] },
    { api_value: 'Mecklenburgische', display_value: [{ text: 'Mecklenburgische' }] },
    { api_value: 'MÜNCHENER VEREIN', display_value: [{ text: 'MÜNCHENER VEREIN' }] },
    { api_value: 'NÜRNBERGER', display_value: [{ text: 'NÜRNBERGER' }] },
    { api_value: 'Ottonova Krankenversicherung AG', display_value: [{ text: 'Ottonova Krankenversicherung AG' }] },
    { api_value: 'PAX-FAMILIENFÜRSORGE', display_value: [{ text: 'PAX-FAMILIENFÜRSORGE' }] },
    { api_value: 'Postbeamtenkrankenkasse', display_value: [{ text: 'Postbeamtenkrankenkasse' }] },
    { api_value: 'Provinzial', display_value: [{ text: 'Provinzial' }] },
    { api_value: 'R + V', display_value: [{ text: 'R + V' }] },
    { api_value: 'SIGNAL-IDUNA', display_value: [{ text: 'SIGNAL-IDUNA' }] },
    { api_value: 'SONO', display_value: [{ text: 'SONO' }] },
    {
        api_value: 'ST. MARTINUS Priesterverein der Diözese Rottenburg-Stuttgart',
        display_value: [{ text: 'ST. MARTINUS Priesterverein der Diözese Rottenburg-Stuttgart' }],
    },
    { api_value: 'Süddeutsche (SDK)', display_value: [{ text: 'Süddeutsche (SDK)' }] },
    { api_value: 'UKV (UNION)', display_value: [{ text: 'UKV (UNION)' }] },
    { api_value: 'UNIVERSA', display_value: [{ text: 'UNIVERSA' }] },
    { api_value: 'VICTORIA', display_value: [{ text: 'VICTORIA' }] },
    {
        api_value: 'Verein Pfälzischer Pfarrerinnen und Pfarrer',
        display_value: [{ text: 'Verein Pfälzischer Pfarrerinnen und Pfarrer' }],
    },
    {
        api_value: 'Versicherungskammer Bayern (BK / VKB)',
        display_value: [{ text: 'Versicherungskammer Bayern (BK / VKB)' }],
    },
    { api_value: 'Volksfürsorge', display_value: [{ text: 'Volksfürsorge' }] },
    { api_value: 'Württembergische', display_value: [{ text: 'Württembergische' }] },
    { api_value: 'Zürich Agrippina', display_value: [{ text: 'Zürich Agrippina' }] },
    { api_value: 'vigo Krankenversicherung VVaG', display_value: [{ text: 'vigo Krankenversicherung VVaG' }] },
];
