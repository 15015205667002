import { Grid, TextField, Typography } from '@material-ui/core';
import { memo, useContext } from 'react';

import { ContextProvider } from '@/context/ProjectContext';

import { dayjs } from '@/utils/dayjsSetup.js';
import { getISODate } from '@/utils/helpers.js';

import { useStyles } from './MainDateStyles';

const DateOfBirthField = ({
    separator,
    setDOB,
    dob,
    setError,
    error,
    errorMessage,
    placeHolderMonth,
    placeHolderYear,
    placeHolderDay,
    requiredDateField,
    mainHeading,
    mt,
}) => {
    const classes = useStyles();

    const { languageGlobal } = useContext(ContextProvider);

    const errorUpdates = (formatedDate) => {
        const year = dayjs().diff(dayjs(formatedDate, 'YYYY-MM-DD'), 'years');
        const month = dayjs().diff(dayjs(formatedDate, 'YYYY-MM-DD'), 'months');
        const date = dayjs().diff(dayjs(formatedDate, 'YYYY-MM-DD'), 'days');
        const year1900 = dayjs().diff(dayjs('1900-01-01', 'YYYY-MM-DD'), 'days');

        if ((Math.sign(date) === -1 || date === 0) && month === 0 && year === 0) {
            setError(true);
        }
        if (isNaN(year) || date > year1900 || Math.sign(year) === -1) {
            setError(true);
        }
        if (!dayjs(formatedDate, 'YYYY-MM-DD', true).isValid()) {
            setError(true);
        }
    };

    const validateForm = (type) => {
        let formatedDate;
        if (dob.length >= 10) {
            formatedDate = getISODate(dob, languageGlobal);
        }

        switch (type) {
            case 'dob':
                setError(false);
                if (dob === '') {
                    setError(true);
                }
                errorUpdates(formatedDate);
                break;
            default:
                break;
        }
    };

    const checkDateFormat = (event) => {
        let text = event.target.value;
        let formatedDate;
        if (text.length >= 10) {
            formatedDate = getISODate(text, languageGlobal);
        }

        if (dob.length > text.length && (text.length === 5 || text.length === 2)) {
            setDOB(text.slice(0, text.length - 1));
            return;
        }
        if (dob.length < text.length && (text.length === 2 || text.length === 5)) {
            text += separator;
        }
        if (text.length > 10) {
            return;
        }
        setDOB(text);

        if (text.length === 10) {
            setError(false);
            errorUpdates(formatedDate);
            if (!dayjs(dob, languageGlobal === 'en' ? 'DD/MM/YYYY' : 'DD.MM.YYYY', true).isValid()) {
                setError(true);
            }
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        id="dateofbirth"
                        autoComplete="off"
                        label={mainHeading}
                        value={dob
                            .replaceAll(languageGlobal === 'en' && '.', '/')
                            .replaceAll(languageGlobal === 'de' && '/', '.')
                            .replace(/[a-z||A-Z]/, '')}
                        onChange={(e) => {
                            checkDateFormat(e);
                        }}
                        onBlur={() => validateForm('dob')}
                        variant="filled"
                        color="primary"
                        fullWidth
                        placeholder={placeHolderDay + separator + placeHolderMonth + separator + placeHolderYear}
                        style={{ marginTop: mt }}
                        classes={{
                            root: error ? classes.textFieldsError : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                </Grid>
            </Grid>
            {error && (
                <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                    {requiredDateField && dob.length === 0 ? requiredDateField : errorMessage}
                </Typography>
            )}
        </>
    );
};

export default memo(DateOfBirthField);
