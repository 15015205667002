import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LinkIcon from '@/assets/Icons/link.svg';

import UnderlinedButton from './UnderlinedButton';

const useStyles = makeStyles((theme) => ({
    locationPreviewCard: {
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
        overflow: 'hidden',
        height: '20rem',
    },
    mapContainer: {
        width: '100%',
        height: '65%',
    },
    addressContainer: {
        fontFamily: 'MessinaSans-Regular',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        gap: '1rem',
        height: '35%',
        padding: '0 1rem',

        '& *': {
            margin: 0,
            lineHeight: '1.5rem',
        },
    },
    mobileViewContainer: {
        fontFamily: 'MessinaSans-Regular',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '1rem',
        '& *': {
            margin: 0,
        },
        '& h4': {
            fontStyle: 'normal',
            fontSize: '0.85rem',
            fontWeight: 500,
            lineHeight: '15px',
            textTransform: 'uppercase',
            color: '#ABABAB',
        },
    },
}));

const LocationPreviewCard = ({ professionalLocation, appointmentSearch }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const [cardHeight, setCardHeight] = useState('20rem');
    const [coordinates, setCoordinates] = useState();

    useEffect(() => {
        setCoordinates({
            lat: professionalLocation.coordinates.latitude,
            lng: professionalLocation.coordinates.longitude,
        });
    }, [professionalLocation]);

    useEffect(() => {
        if (matchesXs) setCardHeight('auto');
        else if (matches) setCardHeight('24rem');
        else if (matchesMd) setCardHeight('23rem');
        else setCardHeight('20rem');
    }, [matchesMd, matches, matchesXs]);

    const containerStyle = {
        height: '100%',
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    });

    return (
        <div style={{ height: cardHeight }} className={classes.locationPreviewCard}>
            {!matchesXs ? (
                <>
                    <div className={classes.mapContainer}>
                        {isLoaded && coordinates && (
                            <GoogleMap
                                options={{ streetViewControl: false }}
                                mapContainerStyle={containerStyle}
                                center={coordinates}
                                zoom={14}
                            >
                                <Marker position={coordinates} />
                            </GoogleMap>
                        )}
                    </div>
                    <div className={classes.addressContainer}>
                        <h4>
                            {appointmentSearch && appointmentSearch.location_key === professionalLocation.key[0].text
                                ? appointmentSearch.location_title
                                : professionalLocation.map_title?.length > 0 &&
                                    professionalLocation.map_title[0]?.text !== ''
                                  ? professionalLocation.map_title[0]?.text
                                  : professionalLocation.title[0].text}
                        </h4>
                        <p>{professionalLocation.address[0].text}</p>
                    </div>
                </>
            ) : (
                <div className={classes.mobileViewContainer}>
                    <h4>
                        {appointmentSearch && appointmentSearch.location_key === professionalLocation.key[0].text
                            ? appointmentSearch.location_title
                            : professionalLocation.map_title?.length > 0 &&
                                professionalLocation.map_title[0]?.text !== ''
                              ? professionalLocation.map_title[0]?.text
                              : professionalLocation.title[0].text}
                    </h4>
                    <p>{professionalLocation.address[0].text}</p>
                    <UnderlinedButton
                        text={t('OpenLink')}
                        icon={LinkIcon}
                        onPress={() => {
                            window.open(professionalLocation.google_maps.url, 'SingleSecondaryWindow', 'noreferrer');
                        }}
                        noMarginTop
                    />
                </div>
            )}
        </div>
    );
};

export default memo(LocationPreviewCard);
