import { makeStyles } from '@material-ui/core/styles';

import { getColorWithOpacity } from '@/utils/colorHelpers.js';

export const useCustomAnamnesisStyles = makeStyles((theme) => ({
    defaultQuestion: {
        width: '100%',
        transition: 'background 0.3s ease, padding 0.3s ease',
    },
    highlight: {
        background: getColorWithOpacity(theme.palette.common.secondary, 0.15),
        padding: 10,
    },
}));
