import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';

import CardContainer from '@/components/Common/CardContainer';
import { DropDown } from '@/components/Common/DropDown/DropDown';
import consentFormsStyles from '@/components/ConsentForms/ConsentFormsStyles';
import FilledButton from '@/components/FilledButton';
import Loader from '@/components/Loader';

import { getAllByEveryTag, getByTag } from '@/utils/fetchers';
import { CURRENT_ENV, getText } from '@/utils/helpers.js';

import { useConfigStore, useFormLocation } from '@/store.js';

const AllFormsLocation = () => {
    const classes = consentFormsStyles();

    const history = useHistory();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const inputContainerClasses = clsx(
        matchesXs ? classes.w100 : matchesSm ? classes.w50 : classes.w35,
        classes.mt20,
        classes.mxAuto
    );

    const formLocationStore = useFormLocation((state) => state);

    const { data: results, isLoading } = useSWRImmutable(
        {
            key: 'all-forms-location-prismic',
            tags: ['loctaion', CURRENT_ENV],
            params: {
                lang: i18n.language === 'en' ? 'en-us' : 'de-de',
            },
        },
        getAllByEveryTag
    );

    const { data: locationResults, isLoading: locationLoading } = useSWRImmutable(
        {
            key: 'all-forms-code-prismic',
            tag: 'all_forms_code',
            params: {
                lang: i18n.language === 'en' ? 'en-us' : 'de-de',
            },
        },
        getByTag
    );
    const locationContent = useMemo(() => locationResults?.results[0].data, [locationResults]);

    // only language 'de-de' has instances
    const { data: locationData, isLoading: locationInstanceLoading } = useSWRImmutable(
        {
            key: 'location-instance-prismic',
            tag: 'location_instance',
            params: {
                lang: 'de-de',
            },
        },
        getByTag
    );

    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    const locationInstanceData = useMemo(() => {
        if (!locationData) return null;
        const { results = [] } = locationData;
        const hubs = results?.map((result) => result?.data);
        return hubs;
    }, [locationData]);

    const content = useMemo(() => {
        if (!results) return null;
        return results.reduce((prev, curr) => {
            const { type } = curr;
            return {
                ...prev,
                [type]: [
                    ...(prev[type] || []),
                    { api_value: curr.data.title, api_key: getText(curr.data.key), city: getText(curr.data.city) },
                ],
            };
        }, {});
    }, [results]);

    const [formLocation, setFormLocation] = useState(formLocationStore);

    // get sublocations based on selected hub city
    const getSublocation = () => {
        if (!content) return null;
        const selectedHub = content?.hub?.find((c) => c.api_key === formLocation.hub?.api_key);
        const hubCity = content?.secondary_location.filter((l) => l.city === selectedHub?.city);
        return hubCity;
    };

    // remove city property from values to prevent dropdown errors
    const locationValues = useMemo(
        () =>
            content?.hub
                ?.filter((entry) =>
                    currentCustomer.locations.some(
                        (customerLocation) => entry.api_key === getText(customerLocation.key)
                    )
                )
                .map(({ api_value, api_key }) => ({
                    api_value: api_value,
                    api_key: api_key,
                })) || [],
        [content, currentCustomer]
    );
    const subLocationValues =
        getSublocation()?.map(({ api_value, api_key }) => ({ api_value: api_value, api_key: api_key })) || [];

    const getInstanceId = useCallback(
        (selectedValue) => {
            const isDev = CURRENT_ENV === 'dev';

            if (isDev) {
                return import.meta.env.VITE_DC_INSTANCE_ID;
            }

            const prismicHubs = results?.filter((item) => item.type === 'hub');
            const selectedHub = prismicHubs.find((hub) => getText(hub?.data?.key) === selectedValue?.api_key);
            const instance = locationInstanceData?.find((instance) =>
                selectedHub?.slugs?.includes(instance?.hub?.slug)
            );

            if (instance) return getText(instance.instance_id) || '';

            return null;
        },
        [locationInstanceData, results]
    );

    const setDropdownValue = (type) => {
        return (value) => {
            const selectedValue = content[type].find((item) => item.api_key === value);
            const dc_instance_id = getInstanceId(selectedValue) ?? '';
            setFormLocation((prev) => ({ ...prev, [type]: selectedValue || {}, dc_instance_id: dc_instance_id }));
        };
    };

    const handleContinue = () => {
        useFormLocation.setState({ ...formLocation });

        // redirects to forms code page
        history.push('/all-forms-code');
    };

    if (isLoading || locationLoading || locationInstanceLoading) return <Loader />;
    return (
        <div className={clsx(classes.w90, classes.center)}>
            <Grid container>
                <Grid item xs={12}>
                    <CardContainer style={{ paddingBlock: '80px' }}>
                        <Typography variant="h5" className={clsx(classes.subHeading, classes.mt30, classes.textCenter)}>
                            {getText(locationContent?.['pre-selection_title']) ?? 'Select a location'}
                        </Typography>

                        <div className={clsx(inputContainerClasses, classes.mt30)}>
                            <DropDown
                                type="default"
                                mainDataHeading={getText(locationContent?.location_label) ?? 'Eterno Space'}
                                mainDataArray={locationValues}
                                data={formLocation.hub?.api_key}
                                setData={setDropdownValue('hub')}
                                compulsory=""
                                validateForm={null}
                                parentStyles={{ width: '100%' }}
                            />
                        </div>
                        <div className={clsx(inputContainerClasses, classes.mb20)}>
                            <DropDown
                                type="default"
                                mainDataHeading={getText(locationContent?.sublocation_label) ?? 'Sublocation'}
                                mainDataArray={subLocationValues}
                                data={formLocation.secondary_location?.api_key}
                                setData={setDropdownValue('secondary_location')}
                                compulsory=""
                                validateForm={null}
                                disabled={!formLocation.hub?.api_key}
                                parentStyles={{ width: '100%' }}
                            />
                        </div>

                        <div className={clsx(classes.dFlex, classes.contentCenter, classes.mb30, classes.gap15)}>
                            <FilledButton
                                text={getText(locationContent?.cta_continue) ?? t('Continue')}
                                onPress={handleContinue}
                                disabled={Object.keys(formLocation.hub ?? {}).length === 0}
                            />
                        </div>
                    </CardContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default AllFormsLocation;
