import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useContext, useMemo } from 'react';

import FamilyFollowingSwitch from '@/components/Common/switchs/FamilyFollowingSwitch';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './FamilyClasses';

export default function FamilyFollowingDisease({
    mainData,
    /* ----- NEW PROPS ----- */
    allDisease1,
    setAllDisease1,
    checkBoxObj,
    setCheckBoxObj,
    haveProperty,
    errOthers,
    setErrOthers,
    checkDisabled,
    isFromHealthData,
    disabledFunction,
    isTrue,
    messageRefTwo,
}) {
    const classes = useStyles();

    const total = mainData.diseases_responses;
    const { whichRelative, setRelativeDisease, setData2_, body6, data2_, newFromTrue } = useContext(ContextProviderTwo);
    console.debug('allDisease112', data2_);

    useMemo(() => {
        if (body6 !== null) {
            console.debug('body511', body6);
            if (data2_.length > 0) {
                const a = data2_;
                const b = [];
                const p = {};
                for (let i = 0; i < a.length; i++) {
                    const c = a[i];
                    b.push({
                        relativeDisease: c.relativeDisease,
                        whichRelative: c.whichRelative,
                        relativeAge: c.relativeAge,
                        whichOther: c.whichOther,
                    });
                    for (let k = 0; k < total.length; k++) {
                        if (total[k].desease_response_api_value === c.relativeDisease) {
                            p[k] = true;
                        }
                        setCheckBoxObj(p);
                    }
                }
                setAllDisease1(b);
            } else {
                setCheckBoxObj({ 0: true });
                setRelativeDisease('NONE OF THE ABOVE');
            }
        }
    }, []);

    const handleFunction = (i) => {
        const noneOfTheAbove = total[i].desease_response_api_value;
        console.debug('🚀 ~ file: FamilyFollowingDisease.jsx:122 ~ noneOfTheAbove', noneOfTheAbove);
        setRelativeDisease(noneOfTheAbove);
        console.debug(
            '🚀 ~ file: FamilyFollowingDisease.jsx:122 ~ haveProperty',
            haveProperty,
            i,
            i === total.length - 2
        );

        if (!haveProperty || noneOfTheAbove !== 'NONE OF THE ABOVE') {
            delete checkBoxObj[0];
            setCheckBoxObj({
                ...checkBoxObj,
                ...{ [i]: !checkBoxObj[i] },
            });
        }

        if (noneOfTheAbove === 'NONE OF THE ABOVE') {
            console.debug('renderrrr');
            setAllDisease1([]);
            setData2_([]);
            setCheckBoxObj({ [i]: !checkBoxObj[i] });
            setRelativeDisease('NONE OF THE ABOVE');
            return;
        }

        if ((!haveProperty || noneOfTheAbove !== 'NONE OF THE ABOVE') && !checkBoxObj[i]) {
            const b = {
                relativeDisease: total[i].desease_response_api_value,
                whichRelative: '',
                relativeAge: '',
                whichOther: '',
            };
            setAllDisease1([...allDisease1, b]);
        }
        if (checkBoxObj[i]) {
            const deletedArr = allDisease1.filter((b) => b.relativeDisease !== total[i].desease_response_api_value);
            setAllDisease1(deletedArr);
        }
    };

    const multipleIndexes = useMemo(() => {
        return allDisease1.map((d) => {
            const a = [];
            const index = mainData.diseases_responses.findIndex((object) => {
                return object.desease_response_api_value === d.relativeDisease;
            });

            if ((d.relativeDisease === 'OTHER' && d.whichOther.length === 0) || d.whichRelative.length === 0) {
                a.push(index);
            } else {
                a.slice(0, index);
            }
            console.debug('🚀 ~ file: FamilyFollowingDisease.jsx:122 ~ multipleIndexes ~ multipleIndexes:', a[0]);

            return a[0];
        });
    }, [allDisease1, whichRelative]);

    return (
        <div className={classes.contentContainerBody}>
            {/* main Part */}
            <div className={classes.fixedQuestion}>
                <Typography className={classes.mainHeading}>{mainData.has_disease_question[0].text}</Typography>

                <Grid
                    style={{
                        marginTop: 20,
                    }}
                    container
                >
                    {mainData &&
                        mainData.diseases_responses.map((parts, index) => (
                            <Grid
                                ref={
                                    disabledFunction() &&
                                    isTrue &&
                                    multipleIndexes.sort().includes(index) &&
                                    (isFromHealthData || newFromTrue)
                                        ? messageRefTwo
                                        : null
                                }
                                className={
                                    disabledFunction() &&
                                    isTrue &&
                                    multipleIndexes.includes(index) &&
                                    (isFromHealthData || newFromTrue)
                                        ? classes.highlight
                                        : classes.main
                                }
                                lg={12}
                                sm={12}
                                xs={12}
                            >
                                <FamilyFollowingSwitch
                                    mainData={mainData}
                                    switchName={parts.disease_response_value[0].text}
                                    savedValue={parts.desease_response_api_value}
                                    index={index}
                                    sergeriesArr1={allDisease1}
                                    setSergeriesArr1={setAllDisease1}
                                    handleFunction={handleFunction}
                                    checkBoxObj={checkBoxObj}
                                    errOthers={errOthers}
                                    setErrOthers={setErrOthers}
                                    checkDisabled={checkDisabled}
                                />
                            </Grid>
                        ))}
                </Grid>
            </div>
        </div>
    );
}
