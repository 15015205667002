/** React */

/** MUI */
import { Typography } from '@material-ui/core';

/** Others */
import clsx from 'clsx';

/** Components */
import consentFormsStyles from '../ConsentFormsStyles';

const Text = ({ children, style }) => {
    const classes = consentFormsStyles();
    return (
        <Typography
            variant="body1"
            className={clsx(classes.paragraph, classes.wrapText, classes.mb40)}
            style={{ ...style }}
        >
            {children}
        </Typography>
    );
};

export default Text;
