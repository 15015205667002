import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './YourBodyStyles';

const YouBodyCholesterol = ({ mainData }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const { cholesterol, setCholesterol } = useContext(ContextProviderTwo);

    return (
        <div className={classes.contentContainerBody} style={{ marginTop: cholesterol !== '' && 40 }}>
            <div className={classes.fixedQuestion}>
                <Typography className={classes.mainHeading}>{mainData.cholesterol_question_title[0].text}</Typography>
                <Grid
                    style={{
                        marginTop: 15,
                        marginLeft: -10,
                    }}
                    container
                >
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {mainData &&
                            mainData.cholesterol_level_answer_group.map((level, index) => (
                                <Button
                                    key={index}
                                    onClick={() => {
                                        console.debug(level.cholesterol_level_answer[0].text);
                                        setCholesterol(level.cholesterol_level_answer[0].text);
                                    }}
                                    disableRipple
                                    className={
                                        cholesterol === level.cholesterol_level_answer[0].text
                                            ? clsx(classes.selectItemCard, classes.selectedCard)
                                            : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                    }
                                    style={{
                                        marginBottom: matches && 20,
                                        margin: 10,
                                        flex: matches ? 1 : null,
                                        display: matches ? null : 'flex',
                                        textAlign: 'center',
                                        paddingInline: matches && 0,
                                    }}
                                >
                                    <div className="">
                                        <Typography className={classes.BtncontentText}>
                                            {level.cholesterol_level_answer[0].text}
                                        </Typography>
                                    </div>
                                </Button>
                            ))}
                    </div>
                </Grid>
            </div>
        </div>
    );
};

export default memo(YouBodyCholesterol);
