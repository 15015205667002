import { Grid, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import check from '@/assets/Icons/green_check.svg';
import red_cross from '@/assets/Icons/not-uploaded-icon.svg';

import FilledButton from '@/components/FilledButton';
import UnderlinedButton from '@/components/UnderlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';
import { Context as BookAppointmentContext, Context as BookContext } from '@/context/BookAppointmentContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as DocumentContext } from '@/context/DocumentContext';
import { ContextProvider } from '@/context/ProjectContext';

const languageMapCalendar = {
    en: 'en-US',
    de: 'de-DE',
};

const useStyles = makeStyles((theme) => ({
    summaryHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    gridItem: {
        width: '100%',
    },
    gridItemUploaded: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    mainCenterCircle: {
        flex: 1,
    },
    labels: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
    },
    values: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
    },
    infoText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.125rem',
        color: theme.palette.common.darkGrey,
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
            // backgroundColor: "red",
        },
    },
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    charactersContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
    charactersText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        color: theme.palette.common.darkGrey,
    },
    checkIcon: {
        width: 28,
        height: 28,
        objectFit: 'contain',
        marginRight: 5,
    },
    messageContainer: {
        marginTop: '1rem',
        borderRadius: 4,
        padding: '18px 20px',
        textAlign: 'center',
        backgroundColor: theme.palette.common.lightBrown,
        border: `1px solid ${theme.palette.common.mediumBrown}`,
    },
    externalAppointmentMessage: {
        fontFamily: 'MessinaSans-Regular',
    },
}));

const BookingSummary = ({ setFormStage, mainData, dataSet, selectedDoctor, appointmentSearch }) => {
    console.debug('BookingPrismicData', appointmentSearch);
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const { setAppointmentDoctorInfo } = useContext(ContextProviderTwo);

    console.debug('mainData under intro page', mainData);
    const {
        state: { user, usersession },
    } = useContext(AuthContext);

    const {
        state: { summaryNotes },
        setSummaryNotes,
        setIsCompleted,
    } = useContext(BookContext);

    // Document Context
    const {
        state: { isDocumentUploading, isDocumentUploaded, uploadedDocument },
        beginFileUpload,
    } = useContext(DocumentContext);

    const {
        state: { appointmentSelection },
    } = useContext(BookAppointmentContext);

    const goBack = () => {
        setFormStage(2, selectedDoctor, true);
    };

    useEffect(() => {
        window.addEventListener('popstate', goBack);
        return () => {
            window.removeEventListener('popstate', goBack);
        };
    }, []);

    useEffect(() => {
        const { haveImg, imgFile, prescriptionType } = appointmentSearch;
        console.debug('fileUpload: haveImg', haveImg);
        console.debug('fileUpload: haveImg', imgFile);
        console.debug('fileUpload: haveImg', prescriptionType);
        console.debug('fileUpload: haveImg', isDocumentUploaded);
        console.debug('fileUpload: haveImg', uploadedDocument);

        if (haveImg === true && imgFile !== undefined && isDocumentUploaded === false && uploadedDocument === null) {
            prepareToUpload(imgFile, prescriptionType);
        }
        // returning back to top on mount
        window.scrollTo(0, 0);
    }, []);

    const prepareToUpload = (file = null, prescriptionType) => {
        if (file && user && user.id !== undefined && usersession) {
            const user_id = user.id;
            const type = prescriptionType;
            const mime_type = file.type;
            const fileNameWithExtension = file.name;
            const fileNameTempArr = fileNameWithExtension.split('.');
            const data = {
                user_id: user_id,
                type: type,
                mime_type: mime_type,
                name: fileNameTempArr[0],
            };
            console.debug('fileUpload: Preparing to process this data:', data);
            beginFileUpload(data, file, usersession);
        }
    };

    const getTime = (time) => {
        const date = new Date(time);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        const current = `${hours}:${minutes}`;
        return current;
    };

    const getDateWithMonth = (date) => {
        const d = new Date(date);
        const lan = languageMapCalendar[i18n.language];
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };

        // an application may want to use UTC and make that visible
        console.debug('appointmentSelection', appointmentSearch);
        return new Intl.DateTimeFormat(lan, options).format(d);
    };

    const {
        loadingIndecator,
        setLoadingIndecator,
        setAppointmentSearchConfirmation,
        setAppointmentSelectionConfirmation,
        setOnceRunState,
    } = useContext(ContextProvider);

    return (
        <div className={classes.mainCenterCircle}>
            <Typography className={classes.summaryHeading} style={{ marginTop: 5 }}>
                {dataSet && mainData && mainData.booking_summary !== undefined
                    ? mainData.booking_summary.summary_heading[0].text
                    : 'Booking Summary'}
            </Typography>

            {appointmentSearch.externalLocation && (
                <div className={classes.messageContainer}>
                    <Typography className={classes.externalAppointmentMessage}>
                        {dataSet ? mainData.external_appointment_message[0].text : t('ExternalAppointmentMessage')}
                    </Typography>
                </div>
            )}

            <Grid container direction="column" spacing={1} style={{ marginTop: 20 }}>
                <Grid item container>
                    <Grid item md={6} xs={12}>
                        <div className={classes.gridItem}>
                            <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                {dataSet && mainData && mainData.booking_summary !== undefined
                                    ? mainData.booking_summary.appointment_service_label[0].text
                                    : 'Appointment'}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className={classes.gridItem} style={{ display: 'flex' }}>
                            {/* {appointmentSearch?.treatment_name_array.map((t) => (
                <div className="">
                  <Typography
                    className={classes.values}
                    style={{ marginTop: 5 }}
                  >
                    {dataSet &&
                      appointmentSearch["location_title"] !== undefined &&  t  }

                  </Typography>
                </div>
              ))} */}
                            <div className="">
                                <Typography className={classes.values} style={{ marginTop: 5 }}>
                                    {dataSet &&
                                        appointmentSearch.appointment_type_keys !== undefined &&
                                        appointmentSearch.appointment_names}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid item container>
                    <Grid item md={6} xs={12}>
                        <div className={classes.gridItem}>
                            <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                {dataSet && mainData && mainData.booking_summary !== undefined
                                    ? mainData.booking_summary.location_label[0].text
                                    : 'Location'}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className={classes.gridItem}>
                            <Typography className={classes.values} style={{ marginTop: 5 }}>
                                {dataSet &&
                                    appointmentSearch?.location_title &&
                                    `${appointmentSearch.location_title} ${
                                        appointmentSearch.location_title !== 'Online' &&
                                        !appointmentSearch.externalLocation
                                            ? 'Hub'
                                            : ''
                                    }`}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid item container>
                    <Grid item md={6} xs={12}>
                        <div className={classes.gridItem}>
                            <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                {dataSet && mainData && mainData.booking_summary !== undefined
                                    ? mainData.booking_summary.date_label[0].text
                                    : 'Date'}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className={classes.gridItem}>
                            <Typography className={classes.values} style={{ marginTop: 5 }}>
                                {dataSet &&
                                    appointmentSelection &&
                                    appointmentSelection.selected_appointment !== undefined &&
                                    getDateWithMonth(appointmentSelection.selected_appointment)
                                        .replace(',', ' ')
                                        .replace(',', ' ')}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid item container>
                    <Grid item md={6} xs={12}>
                        <div className={classes.gridItem}>
                            <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                {dataSet && mainData && mainData.booking_summary !== undefined
                                    ? mainData.booking_summary.time_label[0].text
                                    : 'Time'}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className={classes.gridItem}>
                            <Typography className={classes.values} style={{ marginTop: 5 }}>
                                {dataSet &&
                                    appointmentSelection &&
                                    appointmentSelection.selected_appointment !== undefined &&
                                    getTime(appointmentSelection.selected_appointment)}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                {appointmentSearch.is_with_prescription ? (
                    <Grid item container>
                        <Grid item md={6} xs={12}>
                            <div className={classes.gridItem}>
                                <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                    {dataSet && mainData && mainData.booking_summary !== undefined
                                        ? mainData.booking_summary.prescription_label[0].text
                                        : 'Prescription'}
                                </Typography>
                            </div>
                        </Grid>
                        {/* text */}
                        <Grid item md={6} xs={12}>
                            <div className={classes.gridItemUploaded}>
                                {appointmentSearch.is_with_prescription && appointmentSearch.is_haveImg ? (
                                    <img src={check} className={classes.checkIcon} alt="" />
                                ) : (
                                    appointmentSearch.is_with_prescription &&
                                    !appointmentSearch.is_haveImg && (
                                        <img src={red_cross} className={classes.checkIcon} alt="" />
                                    )
                                )}
                                <Typography className={classes.values} style={{ marginTop: 5 }}>
                                    {appointmentSearch.is_with_prescription && appointmentSearch.is_haveImg
                                        ? dataSet && mainData && mainData.booking_summary !== undefined
                                            ? mainData.booking_summary.prescription_status[0].text
                                            : 'Uploaded'
                                        : mainData.booking_summary !== undefined
                                          ? appointmentSearch.is_with_prescription &&
                                            !appointmentSearch.is_haveImg &&
                                            mainData.booking_summary.not_uploaded[0].text
                                          : 'Not Uploaded'}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>

            <Typography className={classes.infoText} style={{ marginTop: 20, marginBottom: 10 }}>
                {dataSet && mainData && mainData.booking_summary !== undefined
                    ? mainData.booking_summary.info_question_text[0].text
                    : 'Is there anything else you want to share with your doctor?'}
            </Typography>

            <TextField
                id="comments"
                label={
                    dataSet && mainData && mainData.booking_summary !== undefined
                        ? mainData.booking_summary.info_placeholder_text[0].text
                        : 'Enter your comments here'
                }
                variant="filled"
                color="primary"
                placeholder=""
                value={summaryNotes}
                multiline
                onChange={(event) => {
                    const text = event.target.value;
                    if (text.length < 301) {
                        setSummaryNotes(text);
                    }
                }}
                fullWidth
                classes={{ root: classes.textFields }}
                InputLabelProps={{
                    classes: {
                        root: classes.inputLabel,
                        focused: 'focused',
                        shrink: 'shrink',
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.filledInputRoot,
                        focused: classes.fieldFocused,
                        shrink: 'shrink',
                    },
                }}
            />

            <div className={classes.charactersContainer}>
                <Typography className={classes.charactersText} style={{ marginTop: 10, marginBottom: 20 }}>
                    {`${300 - summaryNotes.length} ${
                        dataSet ? mainData.characters_remaining[0].text : 'characters remaining.'
                    }`}
                </Typography>
            </div>

            <div
                className=""
                style={{
                    display: 'flex',
                    justifyContent: matches ? 'center' : 'flex-start',
                    marginTop: 10,
                    flexDirection: matches && 'column',
                    alignItems: 'center',
                }}
            >
                <div
                    className=""
                    style={{
                        marginTop: 10,
                        marginRight: 20,
                    }}
                >
                    <FilledButton
                        variant="contained"
                        text={
                            dataSet && mainData && mainData.booking_summary !== undefined
                                ? mainData.booking_summary.confirm_cta[0].text
                                : 'CONFIRM DETAILS'
                        }
                        disabled={loadingIndecator || isDocumentUploading}
                        loading={loadingIndecator || isDocumentUploading}
                        onPress={() => {
                            setOnceRunState(true);
                            setLoadingIndecator(true);
                            setAppointmentDoctorInfo(selectedDoctor?.key[0].text);
                            setIsCompleted(true);
                            setFormStage(30);
                            setAppointmentSearchConfirmation(appointmentSearch);
                            console.debug(
                                '🚀 ~ file: BookingSummary.jsx ~ line 421 ~ appointmentSearch thisdata',
                                appointmentSearch
                            );
                            setAppointmentSelectionConfirmation(appointmentSelection);
                            console.debug(
                                '🚀 ~ file: BookingSummary.jsx ~ line 423 ~ appointmentSelection thisdata',
                                appointmentSelection
                            );
                            // console.debug("HELLLO BHAI")
                        }}
                        marginBottom={matches && 20}
                    />
                </div>

                <UnderlinedButton
                    noMarginTop
                    text={
                        dataSet && mainData && mainData.booking_summary !== undefined
                            ? mainData.booking_summary.back_cta[0].text
                            : 'GO BACK'
                    }
                    onPress={() => {
                        // setFormStage(20,selectedDoctor,true)
                        // alert("clickeddd")
                        console.debug('selectedDoctorlast', selectedDoctor);
                        goBack();
                        // history.goBack();
                    }}
                />
            </div>
        </div>
    );
};

export default BookingSummary;
