import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { replaceProfessionalType } from '@/utils/helpers.js';

import { useHeaderStore, useProfessionalsStore } from '@/store.js';

import Card from './Card';
import { useStyles } from './DoctorClasses';
import RenderEmpty from './RenderEmpty';

const RenderData = ({ mainData, isLoggedIn }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const {
        mainDataMyProfessionalsID,
        mainDataMyProfessionals,
        allProfessionals,
        shuffledMyProfessionals,
        hubProfessionals,
    } = useProfessionalsStore((state) => state);

    const [isOther, setIsOther] = useState([]);

    const { headerDoctor, headerDoctorKey } = useHeaderStore((state) => state);

    useEffect(() => {
        setIsOther(hubProfessionals?.filter((myDoctor) => !mainDataMyProfessionalsID.includes(myDoctor.key[0].text)));
    }, [hubProfessionals, mainDataMyProfessionalsID]);

    return (
        <div style={{ width: '100%' }}>
            {isLoggedIn && (
                <>
                    <Typography className={classes.mainHeading}>
                        {replaceProfessionalType({ text: mainData?.overview_title[0].text, language: i18n.language })}
                    </Typography>
                    <Grid container style={{ marginTop: 14 }} spacing={3}>
                        {mainDataMyProfessionals !== null &&
                        shuffledMyProfessionals !== null &&
                        shuffledMyProfessionals.length > 0 ? (
                            shuffledMyProfessionals.map((doc) => (
                                <Grid style={{ marginBottom: 25 }} item lg={4} md={6} xs={12}>
                                    <Card
                                        allDoctors={allProfessionals}
                                        allPractitioners={hubProfessionals}
                                        myPractitioners={shuffledMyProfessionals}
                                        myDoctor={doc}
                                        mainData={mainData}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <RenderEmpty mainData={mainData} />
                        )}
                    </Grid>
                </>
            )}

            {isOther.length > 0 && (
                <Typography className={classes.sectionHeading} style={{ marginTop: 34 }}>
                    {mainData
                        ? replaceProfessionalType({
                              text: mainData?.discover_others_heading[0].text.replace(
                                  '{{Location}}',
                                  headerDoctor !== null && headerDoctor !== undefined && headerDoctor
                              ),
                              language: i18n.language,
                          })
                        : t('Discover other practitioners in our Hamburg Hub')}
                </Typography>
            )}

            <Grid container style={{ marginTop: 14 }} spacing={3}>
                {hubProfessionals.map((myDoctor) => {
                    if (!mainDataMyProfessionalsID.includes(myDoctor.key[0].text)) {
                        return (
                            <Grid item lg={4} md={6} xs={12} key={myDoctor.key[0].text}>
                                <Card allDoctors={allProfessionals} myDoctor={myDoctor} mainData={mainData} />
                            </Grid>
                        );
                    }
                    return null;
                })}
            </Grid>
        </div>
    );
};

export default memo(RenderData);
