import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DetailSection from './DetailSection';
import SideBarDoctorDetails from './SideBarDoctorDetails';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingBottom: 20,
        marginTop: 20,

        [theme.breakpoints.down('xs')]: {
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 20,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
    },
    goBackIcon: {
        height: 14,
        marginRight: 10,
    },
}));
const MainDetailSection = ({ myAppointmentsData, myAppointmentsDataStatus, dataSet, mainData }) => {
    const { i18n } = useTranslation();
    const classes = useStyles();

    const languageMap = {
        en: 'en-us',
        de: 'de-de',
    };
    const lan = languageMap[i18n.language];

    const doctors_data = myAppointmentsData[lan];

    return (
        <div className={classes.contentContainer}>
            <Grid container direction="row" spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <SideBarDoctorDetails
                        doctors_data={doctors_data}
                        myAppointmentsDataStatus={myAppointmentsDataStatus}
                        mainData={mainData}
                        dataSet={dataSet}
                    />
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <div>
                        <DetailSection
                            doctor_data={doctors_data}
                            myAppointmentsDataStatus={myAppointmentsDataStatus}
                            mainData={mainData}
                            dataSet={dataSet}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default MainDetailSection;
