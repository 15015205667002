import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ic_arrow_down_black from '@/assets/Icons/ic_down_arrow_black.svg';

import FilledButton from '@/components/FilledButton';

import { ContextProvider } from '@/context/ProjectContext';

import { dayjs } from '@/utils/dayjsSetup.js';
import { languageMap } from '@/utils/helpers.js';

const languageMapCalendar = {
    en: 'en-US',
    de: 'de-DE',
};

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        marginTop: 20,
        alignContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'relative',
    },
    horizontalMain: {
        textAlign: 'center',
    },

    timeContainerInner: {
        backgroundColor: theme.palette.common.creamCalendar,
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 20,
        paddingLeft: 20,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 0,
            paddingLeft: 0,
            fontSize: '.8rem',
        },
    },
    dayText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('xs')]: {
            fontSize: '.7rem',
        },
    },
    dateText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('xs')]: {
            fontSize: '.8rem',
        },
    },
    showMoreText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        marginRight: 10,
        [theme.breakpoints.down('xs')]: {
            fontSize: '.8rem',
        },
    },
    nextSlotContainer: {
        marginTop: 32,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'absolute',
        width: '85%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    nextSlotButton: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
    },
    nextSlotText: {
        fontFamily: 'MessinaSans-Regular',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
    },
}));

const MobileCalender = ({
    setDateOut,
    data,
    scrollToContinueButton,
    startDate,
    setPatientDetails,
    mainData,
    dataSet,
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    // DATE setup
    const [date, setDate] = useState();
    const [btnShown, setBtnShown] = useState('');
    const [timeSlots, setTimeSlots] = useState([]);
    console.debug('🚀 ~ file: MobileCalender.jsx:78 ~ timeSlots', timeSlots);
    const [showAll, setShowAll] = useState(false);
    const [showExpandButton, setShowExpandButton] = useState(false);
    const [nextNonVisibleAppointment, setNextNonVisibleAppointment] = useState(null);
    const [noSlotsAvailable, setNoSlotsAvailable] = useState(false);
    const [appointmentsInNonVisibleDays, setAppointmentsInNonVisibleDays] = useState(false);
    const [loading, setLoading] = useState(false);
    setDateOut(btnShown);

    const { setDateSlot } = useContext(ContextProvider);

    useEffect(() => {
        setLoading(false);
    }, [data]);

    useEffect(() => {
        if (matchesMd) {
            for (let i = 0; i < 3; i++) {
                if (data.availability[i].slots.length > 7) {
                    setShowExpandButton(true);
                    return;
                }
            }
            setShowExpandButton(false);
        } else {
            setShowExpandButton(true);
        }
    }, [matchesMd, data.availability]);

    useEffect(() => {
        let slotsInVisibleDays = false;
        let slotsAvailable = false;
        for (let i = 0; i < 5; i++) {
            if (data.availability[i].slots.length > 0) {
                slotsAvailable = true;
                if (i < 3) slotsInVisibleDays = true;
            }
        }

        if (!slotsAvailable) {
            setNoSlotsAvailable(true);
            setAppointmentsInNonVisibleDays(false);
            setNextNonVisibleAppointment(null);
            return;
        }
        setNoSlotsAvailable(false);

        if (matchesMd) {
            if (
                !slotsInVisibleDays &&
                (data.availability[3].slots.length > 0 || data.availability[4].slots.length > 0)
            ) {
                setAppointmentsInNonVisibleDays(true);
                if (data.availability[3].slots.length > 0) setNextNonVisibleAppointment(data.availability[3].slots[0]);
                else setNextNonVisibleAppointment(data.availability[4].slots[0]);
            } else {
                setAppointmentsInNonVisibleDays(false);
                setNextNonVisibleAppointment(null);
            }
        } else {
            setAppointmentsInNonVisibleDays(false);
            setNextNonVisibleAppointment(null);
        }
    }, [matchesMd, data.availability]);
    console.debug('data of caleder', data);

    const getTime = (time) => {
        const date = new Date(time);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        return `${hours}:${minutes}`;
    };

    const getDay = (date) => {
        const d = new Date(date);
        const options = { weekday: 'long' };
        const lan = languageMapCalendar[i18n.language];
        return new Intl.DateTimeFormat(lan, options).format(d);
    };

    const getDateWithMonth = (date) => {
        const d = new Date(date);
        const day = d.getDate();

        const options = { month: 'long' };
        const month = new Intl.DateTimeFormat('en-US', options).format(d);
        return `${day} ${month}`;
    };

    useEffect(() => {
        const slots = [];
        console.debug('Slots Data', data);
        data.availability.forEach((din) => {
            din.slots.forEach((timee) => {
                const time = getTime(timee);
                if (!slots.includes(time)) {
                    slots.push(time);
                }
            });
        });
        slots.sort();
        setTimeSlots(slots);
    }, []);

    const jumpToFirstAvailableSlot = () => {
        setLoading(true);
        const nextSlotDate = new Date(
            dayjs(appointmentsInNonVisibleDays ? nextNonVisibleAppointment : data.next_slot).valueOf()
        );
        startDate.setTime(dayjs(appointmentsInNonVisibleDays ? nextNonVisibleAppointment : data.next_slot).valueOf());
        setPatientDetails('nothing', nextSlotDate);
    };

    const renderSlot = (slot, placeholder = false) => {
        return (
            <Typography
                className={classes.timeContainerInner}
                onClick={() => {
                    if (placeholder) return;
                    setDate(slot);
                    setDateSlot(slot);
                    setBtnShown(slot);
                    scrollToContinueButton();
                    console.debug(date);
                }}
                style={{
                    border:
                        date === slot && slot !== ''
                            ? `solid 2px ${theme.palette.common.yellow}`
                            : slot === ''
                              ? 0
                              : `solid 1px ${theme.palette.common.mediumBrown}`,
                    cursor: 'pointer',
                    borderRadius: 4,
                    marginTop: date === slot && slot !== '' ? 5 : slot === '' ? 8 : 6,
                    pointerEvents: placeholder ? 'none' : 'auto',
                }}
            >
                {!placeholder && getTime(slot)}
            </Typography>
        );
    };

    const lan = languageMap[i18n.language];

    console.debug('showAll:', showAll, timeSlots, !showAll && timeSlots.length > 7, data.availability);

    const isShowMoreOnMD =
        data?.availability[0].slots.length === 0 &&
        data?.availability[1].slots.length === 0 &&
        data?.availability[2].slots.length === 0;

    const isShowMoreOnLG =
        data?.availability[0].slots.length === 0 &&
        data?.availability[1].slots.length === 0 &&
        data?.availability[2].slots.length === 0 &&
        data?.availability[3].slots.length === 0 &&
        data?.availability[4].slots.length === 0;

    return (
        <div className={classes.container}>
            <div
                className={classes.mainContainer}
                style={{
                    marginLeft: matches ? -0 : matchesMd ? -0 : 0,
                    paddingLeft: matchesXs ? 0 : matches ? 12 : matchesXl ? 20 : 0,
                    paddingRight: matchesXs ? 0 : matches ? 12 : matchesXl ? 20 : 0,
                }}
            >
                {matchesMd
                    ? data.availability.map((din, index) => {
                          if (index < 3)
                              return (
                                  <div style={{ flex: 0.33 }}>
                                      <div className={classes.horizontalMain}>
                                          <div>
                                              <Typography className={classes.dayText}>{getDay(din.date)}</Typography>
                                              <Typography className={classes.dateText}>
                                                  {`${getDateWithMonth(din.date).split(' ')[0]} ${getDateWithMonth(
                                                      din.date
                                                  )
                                                      .split(' ')[1]
                                                      .substring(0, 3)}`}
                                              </Typography>
                                          </div>
                                      </div>
                                      <div style={{ display: 'grid' }}>
                                          {din.slots.map((timeSlot, slotIndex) => {
                                              if (showAll || slotIndex < 7) {
                                                  return (
                                                      <div
                                                          key={timeSlot}
                                                          style={{
                                                              display: 'flex',
                                                              flexDirection: 'column',
                                                          }}
                                                      >
                                                          {renderSlot(timeSlot)}
                                                      </div>
                                                  );
                                              }
                                              return null;
                                          })}
                                          {(data.next_slot || appointmentsInNonVisibleDays || noSlotsAvailable) && (
                                              <div
                                                  style={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                  }}
                                              >
                                                  {renderSlot('', true)}
                                                  {renderSlot('', true)}
                                                  {renderSlot('', true)}
                                                  {renderSlot('', true)}
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              );
                          return null;
                      })
                    : matchesXl
                      ? data.availability.map((din, index) => {
                            if (index < 5)
                                return (
                                    <div style={{ flex: 0.33 }}>
                                        <div className={classes.horizontalMain}>
                                            <div className="">
                                                <Typography className={classes.dayText}>{getDay(din.date)}</Typography>
                                                <Typography className={classes.dateText}>
                                                    {`${getDateWithMonth(din.date).split(' ')[0]} ${getDateWithMonth(
                                                        din.date
                                                    )
                                                        .split(' ')[1]
                                                        .substring(0, 3)}`}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {din.slots.map((timeSlot, slotIndex) => {
                                                if (showAll || slotIndex < 7) {
                                                    return (
                                                        <div
                                                            key={timeSlot}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            {renderSlot(timeSlot)}
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                            {(data.next_slot || appointmentsInNonVisibleDays || noSlotsAvailable) && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    {renderSlot('', true)}
                                                    {renderSlot('', true)}
                                                    {renderSlot('', true)}
                                                    {renderSlot('', true)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            return null;
                        })
                      : null}
            </div>
            {(matchesMd && isShowMoreOnMD) || (matchesLg && isShowMoreOnLG)
                ? null
                : !showAll &&
                  timeSlots.length > 7 && (
                      <div className="">
                          <div
                              className={classes.categoryHeading}
                              style={{
                                  marginTop: 32,
                                  textAlign: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  marginBottom: 20,
                              }}
                              onClick={() => {
                                  setShowAll(true);
                              }}
                          >
                              <Typography className={classes.showMoreText}>
                                  {/* { dataSet ? mainData.show_more_slots_cta[0].text : t("Show more slots")} */}
                                  {lan === 'en-us' ? 'Show more slots' : 'Mehr Slots anzeigen'}
                              </Typography>
                              <img src={ic_arrow_down_black} alt="" />
                          </div>
                      </div>
                  )}
            {(data.next_slot !== '' || appointmentsInNonVisibleDays) && (
                <div className={classes.nextSlotContainer}>
                    <Typography className={classes.nextSlotText}>
                        {dataSet
                            ? `${mainData.next_slot_available_text[0].text} ${getDay(
                                  new Date(appointmentsInNonVisibleDays ? nextNonVisibleAppointment : data.next_slot)
                              )}, ${dayjs(
                                  appointmentsInNonVisibleDays ? nextNonVisibleAppointment : data.next_slot
                              ).format(i18n.language === 'en' ? 'MMMM Do' : 'DD. MMMM')}`
                            : t('JumpToFirstSlot')}
                    </Typography>
                    <div className={classes.nextSlotButton}>
                        <FilledButton
                            text={dataSet ? mainData.next_slot_cta[0].text : t('NextSlotCta')}
                            disabled={loading}
                            loading={loading}
                            onPress={jumpToFirstAvailableSlot}
                        />
                    </div>
                </div>
            )}
            {noSlotsAvailable && !(data.next_slot !== '' || appointmentsInNonVisibleDays) && (
                <div className={classes.nextSlotContainer}>
                    <Typography className={classes.nextSlotText}>
                        {dataSet ? mainData.no_timeslots_available_text[0].text : t('NoTimeslotsAvailable')}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default MobileCalender;
