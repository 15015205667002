import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    progressBar: {
        color: theme.palette.common.yellow,
    },
    loadingProgress: {
        width: '100%',
        minHeight: ({ height }) => height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Loader = ({ height = '60vh' }) => {
    const classes = useStyles({ height: height });
    return (
        <div className={classes.loadingProgress}>
            <CircularProgress className={classes.progressBar} size={50} />
        </div>
    );
};

export default Loader;
