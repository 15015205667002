import axios from 'axios';

const BASE_API_URL_PRAC = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_PRAC_API_DOMAIN}`;
const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;

export async function professionalApiCall(usersession, body) {
    const user_id = usersession?.idToken.payload.sub;
    const token = usersession?.idToken.jwtToken;
    try {
        const resp = await axios.post(`${BASE_API_URL}/secure/user/health-profiles/${user_id}/share-anamnesis`, body, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        console.debug('professionalApi1 resp is ', resp);
        return resp;
    } catch (error) {
        console.debug('professionalApi1 error is ', error);
        return new Error(error.message);
    }
}

export async function professionalsId() {
    try {
        const resp = await axios.get(`${BASE_API_URL_PRAC}/public/doc-cirrus/professionals`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.debug('professionalsId: ', resp);
        return resp;
    } catch (error) {
        console.debug('professionalsId error is ', error);
        return new Error(error.message);
    }
}
