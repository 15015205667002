import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CardWrapper from '@/components/HealthProfileOverview/HealthData/CardWrapper';
import UnderlinedButton from '@/components/UnderlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';

import HealthContainer from '@/pages/HealthProfileOverview/HealthContainer';

import { usePersistedPrismicStore, useUploadDocumentModalStore } from '@/store.js';

import DocumentsByAppointment from './DocumentsByAppointment';
import NoDocumentsPlaceholder from './NoDocumentsPlaceholder';
import OtherDocuments from './OtherDocuments';
import SharedDocumentTabs from './SharedDocumentTabs';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 0 1.5rem 1.5rem',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '1.5rem',
            alignItems: 'flex-start',
        },
    },
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.5rem',
        fontWeight: 'normal',
    },
    tabs: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            marginTop: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
            gap: '1.5rem',
        },
    },
}));

const SharedDocuments = () => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState('byAppointment');

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const openModal = useUploadDocumentModalStore((state) => state.openModal);

    const {
        state: { userDocuments },
    } = useContext(AuthContext);

    const isDocumentsAvailable = userDocuments?.data.length !== 0;

    return isDocumentsAvailable ? (
        <HealthContainer>
            <CardWrapper pBlockXs={20} pInlineXs={0} height="290px">
                <div className={classes.header}>
                    <Typography className={classes.title}>{content?.shared_documents?.[0]?.text}</Typography>
                    <UnderlinedButton
                        noMarginTop
                        text={content?.upload_new_document_cta?.[0]?.text ?? 'Upload a new document'}
                        onPress={openModal}
                        noJSLeft={false}
                    />
                </div>
                <div className={classes.tabs}>
                    <SharedDocumentTabs activeTab={activeTab} setActiveTab={setActiveTab} />
                </div>
                {activeTab === 'byAppointment' && <DocumentsByAppointment />}
                {activeTab === 'others' && <OtherDocuments />}
            </CardWrapper>
        </HealthContainer>
    ) : (
        <NoDocumentsPlaceholder />
    );
};

export default SharedDocuments;
