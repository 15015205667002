import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    main: {
        margin: 20,
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
    },
    mainBack: {
        borderBottomWidth: '20px',
        borderBottomColor: '#F5F5F5',
        backgroundColor: theme.palette.common.white,
        cursor: 'pointer',
        zIndex: 2,
        '&:before': {
            height: '2px',
            backgroundColor: '#F5F5F5',
        },
        '&$expanded': {
            margin: 0,
        },
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    topMain: {
        borderTopLeftRadius: '0px !important',
        borderTopRightRadius: '0px !important',
        padding: '26px',
        [theme.breakpoints.down('sm')]: {
            paddingBlock: '1.4rem',
        },
    },
    heading: {
        fontSize: '1rem',
        fontFamily: 'MessinaSans-Regular',
        color: theme.palette.common.brown,
        display: 'flex',
        alignItems: 'center',
        gap: 20,
    },
    xsHeading: {
        fontSize: '1rem',
        fontFamily: 'MessinaSans-Regular',
        color: theme.palette.common.darkGrey,
        display: 'flex',
        alignItems: 'center',
        gap: 20,
        paddingBlock: '.4rem',
    },
    itemIcon: {
        width: '24px',
        height: '24px',
        objectFit: 'contain',
    },
    icon: {
        fontSize: '2rem',
        color: theme.palette.common.brown,
    },
    menuItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginLeft: '2.2rem',
        width: '100%',
    },

    // MenuItem Design
    menuItemButton: {
        background: 'transparent',
        borderRadius: '5px',
        padding: '.7rem 0.4rem',
        justifyContent: 'flex-start',
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.common.lightGray,
        },
    },
    menuItemButtonText: {
        fontFamily: 'MessinaSans-Regular',
        color: theme.palette.common.brown,
        textTransform: 'initial',
        fontSize: '1rem',
        textAlign: 'left',
    },
    colorTheme: {
        color: theme.palette.common.secondary,
        fontWeight: 600,
    },
    dropText: {},
}));
