import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import isPostalCode from 'validator/es/lib/isPostalCode';
import { useShallow } from 'zustand/react/shallow';

import { createQuestionObj } from '@/utils/helpers.js';

import { useBookingStore, usePersistedPrismicStore } from '@/store.js';

import { DropDown } from '../Common/DropDown/DropDown';
import NumberfieldQuestion, { NumberValidation } from '../CustomAnamnesis/QuestionTypes/NumberfieldQuestion';
import TextfieldQuestion from '../CustomAnamnesis/QuestionTypes/TextfieldQuestion';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        position: 'relative',
    },
    questionGroupContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
    },
}));

const Address = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { i18n } = useTranslation();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const {
        [i18n.language]: { content: prismicData },
    } = usePersistedPrismicStore((state) => state.accountData);
    const { street, zipCode, city, country } = useBookingStore((state) => state.registrationFormData);
    const { loading, setStreet, setZipCode, setCity, setCountry } = useBookingStore(
        useShallow((state) => ({
            loading: state.loading,
            setStreet: state.setStreet,
            setZipCode: state.setZipCode,
            setCity: state.setCity,
            setCountry: state.setCountry,
        }))
    );

    return (
        <div className={classes.container}>
            {loading && (
                <div className={classes.loadingOverlay}>
                    <EternoSpinner />
                </div>
            )}
            <TextfieldQuestion
                question={createQuestionObj('', prismicData.street_label[0].text, 'street')}
                answer={street}
                setAnswer={(value) => setStreet(value)}
                hideTitle
            />
            <div className={classes.questionGroupContainer}>
                <div style={{ width: matchesXs ? '100%' : 'auto' }}>
                    <NumberfieldQuestion
                        question={createQuestionObj('', prismicData.zip_code_label[0].text, 'zipcode', {
                            number_field_validation: NumberValidation.WHOLE_NUMBER,
                        })}
                        answer={zipCode}
                        setAnswer={(value) => setZipCode(value)}
                        hideTitle
                        customValidation={(value) => isPostalCode(value, country)}
                    />
                </div>
                <TextfieldQuestion
                    question={createQuestionObj('', prismicData.city_label[0].text, 'city')}
                    answer={city}
                    setAnswer={(value) => setCity(value)}
                    hideTitle
                />
            </div>
            <DropDown
                type="country"
                mainDataHeading={prismicData.country[0].text}
                setData={setCountry}
                data={country.toUpperCase()}
                noMarginTop
            />
        </div>
    );
};
export default Address;
