import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ContextProvider } from '@/context/ProjectContext';

import UnderlinedButton from './UnderlinedButton';

const useStyles = makeStyles((theme) => ({
    displayCorner: {
        display: 'flex',
        justifyContent: 'end',
    },
    imgClass: {
        borderRadius: '50%',
        height: 170,
        width: 170,
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: 100,
            width: 100,
        },
    },
    displayBtn: {
        textAlign: 'center',
        marginTop: 15,
    },
}));

const MyAppointmentImageSection = ({ dataSet, mainData, dataSetDoctos, upComming, doctors_data }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();

    const { myAppointmentsData, setMyAppointmentsData, setMyAppointmentsDataStatus } = useContext(ContextProvider);

    const forData = (upComming, dataSetDoctos) => {
        console.debug('myDoctorID : data', upComming);
        const uniqueKey = dataSetDoctos && doctors_data?.key[0].text;
        setMyAppointmentsData(upComming?.doctor);
        setMyAppointmentsDataStatus(dataSetDoctos);
        history.push(`/professional/${uniqueKey}`);
    };

    return (
        <div className={classes.displayCorner}>
            <div>
                <img className={classes.imgClass} src={dataSetDoctos && doctors_data?.profile_picture.url} alt="" />
                <div className={classes.displayBtn}>
                    <UnderlinedButton
                        text={dataSet ? mainData.see_profile_cta[0].text : 'See profile'}
                        onPress={() => {
                            forData(upComming, dataSetDoctos);
                        }}
                        noMarginTop
                    />
                </div>
            </div>
        </div>
    );
};

export default MyAppointmentImageSection;
