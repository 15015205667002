import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import scheduledIcon from '@/assets/images/scheduledIcon.svg';

import { Context as AuthContext } from '@/context/AuthContext';
import { Context as BookAppointmentContext } from '@/context/BookAppointmentContext';
import { ConsentAllFormsInitialRoutes, useConsentFormsContext } from '@/context/ConsentFormsContext';
import { Context as DoctorsContext } from '@/context/DoctorsContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { ContextProvider } from '@/context/ProjectContext';

import useFormSteps from '@/hooks/useFormSteps';
import useFormTemplate from '@/hooks/useFormTemplate';
import usePollingData from '@/hooks/usePoolingData';
import useProfessionalMappings from '@/hooks/useProfessionalMappings';
import useRequiredTemplatesAndAnamnesis from '@/hooks/useRequiredTemplatesAndAnamnesis';

import { professionalsId } from '@/service/AnamnesisSectionAPI/ProfessionalAPI';
import { getAppointment } from '@/service/Appointments/appointmentService';

import { BookingStep } from '@/utils/constants.js';
import { getHoursAndMinutes } from '@/utils/dateUtils';
import { AppointmentStatus, getText } from '@/utils/helpers.js';

import {
    useBookingStore,
    useConfigStore,
    useCustomAnamnesisStore,
    useNotificationsStore,
    usePersistedPrismicStore,
    useSearchStore,
} from '@/store.js';

import check from '../assets/Icons/green_check.svg';
import red_cross from '../assets/Icons/not-uploaded-icon.svg';
import GrayTextBox from './Common/GrayTextBox';
import ConfirmCheckInCard from './ConfirmCheckinCard';
import EternoSpinner from './EternoLogoSpinner/EternoSpinner';
import FilledButton from './FilledButton';
import Icon from './Icon/Icon';
import SmsNotificationCard from './SmsNotificationCard';

const useStyles = makeStyles((theme) => ({
    classTitle: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1.31rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    detailsLinks: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,

        color: theme.palette.common.darkGrey,
        marginTop: 30,
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 0,
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    findButton: {
        ...theme.button,
    },
    buttonText: {
        ...theme.typography.buttonText,
    },
    dateText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.75rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    labelText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    cardContentClassDetail: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: '8px',
        display: 'block',
        padding: '30px',
        marginBottom: '20px',
    },
    imgIcon: {
        width: '40px',
        height: '46px',
        marginRight: '20px',
    },
    flexClass: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    gridItem: {
        width: '100%',
    },
    gridItemUploaded: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    mainCenterCircle: {
        flex: 1,
    },
    labels: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
    },
    values: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
    },
    btnLabel: {
        fontFamily: 'MessinaSans-SemiBold',
        color: theme.palette.common.darkGrey,
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        lineHeight: '1.5rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.7rem',
        },
    },
    pendingContainer: {
        width: '40px',
        height: '46px',
        marginRight: '50px',
        marginBottom: '5px',
    },
}));

const languageMapCalendar = {
    en: 'en-US',
    de: 'de-DE',
};

const BookingConfirmed = ({ mainData, dataSet, mainBenefitsReg }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [showWaitForConfirmation, setShowWaitForConfirmation] = useState(false);
    const hideSmsNotificationCard = useNotificationsStore((state) => state.hideSmsNotificationCard);
    const showSmsSuccessMessage = useNotificationsStore((state) => state.showSuccessMessage);
    const appointmentData = useBookingStore((state) => state.appointmentData);
    const bookingId = useBookingStore((state) => state.bookedAppointmentId);
    const treatments = useSearchStore((state) => state.treatments);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);
    const {
        [i18n.language]: { mainData: bookAppointmentPrismicData },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);

    console.debug('appointmentConfirm', mainData, mainBenefitsReg);
    const { appointmentSearch, appointmentSearchConfirmation, appointmentSelectionConfirmation, languageGlobal } =
        useContext(ContextProvider);

    const {
        state: { mainDataMyDoctorsID },
    } = useContext(DoctorsContext);

    const { apppointmentDoctorInfo, setAllDoctorsDataApi } = useContext(ContextProviderTwo);

    const {
        state: { userAccount, usersession },
    } = useContext(AuthContext);

    const appointmentFetcher = async () => getAppointment(usersession, bookingId);

    const hasFinalStatus = (data) => {
        return data?.status === AppointmentStatus.BOOKED || data?.status === AppointmentStatus.DECLINED;
    };
    const { data } = usePollingData(
        {
            key: bookingId,
        },
        appointmentFetcher,
        hasFinalStatus
    );

    const {
        state: { confirmedDoctor },
    } = useContext(BookAppointmentContext);

    const {
        consentAllForms: { isConsentAllForms, formTemplates },
        setSignState,
        setConsentAllForms,
        setProfessional,
    } = useConsentFormsContext();

    useEffect(() => {
        professionalsId()
            .then((response) => {
                const responseData = response?.data.data;
                setAllDoctorsDataApi(responseData);
                console.debug('allDoctorsAPI resp: ', response);
            })
            .catch((error) => {
                console.debug('allDoctorsAPI error: ', error);
                setAllDoctorsDataApi([]);
            });
    }, []);

    console.debug('ConfirmedDoctor2: ', languageGlobal, mainDataMyDoctorsID, apppointmentDoctorInfo);

    const bookedTime = useMemo(() => {
        if (!appointmentSelectionConfirmation?.selected_appointment) return '';

        return getHoursAndMinutes(appointmentSelectionConfirmation.selected_appointment);
    }, [appointmentSelectionConfirmation]);

    const getDateWithMonth = (date) => {
        const d = new Date(date);
        const lan = languageMapCalendar[i18n.language];
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };

        // an application may want to use UTC and make that visible
        console.debug('appointmentSelection1', appointmentSearchConfirmation);
        return new Intl.DateTimeFormat(lan, options).format(d);
    };

    const {
        isLoading: isTemplatesLoading,
        requiredFormsCount,
        templates,
        customAnamnesisTemplates,
    } = useRequiredTemplatesAndAnamnesis({
        appointmentTypeKeys: appointmentSearch?.treatment_keys_array,
        insuranceType:
            appointmentSearch?.insurance_type_key === 'self-payer'
                ? 'SELF_PAYER'
                : appointmentSearch?.insurance_type_key?.toUpperCase(),
        professionalId: confirmedDoctor?.id,
    });

    const { currentStepIndex, step } = useFormSteps();
    const { loading: formTemplateLoading } = useFormTemplate({ type: isConsentAllForms ? 'form' : 'query' });
    const signatureTypeForNextForm = formTemplates[currentStepIndex]?.signatureType;

    // Get the correct professional
    useProfessionalMappings();

    const onConfirmCheckIn = async () => {
        if (data?.status !== AppointmentStatus.BOOKED) {
            setShowWaitForConfirmation(true);
            return;
        }
        setShowWaitForConfirmation(false);

        setSignState(signatureTypeForNextForm);

        setProfessional({
            ...confirmedDoctor,
            id: confirmedDoctor?.id,
            key: confirmedDoctor.key[0].text,
            name: confirmedDoctor.display_name[0].text,
        });
        setConsentAllForms((prev) => ({
            ...prev,
            initialRoute: ConsentAllFormsInitialRoutes.CONFIRM_APPOINTMENT,
            isConsentAllForms: true,
            forms: templates.map((template) => ({
                id: template?.id ?? '',
                ...(template?.name ? { name: template?.name } : {}),
                ...(template?.key ? { key: template?.key } : {}),
            })),
        }));

        if (customAnamnesisTemplates.length > 0) {
            useCustomAnamnesisStore.setState({
                redirectToConsentForms: templates.length > 0,
                formsToFill: customAnamnesisTemplates,
                currentForm: 1,
                loading: true,
            });
            history.push(
                `/custom-anamnesis?professional_id=${confirmedDoctor?.id}&template_id=${customAnamnesisTemplates[0].id}`
            );
        } else history.push(step);
    };

    const handleRebook = () => {
        const { selectedProfessional, consentTreatments, isOnlineAppointment, resetStore } = useBookingStore.getState();
        const newPartialState = {
            selectedProfessional: selectedProfessional,
            consentTreatments: consentTreatments,
            currentStep: BookingStep.TIMESLOT_SELECTION,
            selectedTimeslot: null,
            isOnlineAppointment: isOnlineAppointment,
            showBookingModal: true,
        };
        resetStore();
        useBookingStore.setState(newPartialState);
        history.goBack();
    };

    const renderAppointmentStatus = useCallback(() => {
        if (data?.status !== undefined) {
            const showModal = data.status === AppointmentStatus.DECLINED;
            useBookingStore.setState({ showBookingModal: showModal });
        }

        switch (data?.status) {
            case AppointmentStatus.BOOKED:
                return (
                    <>
                        <div className={classes.flexClass}>
                            <div className={classes.imgIcon}>
                                <Icon src={scheduledIcon} width="100%" height="100%" />
                            </div>
                            <Typography className={classes.classTitle}>
                                {dataSet ? mainData?.appointment_headline[0].text : 'Your appointment is booked'}
                            </Typography>
                        </div>
                        <Typography className={classes.classTeacher} style={{ marginBottom: 20 }}>
                            {dataSet
                                ? mainData.appointment_text[0].text
                                : 'You will receive an appointment confirmation via email.'}
                        </Typography>
                    </>
                );
            case AppointmentStatus.DECLINED:
                return (
                    <>
                        <div className={classes.flexClass}>
                            <div className={classes.imgIcon}>
                                <Icon src={red_cross} width="100%" height="100%" color="#DA3030" />
                            </div>
                            <Typography className={classes.classTitle}>
                                {bookAppointmentPrismicData?.declined_appointment[0].text ??
                                    'Your appointment was declined'}
                            </Typography>
                        </div>
                        <Typography className={classes.classTeacher} style={{ marginBottom: 20 }}>
                            {bookAppointmentPrismicData?.declined_appointment_text[0].text ??
                                'We are very sorry, but your appointment was declined. Please go back to the calendar and chose a different slot.'}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <FilledButton
                                fullWidth={matchesXs}
                                onPress={handleRebook}
                                color="secondary"
                                text={
                                    bookAppointmentPrismicData?.invalid_timeslot_cta[0].text ?? 'Select new time slot'
                                }
                                variant="contained"
                            />
                        </div>
                    </>
                );
            default:
                return (
                    <div className={classes.flexClass}>
                        <div className={classes.pendingContainer}>
                            <EternoSpinner customStyles={{ height: 40, transform: 'scale(0.3)' }} />
                        </div>

                        <Typography className={classes.classTitle}>
                            {bookAppointmentPrismicData?.pending_appointment[0].text ??
                                'We are processing your appointment'}
                        </Typography>
                    </div>
                );
        }
    }, [data, classes, dataSet, matchesXs, mainData, bookAppointmentPrismicData]);

    useEffect(() => {
        if (showSmsSuccessMessage) useNotificationsStore.setState({ showSuccessMessage: false });
    }, []);

    const showSmsNotificationCard = useMemo(
        () =>
            !hideSmsNotificationCard &&
            (showSmsSuccessMessage ||
                !(userAccount.subscriptions?.default && userAccount.notifications.is_sms_active)) &&
            data?.status !== AppointmentStatus.DECLINED,
        [hideSmsNotificationCard, showSmsSuccessMessage, userAccount, data]
    );

    return (
        <>
            <div className={classes.cardContentClassDetail}>
                <Grid container direction="row" style={{ height: '50%' }}>
                    <Grid item xs={12}>
                        <div
                            style={{
                                display: 'flex',
                                height: '70%',
                                flexDirection: 'column',
                            }}
                        >
                            {renderAppointmentStatus()}
                            {data?.status !== AppointmentStatus.DECLINED && (
                                <Grid container direction="column" spacing={1}>
                                    <Grid item container>
                                        <Grid item md={6} xs={12}>
                                            <div className={classes.gridItem}>
                                                <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                                    {dataSet && mainData
                                                        ? mainData.appointment_service_label[0].text
                                                        : 'Appointment'}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <div className={classes.gridItem} style={{ display: 'flex' }}>
                                                <div className="">
                                                    <Typography className={classes.values} style={{ marginTop: 5 }}>
                                                        {dataSet &&
                                                            appointmentData?.type_keys
                                                                ?.map(
                                                                    (key) =>
                                                                        treatments[selectedLocation]?.[key].name[0].text
                                                                )
                                                                .join(', ')}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid item container>
                                        <Grid item md={6} xs={12}>
                                            <div className={classes.gridItem}>
                                                <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                                    {dataSet && mainData ? mainData.location_label[0].text : 'Location'}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <div className={classes.gridItem}>
                                                <Typography className={classes.values} style={{ marginTop: 5 }}>
                                                    {dataSet &&
                                                        appointmentSearchConfirmation?.location_title &&
                                                        `${appointmentSearchConfirmation.location_title} ${
                                                            appointmentSearchConfirmation.location_title !== 'Online' &&
                                                            !appointmentSearchConfirmation.externalLocation &&
                                                            currentCustomer?.is_eterno_customer
                                                                ? 'Hub'
                                                                : ''
                                                        }`}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid item container>
                                        <Grid item md={6} xs={12}>
                                            <div className={classes.gridItem}>
                                                <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                                    {dataSet && mainData ? mainData?.date_label[0].text : 'Date'}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <div className={classes.gridItem}>
                                                <Typography className={classes.values} style={{ marginTop: 5 }}>
                                                    {dataSet &&
                                                        appointmentSelectionConfirmation?.selected_appointment &&
                                                        getDateWithMonth(
                                                            appointmentSelectionConfirmation.selected_appointment
                                                        )
                                                            .replace(',', ' ')
                                                            .replace(',', ' ')}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid item container>
                                        <Grid item md={6} xs={12}>
                                            <div className={classes.gridItem}>
                                                <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                                    {dataSet && mainData ? mainData.time_label[0].text : 'Time'}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <div className={classes.gridItem}>
                                                <Typography className={classes.values} style={{ marginTop: 5 }}>
                                                    {dataSet && bookedTime}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    {!appointmentSelectionConfirmation?.is_returning && (
                                        <div style={{ marginTop: '1rem' }}>
                                            <GrayTextBox text={dataSet && mainData?.new_patients_message[0]?.text} />
                                        </div>
                                    )}
                                    {appointmentSearchConfirmation.is_with_prescription && (
                                        <Grid item container>
                                            <Grid item md={6} xs={12}>
                                                <div className={classes.gridItem}>
                                                    <Typography className={classes.labels} style={{ marginTop: 5 }}>
                                                        {dataSet && mainData?.booking_summary
                                                            ? mainData.booking_summary.prescription_label[0].text
                                                            : 'Prescription'}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item md={6} xs={12} style={{ marginTop: 5 }}>
                                                <div className={classes.gridItemUploaded}>
                                                    {appointmentSearchConfirmation.is_with_prescription &&
                                                    appointmentSearchConfirmation.is_haveImg ? (
                                                        <img
                                                            src={check}
                                                            style={{
                                                                objectFit: 'contain',
                                                                marginRight: 5,
                                                            }}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        appointmentSearchConfirmation.is_with_prescription &&
                                                        !appointmentSearchConfirmation.is_haveImg && (
                                                            <img
                                                                src={red_cross}
                                                                style={{
                                                                    objectFit: 'contain',
                                                                    marginRight: 5,
                                                                }}
                                                                alt=""
                                                            />
                                                        )
                                                    )}
                                                    <Typography className={classes.values}>
                                                        {appointmentSearchConfirmation.is_with_prescription &&
                                                        appointmentSearchConfirmation.is_haveImg
                                                            ? dataSet && mainData?.booking_summary
                                                                ? mainData.booking_summary.prescription_status[0].text
                                                                : 'Uploaded'
                                                            : mainData?.booking_summary
                                                              ? appointmentSearchConfirmation.is_with_prescription &&
                                                                !appointmentSearchConfirmation.is_haveImg &&
                                                                mainData.booking_summary.not_uploaded[0].text
                                                              : 'Not Uploaded'}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <ConfirmCheckInCard
                heading={dataSet ? getText(mainData?.forms_title) : 'Save time before your appointment'}
                subHeading={
                    dataSet
                        ? getText(mainData?.forms_subtitle)
                        : 'Fill out the anamnesis to share with your doctor before the appointment.'
                }
                buttonLabel={`${
                    requiredFormsCount + customAnamnesisTemplates.length !== 1
                        ? `(${requiredFormsCount + customAnamnesisTemplates.length}) `
                        : ''
                } ${t('FormsFlowCta')}`}
                disabledButton={
                    isTemplatesLoading ||
                    formTemplateLoading ||
                    requiredFormsCount + customAnamnesisTemplates.length === 0 ||
                    data?.status !== AppointmentStatus.BOOKED
                }
                onClick={onConfirmCheckIn}
                isBooked={data?.status === AppointmentStatus.BOOKED}
                showWaitForConfirmationText={showWaitForConfirmation}
                waitForConfirmationText={bookAppointmentPrismicData?.wait_for_confirmation[0].text}
                appointmentId={data?.id}
            />
            {showSmsNotificationCard && data?.status === AppointmentStatus.BOOKED && (
                <div className={classes.cardContentClassDetail}>
                    <SmsNotificationCard mainBenefits={mainData} mainBenefitsReg={mainBenefitsReg} dataSet={dataSet} />
                </div>
            )}
        </>
    );
};

export default BookingConfirmed;
