import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MapDoctors from './MapDoctors';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        backgroundColor: theme.palette.common.white,
        marginBottom: 20,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',

        borderRadius: 10,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 10,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.7',
        whiteSpace: 'pre-line',
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
    },
    goBackIcon: {
        // width: 9,
        height: 14,
        marginRight: 10,
    },
    line: {
        height: 1,
        width: '100%',
        backgroundColor: theme.palette.common.mediumBrown,
        marginBottom: 30,
        marginTop: 30,
    },
    subheading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
    },
    services: {
        backgroundColor: '#F5F5F5',
        marginRight: 20,
        paddingBlock: 14,
        paddingInline: 18,
        borderRadius: 5,
        marginTop: 15,
    },
    servicesIner: {
        width: 'auto',
        fontSize: '14px',
    },
    serviceOuter: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 20,
    },
    showMore: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginRight: 7,
        cursor: 'pointer',
    },
    showMoreOuter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const DetailSection = ({ doctor_data, myAppointmentsDataStatus, dataSet, mainData }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        if (!doctor_data) return;
        const newLocations = [doctor_data.locationData];
        doctor_data.secondary_location_mappings.forEach((entry) =>
            entry.secondary_locations.forEach((secondaryLocation) => {
                if (!newLocations.find((location) => location.address[0].text === secondaryLocation.address[0].text))
                    newLocations.push(secondaryLocation);
            })
        );
        setLocations(newLocations);
    }, [doctor_data]);

    return (
        <div className={classes.contentContainer}>
            <div className="">
                <Typography className={classes.mainHeading}>
                    {dataSet ? mainData?.about_heading[0].text : 'About'}{' '}
                    {myAppointmentsDataStatus ? doctor_data?.display_name[0].text : null}
                </Typography>

                <Typography className={classes.shortText}>
                    {myAppointmentsDataStatus ? doctor_data?.about[0].text : null}
                </Typography>
            </div>
            <div className={classes.line} />
            <div className="">
                <Typography className={classes.mainHeading}>
                    {dataSet ? mainData?.services_heading[0].text : 'Services available for you'}
                </Typography>
            </div>
            <div className={classes.serviceOuter}>
                {doctor_data?.servicesList.map((service) => (
                    <div className={classes.services}>
                        <Typography className={classes.servicesIner}>{service.name[0].text}</Typography>
                    </div>
                ))}
            </div>
            <div className={classes.line} />
            <div className="">
                <Typography className={classes.mainHeading}>
                    {locations.length === 1
                        ? dataSet
                            ? mainData?.location_heading[0].text
                            : 'Location'
                        : t('Locations')}
                </Typography>
                {locations.map((location) => (
                    <div className="">
                        <Typography className={classes.subheading} style={{ marginBottom: 20, marginTop: 30 }}>
                            {myAppointmentsDataStatus ? location.address[0].text : null}
                        </Typography>
                        <div className="">
                            <MapDoctors dataSet={dataSet} doctor_location={location} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DetailSection;
