import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dustbinIcon from '@/assets/images/dustbinIcon.png';
import pencilIcon from '@/assets/images/pencilIcon.png';

import { DateTextField } from '@/components/Common/Date/DateTextField';
import FilledButton from '@/components/FilledButton';
import UnderlinedButton from '@/components/UnderlinedButton';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { ContextProvider } from '@/context/ProjectContext';

import { getLocalDateMonth } from '@/utils/helpers.js';

import UnderLinedAddButton from '../UnderLinedAddButton/UnderLinedAddButton';
import { useStyles } from './ExistingConditionStyles';

export default function ExistingConditionHadSurgery({
    mainData,
    addDataSurgeries,
    deeletOptionSurgery,
    editOptionSurgery,
    deleteEditSurgery,
    isFromHealthData,
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [errSurgery, setErrSurgery] = useState('');

    // context for const variables
    const {
        setSurgeries,
        setSergeriesQuestion,
        setSergerieDate,
        setSergeryDataAll,
        surgeries,
        sergeriesQuestion,
        sergerieDate,
        sergeryDataAll,
        editPencil,
        errorDate2,
        setErrorDate2,
    } = useContext(ContextProviderTwo);
    const { languageGlobal } = useContext(ContextProvider);

    const {
        state: { is_open_state },
        setIsOpenState,
    } = useContext(HealthProfileContextMain);

    const validateForm = (type) => {
        switch (type) {
            case 'whichSurgeryErr':
                setErrSurgery('');
                if (sergeriesQuestion === '') {
                    setErrSurgery(t('RequiredField'));
                }
                break;
            default:
                break;
        }
    };

    return (
        <div
            className={classes.contentContainerBody}
            style={{
                marginTop: isFromHealthData ? surgeries && (matches ? 20 : 40) : 40,
                marginBottom: isFromHealthData ? surgeries && 30 : 40,
            }}
        >
            <div className={classes.fixedQuestion}>
                <Typography className={classes.mainHeading}>{mainData.surgeries_question[0].text}</Typography>
                <Grid
                    style={{
                        marginTop: 20,
                    }}
                    container
                >
                    {mainData &&
                        mainData.surgeries_yes_no_reponse.map((parts) => (
                            <Grid lg="auto" sm="auto" xs={6}>
                                <Button
                                    onClick={() => {
                                        console.debug(parts.surgeries_yes_no_api_value);
                                        setSurgeries(parts.surgeries_yes_no_api_value);
                                        if (parts.surgeries_yes_no_api_value === 'YES' && is_open_state) {
                                            setIsOpenState(false);
                                        }
                                        if (parts.surgeries_yes_no_api_value === 'NO') {
                                            setSergeryDataAll([]);
                                            setSergerieDate('');
                                            setErrorDate2(false);
                                        }
                                    }}
                                    disableRipple
                                    className={
                                        surgeries === parts.surgeries_yes_no_api_value
                                            ? clsx(classes.selectItemCard, classes.selectedCard)
                                            : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                    }
                                    style={{
                                        marginBottom: matches && 10,
                                        width: matches && '90%',
                                        display: matches ? null : 'flex',
                                        marginRight: 15,
                                        // height: "100%",
                                    }}
                                >
                                    <div className="">
                                        <Typography className={classes.BtncontentText}>
                                            {parts.surgeries_yes_no_response[0].text}
                                        </Typography>
                                    </div>
                                </Button>
                            </Grid>
                        ))}
                </Grid>
            </div>
            {surgeries.toLocaleLowerCase() === 'yes' && (
                <div className="">
                    {/* gray portion */}
                    {sergeryDataAll.length > 0 && (
                        <div className="">
                            <div className={classes.graySection}>
                                <Typography className={classes.mainHeading}>
                                    {mainData.surgeries_summary_title[0].text}
                                </Typography>
                                {sergeryDataAll.map((all, index) => (
                                    <div className={classes.flexClass}>
                                        <Typography
                                            className={classes.subHeading}
                                            style={{ wordBreak: 'break-all', paddingRight: 5 }}
                                        >
                                            {`${all.sergeriesQuestion}${
                                                all.sergerieDate.length > 0 ? '.' : ''
                                            } ${getLocalDateMonth(all.sergerieDate, languageGlobal)}`}
                                        </Typography>
                                        <div className={classes.flexImg}>
                                            <img
                                                src={pencilIcon}
                                                alt=""
                                                className={classes.icons}
                                                onClick={() => {
                                                    if (is_open_state) {
                                                        setIsOpenState(false);
                                                    }
                                                    !editPencil && editOptionSurgery(index, all);
                                                }}
                                            />
                                            <img
                                                src={dustbinIcon}
                                                alt=""
                                                className={classes.pointer}
                                                onClick={() => {
                                                    if (is_open_state) {
                                                        setIsOpenState(false);
                                                    }
                                                    deeletOptionSurgery(index, all);
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {!is_open_state && <div className={classes.line} />}
                        </div>
                    )}
                    {/* end */}
                    {/* gray portion end */}

                    {!is_open_state && (
                        <Grid container spacing={1} style={{ marginTop: !matches ? 20 : 10 }}>
                            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                <TextField
                                    id="existing_condition_which"
                                    label={mainData ? mainData.surgeries_subquestion_which[0].text : t('TransferSlip')}
                                    // placeholder={t("StartTyping")}
                                    variant="filled"
                                    autoComplete="off"
                                    color="primary"
                                    value={sergeriesQuestion}
                                    onChange={(event) => {
                                        setSergeriesQuestion(event.target.value);
                                    }}
                                    classes={{
                                        root: classes.textFields,
                                    }}
                                    onBlur={() => {
                                        validateForm('whichSurgeryErr');
                                    }}
                                    style={{
                                        width: matches ? '100%' : '100%',
                                        flex: matches ? 1 : 0.5,
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRoot,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                    }}
                                    onKeyPress={(event) => {
                                        if (sergeriesQuestion.length > 0 && event.key === 'Enter') {
                                            console.debug('medication1: Entering...');
                                            addDataSurgeries(false, false, false, [], [], '');
                                        }
                                    }}
                                />
                                {!(sergeryDataAll.length > 0) && (
                                    <Grid xs="12" sm="12">
                                        {errSurgery && (
                                            <Typography className={classes.errorText}>
                                                {mainData.required_field_error_message1[0].text}
                                            </Typography>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                <DateTextField
                                    type="monthAndYear"
                                    separator={mainData.dob_placeholder_separator[0].text}
                                    mainHeading={mainData.surgeries_subquestion_when___[0].text}
                                    setDOB={setSergerieDate}
                                    dob={sergerieDate}
                                    setError={setErrorDate2}
                                    error={errorDate2}
                                    errorMessage={mainData.date_error[0].text}
                                    placeHolderMonth={mainData.dob_placeholder_month[0].text}
                                    placeHolderYear={mainData.dob_placeholder_year[0].text}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <div className={classes.maginB}>
                        {!editPencil && (
                            <UnderLinedAddButton
                                underLineText={`${mainData.add_surgery_cta[0].text}`}
                                onClick={() => {
                                    if (errorDate2) {
                                        return;
                                    }
                                    if (is_open_state) {
                                        setIsOpenState(false);
                                        return;
                                    }
                                    sergeriesQuestion.length > 0 && addDataSurgeries(false, false, false, [], [], '');
                                }}
                            />
                        )}

                        {editPencil && (
                            <div
                                className=""
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                }}
                            >
                                <FilledButton
                                    text={mainData?.continue_cta[0].text}
                                    disabled={errorDate2}
                                    onPress={() => {
                                        sergeriesQuestion.length > 0 &&
                                            addDataSurgeries(false, false, false, [], [], '');
                                    }}
                                />
                                <UnderlinedButton
                                    text={mainData?.cancel[0].text}
                                    onPress={() => {
                                        deleteEditSurgery();
                                    }}
                                    noMarginTop
                                    marginLeft={20}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
