import { Button, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useStyles } from '../../HealthMenuStyles';

const HealthMenuHealthDataItems = ({ index = false, item, onPress, highlightedMenu, setMenuOpen }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Button
            onClick={() => {
                onPress();
                if (matchesSM && setMenuOpen) setMenuOpen(false);
            }}
            disableRipple
            classes={{
                root: classes.menuItemButton,
                label: clsx(classes.menuItemButtonText, highlightedMenu),
            }}
        >
            {item}
        </Button>
    );
};

export default HealthMenuHealthDataItems;
