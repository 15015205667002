import { useContext, useMemo } from 'react';

/** Router */
import { useHistory } from 'react-router-dom';

import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';

/** Child Components */
import { WelcomeComponent } from '@/components/ConsentForms';
import Loader from '@/components/Loader';

import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import usePrismicData from '@/hooks/usePrismicData';

import { getText } from '@/utils/helpers.js';

import { usePersistedPrismicStore } from '@/store.js';

const description =
    'Please read the following documents thoroughly and fill them out. After completing the required sections, these documents will be forwarded to your practitioner immediately.';

const Welcome = () => {
    const history = useHistory();

    const consentFormsTreatment = usePersistedPrismicStore((state) => state.consentFormsTreatment);
    const prismicDataHerakles = usePersistedPrismicStore((state) => state.heraklesCommunication);
    const { loading, content } = usePrismicData(consentFormsTreatment, 'consent_forms_-_welcome_page');
    const { loading: heraklesLoading, content: heraklesContent } = usePrismicData(prismicDataHerakles);

    const {
        state: { user },
    } = useContext(AuthContext);

    const { setIsFromOwnWelcome } = useContext(ConsentFormsContext);

    const handleContinueWithAccount = () => {
        setIsFromOwnWelcome(true);
        if (user) history.push('/consent-communication?step=personal-information');
        else history.push('/login?redirectTo=/consent-communication?step=personal-information');
    };

    const handleContinueAsGuest = () => {
        setIsFromOwnWelcome(true);
        history.push('/consent-communication?step=personal-information');
    };

    const continueText = useMemo(() => {
        return !loading ? getText(content?.continue_as_guest_title) : 'You don’t have an Eterno account?';
    }, [loading, content]);

    if (loading || heraklesLoading) return <Loader />;

    return (
        <WelcomeComponent
            width="70% !important"
            icon={WelcomeIcon}
            title={getText(heraklesContent?.extended_treatment_contract_title) ?? 'Communication'}
            paragraphs={[getText(heraklesContent?.ext_treatment_contract_subtitle_text) ?? description]}
            primaryBtnText={getText(content?.continue_with_account_cta) ?? 'Continue with your eterno account'}
            {...(!user && { continueText: continueText })}
            continueBtnText={getText(content?.continue_as_guest_cta) ?? 'Continue as guest'}
            onPrimaryBtnClick={handleContinueWithAccount}
            onContinueBtnClick={handleContinueAsGuest}
        />
    );
};

export default Welcome;
