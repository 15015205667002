import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    divider: { height: '2px', background: '#F5F5F5', border: 'none' },
}));

const ContentDivider = ({ width, my }) => {
    const classes = useStyles();
    return <hr className={classes.divider} style={{ width: width || null, marginBlock: my || null }} />;
};

export default ContentDivider;
