import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        fontSize: '1rem',
        marginBottom: 20,
        textTransform: 'uppercase',
        color: theme.palette.common.brown,
        fontFamily: 'MessinaSans-Regular',
    },
}));

const CardHeading = ({ children, style }) => {
    const classes = useStyles();
    return (
        <Typography
            className={classes.mainHeading}
            style={{
                fontWeight: 600,
                marginBottom: 20,
                ...style,
            }}
        >
            {children}
        </Typography>
    );
};

export default memo(CardHeading);
