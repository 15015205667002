import { makeStyles } from '@material-ui/core/styles';
import { useContext, useMemo, useState } from 'react';

import Loader from '@/components/Loader';

import { Context as AuthContext } from '@/context/AuthContext';

import { useUserAllAppointments } from '@/hooks/useUserAllAppointments';

import DocumentsAccordion from './DocumentsAccordion';

const useStyles = makeStyles(() => ({
    loading: {
        marginTop: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}));

const DocumentsByAppointment = () => {
    const classes = useStyles();
    const [expandedAccordionId, setExpandedAccordionId] = useState(null);

    const {
        state: { userDocuments },
    } = useContext(AuthContext);

    const { data: appointmentsData } = useUserAllAppointments();

    const handleAccordionChange = (appointmentId) => {
        setExpandedAccordionId((prevId) => (prevId === appointmentId ? null : appointmentId));
    };

    const combinedAppointments = useMemo(() => {
        const activeAppointments = appointmentsData?.active ?? [];
        const inactiveAppointments = appointmentsData?.inactive ?? [];
        return [...activeAppointments, ...inactiveAppointments];
    }, [appointmentsData]);

    const appointmentsWithFiles = useMemo(() => {
        // Create a mapping of appointment ID to its documents
        const documentsByAppointmentId = (userDocuments?.data ?? []).reduce((acc, doc) => {
            const appointmentId = doc?.appointment_id;
            if (!appointmentId) return acc;

            acc[appointmentId] = acc[appointmentId] ?? [];
            acc[appointmentId].push({
                id: doc.id,
                path: doc.path,
                name: doc.name,
                specialty_type: doc.specialty_type,
                type: doc.type,
                meta: doc.meta,
            });

            return acc;
        }, {});

        // Enrich appointments with their corresponding documents
        return combinedAppointments.reduce((enrichedAppointments, appointment) => {
            const matchingDocs = documentsByAppointmentId[appointment.id];

            if (matchingDocs?.length > 0) {
                enrichedAppointments.push({
                    ...appointment,
                    documents: matchingDocs,
                });
            }
            return enrichedAppointments;
        }, []);
    }, [combinedAppointments, userDocuments]);

    if (!appointmentsData) {
        return (
            <div className={classes.loading}>
                <Loader height="100px" />
            </div>
        );
    }

    return appointmentsWithFiles.map((appointment) => (
        <div className={classes.appointmentItem} key={appointment.id}>
            <DocumentsAccordion
                key={appointment.id}
                appointment={appointment}
                expanded={expandedAccordionId === appointment.id}
                onAccordionChange={handleAccordionChange}
            />
        </div>
    ));
};

export default DocumentsByAppointment;
