import { useEffect, useState } from 'react';

const useDynamicImport = (importFunction) => {
    const [module, setModule] = useState(null);

    useEffect(() => {
        const loadModule = async () => {
            const importedModule = await importFunction();
            setModule(importedModule);
        };

        loadModule();
    }, []);

    return module;
};

export default useDynamicImport;
