import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import { client } from '@/service/Prismic';

import { getAllByTag, getAllByType, getByFilter, getByTag, getSingle } from '@/utils/fetchers';
import { isProdEnvironment } from '@/utils/helpers.js';
import {
    processCategorizePrismicData,
    processPrismicCommonData,
    processPrismicDataForAnamnesis,
    processPrismicDataForBookAppointment,
    processPrismicDataForDashboard,
    processPrismicDataForDoctorDetails,
    processPrismicDataForFindAnAppointment,
    processPrismicDataForFooter,
    processPrismicDataForHeader,
    processPrismicDataForHealthProfile,
    processPrismicFlattenData,
} from '@/utils/processPrismicDataHelpers';

import {
    useConfigStore,
    useCustomAnamnesisStore,
    usePersistedPrismicStore,
    usePrismicStore,
    useSearchStore,
} from '@/store.js';

export default function usePrismic() {
    const { i18n } = useTranslation();
    const [shouldFetch, setShouldFetch] = useState(false);
    const footerData = usePersistedPrismicStore((state) => state.footerData);
    const headerData = usePersistedPrismicStore((state) => state.headerData);
    const confirmAppointmentData = usePersistedPrismicStore((state) => state.confirmAppointmentData);
    const staticPages = usePersistedPrismicStore((state) => state.staticPages);
    const cookieConsentData = usePersistedPrismicStore((state) => state.cookieConsentData);
    const consentFormsTreatment = usePersistedPrismicStore((state) => state.consentFormsTreatment);
    const heraklesAllForms = usePersistedPrismicStore((state) => state.heraklesAllForms);
    const heraklesCommunication = usePersistedPrismicStore((state) => state.heraklesCommunication);
    const heraklesCourse = usePersistedPrismicStore((state) => state.heraklesCourse);
    const heraklesTherapy = usePersistedPrismicStore((state) => state.heraklesTherapy);
    const heraklesFormsCheckin = usePersistedPrismicStore((state) => state.heraklesFormsCheckin);
    const wielandFormsThankYou = usePersistedPrismicStore((state) => state.wielandFormsThankYou);
    const loginData = usePersistedPrismicStore((state) => state.loginData);
    const registerData = usePersistedPrismicStore((state) => state.registerData);
    const doctorData = usePersistedPrismicStore((state) => state.doctorData);
    const medicalRecordsData = usePersistedPrismicStore((state) => state.medicalRecordsData);
    const healthProfileData = usePersistedPrismicStore((state) => state.healthProfileData);
    const healthData = usePersistedPrismicStore((state) => state.healthData);
    const locationData = usePersistedPrismicStore((state) => state.locationData);
    const accountData = usePersistedPrismicStore((state) => state.accountData);
    const findAppointmentData = usePersistedPrismicStore((state) => state.findAppointmentData);
    const dashboardData = usePersistedPrismicStore((state) => state.dashboardData);
    const bookAppointmentData = usePersistedPrismicStore((state) => state.bookAppointmentData);
    const anamnesisData = usePersistedPrismicStore((state) => state.anamnesisData);
    const treatmentContractWelcomeData = usePersistedPrismicStore((state) => state.treatmentContractWelcomeData);
    const consentData = usePersistedPrismicStore((state) => state.consentData);
    const appointmentData = usePersistedPrismicStore((state) => state.appointmentData);
    const hasHydrated = usePersistedPrismicStore((state) => state._hasHydrated);

    const { data: footerPrismic, ...footerPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !footerData[i18n.language].dataSet)
            ? {
                  key: 'footer-prismic',
                  tag: 'footer_patients',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: headerPrismic, ...headerPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !headerData[i18n.language].dataSet)
            ? {
                  key: 'header-prismic',
                  tag: 'header_patients',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: registerPrismic, ...registerPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !registerData[i18n.language].dataSet)
            ? {
                  key: 'registration-prismic',
                  tag: 'registration_patients',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: loginPrismic, ...loginPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !loginData[i18n.language].dataSet)
            ? {
                  key: 'login-prismic',
                  tag: 'login_patients',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: dashboardPrismic, ...dashboardPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !dashboardData[i18n.language].dataSet)
            ? {
                  key: 'dashboard-prismic',
                  tag: 'dashboard_patients',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: appointmentPrismic, ...appointmentPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !appointmentData[i18n.language]?.dataSet)
            ? {
                  key: 'my-appointments-prismic',
                  tag: 'my_appointments',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: doctorPrismic, ...doctorPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !doctorData[i18n.language].dataSet)
            ? {
                  key: 'doctor-prismic',
                  tag: 'doctor',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: locationPrismic, ...locationPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !locationData[i18n.language].dataSet)
            ? {
                  key: 'location-prismic',
                  tag: 'loctaion',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: accountPrismic, ...accountPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !accountData[i18n.language].dataSet)
            ? {
                  key: 'account-patients-prismic',
                  tag: 'account_patients',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: findAppointmentPrismic, ...findAppointmentPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !findAppointmentData[i18n.language].dataSet)
            ? {
                  key: 'find-appointment-prismic',
                  tag: 'find_an_appointment',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: confirmAppointmentPrismic, ...confirmAppointmentPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !confirmAppointmentData[i18n.language])
            ? {
                  key: 'confirm-appointment-prismic',
                  tag: 'confirm_appointment_patient',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: heraklesTherapyPrismic, ...heraklesTherapyPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !heraklesTherapy[i18n.language])
            ? {
                  key: 'herakles-therapy-prismic',
                  tag: 'herakles_therapy',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: myRecordsPrismic, ...myRecordsPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !medicalRecordsData[i18n.language]?.dataSet)
            ? {
                  key: 'medical-records-prismic',
                  type: 'medical_records',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getSingle
    );
    console.debug('🚀 ~ file: usePrismic.js:53 ~ usePrismic ~ myRecordsPrismic:', myRecordsPrismic);

    const { data: healthDataPrismic, ...healthDataPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !healthData[i18n.language].dataSet || !anamnesisData[i18n.language].dataSet)
            ? {
                  key: 'health-profile-questions-prismic',
                  tag: 'health_profile_questions',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: healthProfilePrismic, ...healthProfilePrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !healthProfileData[i18n.language]?.dataSet)
            ? {
                  key: 'health-profile-overview-prismic',
                  tag: 'health_profile_overview',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: heraklesCommunicationPrismic, ...heraklesCommunicationPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || heraklesCommunication[i18n.language])
            ? {
                  key: 'herakles-communication-prismic',
                  tag: 'herakles_communication',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const isPrismicConsentDataEmpty = useMemo(
        () => Object.keys(consentData[i18n.language]?.content ?? {}).length === 0,
        [consentData, i18n]
    );

    const { data: consentPrismic, ...consentPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !isPrismicConsentDataEmpty)
            ? {
                  key: 'consent-manager-prismic',
                  type: 'consent_manager',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getSingle
    );
    console.debug('🚀 ~ file ~ usePrismic ~ anamnesisPrismic:', consentPrismic);

    const { data: consentFormsTreatmentData, ...consentFormsTreatmentState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !consentFormsTreatment[i18n.language])
            ? {
                  key: 'consent-forms-treatment-prismic',
                  tag: 'consent_forms_treatment',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: bookAppointmentPrismic, ...bookAppointmentPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !bookAppointmentData[i18n.language].dataSet)
            ? {
                  key: 'book-appointment-prismic',
                  tag: 'book_an_appointment',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: heraklesCoursePrismic, ...heraklesCoursePrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !heraklesCourse[i18n.language])
            ? {
                  key: 'herakles-course-prismic',
                  tag: 'herakles_course',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: customQuestionDocuments, ...customQuestionPrismicState } = useSWRImmutable(
        {
            key: 'custom-question-prismic',
            type: 'custom_question',
            params: { lang: i18n.language === 'en' ? 'en-us' : 'de-de' },
        },
        getAllByType
    );

    const { data: anamnesisWelcomeDocument, ...anamnesisWelcomePrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !anamnesisData[i18n.language].dataSet)
            ? {
                  key: 'anamnesis-welcome-prismic',
                  type: 'welcome_page_to_anamnesis_in_hub',
                  params: { lang: i18n.language === 'en' ? 'en-us' : 'de-de' },
              }
            : null,
        getSingle
    );

    const { data: anamnesisThankYouDocument, ...anamnesisThankYouPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !anamnesisData[i18n.language].dataSet)
            ? {
                  key: 'health-profile-thank-you-prismic',
                  type: 'health_profile_thank_you_pages',
                  params: { lang: i18n.language === 'en' ? 'en-us' : 'de-de' },
              }
            : null,
        getSingle
    );

    const { data: heraklesAllFormsPrismic, ...heraklesAllFormsPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !heraklesAllForms[i18n.language])
            ? {
                  key: 'herakles-all-forms-prismic',
                  tag: 'herakles_all_forms',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: heraklesFormsCheckinPrismic, ...heraklesFormsCheckinPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !heraklesFormsCheckin[i18n.language])
            ? {
                  key: 'herakles-forms-checkin-prismic',
                  tag: 'herakles_forms_checkin',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: wielandFormsThankYouPrismic, ...wielandFormsThankYouPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !wielandFormsThankYou[i18n.language])
            ? {
                  key: 'wieland-forms-pages-prismic',
                  tag: 'wieland_forms_welcome_thankyou_pages',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: treatmentContractWelcome, ...treatmentContractWelcomePrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !treatmentContractWelcomeData[i18n.language])
            ? {
                  key: 'treatment-contract-welcome-prismic',
                  tag: 'treatment_contract_welcome_page',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getByTag
    );

    const { data: customerConfigDocuments, ...customerConfigPrismicState } = useSWRImmutable(
        {
            key: 'customer-configs-prismic',
            filters: {
                'my.customer_config.environment': import.meta.env.VITE_ENVIRONMENT,
            },
            params: {
                lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                pageSize: 200,
            },
        },
        getByFilter
    );

    const { data: appConfigPrismic, ...appConfigPrismicState } = useSWRImmutable(
        shouldFetch && hasHydrated
            ? {
                  key: 'patient-app-config-prismic',
                  filters: {
                      'my.config_patient_app.environment': import.meta.env.VITE_ENVIRONMENT,
                  },
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getByFilter
    );

    const { data: searchToolDocument, ...searchToolPrismicState } = useSWRImmutable(
        {
            key: 'search-tool-prismic',
            type: 'search_tool',
            params: {
                lang: i18n.language === 'en' ? 'en-us' : 'de-de',
            },
        },
        getSingle
    );

    const { data: pageNotFoundDocument, ...pageNotFoundPrismicState } = useSWRImmutable(
        {
            key: 'not-found-prismic',
            type: 'not_found_page',
            params: {
                lang: i18n.language === 'en' ? 'en-us' : 'de-de',
            },
        },
        getSingle
    );

    const { data: staticPagesData, ...staticPagesState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !staticPages[i18n.language])
            ? {
                  key: 'static-pages-prismic',
                  filters: { 'my.static_pages.application': 'Patients' },
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                      pageSize: 100,
                  },
              }
            : null,
        getByFilter
    );

    const { data: unsubscribePageDocument, ...unsubscribePagePrismicState } = useSWRImmutable(
        {
            key: 'unsubscribe-prismic',
            type: 'unsubscrib',
            params: {
                lang: i18n.language === 'en' ? 'en-us' : 'de-de',
            },
        },
        getSingle
    );

    const { data: cookieConsentPrismic, ...cookieConsentPrismicState } = useSWRImmutable(
        hasHydrated && (shouldFetch || !cookieConsentData[i18n.language])
            ? {
                  key: 'cookie-management-prismic',
                  tag: 'cookie_management',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const { data: welcomePageAnamnesisPrismic, ...welcomePageAnamnesisPrismicState } = useSWRImmutable(
        shouldFetch && hasHydrated
            ? {
                  key: 'welcome-page-anamnesis-prismic',
                  tag: 'welcome_page_anamnesis',
                  params: {
                      lang: i18n.language === 'en' ? 'en-us' : 'de-de',
                  },
              }
            : null,
        getAllByTag
    );

    const appVersion = usePersistedPrismicStore((state) => state.appVersion);
    const timestamp = usePersistedPrismicStore((state) => state.timestamp);
    const prismicRef = usePersistedPrismicStore((state) => state.ref);

    const filterByEnvironmentTag = (prismicData) => {
        return prismicData.filter((entry) => entry.tags.includes(import.meta.env.VITE_ENVIRONMENT));
    };

    useEffect(() => {
        if (!hasHydrated) return;
        const triggerFetch = async () => {
            const now = Date.now();
            const isVersionMismatch = appVersion !== import.meta.env.VITE_VERSION;
            const timeElapsed = !timestamp || (now - parseInt(timestamp, 10)) / 60000 > 30;

            if (timeElapsed || isVersionMismatch) {
                const newStatePartial = {
                    timestamp: now,
                };

                if (isVersionMismatch) {
                    usePersistedPrismicStore.getState().resetStore();
                    newStatePartial.appVersion = import.meta.env.VITE_VERSION;
                }

                const { ref } = await client.getMasterRef();
                if (isVersionMismatch || ref !== prismicRef) {
                    newStatePartial.ref = ref;
                    setShouldFetch(true);
                }

                usePersistedPrismicStore.setState(newStatePartial);
            }
        };

        triggerFetch();
    }, [hasHydrated]);

    useEffect(() => {
        if (!headerPrismic) return;
        const headerValues = processPrismicDataForHeader(headerPrismic, 'header', 'Patients');
        usePersistedPrismicStore.setState({ headerData: { ...headerData, [i18n.language]: headerValues } });
    }, [headerPrismic]);

    useEffect(() => {
        if (!footerPrismic) return;
        const footerValues = processPrismicDataForFooter(footerPrismic, 'footer', 'Patients');
        usePersistedPrismicStore.setState({ footerData: { ...footerData, [i18n.language]: footerValues } });
    }, [footerPrismic]);

    useEffect(() => {
        if (!dashboardPrismic) return;
        const dashboardValues = processPrismicDataForDashboard(dashboardPrismic, 'dashboard');
        usePersistedPrismicStore.setState({ dashboardData: { ...dashboardData, [i18n.language]: dashboardValues } });
    }, [dashboardPrismic]);

    useEffect(() => {
        if (!registerPrismic) return;

        const registerValues = processPrismicFlattenData(registerPrismic, 'registration', 'Patients');
        usePersistedPrismicStore.setState({ registerData: { ...registerData, [i18n.language]: registerValues } });
    }, [registerPrismic]);

    useEffect(() => {
        if (!loginPrismic) return;

        const loginValues = processPrismicCommonData(loginPrismic, 'login', 'Patients');
        usePersistedPrismicStore.setState({ loginData: { ...loginData, [i18n.language]: loginValues } });
    }, [loginPrismic]);

    useEffect(() => {
        if (!appointmentPrismic) return;
        const appointmentValues = processCategorizePrismicData(appointmentPrismic, 'appointment_management');
        usePersistedPrismicStore.setState({
            appointmentData: { ...appointmentData, [i18n.language]: appointmentValues },
        });
    }, [appointmentPrismic]);

    useEffect(() => {
        if (!confirmAppointmentPrismic) return;

        usePersistedPrismicStore.setState({
            confirmAppointmentData: { ...confirmAppointmentData, [i18n.language]: confirmAppointmentPrismic },
        });
    }, [confirmAppointmentPrismic]);

    useEffect(() => {
        if (!doctorPrismic) return;

        const { professionalProfileData } = usePersistedPrismicStore.getState();
        const doctorValues = processCategorizePrismicData(doctorPrismic);
        const professionalProfileValues = processPrismicDataForDoctorDetails(doctorPrismic, 'professional_profile');
        usePersistedPrismicStore.setState({
            doctorData: { ...doctorData, [i18n.language]: doctorValues },
            professionalProfileData: { ...professionalProfileData, [i18n.language]: professionalProfileValues },
        });
    }, [doctorPrismic]);

    useEffect(() => {
        if (!locationPrismic) return;

        const locationValues = processCategorizePrismicData(locationPrismic);
        console.debug('🚀 ~ file: usePrismic.js:52 ~ useEffect ~ locationValues:', locationValues);
        usePersistedPrismicStore.setState({
            locationData: { ...locationData, [i18n.language]: locationValues },
        });
    }, [locationPrismic]);

    useEffect(() => {
        if (!accountPrismic) return;
        const accountValues = processPrismicCommonData(accountPrismic, 'account_page_patients');
        usePersistedPrismicStore.setState({ accountData: { ...accountData, [i18n.language]: accountValues } });
    }, [accountPrismic]);

    useEffect(() => {
        if (!findAppointmentPrismic) return;
        const findAppointmentValues = processPrismicDataForFindAnAppointment(findAppointmentPrismic);
        usePersistedPrismicStore.setState({
            findAppointmentData: {
                ...findAppointmentData,
                [i18n.language]: { ...findAppointmentValues, unprocessedData: findAppointmentPrismic },
            },
        });
        console.debug('🚀 ~ file: usePrismic.js:64 ~ useEffect ~ findAppointmentData:', findAppointmentValues);
    }, [findAppointmentPrismic]);

    useEffect(() => {
        if (!healthDataPrismic || !anamnesisWelcomeDocument || !anamnesisThankYouDocument) return;

        const anamnesisValues = processPrismicDataForAnamnesis(healthDataPrismic);
        usePersistedPrismicStore.setState({
            anamnesisData: {
                ...anamnesisData,
                [i18n.language]: {
                    ...anamnesisValues,
                    welcomeData: anamnesisWelcomeDocument.data,
                    thankYouData: anamnesisThankYouDocument.data,
                },
            },
        });
    }, [healthDataPrismic, anamnesisWelcomeDocument, anamnesisThankYouDocument]);

    useEffect(() => {
        if (!heraklesTherapyPrismic) return;

        usePersistedPrismicStore.setState({
            heraklesTherapy: { ...heraklesTherapy, [i18n.language]: heraklesTherapyPrismic },
        });
    }, [heraklesTherapyPrismic]);

    useEffect(() => {
        if (!myRecordsPrismic) return;
        usePersistedPrismicStore.setState({
            medicalRecordsData: {
                ...medicalRecordsData,
                [i18n.language]: { content: myRecordsPrismic?.data, dataSet: true },
            },
        });
    }, [myRecordsPrismic]);

    useEffect(() => {
        if (!healthDataPrismic) return;
        const healthDataValues = processCategorizePrismicData(
            healthDataPrismic,
            'health_profile_questionnaire_public_pages',
            'professional_profile'
        );
        console.debug('🚀 ~ file: usePrismic.js:111 ~ useEffect ~ healthDataValues:', healthDataValues);
        usePersistedPrismicStore.setState((state) => ({
            healthData: { ...state.healthData, [i18n.language]: healthDataValues },
        }));
    }, [healthDataPrismic]);

    useEffect(() => {
        if (!healthProfilePrismic) return;
        const healthProfileValues = processPrismicDataForHealthProfile(healthProfilePrismic);
        usePersistedPrismicStore.setState({
            healthProfileData: { ...healthProfileData, [i18n.language]: healthProfileValues },
        });
    }, [healthProfilePrismic]);

    useEffect(() => {
        if (!heraklesCommunicationPrismic) return;

        usePersistedPrismicStore.setState({
            heraklesCommunication: { ...heraklesCommunication, [i18n.language]: heraklesCommunicationPrismic },
        });
    }, [heraklesCommunicationPrismic]);

    useEffect(() => {
        if (!consentPrismic) return;

        usePersistedPrismicStore.setState({
            consentData: {
                ...consentData,
                [i18n.language]: { loading: !consentPrismic?.data, content: consentPrismic?.data },
            },
        });
    }, [consentPrismic]);

    useEffect(() => {
        if (!consentFormsTreatmentData) return;

        usePersistedPrismicStore.setState({
            consentFormsTreatment: { ...consentFormsTreatment, [i18n.language]: consentFormsTreatmentData },
        });
    }, [consentFormsTreatmentData]);

    useEffect(() => {
        if (!bookAppointmentPrismic) return;
        const bookAppointmentValues = processPrismicDataForBookAppointment(bookAppointmentPrismic);
        usePersistedPrismicStore.setState({
            bookAppointmentData: {
                ...bookAppointmentData,
                [i18n.language]: bookAppointmentValues,
            },
        });
        console.debug('🚀 ~ file: usePrismic.js ~ useEffect ~ bookAppointmentValues:', bookAppointmentValues);
    }, [bookAppointmentPrismic]);

    useEffect(() => {
        if (!heraklesCoursePrismic) return;

        usePersistedPrismicStore.setState({
            heraklesCourse: { ...heraklesCourse, [i18n.language]: heraklesCoursePrismic },
        });
    }, [heraklesCoursePrismic]);

    useEffect(() => {
        if (!treatmentContractWelcome) return;

        usePersistedPrismicStore.setState({
            treatmentContractWelcomeData: {
                ...treatmentContractWelcomeData,
                [i18n.language]: treatmentContractWelcome?.results[0].data,
            },
        });
    }, [treatmentContractWelcome]);

    useEffect(() => {
        if (!heraklesAllFormsPrismic) return;

        usePersistedPrismicStore.setState({
            heraklesAllForms: { ...heraklesAllForms, [i18n.language]: heraklesAllFormsPrismic },
        });
    }, [heraklesAllFormsPrismic]);

    useEffect(() => {
        if (!heraklesFormsCheckinPrismic) return;

        usePersistedPrismicStore.setState({
            heraklesFormsCheckin: { ...heraklesFormsCheckin, [i18n.language]: heraklesFormsCheckinPrismic },
        });
    }, [heraklesFormsCheckinPrismic]);

    useEffect(() => {
        if (!wielandFormsThankYouPrismic) return;

        usePersistedPrismicStore.setState({
            wielandFormsThankYou: { ...wielandFormsThankYou, [i18n.language]: wielandFormsThankYouPrismic },
        });
    }, [wielandFormsThankYouPrismic]);

    useEffect(() => {
        if (!customQuestionDocuments || customQuestionDocuments.length === 0) return;

        const { questionsObj, subQuestionsObj } = customQuestionDocuments.reduce(
            (obj, question) => {
                if (!question.data.question_key?.[0]) return obj;

                if (question.tags.includes('subquestion')) {
                    obj.subQuestionsObj[question.id] = question.data;
                } else {
                    obj.questionsObj[question.data.question_key[0].text] = question.data;
                }

                return obj;
            },
            {
                questionsObj: {},
                subQuestionsObj: {},
            }
        );

        useCustomAnamnesisStore.setState({ questions: questionsObj, subQuestions: subQuestionsObj });
    }, [customQuestionDocuments]);

    useEffect(() => {
        const unprocessedFindAppointmentData = findAppointmentData[i18n.language].unprocessedData;

        if (!unprocessedFindAppointmentData || !customerConfigDocuments) return;

        const filteredData = filterByEnvironmentTag(unprocessedFindAppointmentData);

        const filteredCustomerConfigDocuments = customerConfigDocuments.results;

        const { hubsObj, professionalsObj, specialtiesObj, treatmentTypeCategoriesObj, allDocuments } =
            filteredData.reduce(
                (obj, entry) => {
                    let preFormattedData = null;
                    switch (entry.type) {
                        case 'hub':
                            obj.hubsObj[entry.data.key[0].text] = { ...entry.data, prismicId: entry.id };
                            break;
                        case 'professional_profile':
                            if (!entry.tags.includes(import.meta.env.VITE_ENVIRONMENT) || !entry.data.is_bookable) {
                                return obj;
                            }
                            obj.professionalsObj[entry.data.key[0].text] = entry.data;
                            break;
                        case 'professional_type':
                            obj.specialtiesObj[entry.data.key[0].text] = entry.data;
                            break;
                        case 'service_or_treatment':
                            const data = {
                                ...entry.data,
                            };
                            preFormattedData = data;
                            break;
                        case 'treatment_type_category':
                            obj.treatmentTypeCategoriesObj[entry.data.key[0].text] = entry.data;
                            break;
                        default:
                            break;
                    }
                    obj.allDocuments[entry.id] = preFormattedData ?? entry.data;
                    return obj;
                },
                {
                    hubsObj: {},
                    professionalsObj: {},
                    specialtiesObj: {},
                    treatmentTypeCategoriesObj: {},
                    allDocuments: {},
                }
            );

        let treatmentsObj = {};

        Object.keys(hubsObj).forEach((hubKey) => {
            hubsObj[hubKey].professional_types = hubsObj[hubKey].professional_types
                .map((professionalType) => ({
                    ...allDocuments[professionalType.professional_type.id],
                    services_or_treatments: allDocuments[
                        professionalType.professional_type.id
                    ].services_or_treatments.map((service) => {
                        const treatmentObj = {
                            ...service,
                            service_or_treatment: allDocuments[service.service_or_treatment.id],
                            category: allDocuments[service.category?.id],
                            specialty: allDocuments[professionalType.professional_type.id].key[0].text,
                        };

                        treatmentsObj = {
                            ...treatmentsObj,
                            [hubKey]: {
                                ...treatmentsObj[hubKey],
                                [allDocuments[service.service_or_treatment.id].key[0].text]: {
                                    ...treatmentObj,
                                    ...treatmentObj.service_or_treatment,
                                },
                            },
                        };

                        return treatmentObj;
                    }),
                }))
                .reduce((obj, professionalType) => {
                    obj[professionalType.key[0].text] = professionalType;
                    return obj;
                }, {});
        });

        const customersObj = filteredCustomerConfigDocuments
            .filter((entry) => (isProdEnvironment() ? entry.tags.includes('prod') : !entry.tags.includes('prod')))
            .reduce((obj, customer) => {
                if (!customer.data.key?.[0]?.text) return obj;

                obj[customer.data.key[0].text] = {
                    ...customer.data,
                    locations: customer.data.locations.map(
                        ({ location }) => hubsObj[allDocuments[location.id]?.key?.[0]?.text]
                    ),
                };

                if (customer.data.is_accordion_view_active) {
                    obj[customer.data.key[0].text].categories = obj[customer.data.key[0].text].locations.reduce(
                        (obj, location) => {
                            if (!location) {
                                console.error(
                                    `No location for this customer. Try checking the language of the locations of the customer prismic id ${customer.id}`
                                );
                                return;
                            }
                            Object.values(location.professional_types).forEach((specialty) =>
                                specialty.services_or_treatments.forEach((treatment) => {
                                    if (!treatment.category?.key?.[0]?.text) return;

                                    obj[treatment.category.key[0].text] = {
                                        ...obj[treatment.category.key[0].text],
                                        [treatment.service_or_treatment.key[0].text]:
                                            treatmentsObj[location.key[0].text][
                                                treatment.service_or_treatment.key[0].text
                                            ],
                                    };
                                })
                            );
                            return obj;
                        },
                        {}
                    );
                }

                return obj;
            }, {});

        const customersById = Object.values(customersObj).reduce((obj, customer) => {
            obj[customer.customer_id[0].text] = customer;
            return obj;
        }, {});

        usePrismicStore.setState({ customers: customersObj, customersById: customersById });

        useSearchStore.setState({
            locations: hubsObj,
            professionals: professionalsObj,
            specialties: specialtiesObj,
            treatments: treatmentsObj,
            treatmentTypeCategories: treatmentTypeCategoriesObj,
            allDocuments: allDocuments,
        });
    }, [findAppointmentData, customerConfigDocuments, i18n]);

    useEffect(() => {
        if (!appConfigPrismic) return;

        usePersistedPrismicStore.setState({
            featureFlags: appConfigPrismic.results[0]?.data.feature_flags.reduce(
                (acc, { feature_flag }) => ({
                    ...acc,
                    [feature_flag[0].text]: true,
                }),
                {}
            ),
        });
    }, [appConfigPrismic]);

    useEffect(() => {
        if (!searchToolDocument) return;

        useSearchStore.setState({ prismicData: searchToolDocument.data });
    }, [searchToolDocument]);

    useEffect(() => {
        if (!pageNotFoundDocument) return;

        usePrismicStore.setState({ pageNotFoundData: pageNotFoundDocument.data });
    }, [pageNotFoundDocument]);

    useEffect(() => {
        if (!staticPagesData) return;

        usePersistedPrismicStore.setState({
            staticPages: { ...staticPages, [i18n.language]: staticPagesData.results },
        });
    }, [staticPagesData]);

    useEffect(() => {
        if (!unsubscribePageDocument) return;

        usePrismicStore.setState({ unsubscribeData: unsubscribePageDocument.data });
    }, [unsubscribePageDocument]);

    useEffect(() => {
        if (!cookieConsentPrismic || cookieConsentPrismic.length === 0) return;

        const formatedData = cookieConsentPrismic.find((entry) => entry.type === 'cookie_management')?.data;

        const privacyId = formatedData?.privacy_policy_link?.id;
        formatedData.privacy_policy_link_ = cookieConsentPrismic.filter((doc) => doc.id === privacyId);

        usePersistedPrismicStore.setState(() => ({
            cookieConsentData: {
                ...cookieConsentData,
                [i18n.language]: formatedData,
            },
        }));
    }, [cookieConsentPrismic]);

    useEffect(() => {
        if (!welcomePageAnamnesisPrismic) return;

        usePersistedPrismicStore.setState({
            welcomePageAnamnesisData: { data: welcomePageAnamnesisPrismic, dataSet: true },
        });
    }, [welcomePageAnamnesisPrismic]);

    useEffect(() => {
        const allStates = [
            footerPrismicState,
            headerPrismicState,
            registerPrismicState,
            loginPrismicState,
            dashboardPrismicState,
            appointmentPrismicState,
            doctorPrismicState,
            locationPrismicState,
            accountPrismicState,
            findAppointmentPrismicState,
            myRecordsPrismicState,
            healthProfilePrismicState,
            healthDataPrismicState,
            consentPrismicState,
            bookAppointmentPrismicState,
            customQuestionPrismicState,
            anamnesisWelcomePrismicState,
            anamnesisThankYouPrismicState,
            treatmentContractWelcomePrismicState,
            customerConfigPrismicState,
            searchToolPrismicState,
            pageNotFoundPrismicState,
            unsubscribePagePrismicState,
            confirmAppointmentPrismicState,
            staticPagesState,
            consentFormsTreatmentState,
            cookieConsentPrismicState,
            welcomePageAnamnesisPrismicState,
            heraklesAllFormsPrismicState,
            heraklesCommunicationPrismicState,
            heraklesCoursePrismicState,
            heraklesTherapyPrismicState,
            heraklesFormsCheckinPrismicState,
            wielandFormsThankYouPrismicState,
            appConfigPrismicState,
        ];

        if (allStates.some(({ error, isLoading }) => !!error && !isLoading)) {
            useConfigStore.setState({ showPrismicFallback: true });
        }
    }, [
        footerPrismicState,
        headerPrismicState,
        registerPrismicState,
        loginPrismicState,
        dashboardPrismicState,
        appointmentPrismicState,
        doctorPrismicState,
        locationPrismicState,
        accountPrismicState,
        findAppointmentPrismicState,
        myRecordsPrismicState,
        healthProfilePrismicState,
        healthDataPrismicState,
        consentPrismicState,
        bookAppointmentPrismicState,
        customQuestionPrismicState,
        anamnesisWelcomePrismicState,
        anamnesisThankYouPrismicState,
        treatmentContractWelcomePrismicState,
        customerConfigPrismicState,
        searchToolPrismicState,
        pageNotFoundPrismicState,
        unsubscribePagePrismicState,
        confirmAppointmentPrismicState,
        staticPagesState,
        consentFormsTreatmentState,
        cookieConsentPrismicState,
        welcomePageAnamnesisPrismicState,
        heraklesAllFormsPrismicState,
        heraklesCommunicationPrismicState,
        heraklesCoursePrismicState,
        heraklesTherapyPrismicState,
        heraklesFormsCheckinPrismicState,
        wielandFormsThankYouPrismicState,
        appConfigPrismicState,
    ]);
}
