import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from login
import { useHistory } from 'react-router-dom';

import FilledButton from '@/components/FilledButton';

// import usePrismicLogin from ".'@/hooks/usePrismicLogin";
import { Context as AuthContext } from '@/context/AuthContext';
import { Context as ResetContext } from '@/context/ResetPaswdContext';

import { useStyles } from '../Login/LoginClasses';

export default function ResetNewPswd({ dataSet, mainData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordScnd, setShowPasswordScnd] = useState(false);

    const hasNumber = (myString) => {
        return /\d/.test(myString);
    };

    const hasSpecial = (myString) => {
        const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return format.test(myString);
    };
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleClickShowPasswordScnd = () => setShowPasswordScnd(!showPasswordScnd);
    const handleMouseDownPasswordScnd = () => setShowPasswordScnd(!showPasswordScnd);

    const { setuser, setusersession, setuseraccounts } = useContext(AuthContext);

    // for api
    const {
        state: { passwordValid, confirmPaswd, loading, error, email, resetVerficationCode },
        setPasswordValid,
        setLoading,
        setError,
        setConfirmPassword,
        passwordSame,
    } = useContext(ResetContext);

    useEffect(() => {
        if (!email) history.goBack();
    }, [email]);

    const [newPasswordErr, setNewPasswordErr] = useState('');

    const passwordExist = () => {
        setError(null);
        setNewPasswordErr('');
        if (passwordValid.password === '') {
            setNewPasswordErr(mainData.required_field_error_message[0].text);
            return;
        }
        if (confirmPaswd === '') {
            setError(mainData.required_field_error_message[0].text);
            return;
        }
        if (passwordValid.password !== confirmPaswd && passwordValid.password !== '' && confirmPaswd !== '') {
            setError(mainData.same_password[0].text);
            return;
        }
        const e = email.trim();
        passwordSame(history, e, resetVerficationCode, confirmPaswd, setuser, setusersession, setuseraccounts);
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    // api end

    // login setup start

    // login setup end

    return (
        <div className="">
            <Typography className={classes.heading}>
                {dataSet && mainData?.heading_3_choose_new_password[0].text}
            </Typography>
            {/*  */}

            <Typography className={classes.subheading} style={{ marginTop: 5 }}>
                {dataSet && mainData.choose_new_password_description[0].text}
            </Typography>
            <form
                className={classes.form}
                noValidate
                autoComplete="off"
                // onSubmit={handleFormSubmit}
            >
                <div className="">
                    <div>
                        <TextField
                            id="email"
                            label={dataSet && mainData.new_password_textfield[0].text}
                            value={passwordValid.password}
                            onChange={(event) => {
                                const password = event.target.value;
                                let characters = false;

                                if (password.length > 7) {
                                    characters = true;
                                }

                                const number = hasNumber(password);
                                const special = hasSpecial(password);

                                setPasswordValid({
                                    characters: characters,
                                    number: number,
                                    special: special,
                                    password: password,
                                });
                            }}
                            type={showPasswordScnd ? 'text' : 'password'}
                            variant="filled"
                            color="primary"
                            fullWidth
                            style={{ marginTop: 0 }}
                            classes={{
                                root: newPasswordErr ? classes.textFieldsError : classes.textFields,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                // <-- This is where the toggle button is added.
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPasswordScnd}
                                            onMouseDown={handleMouseDownPasswordScnd}
                                        >
                                            {showPasswordScnd ? (
                                                <VisibilityOutlinedIcon />
                                            ) : (
                                                <VisibilityOffOutlinedIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {newPasswordErr && (
                            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                                {newPasswordErr}
                            </Typography>
                        )}
                        {/* paswrd extra fields */}
                        {passwordValid.password !== '' ? (
                            <div>
                                <Typography className={classes.passwordText} style={{ marginTop: 8 }}>
                                    {dataSet
                                        ? mainData.password_restriction_heading[0].text
                                        : 'Your password must contain:'}
                                </Typography>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <div
                                            className={
                                                passwordValid.characters ? classes.greenCircle : classes.blackCircle
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={
                                                passwordValid.characters
                                                    ? classes.passwordValidText
                                                    : classes.passwordText
                                            }
                                        >
                                            {dataSet
                                                ? mainData.password_restrictions[0].password_restriction[0].text
                                                : 'At least 8 characters'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <div
                                            className={passwordValid.number ? classes.greenCircle : classes.blackCircle}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={
                                                passwordValid.number ? classes.passwordValidText : classes.passwordText
                                            }
                                        >
                                            {dataSet
                                                ? mainData.password_restrictions[1].password_restriction[0].text
                                                : 'A number'}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center">
                                    <Grid item>
                                        <div
                                            className={
                                                passwordValid.special ? classes.greenCircle : classes.blackCircle
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={
                                                passwordValid.special ? classes.passwordValidText : classes.passwordText
                                            }
                                        >
                                            {dataSet
                                                ? mainData.password_restrictions[2].password_restriction[0].text
                                                : 'A special character'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : null}
                        {/* paswrd extra fields end */}
                    </div>

                    <div>
                        <TextField
                            id="password"
                            label={dataSet && mainData.confirm_new_password_textfield[0].text}
                            variant="filled"
                            color="primary"
                            value={confirmPaswd}
                            onChange={(event) => {
                                const password = event.target.value;
                                setConfirmPassword(password);
                            }}
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            style={{ marginTop: 15 }}
                            classes={{
                                root: error ? classes.textFieldsError : classes.textFields,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                // <-- This is where the toggle button is added.
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {error && (
                            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                                {error === 'Invalid verification code provided, please try again.'
                                    ? dataSet && mainData?.invalid_verification_code[0].text
                                    : error}
                            </Typography>
                        )}
                    </div>
                </div>
            </form>

            <div className="" style={{ marginTop: 20 }}>
                <FilledButton
                    text={dataSet && mainData.submit_cta[0].text}
                    onPress={passwordExist}
                    fullWidth
                    loading={loading}
                />
            </div>
        </div>
    );
}
