import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useContext, useEffect } from 'react';

import dustbinIcon from '@/assets/images/dustbinIcon.png';
import pencilIcon from '@/assets/images/pencilIcon.png';

import DateOnlyYearField from '@/components/Common/Date/DateOnlyYear/DateOnlyYearField';
import FilledButton from '@/components/FilledButton';
import UnderlinedButton from '@/components/UnderlinedButton';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

import UnderLinedAddButton from '../UnderLinedAddButton/UnderLinedAddButton';
import { useStyles } from './ExistingConditionStyles';

export default function ExistingConditionImagingPerfomed({
    mainData,
    addDataImaging,
    deleteEditImaging,
    deleteGrayImaging,
    editOptionImaging,
}) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    // context for const variables
    const {
        editState,
        setTestPerfomed,
        setTestPerfomedDate,
        setTestPerfomedArr,
        setTestPerfomedArrEasy,
        testPerfomed,
        testPerfomedDate,
        testPerfomedArr,
        setTestEdit,
        testEdit,
        errorDate4,
        setErrorDate4,
    } = useContext(ContextProviderTwo);

    const {
        state: { is_edit_imaging },
        setIsEditImaging,
    } = useContext(HealthProfileContextMain);

    useEffect(() => {
        if (testPerfomedArr.length === 0 && is_edit_imaging) {
            setIsEditImaging(false);
            setTestPerfomed('None');
        }
    }, []);

    return (
        <div className={classes.contentContainerBody}>
            <div className={classes.contentContainerBody} style={{ marginTop: testPerfomed && 40 }}>
                <div className={classes.fixedQuestion}>
                    <Typography className={classes.mainHeading}>{mainData.imaging_question_title[0].text}</Typography>
                </div>
                {/* Is existing condition */}
                <div className="">
                    {!is_edit_imaging && (
                        <Grid
                            style={{
                                marginTop: 20,
                            }}
                            container
                        >
                            {mainData &&
                                mainData.imaging_answers_group.map((parts) => (
                                    <Grid lg="auto" sm="auto" xs={12}>
                                        <Button
                                            onClick={() => {
                                                console.debug(parts.imaging_answer_selector);
                                                setTestPerfomed(parts.imaging_answer_selector);
                                                if (parts.imaging_answer_selector === 'None') {
                                                    setTestPerfomedArr([]);
                                                    setTestPerfomedArrEasy([]);
                                                    setTestPerfomedDate('');
                                                    setErrorDate4(false);
                                                }
                                            }}
                                            disableRipple
                                            className={
                                                testPerfomed === parts.imaging_answer_selector
                                                    ? clsx(classes.selectItemCard, classes.selectedCard)
                                                    : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                            }
                                            style={{
                                                marginBottom: 20,
                                                width: matches && '100%',
                                                display: matches ? null : 'flex',
                                                marginRight: 15,
                                            }}
                                        >
                                            <div className="">
                                                <Typography className={classes.BtncontentText}>
                                                    {parts.imagine_answer[0].text}
                                                </Typography>
                                            </div>
                                        </Button>
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                </div>

                {/* gray protion */}
                <div style={{ marginBottom: testPerfomedArr.length > 0 ? 30 : 0 }}>
                    {testPerfomedArr.length > 0 && (
                        <div className="">
                            <div className={classes.graySection}>
                                <Typography className={classes.mainHeading}>
                                    {mainData.imaging_summary[0].text}
                                </Typography>
                                {testPerfomedArr.map((all, index) => (
                                    <div className={classes.flexClass}>
                                        <Typography className={classes.subHeading}>
                                            {all.a}. {all.c}
                                        </Typography>
                                        <div className={classes.flexImg}>
                                            <img
                                                src={pencilIcon}
                                                alt=""
                                                className={classes.icons}
                                                onClick={() => {
                                                    if (is_edit_imaging) {
                                                        setIsEditImaging(false);
                                                    }
                                                    !editState && editOptionImaging(index, all);
                                                }}
                                            />
                                            <img
                                                src={dustbinIcon}
                                                alt=""
                                                className={classes.pointer}
                                                onClick={() => {
                                                    if (is_edit_imaging) {
                                                        setIsEditImaging(false);
                                                    }
                                                    deleteGrayImaging(index, all);
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {/* end */}
                </div>
                {/* gray section ends */}

                {/* pastCondition SUB questions */}
                {testPerfomed.length !== 0 && testPerfomed.toLocaleLowerCase() !== 'none' && (
                    <div className="">
                        {testPerfomedArr.length > 0 && <div className={classes.line} />}
                        <Typography className={classes.mainHeading}>
                            {mainData.imaging_when_question[0].text}
                        </Typography>
                        <Grid container spacing={1} style={{ marginTop: 20 }}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <DateOnlyYearField
                                    type="monthAndYear"
                                    mainHeading={mainData.enter_date[0].text}
                                    setDOB={setTestPerfomedDate}
                                    dob={testPerfomedDate}
                                    setError={setErrorDate4}
                                    error={errorDate4}
                                    errorMessage={mainData.date_error[0].text}
                                    placeHolderMonth={mainData.dob_placeholder_month[0].text}
                                    placeHolderYear={mainData.dob_placeholder_year[0].text}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}

                {/* for edit in state */}
                {(is_edit_imaging
                    ? testPerfomedArr.length > 0
                    : testPerfomed.length !== 0 && testPerfomed.toLocaleLowerCase() !== 'none') && (
                    <div className={classes.maginB}>
                        {!testEdit && (
                            <UnderLinedAddButton
                                onClick={() => {
                                    if (errorDate4) {
                                        return;
                                    }
                                    if (is_edit_imaging) {
                                        setIsEditImaging(false);
                                        return;
                                    }
                                    addDataImaging({
                                        openState: false,
                                        openState2: false,
                                    });
                                }}
                                underLineText={mainData.add_another_imaging_cta[0].text}
                            />
                        )}
                        {testEdit && (
                            <div className="">
                                <div
                                    className=""
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                    }}
                                >
                                    <FilledButton
                                        text={mainData?.continue_cta[0].text}
                                        disabled={errorDate4}
                                        onPress={() => {
                                            setTestEdit(false);
                                            addDataImaging({
                                                openState: false,
                                                openState2: false,
                                            });
                                        }}
                                    />
                                    <UnderlinedButton
                                        text={mainData?.cancel[0].text}
                                        onPress={() => {
                                            setTestEdit(false);
                                            deleteEditImaging();
                                        }}
                                        noMarginTop
                                        marginLeft={20}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
