import { CircularProgress } from '@material-ui/core';

import StepMenuStyles from './StepMenuStyles';

const CircularProgressBar = ({ value }) => {
    const classes = StepMenuStyles();
    return (
        <div className={classes.root}>
            <CircularProgress variant="determinate" className={classes.bottom} size={24} thickness={5} value={100} />
            <CircularProgress variant="determinate" className={classes.top} size={24} thickness={5} value={value} />
        </div>
    );
};

export default CircularProgressBar;
