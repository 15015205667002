import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import dustbinIcon from '@/assets/images/dustbinIcon.png';

import { useScrollToQuestion } from '@/hooks/useScrollToQuestion.js';

import UnderLinedAddButton from '@/pages/HealthProfileAnamnesis/UnderLinedAddButton/UnderLinedAddButton.jsx';

import { QuestionComponent } from '@/utils/constants.js';

import { useCustomAnamnesisStore } from '@/store.js';

import { useCustomAnamnesisStyles } from '../customAnamnesisStyles.js';

export const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
        marginBottom: '1rem',
    },
    graySection: {
        marginTop: 20,
        padding: 20,
        backgroundColor: theme.palette.common.lightBrown,
        borderRadius: 8,
    },
    questionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    flexClass: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'baseline',
        },
    },
    subHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        color: theme.palette.common.black,
    },
    flexImg: {
        display: 'flex',
    },
    pointer: {
        cursor: 'pointer',
    },
    placeholderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 0',
    },
}));

const ListQuestion = ({ question, answer, setAnswer }) => {
    const classes = useStyles();
    const customAnamnesisStyles = useCustomAnamnesisStyles();
    const [subQuestionAnswers, setSubQuestionAnswers] = useState({ subQuestions: {} });
    const subQuestions = useCustomAnamnesisStore((state) => state.subQuestions);
    const highlightedQuestions = useCustomAnamnesisStore((state) => state.highlightedQuestions);
    const questionRef = useScrollToQuestion(question);

    useEffect(() => {
        if (!answer) setAnswer([]);
    }, []);

    const addAnswer = () => {
        // TODO check if required
        if (
            Object.keys(subQuestionAnswers.subQuestions).length < question.list_subquestions.length ||
            Object.values(subQuestionAnswers.subQuestions).some((entry) => !entry)
        ) {
            return;
        }

        setAnswer([...(answer ?? []), subQuestionAnswers.subQuestions]);
        setSubQuestionAnswers({ subQuestions: {} });
    };

    const removeAnswer = (item, index) => {
        const newAnswer = [...answer];

        newAnswer.splice(index, 1);
        setAnswer(newAnswer);
    };

    return (
        <div
            ref={questionRef}
            className={clsx(
                customAnamnesisStyles.defaultQuestion,
                highlightedQuestions[question.question_key[0].text] && customAnamnesisStyles.highlight
            )}
        >
            <Typography style={{ marginBottom: '1rem' }} className={classes.title}>
                {question.title[0].text}
            </Typography>
            <div className={classes.questionsContainer}>
                {question.list_subquestions.map(({ list_subquestion }) => {
                    if (!subQuestions[list_subquestion.id]) return null;
                    const Question = QuestionComponent[subQuestions[list_subquestion.id].question_type];
                    return (
                        <Question
                            question={subQuestions[list_subquestion.id]}
                            answer={subQuestionAnswers}
                            setAnswer={setSubQuestionAnswers}
                            isSubQuestion
                        />
                    );
                })}
                <UnderLinedAddButton underLineText="Hinzufügen" onClick={addAnswer} />
            </div>
            <div className={classes.graySection}>
                <Typography className={classes.title}>{question.label[0]?.text}</Typography>
                {answer?.map((item, index) => (
                    <div className={classes.flexClass}>
                        <Typography className={classes.subHeading}>{Object.values(item).join(', ')}</Typography>
                        <div className={classes.flexImg}>
                            {/* <img */}
                            {/*    src={pencilIcon} */}
                            {/*    alt="" */}
                            {/*    className={classes.icons} */}
                            {/*    onClick={() => { */}
                            {/*        if (is_edit_test) { */}
                            {/*            setIsEditImaging(false); */}
                            {/*        } */}
                            {/*        !editState && editOptionTest(index, all); */}
                            {/*    }} */}
                            {/* /> */}
                            <img
                                src={dustbinIcon}
                                alt=""
                                className={classes.pointer}
                                onClick={() => removeAnswer(item, index)}
                            />
                        </div>
                    </div>
                ))}
                {(!answer || answer?.length === 0) && (
                    <div className={classes.placeholderContainer}>{question.placeholder[0].text}</div>
                )}
            </div>
        </div>
    );
};
export default ListQuestion;
