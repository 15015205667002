import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { memo, useContext } from 'react';

import { Context as ConsentManagerContext } from '@/context/ConsentManagerContext';

import tableStyles from './tableStyles';

const TableRow = ({ documentType, sharedWith = 'Dr. Alexander Lübeck', date = '08 Aug 22' }) => {
    const classes = tableStyles();
    const {
        state: { tableType },
    } = useContext(ConsentManagerContext);

    return (
        <Grid container className={classes.row}>
            <Grid item xs={4}>
                <Typography className={clsx(classes.rowItem, classes.fwBold)}>
                    {tableType ? sharedWith : documentType}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography className={classes.rowItem}>{tableType ? documentType : sharedWith}</Typography>
            </Grid>
            <Grid item xs={4} className={classes.lastColumn}>
                <Typography className={classes.rowItem}>{date}</Typography>
            </Grid>
        </Grid>
    );
};

export default memo(TableRow);
