import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useContext } from 'react';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './LifeStyleStyles';

export default function LifeStyleSmoke({ mainData, isFromHealthData }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { exercise, setExercise } = useContext(ContextProviderTwo);
    console.debug('everyday_life_response_api_value', exercise);
    return (
        <div className={classes.contentContainerBody}>
            {/* main Part */}
            <div className={classes.fixedQuestion}>
                <div
                    className={classes.fixedQuestion}
                    style={{
                        marginTop: isFromHealthData ? exercise && (matches ? 20 : 30) : 30,
                    }}
                >
                    <Typography className={classes.mainHeading}>{mainData.every_day_life_question[0].text}</Typography>

                    <Grid
                        style={{
                            marginBlock: 20,
                        }}
                        container
                    >
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                            {mainData &&
                                mainData.every_day_life_response.map((parts) => (
                                    <Button
                                        onClick={() => {
                                            console.debug(parts.everyday_life_response_api_value);
                                            setExercise(parts.everyday_life_response_api_value);
                                        }}
                                        disableRipple
                                        className={
                                            exercise === parts.everyday_life_response_api_value
                                                ? clsx(classes.selectItemCard, classes.selectedCard)
                                                : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                        }
                                        style={{
                                            paddingInline: matches && 10,
                                            wordWrap: 'break-word',
                                            flex: matches && '1 0 40%',
                                        }}
                                    >
                                        <div className="">
                                            <Typography className={classes.BtncontentText}>
                                                {parts.everyday_life_response[0].text}
                                            </Typography>
                                        </div>
                                    </Button>
                                ))}
                        </div>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
