import Grid from '@material-ui/core/Grid';
import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import DialogBox from '@/components/Common/DialogBox';

import { Context as AuthContext } from '@/context/AuthContext';

import { processAPIWithHeaders, useQuery } from '@/utils/helpers.js';

import { useConfigStore } from '@/store.js';

import { useStyles } from './Login/LoginClasses';
import LoginMain from './Login/LoginMain';

export default function Login() {
    const history = useHistory();
    const classes = useStyles();

    const pathname = useLocation();
    const query = useQuery();
    const prevPath = query.get('redirectTo');
    console.debug('🚀 ~ file: Router.jsx ~ line 208 ~ PrivateRoute ~ prevLocation query', query.get('redirectTo'));
    console.debug('🚀 ~ file: Router.jsx ~ line 210 ~ PrivateRoute ~ prevLocation', pathname);

    const [checked, setchecked] = useState(false);
    const [email, setEmail] = useState('');
    const { setuser, setusersession, setuseraccounts } = useContext(AuthContext);
    const [password, setPassword] = useState('');

    const [code, setCode] = useState('');
    const [verifyAccount, setVerifyAccount] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [forgetPswd, setForgetPswd] = useState(false);
    const [mainData, setMainData] = useState({});

    const [open, setOpen] = useState(false);
    const [error, setError] = useState({
        title: 'Invalid Code',
        text: 'Please enter correct code',
    });

    async function signIn(setup = false) {
        console.debug('signing in');
        try {
            setLocalLoading(true);
            const user = await Auth.signIn(email, password);
            console.debug(user);

            const { username } = user;
            const token = user.signInUserSession.idToken.jwtToken;
            const userData = user.attributes;
            const { currentCustomer } = useConfigStore.getState();
            const customer_id = currentCustomer?.customer_id?.[0].text;

            if (setup) {
                const response = await processAPIWithHeaders('secure/users', 'POST', {
                    body: {
                        id: user.username,
                        email: userData.email,
                        name: {
                            first: userData['custom:firstName'],
                            last: userData['custom:lastName'],
                        },
                        gender: userData['custom:gender'],
                        date_of_birth: userData.dob,
                        title: userData.title,
                        phone: {
                            number: '0151521111',
                        },
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.signInUserSession.idToken.jwtToken,
                        customer_id: customer_id,
                    },
                });
                console.debug(response);
            }

            const response = await processAPIWithHeaders(`secure/users/${username}`, 'GET', {
                headers: {
                    // "Content-Type": "application/json",
                    Authorization: token,
                },
            });
            console.debug(response);

            const userAccounts = await processAPIWithHeaders(`secure/user/accounts/${username}`, 'GET', {
                headers: {
                    // "Content-Type": "application/json",
                    Authorization: token,
                },
            });
            console.debug(userAccounts);
            setuser(response);
            setuseraccounts(userAccounts);
            setusersession(user.signInUserSession);
            setLocalLoading(false);
            if (prevPath !== '') {
                history.replace(prevPath);
            } else {
                history.replace('/');
            }
        } catch (error) {
            setLocalLoading(false);
            if (error.code === 'UserNotConfirmedException') {
                await Auth.resendSignUp(email);
                setVerifyAccount(true);
            } else {
                setError({
                    title: mainData?.error_signing_in[0].text,
                    text: mainData?.user_not_found[0].text,
                });
                setOpen(true);
            }

            console.debug('error signing in', error, mainData);
        }
    }

    return (
        <>
            <div className={classes.contentContainer}>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item md={8} sm={10} xs={12}>
                        <LoginMain
                            verifyAccount={verifyAccount}
                            // verifyCode={verifyCode}
                            signIn={signIn}
                            setError={setError}
                            setOpen={setOpen}
                            setCode={setCode}
                            code={code}
                            setEmail={setEmail}
                            email={email}
                            setPassword={setPassword}
                            password={password}
                            setchecked={setchecked}
                            checked={checked}
                            setMainData={setMainData}
                            localLoading={localLoading}
                            setForgetPswd={setForgetPswd}
                            forgetPswd={forgetPswd}
                            setLocalLoading={setLocalLoading}
                        />
                    </Grid>
                </Grid>
            </div>
            <DialogBox
                open={open}
                onClose={() => {
                    console.debug('closing dialog');
                    setOpen(false);
                }}
                title={error.title}
                text={error.text}
            />
        </>
    );
}
