import { TextField, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import { useContext } from 'react';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './YourBodyStyles';

export default function YourBodyWeight({ mainData, user, isFromHealthData }) {
    const classes = useStyles();
    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { value__, setValue__ } = useContext(ContextProviderTwo);

    const handleChange = (event) => {
        setValue__(event.target.value);
    };

    return (
        <div className={classes.contentContainerBody}>
            <div className={classes.rangeQuestion} style={{ marginTop: matches ? 20 : 40 }}>
                <Typography className={classes.mainHeading}>{mainData.weight_question_title[0].text}</Typography>
                <div className={classes.questionContainer}>
                    <div className={classes.answersContainer}>
                        <TextField
                            id="yourBodyChangedWeightAmount"
                            label={mainData ? mainData.enter_weight_here[0].text : 'Enter weight here (cm)'}
                            variant="filled"
                            autoComplete="off"
                            color="primary"
                            value={String(value__).replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '')}
                            onChange={handleChange}
                            classes={{
                                root: classes.textFields,
                            }}
                            style={{ width: '100%' }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
