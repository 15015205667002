import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import bin from '@/assets/Icons/bin.svg';

import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

import { useUserAllAppointments } from '@/hooks/useUserAllAppointments';

import { dayjs } from '@/utils/dayjsSetup.js';

import { usePersistedPrismicStore, useSearchStore, useUploadDocumentModalStore } from '@/store.js';

import TypeOfDoc from './TypeOfDoc';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    fileNameParent: {
        marginTop: 10,
        alignItems: 'center',
    },
    delButtonParent: {
        display: 'flex',
        justifyContent: 'end',
    },
    icon: {
        height: '22px',
        width: '22px',
    },
    heading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '18px',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '2rem',
        marginBottom: '1rem',
        width: '100%',
    },
    dropdown: {
        width: '100%',
    },
    selectLabel: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.72rem',
        },
    },
    appointmentOption: {
        whiteSpace: 'break-spaces',
    },
}));

const FileUploaded = ({ docType, setDocType, removeFile }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const { data: userAppointments } = useUserAllAppointments();
    const professionals = useSearchStore((state) => state.professionals);
    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);
    const appointmentId = useUploadDocumentModalStore((state) => state.appointmentId);
    const isAppointmentSelectDisabled = useUploadDocumentModalStore((state) => state.isAppointmentSelectDisabled);

    const combinedAppointments = useMemo(() => {
        const activeAppointments = userAppointments?.active ?? [];
        const inactiveAppointments = userAppointments?.inactive ?? [];
        return [...activeAppointments, ...inactiveAppointments];
    }, [userAppointments]);

    const {
        state: { acceptedFileName },
        setAcceptedFileName,
    } = useContext(HealthProfileContextMain);

    const onRemoveClick = () => {
        removeFile();
    };

    const onAppointmentSelect = (event) => {
        useUploadDocumentModalStore.setState({ appointmentId: event.target.value });
    };

    return (
        <Grid container>
            <Grid container className={classes.fileNameParent}>
                <Grid item md={11} sm={11} xs={10}>
                    <TextField
                        id="fileName"
                        contentEditable={false}
                        label={content?.document_name_label[0]?.text}
                        variant="filled"
                        color="primary"
                        value={acceptedFileName}
                        onChange={(e) => setAcceptedFileName(e.target.value)}
                        fullWidth
                        style={{ height: '3.75rem' }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                            },
                        }}
                    />
                </Grid>
                <Grid item md={1} sm={1} xs={2} className={classes.delButtonParent}>
                    <IconButton onClick={onRemoveClick}>
                        <img src={bin} className={classes.icon} />
                    </IconButton>
                </Grid>
            </Grid>
            <TypeOfDoc setDocType={setDocType} docType={docType} />
            <FormControl className={classes.dropdown}>
                <InputLabel classes={{ root: classes.selectLabel }} id="appointment-select-label">
                    {content?.select_appointment_label[0]?.text}
                </InputLabel>
                <Select
                    labelId="appointment-select-label"
                    id="appointment-select"
                    value={appointmentId}
                    onChange={onAppointmentSelect}
                    disabled={isAppointmentSelectDisabled}
                >
                    {combinedAppointments.map((item, index) => (
                        <MenuItem
                            key={`option_${item.id}`}
                            value={item.id}
                            classes={{ root: classes.appointmentOption }}
                            divider={index < combinedAppointments.length - 1}
                        >
                            {`${item.name} - ${professionals[item.professional.key]?.display_name[0].text} - ${dayjs(
                                item.day
                            ).format('DD.MM.YYYY')}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
};

export default memo(FileUploaded);
