import { Auth } from 'aws-amplify';
import { useContext } from 'react';

import { Context as AuthContext } from '@/context/AuthContext';

import { downloadDocument } from '@/utils/helpers.js';

import { useDownloadDocumentModalStore } from '@/store.js';

export const useUserDocumentDownload = () => {
    const { fetchUserDocumentById } = useContext(AuthContext);
    const openModal = useDownloadDocumentModalStore((state) => state.openModal);

    const handleDocumentDownload = async (documentId) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const downloadURL = await fetchUserDocumentById(documentId, user.signInUserSession);
            downloadDocument(downloadURL.download_url);
        } catch (error) {
            if (error.response?.status === 403) {
                openModal(documentId);
            }
            console.debug('Error: ', error);
        }
    };

    return { handleDocumentDownload: handleDocumentDownload };
};
