import { makeStyles } from '@material-ui/core/styles';

export const overviewStyles = makeStyles((theme) => ({
    // margin top
    mt10: { marginTop: 10 },
    mt15: { marginTop: 15 },
    mt20: { marginTop: 20 },
    mt25: { marginTop: 25 },
    mt30: { marginTop: 30 },

    // margin bottom
    mb10: { marginBottom: 10 },
    mb15: { marginBottom: 15 },
    mb20: { marginBottom: 20 },
    mb25: { marginBottom: 25 },
    mb30: { marginBottom: 30 },

    // margin block Y
    my10: { marginBlock: 10 },
    my20: { marginBlock: 20 },
    my30: { marginBlock: 30 },

    // margin right
    mr10: { marginRight: 10 },
    mr15: { marginRight: 15 },
    mr20: { marginRight: 20 },
    mr25: { marginRight: 25 },
    mr30: { marginRight: 30 },

    // padding top
    pt10: { paddingTop: 10 },
    pt15: { paddingTop: 15 },
    pt20: { paddingTop: 20 },
    pt25: { paddingTop: 25 },
    pt30: { paddingTop: 30 },

    // padding block Y
    pb10: { paddingBlock: 10 },
    pb15: { paddingBlock: 15 },
    pb20: { paddingBlock: 20 },
    pb25: { paddingBlock: 25 },
    pb30: { paddingBlock: 30 },

    // padding right
    pr10: { paddingRight: 10 },
    pr15: { paddingRight: 15 },
    pr20: { paddingRight: 20 },
    pr25: { paddingRight: 25 },
    pr30: { paddingRight: 30 },

    mainHeading: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: 21,
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('sm')]: {
            marginBottom: '.6rem',
        },
    },
    heading: {
        ...theme.typography.HL1,
    },
    paragraph: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '1rem',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            wordWrap: 'break-word',
            hyphens: 'auto',
        },
    },
    medicalIcon: { height: 50, width: 'auto' },
    emptyBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        borderRadius: 5,
    },
    emptyText: {
        fontFamily: 'MessinaSans-Regular',
        color: theme.palette.common.brown,
        fontSize: 18,
        marginTop: '1rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    icon: { height: 80, width: 80, objectFit: 'contain' },
    policyLink: { display: 'inline-block' },
    processDataImg: {
        width: '70%',
    },
    twoColContainer: {
        alignItems: 'center !important',
    },
    rightCol: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: { width: '80%', objectFit: 'contain' },
    wrapText: { wordWrap: 'break-word' },
}));
