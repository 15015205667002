import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import SummaryPrivacyPolicyModal from '@/pages/HealthProfileAnamnesis/SummaryComponents/SummaryPrivacyPolicyModal.jsx';

import { getColorWithOpacity } from '@/utils/colorHelpers.js';

import { useCustomAnamnesisStore, usePersistedPrismicStore } from '@/store.js';

import { AntSwitch } from '../Common/AntSwitchCommon.jsx';

const useStyles = makeStyles((theme) => ({
    container: {
        borderTop: '1px solid #ccc',
        paddingTop: '1rem',
    },
    mainHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 21,
    },
    subHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        fontWeight: '500',
        color: theme.palette.common.darkGray,
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        display: 'inline-block',
        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3, cursor: 'pointer' },
    },
    highlight: {
        background: getColorWithOpacity(theme.palette.common.secondary, 0.15),
        padding: 10,
        marginBlock: 10,
    },
}));

const PrivacyPolicy = ({
    openPrivacyPolicyPopup,
    setOpenPrivacyPolicyPopup,
    isPrivacyPolicyConfirmed,
    setIsPrivacyPolicyConfirmed,
}) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const { [i18n.language]: anamnesisData } = usePersistedPrismicStore((state) => state.anamnesisData);
    const professional = useCustomAnamnesisStore((state) => state.professional);

    return (
        <div className={classes.container}>
            <Typography className={classes.mainHeading}>
                {anamnesisData.mainDataSummary?.consent_to_privacy_policy_title[0].text}
            </Typography>
            <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                {anamnesisData.mainDataSummary?.subtitle_privacy_policy[0].text}
            </Typography>
            <Grid container direction="row" style={{ marginTop: 20 }}>
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                    <AntSwitch
                        checked={isPrivacyPolicyConfirmed}
                        onChange={() => setIsPrivacyPolicyConfirmed(!isPrivacyPolicyConfirmed)}
                    />
                </Grid>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <div className={classes.subHeading}>
                        <span> {anamnesisData.mainDataSummary?.privacy_policy_text[0].text}</span>
                        <Typography
                            className={classes.contentTextLink}
                            onClick={() => setOpenPrivacyPolicyPopup(!openPrivacyPolicyPopup)}
                        >
                            {anamnesisData.mainDataSummary?.privacy_policy_link_name[0].text}
                        </Typography>
                        {anamnesisData.mainDataSummary?.['2nd_consent_subtitle'][0].text}
                    </div>
                </Grid>
                {professional && (
                    <SummaryPrivacyPolicyModal
                        open={openPrivacyPolicyPopup}
                        onClose={() => setOpenPrivacyPolicyPopup(false)}
                        link={professional.practitioner_privacy_policy.url}
                        mainData={anamnesisData.mainDataSummary}
                        subHeading={professional.display_name[0].text ?? ''}
                    />
                )}
            </Grid>
        </div>
    );
};

export default PrivacyPolicy;
