import AutoCompleteDropDown from './AutoCompleteDropDown/AutoCompleteDropDown';
import CountryDropDown from './CountryDropDown/CountryDropDown';
import DefaultDropDown from './DefaultDropDown/DefaultDropDown';
import TelephoneDropDown from './TelephoneDropDown/TelephoneDropDown';

export const DropDown = ({
    type = '',
    mainDataHeading,
    mainDataArray,
    setData,
    data,
    compulsory,
    validateForm,
    widthID,
    disabled,
    mainDataPlaceHolder,
    toMatch,
    value = '',
    relation,
    fullWidth,
    error,
    errorMessage,
    errorText = '',
    setError,
    parentStyles = {},
    noMarginTop,
}) => {
    const properties = {
        mainDataHeading: mainDataHeading,
        mainDataArray: mainDataArray,
        setData: setData,
        data: data,
        compulsory: compulsory,
        validateForm: validateForm,
        widthID: widthID,
        disabled: disabled,
        mainDataPlaceHolder: mainDataPlaceHolder,
        toMatch: toMatch,
        value: value,
        relation: relation,
        fullWidth: fullWidth,
        error: error,
        errorMessage: errorMessage,
        errorText: errorText,
        setError: setError,
        noMarginTop: noMarginTop,
    };

    const DropDownComponentMap = {
        default: DefaultDropDown, // lazy(() => import('./DefaultDropDown/DefaultDropDown')), // DefaultDropDown,
        autoComplete: AutoCompleteDropDown, // lazy(() => import('./AutoCompleteDropDown/AutoCompleteDropDown')), // AutoCompleteDropDown
        telephone: TelephoneDropDown, // lazy(() => import('./TelephoneDropDown/TelephoneDropDown')), // TelephoneDropDown
        country: CountryDropDown, // lazy(() => import('./CountryDropDown/CountryDropDown')), // CountryDropDown
    };

    const renderDropDown = () => {
        const Component = DropDownComponentMap[type];
        return <Component {...properties} />;
    };

    return <div style={parentStyles}>{renderDropDown()}</div>;
};
