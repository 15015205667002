import { makeStyles } from '@material-ui/core/styles';

import SearchTool from '@/components/Search/SearchTool';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    page: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'MessinaSans-Regular',
        flexDirection: 'column',
        maxWidth: theme.layout.contentWidth,
        padding: '0 50px',
        width: '100%',
        minHeight: '70vh',

        [theme.breakpoints.down('xs')]: {
            padding: '0 20px',
        },
    },
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh',
        fontFamily: 'MessinaSans-Regular',
    },
}));

const SearchPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.page}>
                <SearchTool />
            </div>
        </div>
    );
};

export default SearchPage;
