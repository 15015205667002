import { CircularProgress, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CountryFlag from '@/components/Common/CountryFlag';
import LocationPreviewCard from '@/components/LocationPreviewCard';
import OutlinedButton from '@/components/OutlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';
import { Context as BookAppointmentContext } from '@/context/BookAppointmentContext';
import { Context as DoctorsContext } from '@/context/DoctorsContext';
import { ContextProvider } from '@/context/ProjectContext';

import { BookingStep } from '@/utils/constants.js';
import { formatDate, getConsentTreatments, processAPIWithHeadersForPractitioners } from '@/utils/helpers.js';

import { useBookingStore, useConfigStore, usePersistedPrismicStore, useSearchStore } from '@/store.js';

import PatientTypeSelection from '../../components/PatientTypeSelection';
import BookingSummary from './BookingSummary';
import Calendar from './Calendar';
import ContactDetails from './ContactDetails';
import HealthInsurance from './HealthInsurance';
import LoginAppointment from './LoginAppointment';
import Verification from './Verification';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        backgroundColor: 'white',
        height: 330,
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
    },
    calendarCardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
    },
    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingBottom: 20,

        [theme.breakpoints.down('xs')]: {},
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    doctorImg: {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        textAlign: 'center',

        color: theme.palette.common.darkGrey,
    },
    textFields: {
        height: '3.75em',
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    flagsContainer: {
        display: 'flex',
        gap: 15,
        justifyContent: 'center',
        marginBlock: 10,
    },
    progressBar: {
        color: theme.palette.common.yellow,
    },
}));

const AnotherSectionImg = ({
    queryStage,
    setFormStage,
    formStage,
    doctor,
    appointmentSearch,
    setAppointmentSearch,
    selectedDoctor,
    setAppointmentData,
    mainData,
    dataSet,
    verifyCode,
    signUp,
    setGender,
    setTitle,
    setFirstName,
    setMiddleName,
    setLastName,
    setEmail,
    setDOB,
    setConfirmPassword,
    email,
    dob,
    lastName,
    middleName,
    confirmPassword,
    firstName,
    gender,
    title,
    useriner,
    handleFormSubmit,
    code,
    setCode,
    mainBenefits,
    dataSetReg,
    phone,
    setPhone,
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const selectedTreatments = useSearchStore((state) => state.selectedTreatments);
    const treatments = useSearchStore((state) => state.treatments);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const allDocuments = useSearchStore((state) => state.allDocuments);
    const selectedHealthInsurance = useSearchStore((state) => state.selectedHealthInsurance);
    const selectedSpecialty = useSearchStore((state) => state.selectedSpecialty);

    const { location_key, appointment_type_keys, insurance_type_key, professional_type_key, journey_type } =
        appointmentSearch;

    const {
        state: { user },
    } = useContext(AuthContext);

    const [isReturning, setIsReturning] = useState(false);
    const history = useHistory();
    const {
        state: { currentDoctor, appointmentSelection },
        setAppointmentSelection,
    } = useContext(BookAppointmentContext);
    console.debug('🚀 ~ file: AnotherSectionImg.jsx ~ line 159 ~ currentDoctorfromcontext', appointmentSelection);

    const {
        state: { mainDataMyDoctorsID },
    } = useContext(DoctorsContext);
    console.debug('🚀 ~ file: AnotherSectionImg.jsx:171 ~ mainDataMyDoctorsID:', mainDataMyDoctorsID);

    const {
        setMyAppointmentsData,
        setMyAppointmentsDataStatus,
        setIsDoctorsPage,
        setDoctorConfirmation,
        activeResp,
        onceRunState,
    } = useContext(ContextProvider);

    const {
        [i18n.language]: { professionalProfiles },
    } = usePersistedPrismicStore((state) => state.findAppointmentData);

    const [calendarData, setCalendarData] = useState(null);
    const [secondaryLocations, setSecondaryLocations] = useState([]);
    const [secondaryLocationSet, setSecondaryLocationSet] = useState(false);
    const [selectedSecondaryLocation, setSelectedSecondaryLocation] = useState();
    const [professions, setProfessions] = useState([]);
    const [newAppointmentData, setNewAppointmentData] = useState(null);

    useEffect(() => {
        if (!doctor) return;
        const newProfessions = [doctor.specialityType];
        doctor.secondary_location_mappings
            .filter((entry) => entry.professional_type)
            .forEach((entry) => {
                if (
                    !newProfessions.find(
                        (profession) => profession.name[0].text === entry.professional_type.name[0].text
                    )
                ) {
                    newProfessions.push(entry.professional_type);
                }
            });
        setProfessions(newProfessions);
    }, [doctor]);

    const setDetails = (data) => {
        const newData = { ...data, is_returning: isReturning };
        setAppointmentSelection(newData);
    };

    useEffect(() => {
        if (!doctor) return;
        setDoctorConfirmation(doctor);
        const filteredSecondaryLocations = doctor.secondary_location_mappings.filter(
            (entry) => entry.professional_type
        );

        if (filteredSecondaryLocations.length > 0) {
            const locations = doctor.secondary_location_mappings.find(
                (entry) => entry.professional_type.key[0].text === professional_type_key
            ).secondary_locations;

            setSecondaryLocations(locations);

            // preselect first secondary location
            setSelectedSecondaryLocation(locations[0]);
        }
        setSecondaryLocationSet(true);
        console.debug('currentDoctor[0]?.profile_picture.url', doctor);
    }, [doctor]);

    const updateAppointmentData = () => {
        setAppointmentData(newAppointmentData);
        if (
            appointmentSearch.location_title === selectedSecondaryLocation?.title[0].text &&
            appointmentSearch.sub_loc_key === selectedSecondaryLocation?.key[0].text
        ) {
            return;
        }

        const externalLocation =
            selectedSecondaryLocation &&
            selectedSecondaryLocation?.external &&
            appointmentSearch.location_title.toLowerCase() !== 'online';

        setAppointmentSearch({
            ...appointmentSearch,
            location_title: externalLocation
                ? selectedSecondaryLocation.title[0].text
                : doctor.locationData.eterno_map_title[0].text,
            sub_loc_key: externalLocation ? selectedSecondaryLocation.key[0].text : null,
            externalLocation: externalLocation,
        });
    };

    // see profile of doctor
    const forDoctorDetails = (doctor) => {
        const uniqueKey = doctor.key[0].text;
        const data = [];
        const foundProfile = professionalProfiles.find((data) => data.key[0].text === uniqueKey);
        data['en-us'] = foundProfile;
        data['de-de'] = foundProfile;

        setMyAppointmentsData(data);
        setMyAppointmentsDataStatus(dataSet);
        setIsDoctorsPage(true);
        history.push(`/professional/${uniqueKey}`);
    };

    const [timeSlots, setTimeSlots] = useState([]);
    const getTime = (time) => {
        const date = new Date(time);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        const current = `${hours}:${minutes}`;
        return current;
    };

    const forCurrentUser = () => {
        setPatientDetails('current');
    };

    const isNotCurrentUser = () => {
        return !!(
            user &&
            activeResp?.length > 0 &&
            mainDataMyDoctorsID?.length > 0 &&
            doctor &&
            mainDataMyDoctorsID.includes(doctor?.key[0].text)
        );
    };

    const isNotMyCalendarData = () => {
        if (
            user &&
            doctor !== selectedDoctor &&
            activeResp?.length > 0 &&
            mainDataMyDoctorsID?.length > 0 &&
            doctor &&
            !mainDataMyDoctorsID.includes(doctor?.key[0].text)
        ) {
            return true;
        }
        if (user && activeResp?.length === 0 && doctor !== selectedDoctor) {
            return true;
        }
        return !user && doctor !== selectedDoctor;
    };

    const checkingDoctor = () => {
        if (isNotCurrentUser()) {
            console.debug('dsadaasdasads');
            forCurrentUser();
        }
    };

    console.debug('formStage123', formStage);

    useEffect(() => {
        if (!onceRunState && secondaryLocationSet) {
            checkingDoctor();
            console.debug('dsadaasdasads    f', doctor);
        }
    }, [formStage === 1, secondaryLocationSet]);
    console.debug('queryStage ander', parseInt(queryStage));

    const startBooking = (isReturningPatient) => {
        const professional = professionalProfiles.find(
            (professional) => professional.key[0].text === doctor.key[0].text
        );

        const dcCalendarId = useSearchStore
            .getState()
            .availableProfessionals.find(
                (availableProfessional) => availableProfessional.key[0].text === doctor.key[0].text
            )?.dc_calendar_ids?.[0];

        const secondaryLocations =
            professional.secondary_location_mappings.find(
                (secondaryLocation) => secondaryLocation.professional_type.key[0].text === selectedSpecialty
            )?.secondary_locations ?? [];

        const consentTreatments = getConsentTreatments({
            selectedTreatments: selectedTreatments,
            treatments: treatments,
            selectedLocation: selectedLocation,
            allDocuments: allDocuments,
            selectedHealthInsurance: selectedHealthInsurance,
        });

        const determineBookingStep = () => {
            if (consentTreatments.length > 0) {
                return BookingStep.CONSENT;
            }
            if (secondaryLocations.length > 1) {
                return BookingStep.LOCATION_SELECTION;
            }
            return BookingStep.TIMESLOT_SELECTION;
        };

        const { isOnlineAppointment } = useSearchStore.getState();
        useBookingStore.getState().resetStore();
        useBookingStore.setState({
            isReturningPatient: isReturningPatient ?? false,
            isOnlineAppointment: isOnlineAppointment,
            showReturningPatientToggle: false,
            showPatientTypeSelection: false,
            selectedProfessional: doctor,
            showBookingModal: true,
            consentTreatments: consentTreatments,
            isAtSecondaryLocation: professional.secondary_location_mappings.length > 0 && secondaryLocations.length > 0,
            secondaryLocation: secondaryLocations.length === 1 ? secondaryLocations[0] : null,
            currentStep: determineBookingStep(),
            secondaryLocationOptions:
                professional.secondary_location_mappings.length > 0 && secondaryLocations.length > 1
                    ? secondaryLocations
                    : [],
            dcCalendarId: dcCalendarId,
            isProfessionalSelectionSkipped: false,
        });
    };

    const setPatientDetails = async (data, d = new Date(), subLocKey) => {
        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;
        let is_returning = data === 'current';
        if (data === 'nothing') {
            is_returning = isReturning;
        }
        const is_online = location_key === 'online';
        const today = d;
        const start_date = formatDate(today, 'full-mm-dd');
        console.debug(start_date);

        console.debug('selectedDoctorwithapi', selectedDoctor);
        console.debug('selectedDoctorwithapi', doctor);

        const response = await processAPIWithHeadersForPractitioners(
            `public/users/availability/${doctor.id}?is_online=${is_online}&is_returning=${
                is_returning
            }&appointment_type_keys=${appointment_type_keys}&insurance_type_key=${insurance_type_key}&start_date=${
                start_date
            }&location_key=${location_key}&professional_type_key=${professional_type_key}${
                appointmentSearch.location_key !== 'online' && (subLocKey || selectedSecondaryLocation)
                    ? `&sub_loc_key=${subLocKey || selectedSecondaryLocation.key[0].text}`
                    : ''
            }&journey_type=${journey_type}`,
            'GET',
            {
                headers: {
                    'Content-Type': 'application/json',
                    customer_id: customer_id,
                },
            }
        );
        console.debug('appointment_type_keys', appointment_type_keys, insurance_type_key);

        console.debug('response', response);

        setCalendarData(response.data);
        setNewAppointmentData(response.data);

        const slots = [];
        response?.data.availability.forEach((day) => {
            day.slots.forEach((slot) => {
                const time = getTime(slot);
                if (!slots.includes(time)) {
                    slots.push(time);
                }
            });
        });
        slots.sort();
        setTimeSlots(slots);

        setIsReturning(is_returning);
        setFormStage(2, doctor);
        setDetails({ is_returning: is_returning });
    };
    console.debug('doctor: profile', doctor);

    const renderForm = () => {
        if ((!selectedDoctor || doctor?.id !== selectedDoctor.id) && !appointmentSelection) {
            return (
                <PatientTypeSelection
                    doctor={doctor}
                    setPatientType={startBooking}
                    mainData={mainData}
                    loading={!dataSet}
                />
            );
        }

        console.debug('BookAppointment: Selected Doctor', doctor);
        console.debug('BookAppointment: Form Stage in Render Form', formStage);
        console.debug('queryStage:', parseInt(queryStage));
        switch (parseInt(queryStage)) {
            case 1:
                return isNotCurrentUser() ? (
                    <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress className={classes.progressBar} />
                    </div>
                ) : (
                    <PatientTypeSelection
                        doctor={doctor}
                        setPatientType={startBooking}
                        mainData={mainData}
                        loading={!dataSet}
                    />
                );
            case 2:
                if (calendarData) {
                    return (
                        <Calendar
                            setFormStage={setFormStage}
                            setPatientDetails={setPatientDetails}
                            selectedDoctor={selectedDoctor}
                            setDetails={setDetails}
                            mainData={mainData}
                            dataSet={dataSet}
                            data={calendarData}
                            mainBenefits={mainBenefits}
                            dataSetReg={dataSetReg}
                            history={history}
                            useriner={useriner}
                            timeSlots={timeSlots}
                            getTime={getTime}
                            doctor={doctor}
                            isReturning={isReturning}
                            secondaryLocations={secondaryLocations}
                            selectedSecondaryLocation={selectedSecondaryLocation}
                            setSelectedSecondaryLocation={setSelectedSecondaryLocation}
                            updateAppointmentData={updateAppointmentData}
                            appointmentSearch={appointmentSearch}
                        />
                    );
                }
                if (isNotMyCalendarData()) {
                    return (
                        <PatientTypeSelection
                            doctor={doctor}
                            setPatientType={startBooking}
                            mainData={mainData}
                            loading={!dataSet}
                        />
                    );
                }
                break;
            case 3:
                return (
                    <LoginAppointment
                        verifyCode={verifyCode}
                        signUp={signUp}
                        setEmail={setEmail}
                        setConfirmPassword={setConfirmPassword}
                        setGender={setGender}
                        setTitle={setTitle}
                        setFirstName={setFirstName}
                        setMiddleName={setMiddleName}
                        setLastName={setLastName}
                        setDOB={setDOB}
                        phone={phone}
                        setPhone={setPhone}
                        email={email}
                        dob={dob}
                        lastName={lastName}
                        middleName={middleName}
                        confirmPassword={confirmPassword}
                        firstName={firstName}
                        gender={gender}
                        title={title}
                        selectedDoctor={selectedDoctor}
                        mainBenefits={mainBenefits}
                        dataSetReg={dataSetReg}
                        mainData={mainData}
                        setFormStage={setFormStage}
                    />
                );

            case 4:
                return (
                    <ContactDetails
                        setFormStage={setFormStage}
                        selectedDoctor={selectedDoctor}
                        mainBenefits={mainBenefits}
                        dataSetReg={dataSetReg}
                        mainData={mainData}
                        history={history}
                        useriner={useriner}
                        signUp={signUp}
                    />
                );
            case 5:
                return (
                    <HealthInsurance
                        signUp={signUp}
                        setFormStage={setFormStage}
                        selectedDoctor={selectedDoctor}
                        mainBenefits={mainBenefits}
                        dataSetReg={dataSetReg}
                        mainData={mainData}
                        appointmentSearch={appointmentSearch}
                    />
                );
            case 6:
                return (
                    <Verification
                        handleFormSubmit={handleFormSubmit}
                        selectedDoctor={selectedDoctor}
                        code={code}
                        setCode={setCode}
                        setFormStage={setFormStage}
                        dataSetReg={dataSetReg}
                        mainData={mainData}
                        useriner={useriner}
                        email={email}
                    />
                );

            case 7:
                return (
                    <BookingSummary
                        setPatient={setPatientDetails}
                        mainData={mainData}
                        dataSet={dataSet}
                        selectedDoctor={selectedDoctor}
                        appointmentSearch={appointmentSearch}
                        setFormStage={setFormStage}
                    />
                );
            default:
                break;
        }
    };

    if (formStage > 2 && selectedDoctor && doctor.id !== selectedDoctor.id) {
        return <div />;
    }

    return (
        <Grid
            container
            spacing={2}
            style={{
                marginTop: 15,
            }}
        >
            <Grid item md={4} sm={5} xs={12}>
                <div className={classes.cardContentClasses}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                            marginTop: 0,
                        }}
                    >
                        <img
                            src={
                                currentDoctor[0]?.profile_picture.url
                                    ? currentDoctor[0]?.profile_picture.url
                                    : doctor?.profile_picture?.url
                            }
                            className={classes.doctorImg}
                            alt={
                                currentDoctor[0]?.profile_picture?.alt
                                    ? currentDoctor[0]?.profile_picture?.alt
                                    : doctor?.profile_picture?.alt
                            }
                        />

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography className={classes.classTitle}>
                                {currentDoctor[0]?.display_name[0].text ?? doctor.display_name[0].text}
                            </Typography>

                            <Typography className={classes.classTeacher}>
                                {professions.length === 0
                                    ? dataSet
                                        ? doctor?.specialityType.name[0].text
                                        : 'Meditation Trainer'
                                    : professions
                                          .filter(Boolean)
                                          .map((profession) => profession.name[0].text)
                                          .join(' / ')}
                            </Typography>
                        </div>
                        <div className={classes.flagsContainer}>
                            {(currentDoctor.length > 0 ? currentDoctor : [doctor])[0].languages.map((lang) => (
                                <CountryFlag
                                    countryCode={lang.languages1 === 'en' ? 'gb' : lang.languages1}
                                    alt={lang.languages1}
                                />
                            ))}
                        </div>
                        <Typography className={classes.classTeacher} style={{ marginBottom: 10, textAlign: 'center' }}>
                            {currentDoctor[0]?.practice_name[0].text ?? doctor.practice_name[0].text}
                        </Typography>
                        <div
                            style={{
                                width: '-webkit-fill-available',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <OutlinedButton
                                text={dataSet ? mainData.see_profile_cta[0].text : t('SeeProfile')}
                                onPress={() => {
                                    forDoctorDetails(doctor);
                                }}
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                {appointmentSearch.location_key !== 'online' && queryStage > 1 && doctor && calendarData && (
                    <LocationPreviewCard
                        appointmentSearch={appointmentSearch}
                        professionalLocation={selectedSecondaryLocation || doctor.locationData}
                    />
                )}
            </Grid>

            <Grid item md={8} sm={7} xs={12}>
                <div
                    className={classes.calendarCardContent}
                    style={{
                        padding: 30,
                    }}
                >
                    {renderForm()}
                </div>
            </Grid>
        </Grid>
    );
};

export default memo(AnotherSectionImg);
