import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

import close from '@/assets/Icons/close_grey.svg';

import FilledButton from '@/components/FilledButton';

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        backgroundColor: 'transparent',
    },
    scrollRoot: {
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    text: {
        ...theme.typography.content,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
    },
    title: {
        ...theme.typography.HL1,
        marginTop: 10,
        width: '90%',
        padding: 0,
        '& .MuiTypography-h6': {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '24px !important',
        },
    },
    privacyPolicyFrame: { border: 'none' },
    btnClose: {
        position: 'absolute',
        right: 10,
    },
}));

const SummaryPrivacyPolicyModal = ({ link, mainData, subHeading, open, onClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [heading, setHeading] = useState(null);
    const [closeCtaLabel, setCloseCtaLabel] = useState(null);

    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

    useEffect(() => {
        if (mainData && Object.keys(mainData).length > 0) {
            setHeading(mainData.privacy_policy_link_name[0].text);
            setCloseCtaLabel(mainData.privacy_policy_close_cta[0].text);
        }
    }, [mainData]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.dialogRoot,
            }}
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                    borderRadius: 8,
                    maxWidth: '100%',
                    paddingLeft: matches ? 20 : matchesMd ? 30 : matchesLg ? 40 : matchesXl && 40,
                    paddingRight: matches ? 20 : matchesMd ? 30 : matchesLg ? 40 : matchesXl && 40,
                    paddingTop: matches ? 20 : matchesMd ? 20 : matchesLg ? 20 : matchesXl && 20,
                    paddingBottom: matches ? 20 : matchesMd ? 30 : matchesLg ? 40 : matchesXl && 40,
                },
            }}
        >
            <Button color="primary" className={classes.btnClose} onClick={onClose}>
                <img
                    alt=""
                    src={close}
                    style={{
                        width: 20,
                        height: 20,
                    }}
                />
            </Button>
            <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
                {heading}
            </DialogTitle>

            <DialogContent
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    paddingBottom: 20,
                    paddingLeft: 0,
                    paddingRight: 0,
                    padding: 0,
                }}
            >
                <DialogContentText
                    id="alert-dialog-description"
                    classes={{
                        root: classes.text,
                    }}
                >
                    {subHeading}
                </DialogContentText>

                {/* File Drop Area Code */}
                <div>
                    <iframe
                        className={classes.privacyPolicyFrame}
                        src={link}
                        height={500}
                        width={706}
                        title="Privacy Policy"
                    />
                </div>
                {/* File Drop Area Code */}

                <div
                    style={{
                        display: 'flex',
                        flexDirection: matches ? 'column' : 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        marginTop: 20,
                    }}
                >
                    <FilledButton text={closeCtaLabel} onPress={onClose} fullWidth={matches && true} />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SummaryPrivacyPolicyModal;
