import Amplify from 'aws-amplify';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from '@/components/ErrorFallback';

import { AuthContextTwo } from '@/context/DoctorsContextTwo';
import { AuthContext } from '@/context/ProjectContext';

import { useLoggingStore, usePersistedPrismicStore } from '@/store.js';

import App from './App';
import awsExports from './aws-exports';
import './i18n';
import './index.css';

Amplify.configure(awsExports);

// hydrate prismic store
usePersistedPrismicStore.getState().loadStore();

const logError = (error, info) => {
    console.debug(error);
    console.debug(info);
    console.debug(useLoggingStore.getState().context);
};

ReactDOM.createRoot(document.getElementById('root')).render(
    <AuthContextTwo>
        <AuthContext>
            <ErrorBoundary onError={logError} FallbackComponent={ErrorFallback}>
                <App />
            </ErrorBoundary>
        </AuthContext>
    </AuthContextTwo>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
