import { Button, CircularProgress, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Auth } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import UnderlinedButton from '@/components/UnderlinedButton';

// import { ContextProvider } from "../../context/ProjectContext";
import { Context as AuthContext } from '@/context/AuthContext';
import { ContextProvider } from '@/context/ProjectContext';

import { usePersistedPrismicStore } from '@/store.js';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        backgroundColor: 'white',
        height: 330,
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
    },
    calendarCardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
    },
    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            height: 370,
        },
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    doctorImg: {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    textFields: {
        height: '3.75em',
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
            // backgroundColor: "red",
        },
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 18,
        borderRadius: '2em',
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
}));

const Verification = ({
    handleFormSubmit,
    code,
    setCode,
    setFormStage,
    mainData,
    dataSetReg,
    selectedDoctor,
    useriner,
    email,
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const { setOnceRunState } = useContext(ContextProvider);
    const history = useHistory();

    const {
        state: { verificationLoading },
    } = useContext(AuthContext);

    // prismic data
    const {
        [i18n.language]: { dataSet: dataSetDrop, benefits: mainBenefitsDrop },
    } = usePersistedPrismicStore((state) => state.registerData);

    const [disableResend, setDisableResend] = useState(false);

    // According to currently selected language we set our main data which we use below in render
    console.debug('mainBenefitsDrop', mainBenefitsDrop);

    let timer;
    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    }, [disableResend]);

    async function resendConfirmationCode() {
        try {
            // enabled button on re-send click
            setDisableResend(true);

            await Auth.resendSignUp(email);

            const timeOut = 1000 * 60 * 5;
            timer = setTimeout(() => {
                // disabled button on re-send click
                setDisableResend(false);
            }, timeOut);

            console.debug('code resent successfully');
        } catch (err) {
            clearTimeout(timer);
            console.debug('error resending code: ', err);
        }
    }

    useEffect(() => {
        // returning back to top on mount
        window.scrollTo(0, 0);
    }, []);

    // Prevent user from going back
    window.addEventListener('popstate', function (event) {
        history.go(1);
    });

    return (
        <form className={classes.form} noValidate autoComplete="off" onSubmit={handleFormSubmit}>
            <Typography
                className={classes.heading}
                style={{
                    marginBottom: 5,
                }}
            >
                {dataSetDrop ? mainBenefitsDrop.heading : 'Verification'}
            </Typography>

            <Typography
                className={classes.subHeading}
                style={{
                    fontFamily: 'MessinaSans-Regular',
                    color: theme.palette.common.darkGrey,
                    textTransform: 'none',
                    marginLeft: 0,
                    textAlign: 'left',
                    marginBottom: 20,
                }}
            >
                {dataSetDrop ? mainBenefitsDrop.description : 'Lorem ipsum dolor sit amet'}
            </Typography>

            <TextField
                id="verifyCode"
                label={dataSetDrop ? mainBenefitsDrop.verification_code_input_label : 'Verification Code'}
                variant="filled"
                color="primary"
                placeholder={
                    dataSetDrop
                        ? mainBenefitsDrop.verify_code_textfield_placeholder
                        : 'Please Enter the code sent to your email address.'
                }
                value={code}
                onChange={(event) => {
                    const text = event.target.value;
                    setCode(text);
                }}
                fullWidth
                classes={{ root: classes.textFields }}
                InputLabelProps={{
                    classes: {
                        root: classes.inputLabel,
                        focused: 'focused',
                        shrink: 'shrink',
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.filledInputRoot,
                        focused: classes.fieldFocused,
                        shrink: 'shrink',
                    },
                }}
            />
            <div className="" style={{ display: 'flex' }}>
                <Typography
                    className={classes.subHeading}
                    style={{
                        fontFamily: 'MessinaSans-Regular',
                        color: theme.palette.common.darkGrey,
                        textTransform: 'none',
                        marginLeft: 0,
                        textAlign: 'left',
                        fontSize: 14,
                        marginTop: 20,
                    }}
                >
                    {dataSetDrop ? mainBenefitsDrop.resend_code_prompt : 'You didn’t receive any code?'}
                </Typography>
                <div className="" style={{ marginLeft: 5 }}>
                    <UnderlinedButton
                        disabled={disableResend}
                        text={dataSetDrop ? mainBenefitsDrop.resend_code_cta : t('Resend Code')}
                        color={disableResend ? '#999' : theme.palette.common.darkGrey}
                        onPress={() => {
                            resendConfirmationCode();
                        }}
                        noMarginTop={false}
                    />
                </div>
            </div>

            <div
                className=""
                style={{
                    marginTop: 30,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'baseline',
                }}
            >
                <Button
                    variant="contained"
                    disabled={verificationLoading}
                    type="submit"
                    classes={{
                        root: classes.submitButton,
                        label: classes.buttonText,
                    }}
                    style={{
                        opacity: 1,
                        backgroundColor: theme.palette.common.yellow,
                        marginTop: 0,
                        width: matches ? '40%' : '30%',
                    }}
                    onClick={() => {
                        setOnceRunState(true);
                    }}
                >
                    {dataSetReg && !verificationLoading ? (
                        mainData.continue_button[0].text
                    ) : !verificationLoading ? (
                        t('Continue')
                    ) : (
                        <CircularProgress />
                    )}
                </Button>
            </div>
        </form>
    );
};

export default Verification;
