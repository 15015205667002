import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Auth } from 'aws-amplify';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/es/lib/isEmail';

import { AntSwitch } from '@/components/Common/AntSwitchCommon';
import { DateTextField } from '@/components/Common/Date/DateTextField';
import { DropDown } from '@/components/Common/DropDown/DropDown';
import PrivacyPolicyText from '@/components/Common/PrivacyPolicyText';
import UnderlinedButton from '@/components/UnderlinedButton';

import { Context as BookAppointmentContext } from '@/context/BookAppointmentContext';
import { ContextProvider } from '@/context/ProjectContext';

import { useDebounce } from '@/hooks/useDebounce';

import { dayjs } from '@/utils/dayjsSetup.js';

import { usePersistedPrismicStore } from '@/store.js';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        position: 'relative',
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
    },
    heading: {
        ...theme.typography.HL1,
        fontWeight: 'bold',
    },
    subheading: {
        ...theme.typography.HL4,
        color: '#000',
        fontSize: 14,
    },
    categoryHeading: {
        ...theme.typography.HL5,
    },
    contentText: {
        ...theme.typography.content,
    },
    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            height: 370,
        },
    },
    cardContentBottomClasses: {
        position: 'relative',
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        paddingBottom: 10,
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    doctorImg: {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    findClassText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    priceText: {
        ...theme.typography.HL1,
    },
    formControl: {
        width: '100%',
        backgroundColor: 'white',
        height: '4.3em',

        justifyContent: 'space-between',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    downArrow: {
        height: '0.75em',
        marginRight: 15,
    },
    menuItem: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
    },
    textFields: {
        height: '3.75em',
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: '5px',
    },
    blackCircle: {
        backgroundColor: theme.palette.common.darkGrey,
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    greenCircle: {
        backgroundColor: '#5ba668',
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    passwordText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    passwordValidText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    selectionPopup: {
        height: 240,
        width: '100%',
        background: 'white',
        top: '2.5em',
        paddingTop: 1,
        zIndex: 9999,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            height: 280,
        },
        [theme.breakpoints.down('xs')]: {
            height: 100,
        },
    },
    closeButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    selectionContainer: {
        marginTop: 28,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            height: 18,
        },
        [theme.breakpoints.down('xs')]: {
            height: 18,
        },
    },
    selectionTitle: {
        ...theme.typography.content,

        textDecoration: 'underline',
    },
    userData: {
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',

        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    progressInner: {
        width: 300,
        [theme.breakpoints.down('sm')]: {
            width: 150,
        },
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 18,
        borderRadius: '2em',
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    disabledButton: {
        backgroundColor: theme.palette.common.yellow,
    },
    buttonText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'uppercase',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    icon: {
        borderRadius: '50%',
        width: 22,
        height: 22,
        backgroundColor: theme.palette.common.lightBrown,

        'input:hover ~ &': {
            backgroundColor: theme.palette.common.lightBrown,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.common.yellow,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 22,
            height: 22,
            backgroundImage: 'radial-gradient(#000,#000 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    radioRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    pointer: {
        cursor: 'pointer',
    },
}));

const LoginAppointment = ({
    setGender,
    setFirstName,
    setLastName,
    setEmail,
    setDOB,
    setConfirmPassword,
    email,
    dob,
    lastName,
    confirmPassword,
    firstName,
    gender,
    selectedDoctor,
    mainBenefits,
    dataSetReg,
    mainData,
    phone,
    setPhone,
    setFormStage,
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [checked, setChecked] = useState(false);
    const [passwordValid, setPasswordValid] = useState({
        characters: false,
        number: false,
        special: false,
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errFirstName, setErrFirstName] = useState('');
    const [errLastName, setErrLastName] = useState('');
    const [errEmail, setErrEmail] = useState('');
    const [errDOB, setErrDOB] = useState('');
    const [errPassword, setErrPassword] = useState('');
    const [errConfirmPassword, setErrConfirmPassword] = useState('');
    const [error, setError] = useState({
        title: 'Invalid Code',
        text: 'Please enter correct code',
    });
    const [errGender, setErrGender] = useState('');
    const [useriner, setUseriner] = useState(null);
    const [code, setCode] = useState('');
    // Functions
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    //  Confirm password
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const { languageGlobal } = useContext(ContextProvider);
    const [errPhoneNumber, setErrPhoneNumber] = useState(false);

    const {
        state: { password },
        setPassword,
    } = useContext(BookAppointmentContext);

    const hasNumber = (myString) => {
        return /\d/.test(myString);
    };

    const hasSpecial = (myString) => {
        const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return format.test(myString);
    };

    const {
        [i18n.language]: { dataSet: dataSetDrop, benefits: mainBenefitsDrop },
    } = usePersistedPrismicStore((state) => state.registerData);

    console.debug('mainBenefitsDrop', mainBenefitsDrop);

    const goBack = () => {
        setFormStage(2, selectedDoctor, false);
    };

    useEffect(() => {
        window.addEventListener('popstate', goBack);
        return () => {
            window.removeEventListener('popstate', goBack);
        };
    }, []);

    const emailExists = async (value) => {
        Auth.signIn(email || value, '123')
            .then(() => {
                setErrEmail('');
            })
            .catch((error) => {
                const { code } = error;
                switch (code) {
                    case 'NotAuthorizedException':
                        setErrEmail(dataSetDrop ? mainBenefitsDrop.used_email : t('EmailAlreadyExists'));
                        break;
                    default:
                        break;
                }
            });
    };

    /** State to stop continous API calls */
    const [isFetching, setIsFetching] = useState(false);

    const { debounceEmail } = useDebounce();

    const validateForm = (type, val = '-') => {
        switch (type) {
            case 'GenderSelection':
                console.debug('val1122', val);
                setErrGender('');
                if (val === '-') {
                    setErrGender(t('RequiredField'));
                }
                break;
            case 'firstName':
                setErrFirstName('');
                if (firstName === '') {
                    setErrFirstName(t('RequiredField'));
                }
                break;

            case 'lastName':
                setErrLastName('');
                if (lastName === '') {
                    setErrLastName(t('RequiredField'));
                }
                break;
            case 'dob':
                setErrDOB('');
                if (dob === '') {
                    setErrDOB(t('RequiredField'));
                } else if (!dayjs(dob, languageGlobal === 'en' ? 'DD/MM/YYYY' : 'DD.MM.YYYY', true).isValid()) {
                    setErrDOB(t('InvalidDate'));
                }
                break;

            case 'confirmPassword':
                setErrConfirmPassword('');
                if (confirmPassword === '') {
                    setErrConfirmPassword(
                        dataSetDrop ? mainBenefitsDrop.required_field_error_message : t('RequiredField')
                    );
                } else if (confirmPassword !== passwordValid.password) {
                    setErrConfirmPassword(dataSetDrop ? mainBenefitsDrop.same_password : t('PasswordsNotMatch'));
                }
                break;
            case 'email':
                setErrEmail('');
                if (email === '') {
                    setErrEmail(dataSetDrop ? mainBenefitsDrop.required_field_error_message : t('RequiredField'));
                } else if (!isEmail(email)) {
                    setErrEmail(dataSetDrop ? mainBenefitsDrop.invalid_email_error_message : t('InvalidEmail'));
                } else {
                    emailExists();
                }
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        setPasswordValid({
            characters: true,
            number: true,
            special: true,
            password: password,
        });
    }, [password]);

    console.debug('passwordValid.password Uper', passwordValid.password);
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrEmail('');
        setErrFirstName('');
        setErrLastName('');
        setErrDOB('');
        setErrPassword('');
        setErrConfirmPassword('');
        setErrGender('');
        setErrPhoneNumber(false);

        // setting main password in context
        setPassword(passwordValid.password);
        console.debug('passwordValid.password', passwordValid.password);

        console.debug('btn pressed');
        if (useriner) {
            if (code.length !== 6) {
                setError({
                    title: 'Invalid Code',
                    text: 'Please enter correct code',
                });
            }
        } else {
            let err = false;
            if (email === '') {
                setErrEmail(dataSetDrop ? mainBenefitsDrop.required_field_error_message : t('RequiredField'));
                err = true;
            } else if (!isEmail(email)) {
                setErrEmail(dataSetDrop ? mainBenefitsDrop.invalid_email_error_message : t('InvalidEmail'));
                err = true;
            }

            if (gender === '' || gender === '-') {
                setErrGender(t('RequiredField'));
                err = true;
            }

            if (firstName === '') {
                setErrFirstName(t('RequiredField'));
                err = true;
            }

            if (phone?.split(' ')[1]?.length === 0) {
                setErrPhoneNumber(true);
                err = true;
            }

            if (lastName === '') {
                setErrLastName(t('RequiredField'));
                err = true;
            }
            if (dob === '') {
                setErrDOB(t('RequiredField'));
                err = true;
            }
            if (confirmPassword === '') {
                setErrConfirmPassword(dataSetDrop ? mainBenefitsDrop.required_field_error_message : errFirstName);
                err = true;
            }
            if (passwordValid.password === '') {
                setErrPassword(t('RequiredField'));
                err = true;
            }
            if (err) {
                return;
            }

            setFormStage(20, selectedDoctor, false);
        }
    };

    useEffect(() => {
        // returning back to top on mount
        window.scrollTo(0, 0);
    }, []);

    const privacyPolicyContent = useMemo(() => {
        if (dataSetReg) {
            return [
                {
                    id: 1,
                    text: mainBenefits?.agree_statment,
                    link: mainBenefits?.term_and_condition_one,
                    href: mainBenefits?.link_one,
                },
                {
                    id: 2,
                    text: mainBenefits?.and,
                    link: mainBenefits?.term_and_condition_two,
                    href: mainBenefits?.link_two,
                },
                {
                    id: 3,
                    text: mainBenefits?.third_section,
                    link: mainBenefits?.privacy_policy_link[0]?.data.path[0]?.text,
                    href: mainBenefits?.privacy_policy_link[0]?.data.title[0]?.text,
                },
            ];
        }
        return [];
    }, [dataSetReg, mainBenefits]);

    return (
        <form onSubmit={handleFormSubmit} noValidate autoComplete="off">
            <Typography className={classes.categoryHeading}>
                {dataSetReg ? mainBenefits.account_information : 'Account Information'}
            </Typography>

            <Typography className={classes.contentText} style={{ marginTop: 5 }}>
                {dataSetReg ? mainBenefits.information_text : t('WeNeedSomeInformatonText')}
            </Typography>

            <Grid container style={{ paddingBottom: 0 }} spacing={matchesXs ? 2 : matches ? 0 : 2}>
                <Grid item xs={6} md={6} sm={12}>
                    {dataSetDrop && (
                        <DropDown
                            type="default"
                            mainDataHeading={mainBenefits?.gender}
                            mainDataArray={mainBenefitsDrop?.gender_values}
                            setData={setGender}
                            data={gender}
                            compulsory="GenderSelection"
                            widthID="gender"
                            validateForm={validateForm}
                            error={errGender}
                            errorMessage={mainBenefitsDrop.required_field_error_message}
                        />
                    )}
                </Grid>

                <Grid item md={6} sm={12} xs={6}>
                    {dataSetDrop && (
                        <DateTextField
                            type="dateOfBirth"
                            separator={mainBenefitsDrop.dob_placeholder_separator}
                            setDOB={setDOB}
                            dob={dob}
                            setError={setErrDOB}
                            error={errDOB}
                            errorMessage={mainData?.invalid_date[0].text}
                            placeHolderMonth={mainBenefitsDrop.dob_placeholder_month}
                            placeHolderYear={mainBenefitsDrop.dob_placeholder_year}
                            placeHolderDay={mainBenefitsDrop.dob_placeholder_day}
                            requiredDateField={mainBenefitsDrop.required_field_error_message}
                            mainHeading={mainBenefits.dob}
                            mt={9}
                        />
                    )}
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: 4 }}>
                <Grid item md={6} sm={12} xs={12}>
                    <TextField
                        id="firstName"
                        label={dataSetReg ? mainBenefits.first_name : 'First Name'}
                        variant="filled"
                        color="primary"
                        value={firstName}
                        onChange={(event) => {
                            const text = event.target.value;
                            setFirstName(text);
                        }}
                        onBlur={() => validateForm('firstName')}
                        fullWidth
                        classes={{
                            root: errFirstName ? classes.textFieldsError : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />

                    {errFirstName && (
                        <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                            {dataSetDrop ? mainBenefitsDrop.required_field_error_message : errFirstName}
                        </Typography>
                    )}
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                    <TextField
                        id="lastName"
                        label={dataSetReg ? mainBenefits.last_name : 'Last Name'}
                        variant="filled"
                        color="primary"
                        value={lastName}
                        onBlur={() => validateForm('lastName')}
                        onChange={(event) => {
                            const text = event.target.value;
                            setLastName(text);
                        }}
                        fullWidth
                        classes={{
                            root: errLastName ? classes.textFieldsError : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />

                    {errLastName && (
                        <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                            {dataSetDrop ? mainBenefitsDrop.required_field_error_message : errLastName}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {/* debounce */}
            <Grid container spacing={2} style={{ marginTop: 4 }}>
                <Grid item md={6} sm={12} xs={12}>
                    <TextField
                        id="email"
                        label={dataSetReg ? mainBenefits.email : 'Email'}
                        value={email.replaceAll(' ', '')}
                        onChange={(event) => {
                            const text = event.target.value.replaceAll(' ', '');

                            debounceEmail([
                                emailExists,
                                text,
                                {
                                    isFetching: isFetching,
                                    setIsFetching: setIsFetching,
                                },
                            ]);

                            setEmail(text);
                        }}
                        onBlur={() => validateForm('email')}
                        variant="filled"
                        color="primary"
                        fullWidth
                        classes={{
                            root: errEmail ? classes.textFieldsError : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />

                    {errEmail && (
                        <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                            {errEmail}
                        </Typography>
                    )}
                </Grid>

                <Grid item md={6} sm={12} xs={12} style={{ marginTop: -15 }}>
                    {/* <Phone setFullNumber={setPhone} mainBenefits={mainBenefits} dataSetReg={dataSetReg} /> mainBenefits?.mobileNumber */}
                    <DropDown
                        type="telephone"
                        setData={setPhone}
                        mainDataHeading={mainBenefits?.mobile_number}
                        compulsory="phoneNumber"
                        errorText={mainBenefitsDrop?.required_field_error_message}
                        validateForm={validateForm}
                        error={errPhoneNumber}
                        setError={setErrPhoneNumber}
                        data={phone}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: 4 }}>
                <Grid item md={6} sm={12} xs={12}>
                    <TextField
                        id="password"
                        label={dataSetReg ? mainBenefits.password : 'Password'}
                        variant="filled"
                        color="primary"
                        value={passwordValid.password || password}
                        onChange={(event) => {
                            const password = event.target.value;
                            let characters = false;
                            let number;
                            let special;

                            if (password.length > 7) {
                                characters = true;
                            }

                            number = hasNumber(password);
                            special = hasSpecial(password);

                            setPasswordValid({
                                characters: characters,
                                number: number,
                                special: special,
                                password: password,
                            });
                        }}
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        classes={{
                            root:
                                errPassword && passwordValid.password === ''
                                    ? classes.textFieldsError
                                    : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />

                    {errPassword && passwordValid.password === '' && (
                        <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                            {dataSetDrop ? mainBenefitsDrop.required_field_error_message : errPassword}
                        </Typography>
                    )}
                    {passwordValid.password !== '' ? (
                        <div>
                            <Typography className={classes.passwordText} style={{ marginTop: 8 }}>
                                {dataSetDrop
                                    ? mainBenefitsDrop.password_restrictions_heading
                                    : 'Your password must contain:'}
                            </Typography>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <div
                                        className={passwordValid.characters ? classes.greenCircle : classes.blackCircle}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className={
                                            passwordValid.characters ? classes.passwordValidText : classes.passwordText
                                        }
                                    >
                                        {dataSetDrop
                                            ? mainBenefitsDrop.password_restrictions[0].password_restriction[0].text
                                            : 'At least 8 characters'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <div className={passwordValid.number ? classes.greenCircle : classes.blackCircle} />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className={
                                            passwordValid.number ? classes.passwordValidText : classes.passwordText
                                        }
                                    >
                                        {dataSetDrop
                                            ? mainBenefitsDrop.password_restrictions[1].password_restriction[0].text
                                            : 'A number'}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center">
                                <Grid item>
                                    <div
                                        className={passwordValid.special ? classes.greenCircle : classes.blackCircle}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className={
                                            passwordValid.special ? classes.passwordValidText : classes.passwordText
                                        }
                                    >
                                        {dataSetDrop
                                            ? mainBenefitsDrop.password_restrictions[2].password_restriction[0].text
                                            : 'A special character'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ) : null}
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                    <TextField
                        id="confirmpasword"
                        label={dataSetReg ? mainBenefits.confirm_password : 'Confirm Password'}
                        variant="filled"
                        color="primary"
                        fullWidth
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(event) => {
                            const text = event.target.value;
                            setConfirmPassword(text);
                        }}
                        onBlur={() => validateForm('confirmPassword')}
                        classes={{
                            root: errConfirmPassword ? classes.textFieldsError : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownConfirmPassword}
                                    >
                                        {showConfirmPassword ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />

                    {errConfirmPassword && (
                        <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                            {errConfirmPassword}
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <div
                style={{
                    marginTop: 20,
                    marginBottom: 15,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'start',
                }}
            >
                <AntSwitch
                    checked={checked}
                    onChange={() => {
                        setChecked(!checked);
                    }}
                    name="notifications"
                    className={classes.userData}
                />
                <PrivacyPolicyText
                    data={privacyPolicyContent}
                    seeLess={mainBenefitsDrop?.see_more_cta}
                    seeMore={mainBenefitsDrop?.see_less_cta}
                />
            </div>

            <div
                className=""
                style={{
                    marginTop: 30,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <UnderlinedButton
                    text={dataSetReg ? mainData.go_back_button[0].text : 'Go Back'}
                    onPress={() => {
                        goBack();
                    }}
                />

                <Button
                    variant="contained"
                    type="submit"
                    disabled={!checked || errDOB || errPhoneNumber}
                    classes={{
                        root: classes.submitButton,
                        label: classes.buttonText,
                    }}
                    style={{
                        opacity: !checked || errDOB || errPhoneNumber ? 0.4 : 1,
                        backgroundColor: theme.palette.common.yellow,
                        width: matches ? '40%' : '30%',
                        marginTop: 0,
                    }}
                >
                    {dataSetReg ? mainData.continue_button[0].text : t('Continue')}
                </Button>
            </div>
        </form>
    );
};

export default LoginAppointment;
