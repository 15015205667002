import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    mainPage: {
        backgroundColor: theme.palette.common.cream_disable,
        paddingInline: 30,
        paddingBlock: 20,
        borderRadius: 10,
        position: 'relative',
        right: 0,
        border: '1px solid #D5D5D5',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
}));

/**
 *
 * @param {*} Classnames as String:Array [class1,class2]
 * @param {*} Rest Props
 */
const HighlightContainer = ({ children, bgColor, classNames = [], ...props }) => {
    const classes = useStyles();
    return (
        <div
            className={clsx(classes.mainPage, ...classNames)}
            style={{ backgroundColor: bgColor || '#F9F8F4' }}
            {...props}
        >
            {children}
        </div>
    );
};

export default HighlightContainer;
