import { useContext } from 'react';
import useSWRImmutable from 'swr/immutable';

import { Context as AuthContext } from '@/context/AuthContext.js';

import { getSharedDocuments, getSingleFormTemplate } from '@/service/ConsentForms/consentFormService.js';

import { useSharedFormsStore } from '@/store.js';

const fetchFormsTemplateInfo = async (data) => {
    try {
        const documentsInfo = await Promise.all(
            data
                .filter((form) => form.context.professional_id && form.context.template_id)
                .map(async (form) => {
                    return getSingleFormTemplate(form.context.professional_id, form.context.template_id);
                })
        );

        return documentsInfo.filter(Boolean); // remove undefined value
    } catch (error) {
        console.error('Error fetching document templates', error);
        return [];
    }
};

const fetchSharedForms = async (user_id) => {
    try {
        const { data: sharedDocuments } = await getSharedDocuments({ userId: user_id });
        const sharedForms = sharedDocuments.filter((data) => data.context.status === 'COMPLETED'); // Only completed

        // Create a lookup object with form_id and professional_id as combined key
        const fetchedForms = await fetchFormsTemplateInfo(sharedForms);
        const formInfoLookup = fetchedForms.reduce((acc, form) => {
            acc[`${form.id}_${form.professional_id}`] = form;
            return acc;
        }, {});

        const enrichedData = sharedForms.map((item) => {
            const key = `${item.context.template_id}_${item.context?.professional_id}`;
            const matchingForm = formInfoLookup[key];

            return {
                ...item,
                name: matchingForm
                    ? {
                          de: matchingForm?.form_name_de ?? matchingForm?.content?.name,
                          en: matchingForm?.form_name_en ?? matchingForm?.content?.name,
                      }
                    : undefined,
            };
        });

        useSharedFormsStore.setState({ sharedForms: enrichedData });
    } catch (err) {
        console.error(err);
    }

    return null;
};

export const useUserSharedForms = () => {
    const {
        state: { user, usersession },
    } = useContext(AuthContext);

    const { isLoading } = useSWRImmutable(
        usersession ? { usersession: usersession, key: 'all-user-shared-forms-and-anamnesis' } : null,
        () => fetchSharedForms(user.id)
    );

    return {
        isLoading,
    };
};
