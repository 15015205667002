import { makeStyles } from '@material-ui/core/styles';

import { useBookingStore } from '@/store.js';

import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import SecondaryLocationCard from './SecondaryLocationCard';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        position: 'relative',
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
    },
    locationCardsContainer: {
        display: 'flex',
        gap: '1rem',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

const SecondaryLocationSelection = () => {
    const classes = useStyles();
    const secondaryLocationOptions = useBookingStore((state) => state.secondaryLocationOptions);

    return (
        <div className={classes.container}>
            {!secondaryLocationOptions && (
                <div className={classes.loadingOverlay}>
                    <EternoSpinner />
                </div>
            )}
            <div className={classes.locationCardsContainer}>
                {secondaryLocationOptions.map((locationOption) => (
                    <SecondaryLocationCard key={locationOption.key[0].text} locationData={locationOption} />
                ))}
            </div>
        </div>
    );
};

export default SecondaryLocationSelection;
