/**
 *
 * @param top marginTop
 * @param left marginLeft
 * @param bottom marginBottom
 * @param right marginRight
 * @param y marginBlock (Y axis)
 * @param x marginInline (X axis)
 */
const Spacer = ({ top = 0, left = 0, bottom = 0, right = 0, y = 0, x = 0 }) => {
    return (
        <div
            style={{
                ...(top ? { marginTop: top } : {}),
                ...(left ? { marginLeft: left } : {}),
                ...(bottom ? { marginBottom: bottom } : {}),
                ...(right ? { marginRight: right } : {}),
                ...(y ? { marginBlock: y } : {}),
                ...(x ? { marginInline: x } : {}),
            }}
        />
    );
};

export default Spacer;
