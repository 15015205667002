import { Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePersistedPrismicStore } from '@/store.js';

import EmptyDataBox from '../Common/EmptyDataBox';
import { historyStyles } from './HistoryStyles';

const HistoryEmpty = ({ content }) => {
    console.debug('🚀 ~ file: HistoryEmpty.jsx:8 ~ HistoryEmpty ~ content', content);
    const classes = historyStyles();
    const { i18n } = useTranslation();
    const { [i18n.language]: consentPrismicData } = usePersistedPrismicStore((state) => state.consentData);
    const { content: consentData } = consentPrismicData;
    return (
        <>
            <Typography className={classes.paragraph}>
                {consentData ? consentData.history_intro_text[0].text : 'This site provides you with a history...'}
            </Typography>
            <div className={classes.mt25}>
                <EmptyDataBox
                    text={
                        consentData
                            ? consentData.you_haven_t_shared_any_data_yet[0].text
                            : 'You haven’t shared any data yet.'
                    }
                />
            </div>
        </>
    );
};

export default memo(HistoryEmpty);
