import { useEventCallback } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import arrayShuffle from 'array-shuffle';
import clsx from 'clsx';
import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import downArrow from '@/assets/Icons/Chevron Down.svg';

import { ContextProvider } from '@/context/ProjectContext';

import AnotherSectionImg from '@/pages/BookAppointment/AnotherSectionImg';
import RenderEmptyBook from '@/pages/BookAppointment/RenderEmptyBook';

import { BookingStep } from '@/utils/constants.js';
import {
    JourneyType,
    fetchAvailableProfessionals,
    getConsentTreatments,
    getInsuranceAvailability,
} from '@/utils/helpers.js';

import { useBookingStore, useConfigStore, usePersistedPrismicStore, useSearchStore } from '@/store.js';

import NoTreatmentsMessage from '../Common/NoTreatmentsMessage';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import FilledButton from '../FilledButton';
import BookWithProfessional from './BookWithProfessional';
import { HealthInsurance } from './SearchBar';
import SearchResultCard, { SearchResultType } from './SearchResultCard';

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: '16px',
        boxShadow: '0px 3px 12px 0px rgba(120, 120, 120, 0.22)',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 'lighter',
        padding: '20px',
        gap: '2rem',
        transition: 'opacity 0.3s ease',
        boxSizing: 'border-box',
        background: 'white',
    },
    accordionContainer: {
        maxHeight: '55vh',
        overflow: 'scroll',
    },
    selectionBar: {
        position: 'sticky',
        bottom: 0,
        left: 0,
        width: '100%',
        background: '#FFF',
        boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.17)',
        padding: '16px 32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
            padding: '16px',
        },
    },
    resultCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '1rem',
    },
    subTitleWrapper: {
        width: '100%',
    },
    resultSubTitle: {
        color: '#111',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        textTransform: 'uppercase',
        margin: 0,
        opacity: 0.5,
    },
    specialtyTitle: {
        color: '#2e2e2e',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        margin: 0,
    },
    resultCardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
    },
    accordion: {
        background: 'transparent',
        padding: '0.5rem 0.25rem',
        boxShadow: 'none',
        width: '100%',
    },
    accordionRoot: {
        margin: 0,
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    accordionDetailsRoot: {
        padding: '8px 16px 16px',
        [theme.breakpoints.down('xs')]: {
            padding: '8px 8px 16px',
        },
    },
    accordionWrapper: {
        width: '100%',
    },
    line: {
        background: '#D9D9D9',
        height: '1px',
        width: '100%',
    },
    selectionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    selections: {
        margin: 0,
        maxWidth: '30rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '10rem',
        },
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '20vh',
    },
    noTreatmentsHeading: {
        padding: '2rem 0',
    },
    availableProfessionalsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
    },
    goBackContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    goBackIcon: {
        transform: 'rotate(90deg)',
        width: '1.25rem',
    },
}));

const SearchResults = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { i18n } = useTranslation();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const { appointmentSearch, setAppointmentSearch } = useContext(ContextProvider);
    const prismicData = useSearchStore((state) => state.prismicData);
    const search = useSearchStore((state) => state.search);
    const professionals = useSearchStore((state) => state.professionals);
    const treatments = useSearchStore((state) => state.treatments);
    const locations = useSearchStore((state) => state.locations);
    const selectedProfessional = useSearchStore((state) => state.selectedProfessional);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const selectedSpecialty = useSearchStore((state) => state.selectedSpecialty);
    const selectedHealthInsurance = useSearchStore((state) => state.selectedHealthInsurance);
    const treatmentTypeCategories = useSearchStore((state) => state.treatmentTypeCategories);
    const selectedTreatments = useSearchStore((state) => state.selectedTreatments);
    const showAvailableProfessionals = useSearchStore((state) => state.showAvailableProfessionals);
    const allDocuments = useSearchStore((state) => state.allDocuments);
    const isOnlineAppointment = useSearchStore((state) => state.isOnlineAppointment);
    const availableProfessionals = useSearchStore((state) => state.availableProfessionals);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);
    const {
        [i18n.language]: { mainData, dataSet },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);
    const {
        [i18n.language]: { professionalProfiles },
    } = usePersistedPrismicStore((state) => state.findAppointmentData);
    const {
        [i18n.language]: { mainData: professionalProfilePrismicData },
    } = usePersistedPrismicStore((state) => state.professionalProfileData);
    const showBookingModal = useBookingStore((state) => state.showBookingModal);
    const [filteredProfessionals, setFilteredProfessionals] = useState([]);
    const [filteredProfessionalTypes, setFilteredProfessionalTypes] = useState([]);
    const [filteredTreatments, setFilteredTreatments] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [loading, setLoading] = useState(false);
    const [expandedCategories, setExpandedCategories] = useState({});
    const accordionContainerRef = useRef();

    const isTreatmentAvailableForSelectedInsurance = (treatment) => {
        switch (selectedHealthInsurance) {
            case HealthInsurance.PUBLIC:
                return treatment.public_insurance;
            case HealthInsurance.PRIVATE:
                return treatment.private_insurance;
            case HealthInsurance.SELF_PAYER:
                return treatment.self_payer;
            default:
                return false;
        }
    };

    const getGridColumns = (additionalColumns = 0) => {
        if (matchesXs) return `${1 + additionalColumns}`;
        if (matchesSm) return `${2 + additionalColumns}`;
        if (matchesMd) return `${3 + additionalColumns}`;
        return `${4 + additionalColumns}`;
    };

    const availableInsurances = useMemo(() => {
        if (!selectedSpecialty) return [];

        const treatmentsArray =
            locations[selectedLocation]?.professional_types[selectedSpecialty]?.services_or_treatments || [];

        const insuranceAvailability = getInsuranceAvailability(treatmentsArray);

        return Object.keys(insuranceAvailability).filter((key) => insuranceAvailability[key]);
    }, [filteredTreatments, selectedHealthInsurance]);

    const startBooking = () => {
        if (currentCustomer?.is_accordion_for_multiple_professionals) {
            useSearchStore.setState({
                showAvailableProfessionals: true,
            });
        } else {
            const consentTreatments = getConsentTreatments({
                selectedTreatments: selectedTreatments,
                treatments: treatments,
                selectedLocation: selectedLocation,
                allDocuments: allDocuments,
                selectedHealthInsurance: selectedHealthInsurance,
            });
            useBookingStore.getState().resetStore();
            useBookingStore.setState({
                selectedProfessional: professionalProfiles.find(
                    (professional) => professional.location_hub.id === currentCustomer.locations[0].prismicId
                ),
                showBookingModal: true,
                consentTreatments: consentTreatments,
                currentStep: consentTreatments.length > 0 ? BookingStep.CONSENT : BookingStep.TIMESLOT_SELECTION,
                isOnlineAppointment: isOnlineAppointment,
            });
        }
    };

    const getAvailableProfessionals = async () => {
        setLoading(true);
        useSearchStore.setState({ availableProfessionals: [] });
        const selectedTreatment = treatments[selectedLocation][Object.keys(selectedTreatments)[0]];

        if (!selectedTreatment) {
            setLoading(false);
            return;
        }

        const response = await fetchAvailableProfessionals(
            selectedLocation,
            selectedTreatment.specialty,
            selectedTreatment.key[0].text,
            selectedHealthInsurance.replace('_', '-'),
            JourneyType.SEARCH_BOOK
        );

        if (!response?.data) {
            setLoading(false);
            return;
        }

        const {
            [i18n.language]: { content: findAppointmentPrismicData },
        } = usePersistedPrismicStore.getState().findAppointmentData;
        setAppointmentSearch({
            location_key: selectedLocation,
            professional_type_key: selectedTreatment.specialty,
            insurance_type_key: selectedHealthInsurance,
            professional_name: null,
            is_for_self: true,
            is_with_prescription: false,
            is_haveImg: false,
            symptoms: [],
            other_symptom: '',
            map_title: findAppointmentPrismicData[selectedLocation]
                ? findAppointmentPrismicData[selectedLocation].title
                : 'Online',
            location_title: findAppointmentPrismicData[selectedLocation]
                ? findAppointmentPrismicData[selectedLocation].eterno_map_title
                : 'Online',
            journey_type: JourneyType.SEARCH_BOOK,
        });

        const professionalsArray = arrayShuffle(
            response.data
                .filter((professional) => professionals[professional.key])
                .map((professional) => ({
                    ...professional,
                    ...professionals[professional.key],
                    specialityType: allDocuments[professionals[professional.key].type.id],
                }))
        );

        useSearchStore.setState({ availableProfessionals: professionalsArray });
        setLoading(false);
    };

    useLayoutEffect(() => {
        if (!showAvailableProfessionals || !selectedHealthInsurance) return;
        getAvailableProfessionals();
    }, [showAvailableProfessionals, selectedHealthInsurance, selectedLocation]);

    const filterCategories = (categories, predicate) => {
        return Object.entries(categories).reduce((categoriesAccumulator, [categoryKey, treatmentObj]) => {
            const availableTreatments = Object.entries(treatmentObj).reduce(
                (treatmentsAccumulator, [treatmentKey, treatment]) => {
                    if (predicate(treatment)) {
                        // eslint-disable-next-line no-param-reassign
                        treatmentsAccumulator[treatmentKey] = treatment;
                    }
                    return treatmentsAccumulator;
                },
                {}
            );

            if (Object.keys(availableTreatments).length > 0) {
                // eslint-disable-next-line no-param-reassign
                categoriesAccumulator[categoryKey] = availableTreatments;
            }
            return categoriesAccumulator;
        }, {});
    };

    const searchTagMatch = (searchTagsField, formattedSearch) => {
        if (!searchTagsField?.[0]?.text) return false;

        const tagsArray = searchTagsField[0].text
            .toLowerCase()
            .split(',')
            .map((tag) => tag.trim())
            .filter(Boolean);

        return tagsArray.some((tag) => tag.includes(formattedSearch));
    };

    useEffect(() => {
        if (!selectedLocation) return;
        if (!search || search.length < 3) {
            setFilteredProfessionals([]);
            setFilteredProfessionalTypes([]);
            setFilteredCategories(null);

            if (currentCustomer?.is_accordion_view_active && currentCustomer?.is_accordion_for_multiple_professionals) {
                setFilteredTreatments(
                    Object.values(treatments[selectedLocation]).filter((treatment) =>
                        isTreatmentAvailableForSelectedInsurance(treatment)
                    )
                );
            } else {
                setFilteredTreatments([]);
            }

            if (currentCustomer && currentCustomer.is_category_view_active && currentCustomer.categories) {
                const newFilteredCategories = filterCategories(
                    currentCustomer.categories,
                    isTreatmentAvailableForSelectedInsurance
                );

                setFilteredCategories(newFilteredCategories);
            }

            return;
        }

        const formattedSearch = search.toLowerCase().trim();

        setFilteredProfessionals(
            Object.values(professionals).filter(
                (professional) =>
                    allDocuments[professional.location_hub.id]?.key[0].text === selectedLocation &&
                    (professional.display_name[0].text.toLowerCase().includes(formattedSearch) ||
                        searchTagMatch(professional.search_tags, formattedSearch))
            )
        );
        setFilteredProfessionalTypes(
            Object.values(locations[selectedLocation]?.professional_types).filter(
                (professionalType) =>
                    professionalType.name[0].text.toLowerCase().includes(formattedSearch) ||
                    searchTagMatch(professionalType.search_tags, formattedSearch)
            )
        );

        const treatmentArray = Object.values(
            selectedSpecialty
                ? (locations[selectedLocation]?.professional_types[selectedSpecialty]?.services_or_treatments.map(
                      (entry) => ({
                          ...entry,
                          ...entry.service_or_treatment,
                      })
                  ) ?? {})
                : (treatments[selectedLocation] ?? {})
        );

        setFilteredTreatments(
            treatmentArray.filter(
                (treatment) =>
                    (selectedSpecialty ||
                        treatment.name[0].text.toLowerCase().includes(formattedSearch) ||
                        searchTagMatch(treatment.search_tags, formattedSearch)) &&
                    isTreatmentAvailableForSelectedInsurance(treatment)
            )
        );

        if (!currentCustomer?.categories || !currentCustomer?.is_category_view_active) return;

        const newFilteredCategories = filterCategories(
            currentCustomer.categories,
            (treatment) =>
                (treatment.name[0].text.toLowerCase().includes(formattedSearch) ||
                    searchTagMatch(treatment.search_tags, formattedSearch)) &&
                isTreatmentAvailableForSelectedInsurance(treatment)
        );

        setFilteredCategories(newFilteredCategories);
    }, [search, selectedLocation, selectedHealthInsurance, treatments, selectedSpecialty]);

    const handleWheel = useEventCallback(
        (event) => {
            document.body.style.overflow =
                accordionContainerRef.current?.contains(event.target) || showBookingModal ? 'hidden' : 'unset';
        },
        [showBookingModal]
    );

    useEffect(() => {
        window.addEventListener('wheel', handleWheel);

        return () => {
            window.removeEventListener('wheel', handleWheel);
            document.body.style.overflow = 'unset';
        };
    }, []);

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <EternoSpinner />
            </div>
        );
    }

    if (showAvailableProfessionals) {
        return (
            <div className={classes.availableProfessionalsContainer}>
                <div
                    className={classes.goBackContainer}
                    onClick={() => useSearchStore.setState({ showAvailableProfessionals: false })}
                >
                    <img className={classes.goBackIcon} src={downArrow ?? ''} alt="Go Back" />
                    <Typography className={classes.shortText}>
                        {professionalProfilePrismicData?.go_back_cta[0].text ?? 'Go Back'}
                    </Typography>
                </div>
                {availableProfessionals.map((availableProfessional) => (
                    <AnotherSectionImg
                        dataSet={dataSet}
                        mainData={mainData}
                        doctor={availableProfessional}
                        queryStage={1}
                        appointmentSearch={appointmentSearch}
                        setAppointmentSearch={setAppointmentSearch}
                    />
                ))}
                {availableProfessionals.length === 0 && <RenderEmptyBook mainData={mainData} />}
            </div>
        );
    }

    const renderSelectionBar = () => {
        return (
            Object.values(selectedTreatments).some((value) => value) && (
                <div className={classes.selectionBar}>
                    <div className={classes.selectionsWrapper}>
                        <p className={classes.selections}>
                            {isOnlineAppointment && 'Online '}
                            {`${Object.entries(selectedTreatments)
                                .filter((selectedTreatment) => selectedTreatment[1])
                                .map(
                                    (selectedTreatment) =>
                                        treatments[selectedLocation]?.[selectedTreatment[0]].name[0].text
                                )
                                .join(', ')}`}
                        </p>
                        {currentCustomer?.is_multi_treatment_booking_allowed && (
                            <p>{`(${
                                Object.entries(selectedTreatments).filter((selectedTreatment) => selectedTreatment[1])
                                    .length
                            })`}</p>
                        )}
                    </div>
                    <FilledButton onPress={startBooking} text={prismicData.accordion_continue_cta[0].text} />
                </div>
            )
        );
    };

    if (currentCustomer?.is_accordion_view_active && !currentCustomer?.is_category_view_active) {
        if (!selectedLocation) return null;

        return (
            <div
                ref={accordionContainerRef}
                className={clsx(classes.container, classes.accordionContainer)}
                style={{
                    gap: 0,
                    padding: 0,
                }}
            >
                {filteredTreatments.length > 0 ? (
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(${getGridColumns()}, 1fr)`,
                            columnGap: '1rem',
                            rowGap: '1rem',
                            padding: '20px',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        {filteredTreatments.map((treatment) => (
                            <SearchResultCard
                                key={treatment.key[0].text}
                                prismicData={treatment}
                                searchResultType={SearchResultType.TREATMENT}
                            />
                        ))}
                    </div>
                ) : (
                    <Typography className={classes.noTreatmentsHeading}>
                        {mainData.no_treatments_found[0].text}
                    </Typography>
                )}

                {renderSelectionBar()}
            </div>
        );
    }

    if (currentCustomer?.is_accordion_view_active) {
        return (
            <div
                ref={accordionContainerRef}
                className={clsx(classes.container, classes.accordionContainer)}
                style={{
                    gap: 0,
                    padding: 0,
                }}
            >
                <div
                    style={{
                        padding: '20px',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    {Object.keys(filteredCategories || currentCustomer.categories).map((categoryKey, index) => {
                        return (
                            <div
                                className={classes.accordionWrapper}
                                key={categoryKey}
                                style={{
                                    marginBottom:
                                        Object.values(selectedTreatments).some((value) => value) &&
                                        Object.keys(filteredCategories || currentCustomer.categories).length ===
                                            index + 1
                                            ? '1rem'
                                            : 0,
                                }}
                            >
                                <div className={classes.line} />
                                <Accordion
                                    expanded={search.length > 2 || expandedCategories[categoryKey]}
                                    onChange={() =>
                                        setExpandedCategories({
                                            ...expandedCategories,
                                            [categoryKey]: !expandedCategories[categoryKey],
                                        })
                                    }
                                    className={classes.accordion}
                                    classes={{ root: classes.accordionRoot }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>
                                            {treatmentTypeCategories[categoryKey]?.name[0].text}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        classes={{
                                            root: classes.accordionDetailsRoot,
                                        }}
                                    >
                                        <div className={classes.resultCategoryContainer}>
                                            <div className={classes.resultCardsContainer}>
                                                {Object.values(
                                                    filteredCategories?.[categoryKey] ||
                                                        currentCustomer.categories[categoryKey]
                                                ).map((treatment) => (
                                                    <SearchResultCard
                                                        key={treatment.key[0].text}
                                                        cardKey={treatment.key[0].text}
                                                        prismicData={treatment}
                                                        searchResultType={SearchResultType.TREATMENT}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                {Object.keys(filteredCategories || currentCustomer.categories).length === index + 1 && (
                                    <div className={classes.line} />
                                )}
                            </div>
                        );
                    })}
                </div>
                {Object.keys(filteredCategories || currentCustomer.categories).length === 0 && (
                    <Typography className={classes.noTreatmentsHeading}>
                        {mainData.no_treatments_found[0].text}
                    </Typography>
                )}
                {renderSelectionBar()}
            </div>
        );
    }

    if (selectedProfessional) {
        return <BookWithProfessional />;
    }

    if (!search || filteredTreatments.length + filteredProfessionals.length + filteredProfessionalTypes.length === 0) {
        return null;
    }

    if (selectedSpecialty) {
        const noTreatments = filteredTreatments.length === 0;
        return (
            <div className={classes.container}>
                <div className={classes.resultCategoryContainer}>
                    <div className={classes.subTitleWrapper}>
                        <p className={classes.specialtyTitle}>{prismicData.treatment_section_title[0].text}</p>
                    </div>
                    <div
                        style={
                            noTreatments
                                ? { display: 'flex' }
                                : {
                                      display: 'grid',
                                      gridTemplateColumns: `repeat(${getGridColumns()}, 1fr)`,
                                      columnGap: '1rem',
                                      rowGap: '1rem',
                                  }
                        }
                    >
                        {!noTreatments ? (
                            filteredTreatments.map((treatment) => (
                                <SearchResultCard
                                    key={treatment.key[0].text}
                                    cardKey={treatment.key[0].text}
                                    prismicData={treatment}
                                    searchResultType={SearchResultType.TREATMENT}
                                />
                            ))
                        ) : (
                            <NoTreatmentsMessage
                                selectedHealthInsurance={selectedHealthInsurance}
                                publicText={prismicData.no_treatments_available_for_public_insurance[0].text}
                                privateText={prismicData.no_treatments_available_for_private_insurance[0].text}
                                selfPayText={prismicData.no_treatments_available_for_self_payer[0].text}
                                changeInsuranceText={prismicData.change_insurance_to_see_treatments[0].text}
                                availableInsurances={availableInsurances}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            {filteredProfessionalTypes.length > 0 && (
                <div className={classes.resultCategoryContainer}>
                    <div className={classes.subTitleWrapper}>
                        <p className={classes.resultSubTitle}>{prismicData.specialty_section_title[0].text}</p>
                    </div>
                    <div className={classes.resultCardsContainer}>
                        {filteredProfessionalTypes.map((professionalType) => (
                            <SearchResultCard
                                key={professionalType.key[0].text}
                                cardKey={professionalType.key[0].text}
                                prismicData={professionalType}
                                searchResultType={SearchResultType.SPECIALTY}
                            />
                        ))}
                    </div>
                </div>
            )}

            {filteredTreatments.length > 0 && (
                <div className={classes.resultCategoryContainer}>
                    <div className={classes.subTitleWrapper}>
                        <p className={classes.resultSubTitle}>{prismicData.treatment_section_title[0].text}</p>
                    </div>
                    <div className={classes.resultCardsContainer}>
                        {filteredTreatments.map((treatment) => (
                            <SearchResultCard
                                key={treatment.key[0].text}
                                cardKey={treatment.key[0].text}
                                prismicData={treatment}
                                searchResultType={SearchResultType.TREATMENT}
                            />
                        ))}
                    </div>
                </div>
            )}

            {filteredProfessionals.length > 0 && (
                <div className={classes.resultCategoryContainer}>
                    <div className={classes.subTitleWrapper}>
                        <p className={classes.resultSubTitle}>{prismicData.professional_section_title[0].text}</p>
                    </div>
                    <div className={classes.resultCardsContainer}>
                        {filteredProfessionals.map((professional) => (
                            <SearchResultCard
                                key={professional.key[0].text}
                                cardKey={professional.key[0].text}
                                prismicData={professional}
                                searchResultType={SearchResultType.PROFESSIONAL}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchResults;
