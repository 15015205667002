import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ClickAwayListener,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import ArrowDown from '@/assets/images/ArrowDown.png';
import IconHealthData from '@/assets/images/Side-Nav-Icon-2.svg';
import HealthMedicalRecordsIcon from '@/assets/images/Side-Nav-Icon-3.svg';
import HealthConsentIcon from '@/assets/images/Side-Nav-Icon-4.svg';
import OverviewIcon from '@/assets/images/Side-Nav-Icon.svg';

import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { Context as healthProfileOverviewContext } from '@/context/HealthProfileOverviewContext';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import { menuItems, useQuery } from '@/utils/helpers.js';

import { usePersistedPrismicStore } from '@/store.js';

import Icon from '../Icon/Icon';
import HealthMenuHealthDataItems from './HealthData/HealthMenu/HealthMenuHealthDataItems';
import { useStyles } from './HealthMenuStyles';

const HealthMenu = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const query = useQuery();
    const location = useLocation();
    console.debug('🚀 ~ file: HealthMenu.jsx ~ line 131 ~ HealthMenu ~ location', location.pathname);
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const {
        state: { step },
        saveChangesOption,
        setStep,
    } = useContext(HealthProfileContextMain);
    console.debug('🚀 ~ file: HealthMenu.jsx:46 ~ HealthMenu ~ step', step);

    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');

    const {
        state: { menuOpen },
        setMenuOpen,
    } = useContext(healthProfileOverviewContext);

    const {
        [i18n.language]: { content, dataArr },
    } = usePersistedPrismicStore((state) => state.healthProfileData);

    const {
        [i18n.language]: { content: uploadedByMeData },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const onPageChange = (index) => {
        saveChangesOption(false);
        console.debug('onPageChange1', history, index);
        /** setting selected item's step in context */
        setStep(index);
        history.push(`/health-data?step=${stepsPathname[index]}`);
    };

    const stepsMenu = useMemo(() => {
        if (dataArr && Object.keys(dataArr).length > 0) {
            return [
                dataArr.personal_information_title[0].text,
                dataArr.your_body_title[0].text,
                dataArr['pre-existing_conditions_title'][0].text,
                dataArr.allergies_title[0].text,
                dataArr.medications_title[0].text,
                dataArr.family_disease_history_title[0].text,
                dataArr.lifestyle_title[0].text,
            ];
        }
    }, [dataArr]);
    console.debug('🚀 ~ file: HealthMenu.jsx ~ line 213 ~ steps ~ steps', menuOpen);

    const stepsPathname = menuItems();

    // custom consent prismic data hook
    const [isData, setIsData] = useState(false);
    const { [i18n.language]: consentPrismicData } = usePersistedPrismicStore((state) => state.consentData);
    const { content: consentData } = consentPrismicData;

    useEffect(() => {
        if (consentData && Object.keys(consentData).length > 0) {
            setIsData(true);
        }
    }, [consentData]);

    const handleChange = (panel) => (event, isExpanded) => {
        console.debug('isExpanded1', isExpanded);
        setMenuOpen(isExpanded ? panel : false);
    };

    const props = {
        expanded: menuOpen === 'panel2',
        onChange: handleChange('panel2'),
    };

    const con = !matches ? { ...props } : {};

    const props2 = {
        expanded: menuOpen === 'panel3',
        onChange: handleChange('panel3'),
    };

    const con2 = !matches ? { ...props2 } : {};

    const props3 = {
        expanded: menuOpen === 'panel4',
        onChange: handleChange('panel4'),
    };
    const con3 = !matches ? { ...props3 } : {};

    const handleMedicalRecordsClick = () => {
        const documentsAddress = isDocumentExchangeFeatureFlagActivated ? '/shared-documents' : '/uploaded-by-me';
        const isPathMismatch = !location.pathname.includes(documentsAddress);

        if (!matches && isPathMismatch) {
            setTimeout(() => {
                history.push(targetPath);
            }, 100);
        }
    };

    return (
        <ConditionalWrapper
            condition={matches}
            wrapper={(children) => (
                <ClickAwayListener
                    onClickAway={() => {
                        if (matches && menuOpen) setMenuOpen(false);
                    }}
                >
                    <Accordion
                        className={classes.mainBack}
                        expanded={menuOpen === 'panel1'}
                        onChange={handleChange('panel1')}
                        style={{
                            borderRadius: '10px',
                            borderBottomLeftRadius: menuOpen ? 0 : '10px',
                            borderBottomRightRadius: menuOpen ? 0 : '10px',
                            boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
                            width: matchesXs && menuOpen ? '100%' : matches && !matchesXs ? '35%' : '',
                        }}
                    >
                        {children}
                    </Accordion>
                </ClickAwayListener>
            )}
        >
            {matches && (
                <AccordionSummary
                    disableRipple
                    disableTouchRipple
                    expandIcon={<img alt="arrow_down" src={ArrowDown} className={classes.icon} />}
                    aria-controls="panel2a-content"
                    id="panel2a-content"
                >
                    <Typography className={classes.xsHeading}>{content?.your_health_profile[0].text}</Typography>
                </AccordionSummary>
            )}
            <div
                className={!matches && classes.main}
                style={{
                    position: matches ? 'absolute' : null,
                    zIndex: 1,
                    width: matchesXs ? '100%' : null,
                    borderTopLeftRadius: matches && 0,
                    borderTopRightRadius: matches && 0,
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    boxShadow: matches ? 'rgb(120 120 120 / 15%) 0px 20px 40px' : null,
                }}
            >
                <Accordion className={clsx(classes.mainBack, classes.topMain)} onClick={() => setMenuOpen(false)}>
                    <Typography
                        onClick={() => {
                            if (matches) setMenuOpen(false);
                            history.push('/health-profile-overview');
                        }}
                        className={classes.heading}
                        // Highlight Color
                        classes={{
                            root: highlightMenuItem(location, classes, '/health-profile-overview'),
                        }}
                    >
                        <Icon src={OverviewIcon} width={24} height={24} />
                        {content?.health_overview_side_bar && content?.health_overview_side_bar[0].text}
                    </Typography>
                </Accordion>
                <Accordion {...con} className={classes.mainBack} style={{ padding: '10px' }}>
                    <AccordionSummary
                        disableRipple
                        expandIcon={<img alt="arrow_down" src={ArrowDown} className={classes.icon} />}
                        aria-controls="panel2a-content"
                        id="panel2a-content"
                        onClick={() =>
                            !matches &&
                            !location.pathname.includes('health-data') &&
                            setTimeout(() => onPageChange(0), 100)
                        }
                    >
                        <Typography
                            className={classes.heading}
                            // Highlight Color
                            classes={{
                                root: highlightMenuItemWithQuery(
                                    query,
                                    classes,
                                    stepsPathname?.find((path) => path === query.get('step'))
                                ),
                            }}
                        >
                            <Icon src={IconHealthData} width={24} height={24} />
                            {dataArr && Object.keys(dataArr).length > 0 && dataArr?.health_data[0].text}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails onClick={() => matches && menuOpen && setMenuOpen(false)}>
                        <div className={classes.menuItems}>
                            {stepsMenu &&
                                stepsMenu.map((item, index) => {
                                    return (
                                        <HealthMenuHealthDataItems
                                            setMenuOpen={setMenuOpen}
                                            index={index}
                                            item={item}
                                            highlightedMenu={highlightMenuItemWithQuery(
                                                query,
                                                classes,
                                                stepsPathname[index]
                                            )}
                                            onPress={() => onPageChange(index)}
                                        />
                                    );
                                })}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    {...con2}
                    className={classes.mainBack}
                    style={{
                        padding: '10px',
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        boxShadow: 'rgb(120 120 120 / 15%) 0px 20px 40px',
                    }}
                >
                    <AccordionSummary
                        disableRipple
                        expandIcon={<img alt="arrow_down" src={ArrowDown} className={classes.icon} />}
                        aria-controls="panel2a2-content"
                        id="panel2a2-content"
                        onClick={handleMedicalRecordsClick}
                    >
                        <Typography
                            className={classes.heading}
                            // Highlight Color
                            classes={{
                                root: highlightMenuItem(location, classes, '/uploaded-by-me'),
                            }}
                        >
                            <Icon src={HealthMedicalRecordsIcon} width={24} height={24} />{' '}
                            {content?.health_medical_records_side_bar &&
                                content?.health_medical_records_side_bar[0].text}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails onClick={() => matches && menuOpen && setMenuOpen(false)}>
                        <div className={classes.menuItems}>
                            <HealthMenuHealthDataItems
                                setMenuOpen={setMenuOpen}
                                onPress={() =>
                                    history.push(
                                        isDocumentExchangeFeatureFlagActivated ? '/shared-documents' : '/uploaded-by-me'
                                    )
                                }
                                item={
                                    isDocumentExchangeFeatureFlagActivated
                                        ? uploadedByMeData?.shared_documents?.[0]?.text
                                        : content?.upload_by_me_side_bar?.[0].text
                                }
                                highlightedMenu={highlightMenuItem(
                                    location,
                                    classes,
                                    isDocumentExchangeFeatureFlagActivated ? '/shared-documents' : '/uploaded-by-me'
                                )}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion {...con3} className={classes.mainBack} style={{ padding: '10px' }}>
                    <AccordionSummary
                        disableRipple
                        expandIcon={<img alt="arrow_down" src={ArrowDown} className={classes.icon} />}
                        aria-controls="panel2a2-content"
                        id="panel2a2-content"
                        onClick={() =>
                            !matches &&
                            !location.pathname.includes('consent') &&
                            setTimeout(() => history.push('consent-overview'), 100)
                        }
                    >
                        <Typography
                            className={classes.heading}
                            // Highlight Color
                            classes={{
                                root: highlightMenuItem(location, classes, '/consent'),
                            }}
                        >
                            <Icon src={HealthConsentIcon} height={24} width={24} />
                            {isData ? consentData?.overview_page_title[0].text : 'Consent Management'}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails onClick={() => matches && menuOpen && setMenuOpen(false)}>
                        <div className={classes.menuItems}>
                            <HealthMenuItem
                                setMenuOpen={setMenuOpen}
                                onClick={() => history.push('consent-overview')}
                                className={highlightMenuItem(location, classes, '/consent-overview')}
                            >
                                {isData ? consentData?.navigation_overview_subpage_title[0].text : 'Overview'}
                            </HealthMenuItem>
                            <HealthMenuItem
                                setMenuOpen={setMenuOpen}
                                onClick={() => history.push('consent-history')}
                                className={highlightMenuItem(location, classes, '/consent-history')}
                            >
                                {isData ? consentData?.overview_button_history[0].text : 'History'}
                            </HealthMenuItem>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </ConditionalWrapper>
    );
};

const HealthMenuItem = ({ onClick, children, className, setMenuOpen }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Button
            onClick={() => {
                onClick();
                if (matches) setMenuOpen(false);
            }}
            disableRipple
            classes={{
                root: classes.menuItemButton,
                label: clsx(classes.menuItemButtonText, className),
            }}
        >
            {children}
        </Button>
    );
};

// function to highlight current menu item
const highlightMenuItem = (location, classes, path) => {
    return location.pathname.includes(path) ? classes.colorTheme : null;
};

const highlightMenuItemWithQuery = (query, classes, path) => {
    const currStep = query.get('step');
    return currStep === path ? classes.colorTheme : null;
};

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export default memo(HealthMenu);
