/** React */
import { useContext } from 'react';

/** Context */
import { ConsentFormsContext } from '@/context/ConsentFormsContext';

/** Components */
import consentFormsStyles from '../ConsentFormsStyles';
import Heading from './Heading';
import Text from './Text';

const ContractDetails = ({ withHeadings = false, mainData = [], bottomHeading = '', data = '', logo = null }) => {
    const classes = consentFormsStyles();

    /** Context */
    const { consentFormsContent: content } = useContext(ConsentFormsContext);

    return (
        <div className={classes.mt20}>
            <div>
                {withHeadings ? (
                    mainData.map(({ type, text }, index) => {
                        if (type === 'heading')
                            return (
                                <Heading classes={index === 0 ? [classes.mt30, classes.mb30] : [classes.mb10]}>
                                    {text}
                                </Heading>
                            );
                        return <Text>{text}</Text>;
                    })
                ) : (
                    <Text style={{ whiteSpace: 'pre-wrap' }}>{data}</Text>
                )}
                {logo && <img src={logo} />}
            </div>

            {content ? <Heading classes={[classes.mb15]}>{bottomHeading}</Heading> : null}
        </div>
    );
};

export default ContractDetails;
