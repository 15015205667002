import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';

import { ContextProvider } from '@/context/ProjectContext.jsx';

import { useConfigStore } from '@/store.js';

import RenderEmptyAppointments from './RenderEmptyAppointments.jsx';
import UpcomingCard from './UpcomingCard.jsx';

const useStyles = makeStyles((theme) => ({
    cardContentClassDetail: {
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0 .2rem .8rem rgba(120, 120, 120, 0.21)',
        borderRadius: '.5rem',
        display: 'block',
        padding: '2rem',
        marginBottom: '1.3rem',
    },
    subHeading: {
        ...theme.typography.HL2,
        marginBottom: '1rem',
    },
    highlightedBorder: {
        border: `.125rem solid ${theme.palette.common.secondary}`,
    },
}));

const UpcomingAppointmnets = ({
    dataSet,
    mainData,
    dataSetDoctos,
    moveAppointmentToCancelled,
    showAllAppointments,
}) => {
    const classes = useStyles();
    const { activeResponseDataCon } = useContext(ContextProvider);
    const [two, setTwo] = useState(false);
    const [processedAppointments, setProcessedAppointments] = useState([]);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    useEffect(() => {
        if (!activeResponseDataCon) return;

        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;

        setProcessedAppointments(
            showAllAppointments
                ? activeResponseDataCon.active
                : activeResponseDataCon.active.filter((entry) => entry.customer_id === customer_id)
        );
    }, [activeResponseDataCon, showAllAppointments]);

    setTimeout(() => {
        setTwo(true);
    }, 400);

    console.debug('mainData: upcoming', activeResponseDataCon);
    return (
        <>
            <div>
                <Typography className={classes.subHeading} style={{}}>
                    {dataSet ? mainData.active_title[0].text : 'Upcoming Appointments'}
                </Typography>
            </div>

            {processedAppointments.length > 0
                ? processedAppointments.map(
                      (upComming) =>
                          dataSetDoctos &&
                          upComming?.doctor &&
                          upComming?.name && (
                              <div
                                  key={upComming.id}
                                  className={clsx(
                                      classes.cardContentClassDetail,
                                      currentCustomer.customer_id[0].text !== upComming.customer_id &&
                                          classes.highlightedBorder
                                  )}
                              >
                                  <UpcomingCard
                                      dataSet={dataSet}
                                      mainData={mainData}
                                      upComming={upComming}
                                      dataSetDoctos={dataSetDoctos}
                                      moveAppointmentToCancelled={moveAppointmentToCancelled}
                                  />
                              </div>
                          )
                  )
                : two && (
                      <div>
                          <RenderEmptyAppointments mainData={mainData} />
                      </div>
                  )}
        </>
    );
};

export default UpcomingAppointmnets;
