import { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { Context as AuthContext } from '@/context/AuthContext.js';
import { ContextProvider } from '@/context/ProjectContext.jsx';

import { getUserAppointments } from '@/service/Appointments/appointmentService.js';

export const useUserAllAppointments = () => {
    const [data, setData] = useState(null);

    const { setActiveResponseDataCon, setActiveResp } = useContext(ContextProvider);
    const {
        state: { usersession },
    } = useContext(AuthContext);

    const { data: appointmentsData, error } = useSWR(
        usersession ? { usersession: usersession, key: 'all-user-appointments' } : null,
        () => getUserAppointments(usersession),
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        if (!appointmentsData) return;

        setData(appointmentsData?.data?.data);
        setActiveResponseDataCon(appointmentsData?.data?.data);
        setActiveResp(appointmentsData?.data?.data?.active);
    }, [appointmentsData]);

    return {
        data,
        setData,
        error,
    };
};
