import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePersistedPrismicStore } from '../store';

export const useDisableResend = () => {
    const { i18n } = useTranslation();
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const resendButtonText = useMemo(
        () =>
            isResendDisabled
                ? content?.disabled_resend_button[0]?.text.replace('XX', countdown.toString())
                : content?.resend_otp[0]?.text,
        [isResendDisabled, countdown, content]
    );

    const disableResend = () => {
        setIsResendDisabled(true);
        setCountdown(60);
    };

    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);

            return () => clearInterval(timer);
        }

        setIsResendDisabled(false);
    }, [countdown]);

    return { isResendDisabled: isResendDisabled, disableResend: disableResend, resendButtonText: resendButtonText };
};
