function executeCallback(promise, callback) {
    if (callback) {
        promise.then(
            function (result) {
                callback(null, result);
            },
            function (error) {
                callback(error);
            }
        );
    }
}

export default executeCallback;
