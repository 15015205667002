import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import logo from '@/assets/logo.svg';

import { useLoggingStore } from '@/store.js';

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        backgroundColor: '#F9F8F4',
        fontFamily: 'MessinaSans-Regular',
        color: '#2e2e2e',
        padding: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '3rem',

        [theme.breakpoints.down('xs')]: {
            padding: '4rem 2rem',
        },
    },
    logo: {
        width: '15rem',
    },
    largeText: {
        margin: 0,
    },
    smallText: {
        margin: 0,
        lineHeight: '24px',
    },
    button: {
        height: '3rem',
        backgroundColor: theme.palette.common.yellow,
        borderRadius: '2em',
        width: '15rem',
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
}));

const ErrorFallback = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const largeText = useLoggingStore((state) => state.largeText);
    const smallText = useLoggingStore((state) => state.smallText);
    const ctaText = useLoggingStore((state) => state.ctaText);

    return (
        <div className={classes.pageContainer}>
            <img className={classes.logo} src={logo} alt="Eterno Logo" />
            <h1 className={classes.largeText}>{largeText || t('ErrorFallbackHeading')}</h1>
            <p className={classes.smallText}>{smallText || t('ErrorFallbackParagraph')}</p>
            <Button onClick={() => window.location.reload()} className={classes.button} variant="contained">
                {ctaText || t('ErrorFallbackCta')}
            </Button>
        </div>
    );
};

export default ErrorFallback;
