import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,

        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    mainHeading: {
        ...theme.typography.HL1,
    },
    heading: {
        ...theme.typography.HL2,
    },
    smallHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.5rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },

    subHeading: {
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        whiteSpace: 'pre-line',
        color: theme.palette.common.darkGrey,
    },
}));
