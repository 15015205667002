import { CircularProgress, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, rgbToHex, useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ACCOUNT_eterno_account from '@/assets/Icons/ACCOUNT_eterno_account.svg';
import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';

import DialogBox from '@/components/Common/DialogBox';
import FilledButton from '@/components/FilledButton';
import Icon from '@/components/Icon/Icon';
import OutlinedButton from '@/components/OutlinedButton';
import countryCode from '@/components/PhoneInput/countryCode.json';
import UnderlinedButton from '@/components/UnderlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';

import { processAPIWithHeaders } from '@/utils/helpers.js';

const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        contentContainer: {
            maxWidth: theme.layout.contentWidth,

            margin: '0 auto',
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 20,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 30,
            },
        },
        heading: {
            ...theme.typography.HL1,
        },
        cardContent: {
            backgroundColor: 'white',

            maxWidth: theme.layout.contentWidth,
            boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
            borderRadius: 8,
            display: 'block',

            marginTop: 23,
        },
        avatar: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            objectFit: 'cover',
            maxHeight: 400,
            backgroundColor: 'red',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        editAvatar: {
            width: 200,
            height: 200,
            borderRadius: 100,
            objectFit: 'cover',
            // maxHeight: 400,
            // minHeight: 250,
            marginTop: 10,
        },
        accountData: {
            // paddingLeft: 38,
            // paddingRight: 38,
            paddingTop: 32,
            paddingBottom: 32,
        },
        icon: {
            width: 50,
            height: 50,
            objectFit: 'contain',
        },
        subHeading: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.75rem',
            textTransform: 'none',

            color: theme.palette.common.brown,
            marginTop: 20,
        },
        userData: {
            fontFamily: 'MessinaSerif-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        downArrow: {
            height: '0.75em',
            marginRight: 15,
        },
        selectedOptions: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        editLinks: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.darkGrey,
            marginTop: 25,
        },
        deleteLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
            marginLeft: 20,
        },
        deleteAccountLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
        },
        editButton: {
            background: 'transparent',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        buttonLabel: {
            padding: 0,
            justifyContent: 'left',
        },
        cardbg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'rgba(255,200,119,0.2)',
            maxHeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        imagebg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            maxHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        card: {
            width: '80%',
            height: '85%',
            maxWidth: 400,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            backgroundColor: theme.palette.common.cream,

            maxHeight: 400,
            [theme.breakpoints.down('sm')]: {
                width: '70%',
                height: '85%',
            },

            [theme.breakpoints.down('xs')]: {
                width: '70%',
                height: '85%',
            },
        },
        visa: {
            width: 53,
            height: 30,
            objectFit: 'contain',
            marginTop: 30,
            marginLeft: 30,
            marginBottom: 20,
        },
        textFields: {
            height: '3.75em',
            marginTop: 10,
        },

        inputLabel: {
            '&.focused': {
                color: theme.palette.common.darkGrey,
            },
            '&.shrink': {
                fontSize: '1rem',
            },
        },
        submitButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.yellow,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.yellow,
            },
        },
        cancelButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.mediumBrown,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.mediumBrown,
            },
        },
        buttonText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        menuItem: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            color: theme.palette.common.darkGrey,
            textTransform: 'none',
        },
        formControl: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',
            // marginBottom: 15,
            justifyContent: 'space-between',
            // [theme.breakpoints.down("md")]: {
            //   width: 150,
            // },
            // [theme.breakpoints.down("sm")]: {
            //   width: 100,
            // },
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        formControlMobileBtn: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',

            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,

            borderRightWidth: 0,

            marginBottom: 15,
            justifyContent: 'space-between',
            // [theme.breakpoints.down("md")]: {
            //   width: 150,
            // },
            // [theme.breakpoints.down("sm")]: {
            //   width: 100,
            // },
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        accountIcon: {
            width: 250,
            height: 250,
            objectFit: 'contain',
            backgroundColor: 'rgba(255,200,119,0.2)',
            padding: 10,
            borderRadius: 135,

            [theme.breakpoints.down('xs')]: {
                width: 150,
                height: 150,
            },
        },
        butnMargin: {},
        fieldFocused: {
            backgroundColor: 'white',
            border: `2px solid ${theme.palette.common.yellow} !important`,
        },
        filledInputRoot: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
                // backgroundColor: "red",
            },
        },
        filledInputRootMobile: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
                // backgroundColor: "red",
            },
        },
        btnClass: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },

        blackCircle: {
            backgroundColor: theme.palette.common.darkGrey,
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        greenCircle: {
            backgroundColor: '#5ba668',
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        passwordText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        passwordValidText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.brown,
        },

        errorText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.red,
        },
        font: {
            fontSize: '1.5rem',
            backgroundColor: 'red',
            height: '0',
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
});

const AntSwitch = withStyles((theme) => ({
    // root: {
    //   width: 28,
    //   height: 18,
    //   padding: 0,
    //   display: "flex",
    // },

    // switchBase: {
    //   padding: 3,
    //   color: theme.palette.common.yellow,
    //   "&$checked": {
    //     transform: "translateX(12px)",
    //     color: theme.palette.common.white,
    //     "& + $track": {
    //       opacity: 1,
    //       backgroundColor: theme.palette.common.yellow,
    //       borderColor: theme.palette.common.yellow,
    //     },
    //   },
    // },
    // thumb: {
    //   width: 12,
    //   height: 12,
    //   boxShadow: "none",
    // },
    // track: {
    //   border: `1px solid ${theme.palette.common.yellow}`,
    //   borderRadius: 18 / 2,
    //   opacity: 1,
    //   backgroundColor: theme.palette.common.white,
    //   height: 16,
    // },
    // checked: {},
    root: {
        width: 34,
        height: 20,
        padding: 0,
        display: 'flex',
    },

    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&:hover + $track': {
            backgroundColor: '#898989',
            borderColor: '#898989',
        },
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.common.yellow,
                borderColor: theme.palette.common.yellow,
            },
            '&:hover + $track': {
                backgroundColor: '#CCA05F',
                borderColor: '#CCA05F',
            },
        },
    },
    thumb: {
        width: 15,
        height: 15,
        boxShadow: 'none',
        // "&$disabled": {
        //     width: 15,
        //     height: 15,
        //   },
    },
    track: {
        border: `1px solid ${theme.palette.common.brown}`,
        borderRadius: 18 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.brown,
        height: 16,
    },
    checked: {},
}))(Switch);

const EternoAccount = ({ dataSet, mainData }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesxs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesmd = useMediaQuery(theme.breakpoints.down('md'));
    const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesxl = useMediaQuery(theme.breakpoints.down('xl'));

    const [accountEdit, setAccountEdit] = useState(false);

    const [openChangeDialogAccount, setOpenChangeDialogAccount] = useState(false);
    const [loader, setLoader] = useState(false);

    const {
        state: { user, usersession, userAccount },
        setuser,
        setuseraccounts,
    } = useContext(AuthContext);

    const [emailChecked, setEmailChecked] = useState(userAccount.notifications.is_email_active);
    const [smsChecked, setSMSChecked] = useState(userAccount.notifications.is_sms_active);
    const [mobileNumber, setMobileNumber] = useState('');
    const [mobileCode, setMobileCode] = useState('+49');
    const [anchor, setAnchor] = useState(null);
    const messageRef = useRef();

    useEffect(() => {
        if (user.phone?.number) {
            const numberParts = user.phone.number.split(' ');
            if (numberParts.length >= 2) {
                setMobileNumber(numberParts[1]);
                setMobileCode(numberParts[0]);
                return;
            }

            setMobileNumber(numberParts[0]);
            setMobileCode('+49');
        }
    }, []);

    const [state, setState] = useState({
        analytics: userAccount.use_of_analytics_status === 'ACCEPTED',
        notifications: userAccount.subscriptions.default,
    });

    useEffect(() => {
        messageRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    });

    const updateUserAccount = async () => {
        const updateAccount = await processAPIWithHeaders(`secure/user/accounts/${userAccount.user_id}`, 'POST', {
            body: {
                user_id: userAccount.user_id,
                privacy_policy_status: userAccount.privacy_policy_status,
                terms_and_conditions_status: userAccount.terms_and_conditions_status,
                use_of_analytics_status: state.analytics ? 'ACCEPTED' : 'DECLINED',
                verification_status: userAccount.verification_status,
                locale: {
                    language: userAccount.locale.language,
                    country: userAccount.locale.country,
                },
                status: userAccount.status,
                notifications: {
                    is_email_active: emailChecked,
                    is_sms_active: smsChecked,
                },
                subscriptions: {
                    default: state.notifications,
                },
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: usersession.idToken.jwtToken,
            },
        });
        console.debug(updateAccount);

        if (user.phone?.number !== mobileCode.concat(` ${mobileNumber}`)) {
            const updatedUser = await processAPIWithHeaders(`secure/users/${user.id}`, 'POST', {
                body: {
                    ...user,
                    phone: {
                        number: mobileCode.concat(` ${mobileNumber}`),
                    },
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: usersession.idToken.jwtToken,
                },
            });

            setuser(updatedUser);
        }

        setuseraccounts(updateAccount);
        setAccountEdit(false);
    };
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleClosePhone = (mobileCode = '') => {
        setAnchor(null);
        if (mobileCode !== '') setMobileCode(mobileCode);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <div
                    className={classes.accountData}
                    style={{
                        paddingRight: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                        paddingLeft: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                    }}
                >
                    <Icon src={ACCOUNT_eterno_account} description="eterno account" />
                    <Typography className={classes.subHeading}>
                        {dataSet ? mainData.eterno_account[0].text : t('ETERNO ACCOUNT')}
                    </Typography>

                    {accountEdit && (
                        <div style={{ marginTop: 0 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <AntSwitch
                                    checked={state.notifications}
                                    onChange={handleChange}
                                    name="notifications"
                                    className={classes.userData}
                                />
                                <Typography className={classes.userData} style={{ marginLeft: 10, marginTop: 7 }}>
                                    {dataSet
                                        ? state.notifications
                                            ? mainData.notifications_activated[0].text
                                            : mainData.activate_notification[0].text
                                        : 'ActivateNotification'}
                                </Typography>
                            </div>

                            {state.notifications && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginTop: 20,
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <Link
                    href="#"
                    onClick={() => {
                      setEmailChecked(!emailChecked);
                    }}
                  >
                    <img
                      src={emailChecked ? check : uncheck}
                      alt="icon"
                      className={classes.check}
                    />
                  </Link> */}
                                    <AntSwitch
                                        checked={emailChecked}
                                        onChange={() => {
                                            setEmailChecked(!emailChecked);
                                        }}
                                        name="Email"
                                        className={classes.userData}
                                    />
                                    <Typography
                                        className={classes.userData}
                                        style={{
                                            marginLeft: 10,
                                            marginTop: -5,
                                        }}
                                    >
                                        {dataSet ? mainData.notifications_type_email[0].text : t('Email')}
                                    </Typography>
                                </div>
                            )}

                            {state.notifications && emailChecked && (
                                <div
                                    style={{
                                        width: matches ? '100%' : '50%',
                                    }}
                                >
                                    <TextField
                                        id="notificationEmail"
                                        label={dataSet ? mainData.notifications_type_email[0].text : t('Email')}
                                        variant="filled"
                                        color="primary"
                                        value={user.email}
                                        disabled
                                        fullWidth
                                        classes={{
                                            root: classes.textFields,
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.inputLabel,
                                                focused: 'focused',
                                                shrink: 'shrink',
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.filledInputRoot,
                                                focused: classes.fieldFocused,
                                                shrink: 'shrink',
                                            },
                                        }}
                                    />
                                </div>
                            )}

                            {state.notifications && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginTop: 20,
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <Link
                    href="#"
                    onClick={() => {
                      setSMSChecked(!smsChecked);
                    }}
                  >
                    <img
                      src={smsChecked ? check : uncheck}
                      alt="icon"
                      className={classes.check}
                    />
                  </Link> */}
                                    <AntSwitch
                                        checked={smsChecked}
                                        onChange={() => {
                                            setSMSChecked(!smsChecked);
                                        }}
                                        name="SMS"
                                        className={classes.userData}
                                    />
                                    <Typography
                                        className={classes.userData}
                                        style={{
                                            marginLeft: 10,
                                            marginTop: -5,
                                        }}
                                    >
                                        {dataSet ? mainData.notifications_type_sms[0].text : t('SMS')}
                                    </Typography>
                                </div>
                            )}

                            {state.notifications && smsChecked && (
                                <div
                                    style={{
                                        width: matches ? '100%' : '50%',
                                    }}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Grid item md={2} sm={3} xs={3}>
                                            <Button
                                                // id="mobileCodeButton"
                                                style={{ marginTop: 15 }}
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={(e) => setAnchor(e.currentTarget)}
                                                disableRipple
                                                className={classes.formControlMobileBtn}
                                                classes={{
                                                    label: classes.menuLabel,
                                                }}
                                            >
                                                <div>
                                                    <Typography
                                                        style={{
                                                            fontFamily: 'MessinaSans-SemiBold',
                                                            fontSize: '0.9rem',
                                                            color: theme.palette.common.darkGrey,
                                                            textTransform: 'none',
                                                            textAlign: 'center',
                                                            // backgroundColor: "red"
                                                            // marginLeft:
                                                            //   mobileCode === "Female" ? 5 : mobileCode === "Other" ? -5 : -10,
                                                        }}
                                                    >
                                                        {mobileCode}
                                                    </Typography>
                                                </div>
                                                <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
                                            </Button>

                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchor}
                                                keepMounted
                                                open={Boolean(anchor)}
                                                onClose={() => {
                                                    handleClosePhone();
                                                }}
                                                PaperProps={{
                                                    style: {
                                                        width: 285,
                                                        backgroundColor: 'white',
                                                        marginTop: -1,
                                                        border: `2px solid ${theme.palette.common.yellow}`,
                                                        height: '40%',
                                                        // widht: "7%",
                                                    },
                                                }}
                                            >
                                                {countryCode.map((code) => (
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={() => handleClosePhone(code.dialCode)}
                                                        style={{
                                                            backgroundColor:
                                                                code.dialCode === mobileCode ? '#F9F8F4' : 'white',
                                                        }}
                                                        ref={code.dialCode === mobileCode ? messageRef : null}
                                                    >
                                                        <div
                                                            className=""
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <div
                                                                className=""
                                                                style={{
                                                                    paddingRight: 10,
                                                                }}
                                                            >
                                                                <img src={code.flag.toLowerCase()} />
                                                            </div>

                                                            {code.name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </Grid>
                                        <TextField
                                            id="mobileNumber"
                                            label={t('MobileNumber')}
                                            variant="filled"
                                            color="primary"
                                            type="number"
                                            value={mobileNumber}
                                            onChange={(event) => setMobileNumber(event.target.value)}
                                            // onBlur={() => validateForm("mobileNumber")}
                                            fullWidth
                                            style={{
                                                marginTop: 15,
                                                magrinBottom: 15,
                                                height: '3.75em',
                                            }}
                                            classes={classes.textFields}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.inputLabel,
                                                    focused: 'focused',
                                                    shrink: 'shrink',
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.filledInputRootMobile,
                                                    focused: classes.fieldFocused,
                                                    shrink: 'shrink',
                                                },
                                            }}
                                        />
                                        {/* </Grid> */}
                                    </Grid>
                                </div>
                            )}

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: 20,
                                }}
                            >
                                <AntSwitch
                                    checked={state.analytics}
                                    onChange={handleChange}
                                    name="analytics"
                                    className={classes.userData}
                                />
                                <Typography className={classes.userData} style={{ marginLeft: 10, marginTop: 7 }}>
                                    {dataSet ? mainData.uoa_label[0].text : t('UseAnalytics')}:{' '}
                                </Typography>
                            </div>
                        </div>
                    )}

                    {!accountEdit && (
                        <Typography className={classes.userData}>
                            {dataSet ? mainData.uoa_label[0].text : t('UseAnalytics')}:{' '}
                            <span className={classes.selectedOptions}>
                                {state.analytics
                                    ? dataSet
                                        ? mainData.uoa_on_value[0].text
                                        : 'On'
                                    : dataSet
                                      ? mainData.uoa_off_value[0].text
                                      : 'Off'}
                            </span>
                        </Typography>
                    )}

                    {!accountEdit && (
                        <Typography className={classes.userData}>
                            {dataSet ? mainData.notifications_label[0].text : t('Notifications')}:{' '}
                            <span className={classes.selectedOptions}>
                                {state.notifications
                                    ? emailChecked && smsChecked
                                        ? 'SMS, Mail'
                                        : emailChecked && !smsChecked
                                          ? 'Mail'
                                          : !emailChecked && smsChecked
                                            ? 'SMS'
                                            : dataSet
                                              ? mainData.uoa_off_value[0].text
                                              : 'Off'
                                    : dataSet
                                      ? mainData.uoa_off_value[0].text
                                      : 'Off'}
                            </span>
                        </Typography>
                    )}

                    {!accountEdit && (
                        <UnderlinedButton
                            text={dataSet ? mainData.edit_cta[0].text : t('Edit')}
                            onPress={() => {
                                setAccountEdit(true);
                            }}
                            color={theme.palette.common.darkGrey}
                        />
                    )}
                </div>

                {accountEdit && (
                    <Grid
                        item
                        xs={12}
                        style={{
                            paddingLeft: 38,
                            paddingBottom: 32,
                            paddingRight: 38,
                        }}
                    >
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: matches ? 'column' : 'row',
                                        justifyContent: matches ? 'center' : 'space-between',
                                        alignItems: matches ? 'space-between' : 'center',
                                    }}
                                >
                                    {matchesxs ? (
                                        <div className="">
                                            <div style={{ marginBottom: 10 }}>
                                                <FilledButton
                                                    text={
                                                        dataSet
                                                            ? mainData.health_insurance_save_cta[0].text
                                                            : t('SaveChanges')
                                                    }
                                                    onPress={() => {
                                                        updateUserAccount();
                                                        setAccountEdit(false);
                                                    }}
                                                    color="yellow"
                                                    fullWidth
                                                />
                                            </div>
                                            <OutlinedButton
                                                text={dataSet ? mainData.account_cancel_cta[0].text : t('Cancel')}
                                                onPress={() => {
                                                    setOpenChangeDialogAccount(true);
                                                }}
                                                color="yellow"
                                                fullWidth
                                                disableRipple
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <OutlinedButton
                                                text={dataSet ? mainData.account_cancel_cta[0].text : t('Cancel')}
                                                onPress={() => {
                                                    setOpenChangeDialogAccount(true);
                                                }}
                                                color="yellow"
                                                disableRipple
                                            />
                                            <div style={{ marginLeft: 10 }}>
                                                <FilledButton
                                                    disabled={
                                                        loader ||
                                                        (smsChecked &&
                                                            !isValidPhoneNumber(mobileCode.concat(` ${mobileNumber}`)))
                                                    }
                                                    text={
                                                        dataSet && !loader ? (
                                                            mainData.health_insurance_save_cta[0].text
                                                        ) : !loader ? (
                                                            t('SaveChanges')
                                                        ) : (
                                                            <CircularProgress
                                                                style={{
                                                                    color: theme.palette.common.yellow,
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    onPress={() => {
                                                        setLoader(true);
                                                        updateUserAccount();
                                                    }}
                                                    color="yellow"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <UnderlinedButton
                                        text={dataSet ? mainData.account_delete_cta[0].text : t('DeleteAccount')}
                                        onPress={() => {}}
                                        color={theme.palette.common.darkGrey}
                                        underlineColor={theme.palette.common.red}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <DialogBox
                open={openChangeDialogAccount}
                onClose={() => {
                    console.debug('closing dialog');
                    setOpenChangeDialogAccount(false);
                }}
                onPress={() => {
                    setOpenChangeDialogAccount(false);
                    setAccountEdit(false);
                    setState({
                        analytics: userAccount.use_of_analytics_status === 'ACCEPTED',
                        notifications: userAccount.subscriptions.default,
                    });
                    setEmailChecked(userAccount.notifications.is_email_active);
                    setSMSChecked(userAccount.notifications.is_sms_active);
                }}
                title={dataSet ? mainData.sure_warning[0].text : 'Are you sure?'}
                text={dataSet ? mainData.changes_not_saved[0].text : 'The changes you have made will not be saved.'}
                cancelButton
                cancelText={dataSet ? mainData.cancel[0].text : 'Are you sure?'}
                mainButton
                mainButtonText={dataSet ? mainData.don_t_save[0].text : "Don't Save"}
                mainButtonColor="yellow"
            />
        </Grid>
    );
};

export default EternoAccount;
