import createDataContext from './createDataContext';

const consentManagerReducer = (state, action) => {
    switch (action.type) {
        case 'set_consent_data':
            return { ...state, consentData: action.payload };
        case 'set_table_type':
            return { ...state, tableType: action.payload };
        case 'set_documents':
            return { ...state, documents: action.payload };
        case 'set_show_btn':
            return { ...state, showBtn: action.payload };
        case 'set_main_documents':
            return { ...state, mainDocuments: action.payload };
        case 'set_search_documents':
            return { ...state, searchText: action.payload };
        case 'set_all_documents':
            return { ...state, allDocuments: action.payload };
        case 'set_sort_type':
            return { ...state, sortType: action.payload };
        case 'set_sort_doctor':
            return { ...state, sortDoctor: action.payload };
        case 'set_sort_date':
            return { ...state, sortDate: action.payload };
        case 'set_search_started':
            return { ...state, searchStarted: action.payload };
        default:
            return state;
    }
};

// Actions
const setConsentData = (dispatch) => (data) => {
    dispatch({
        type: 'set_consent_data',
        payload: data,
    });
};

const setTableType = (dispatch) => (data) => {
    dispatch({
        type: 'set_table_type',
        payload: data,
    });
};

const setDocuments = (dispatch) => (data) => {
    dispatch({
        type: 'set_documents',
        payload: data,
    });
};

const setShowBtn = (dispatch) => (data) => {
    dispatch({
        type: 'set_show_btn',
        payload: data,
    });
};

const setMainDocuments = (dispatch) => (data) => {
    dispatch({
        type: 'set_main_documents',
        payload: data,
    });
};

const setSearchText = (dispatch) => (data) => {
    dispatch({
        type: 'set_search_documents',
        payload: data,
    });
};

const setAllDocuments = (dispatch) => (data) => {
    dispatch({
        type: 'set_all_documents',
        payload: data,
    });
};

const setSortType = (dispatch) => (data) => {
    dispatch({
        type: 'set_sort_type',
        payload: data,
    });
};

const setSortDate = (dispatch) => (data) => {
    dispatch({
        type: 'set_sort_date',
        payload: data,
    });
};

const setSortDoctor = (dispatch) => (data) => {
    dispatch({
        type: 'set_sort_doctor',
        payload: data,
    });
};

export const showBtnStatus = {
    SHOW: 'show',
    HIDE: 'hide',
    SHOWED: 'showed',
};

const setSearchStarted = (dispatch) => (data) => {
    dispatch({
        type: 'set_search_started',
        payload: data,
    });
};

export const { Provider, Context } = createDataContext(
    // Reducer
    consentManagerReducer,
    {
        // Actions
        setConsentData: setConsentData,
        setTableType: setTableType,
        setDocuments: setDocuments,
        setShowBtn: setShowBtn,
        setMainDocuments: setMainDocuments,
        setAllDocuments: setAllDocuments,
        setSearchText: setSearchText,
        setSortType: setSortType,
        setSortDoctor: setSortDoctor,
        setSortDate: setSortDate,
        setSearchStarted: setSearchStarted,
    },
    {
        // Initial State
        consentData: null,
        tableType: 0,
        documents: null,
        showBtn: showBtnStatus.HIDE,
        mainDocuments: null,
        allDocuments: null,
        searchText: '',
        sortType: null,
        sortDoctor: null,
        sortDate: 'desc',
        searchStarted: false,
    }
);
