import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import arrow_up from '@/assets/Icons/ic_arrow_top.svg';

import { useSearchStore } from '@/store.js';

import PastAppointmentDetails from './PastAppointmentDetails';

const useStyles = makeStyles((theme) => ({
    summaryContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',

        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {},
    },
    accordionRoot: {
        borderRadius: 8,
        boxShadow: '0 .2rem .8rem rgba(120, 120, 120, 0.21)',
        backgroundColor: '#fff',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },
    accordionSummaryRoot: {
        padding: 0,
        paddingLeft: '2rem',
        marginRight: '2rem',
        minHeight: '4rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    appointmentType: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        width: '14rem',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '17rem',
        },
    },
    date: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        fontWeight: 'bolder',
        color: theme.palette.common.darkGrey,
        width: '7rem',
        textAlign: 'left',
    },
    by: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textAlign: 'left',
        width: '13rem',
        [theme.breakpoints.down('sm')]: {
            minWidth: '11rem',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 0,
        },
    },
    accordionDetailsRoot: {
        padding: 0,
        borderRadius: '0 0 .5rem .5rem',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
    },
    accordionDetailsContainer: {
        padding: '2rem',
    },
    separator: {
        width: '100%',
        height: '.07rem',
        background: '#d5d5d5',
    },
    cancelledClass: {
        display: 'flex',
        width: '20rem',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cancelledText: {
        color: theme.palette.common.red,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
    },
    icon: {
        width: '1rem',
        height: '1rem',
        objectFit: 'contain',
    },
}));

const PastAppointmentAccordion = ({ appointment, dataSet, mainData, dataSetDoctos }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matchessm = useMediaQuery(theme.breakpoints.down('sm'));
    const [expanded, setExpanded] = useState(false);
    const [twoSeconds, setTwoSeconds] = useState(false);

    const treatments = useSearchStore((state) => state.treatments);
    const languageMap = {
        en: 'en-us',
        de: 'de-de',
    };
    const lan = languageMap[i18n.language];

    setTimeout(() => {
        setTwoSeconds(true);
    }, 2000);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    const doctors_data =
        dataSetDoctos && twoSeconds && appointment.doctor !== undefined && appointment.doctor !== null
            ? appointment?.doctor[lan]
            : null;
    const doctor_location =
        dataSetDoctos && twoSeconds && appointment.doctor !== undefined && appointment.doctor !== null
            ? appointment?.location[lan]
            : null;

    const getDateWithMonth = (date) => {
        if (date !== null && date !== undefined) {
            const d = new Date(date);
            if (d && d !== 'Invalid Date') {
                const options = { day: 'numeric', month: 'long' };
                return new Intl.DateTimeFormat(lan, options).format(d);
            }
            return '';
        }
        return '';
    };

    return (
        <div>
            <Accordion classes={{ root: classes.accordionRoot }} expanded={expanded}>
                <AccordionSummary
                    onClick={handleChange}
                    expandIcon={<img alt="shrink accordion" src={arrow_up} className={classes.icon} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{
                        root: classes.accordionSummaryRoot,
                    }}
                >
                    {matchessm ? (
                        <div className={classes.summaryContainer}>
                            <div>
                                <Typography className={classes.appointmentType}>
                                    {dataSetDoctos && appointment.name}
                                </Typography>
                                {dataSetDoctos && appointment?.status === 'CANCELLED' && (
                                    <Typography className={classes.cancelledText}>
                                        {dataSet ? mainData?.cancelled_label1[0].text : 'Cancelled'}
                                    </Typography>
                                )}
                                <Typography className={classes.by}>
                                    {dataSetDoctos && doctors_data?.display_name[0].text}
                                </Typography>

                                <Typography className={classes.date}>
                                    {dataSetDoctos && lan === 'en-us'
                                        ? getDateWithMonth(appointment.start).split(' ').reverse().join(' ')
                                        : getDateWithMonth(appointment.start).replace('.', ' ')}
                                </Typography>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.summaryContainer}>
                            <div className={classes.cancelledClass}>
                                <Typography className={classes.appointmentType}>
                                    {dataSetDoctos &&
                                        (treatments[appointment.location_key]?.[appointment.data.services[0].key]
                                            ?.name[0].text ??
                                            appointment.name)}
                                </Typography>
                                {dataSetDoctos && appointment?.status === 'CANCELLED' && (
                                    <Typography className={classes.cancelledText}>
                                        {dataSet ? mainData?.cancelled_label1[0].text : 'Cancelled'}
                                    </Typography>
                                )}
                            </div>

                            <Typography className={classes.by}>
                                {dataSetDoctos && doctors_data?.display_name[0].text}
                            </Typography>
                            <Typography className={classes.date}>
                                {dataSetDoctos && lan === 'en-us'
                                    ? getDateWithMonth(appointment.start).split(' ').reverse().join(' ')
                                    : getDateWithMonth(appointment.start).replace('.', ' ')}
                            </Typography>
                        </div>
                    )}
                </AccordionSummary>
                <AccordionDetails
                    classes={{
                        root: classes.accordionDetailsRoot,
                    }}
                >
                    <div className={classes.separator} />
                    <div className={classes.accordionDetailsContainer}>
                        {expanded && (
                            <PastAppointmentDetails
                                appointment={appointment}
                                dataSet={dataSet}
                                mainData={mainData}
                                dataSetDoctos={dataSetDoctos}
                                doctorLocation={doctor_location}
                            />
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default PastAppointmentAccordion;
