import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';

const useStyles = makeStyles((theme) => ({
    tagBg: {
        background: '#F5F5F5',
        borderRadius: '5px',
        margin: '.4rem',
        '&:hover': {
            background: '#F5F5F5',
        },
    },
    tagText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '14px',
        textAlign: 'left',
        textTransform: 'none',
    },
}));

const Tag = ({ children, style }) => {
    const classes = useStyles();
    return (
        <Button
            variant="contained"
            disableElevation
            disableRipple
            classes={{ root: classes.tagBg, label: classes.tagText }}
            style={style}
        >
            {children?.substring(0, 40)}
            {children?.length > 40 && '...'}
        </Button>
    );
};

export default memo(Tag);
