import { isProcessedPrismicValues } from './helpers.js';

export const processPrismicDataForHeader = (headerPrismicData, type, application) => {
    const navMenuFunc = (mainNavMenuItemsObjs, results) => {
        const getMenuLink = (linkId) => {
            return results.find((result) => result.id === linkId)?.data;
        };

        const createSubMenu = (subMenuItemsObjs) => {
            return subMenuItemsObjs.map((subMenuItem) => {
                const subMenuLinkType = subMenuItem.external_link.id ? 'external' : 'internal';
                const subMenuLink = getMenuLink(subMenuItem[`${subMenuLinkType}_link`].id);
                return {
                    type: subMenuLinkType,
                    link: subMenuLink,
                };
            });
        };

        const mainNavMenu = mainNavMenuItemsObjs.map((menuItem) => {
            const linkType = menuItem.external_link.id ? 'external' : 'internal';
            const link = getMenuLink(menuItem[`${linkType}_link`].id);

            let subMenu = [];
            if (menuItem.sub_menu.id) {
                const subMenuArr = results.filter((result) => result.id === menuItem.sub_menu.id);
                const subMenuObj = subMenuArr.length > 0 ? subMenuArr[0] : null;
                if (subMenuObj) {
                    subMenu = createSubMenu(subMenuObj.data.items);
                }
            }

            return {
                type: linkType,
                link: link,
                subMenu: subMenu,
            };
        });

        return mainNavMenu;
    };

    let contentHeading = {};
    let mainNav = {};
    headerPrismicData.forEach((result) => {
        if (result.type === type && result.data.application === application) {
            contentHeading = result.data;
            const mainNavMenuItemsObjs = headerPrismicData.find(
                (results) => results.id === result.data.navigation_menu.id
            )?.data?.items;

            mainNav = navMenuFunc(mainNavMenuItemsObjs, headerPrismicData);
        }
    });

    return {
        contentHeading: contentHeading,
        mainNav: mainNav,
        dataSet: true,
    };
};
// for footer data
export const processPrismicDataForFooter = (footerPrismicData, type, application) => {
    const buildNavMenu = (result, menu) => {
        const navMenuItemsObjs = footerPrismicData.find((results) => results.id === result?.data[menu]?.id)?.data.items;
        const navMenu = navMenuItemsObjs.map((menuItem) => {
            const linkType = menuItem.external_link?.id !== undefined ? 'external' : 'internal';
            let link = null;

            if (linkType === 'external') {
                const externalLinkArr = footerPrismicData.find((results) => results.id === menuItem.external_link.id);
                link = externalLinkArr?.data;
            } else if (linkType === 'internal') {
                const internalLinkArr = footerPrismicData.find((results) => results.id === menuItem.internal_link.id);
                link = internalLinkArr?.data;
            }

            return {
                type: linkType,
                link: link,
            };
        });
        return navMenu;
    };
    let content = {};
    let aboutMenu = {};
    let legalMenu = {};

    footerPrismicData?.forEach((result) => {
        if (result.type === type && result.data.application === application) {
            // language data
            content = result.data;
            // About Menu
            aboutMenu = buildNavMenu(result, 'about_eterno');
            // Legal Menu
            legalMenu = buildNavMenu(result, 'legal');
        }
    });

    return { content: content, aboutMenu: aboutMenu, legalMenu: legalMenu, dataSet: true };
};

export const processPrismicFlattenData = (registerPrismic, type) => {
    const registrationData = registerPrismic.find((result) => result.type === type);

    return { benefits: isProcessedPrismicValues(registrationData.data, registerPrismic), dataSet: true };
};

export const processPrismicCommonData = (loginPrismicData, type, application) => {
    let content = {};
    loginPrismicData.forEach((result) => {
        if (result.type === type && result.data.application === application) content = result.data;
    });
    return { content: content, dataSet: true };
};

export const processPrismicDataForDashboard = (prismicData, type) => {
    const updatePrismicData = {};

    const findByType = (findFrom, typeValue) => {
        return prismicData.find((filter) => filter?.id === findFrom[typeValue]?.id);
    };
    let c = {};
    prismicData.forEach((result) => {
        if (result.type === type) {
            c = result.data;
            const { dashboard_navigation } = c;
            const iconFilter = [];
            const linkFilter = [];
            dashboard_navigation.forEach((idd) => {
                const filteredIcon = findByType(idd, 'navigation_icons');
                const filteredLink = findByType(idd, 'link');
                const updatedIcon = filteredIcon?.data;
                updatedIcon.link = filteredLink?.data;
                linkFilter.push(filteredLink?.data);
                iconFilter.push(updatedIcon);
            });
            c.icons = iconFilter;
            c.links = linkFilter;
        }
    });
    updatePrismicData.content = c;
    updatePrismicData.dataSet = true;
    return updatePrismicData;
};

export const processCategorizePrismicData = (prismicData, primaryDataType, secondaryDataType) => {
    let primaryData = {};
    const secondaryData = [];
    const otherData = [];

    // TODO: needs to be refactored
    prismicData?.forEach((result) => {
        if (result.type === primaryDataType) primaryData = result.data;
        else if (result.type === secondaryDataType) secondaryData.push(result.data);
        else otherData.push(result);
    });
    return {
        allAppointmentsPrismicData: otherData,
        content: primaryData,
        mainDataProfessionalDoctors: secondaryData,
        dataSet: true,
    };
};

const createPrismicProfessionalProfile = (prismicData, type) => {
    const professionalProfiles = [];

    prismicData.forEach((result) => {
        if (result.type === type) {
            const doctorData = { ...result.data }; // Use object spread to clone data
            const locationDoctorData = doctorData.location_hub.id;

            doctorData.locationData = prismicData.find((location) => location.id === locationDoctorData)?.data;

            if (doctorData.secondary_location_mappings.length > 0 && !doctorData.secondaryLocationDataSet) {
                const secondaryLocationMappings = doctorData.secondary_location_mappings
                    .map((location) => {
                        const mappingEntry = prismicData.find((entry) => entry.id === location.mapping?.id);
                        return mappingEntry?.data || null;
                    })
                    .filter(Boolean); // Use filter(Boolean) to remove null entries

                const updatedSecondaryLocationMappings = secondaryLocationMappings.map((location) => {
                    return {
                        professional_type:
                            prismicData.find((entry) => entry.id === location.professional_type.id)?.data || {},
                        secondary_locations: location.secondary_locations
                            .map((secondaryLocation) => {
                                const secondaryLocationEntry = prismicData.find(
                                    (entry) => entry.id === secondaryLocation.secondary_location.id
                                );
                                return secondaryLocationEntry?.data || null;
                            })
                            .filter(Boolean),
                    };
                });

                doctorData.secondary_location_mappings = updatedSecondaryLocationMappings;
                doctorData.secondaryLocationDataSet = true;
            }
            doctorData.servicesList = prismicData
                .filter((filter) => doctorData.services.some((service) => service.service.id === filter.id))
                ?.map((data) => data.data);

            doctorData.specialityType = prismicData.find((data) => data.id === result.data.type.id)?.data;
            professionalProfiles.push(doctorData);
        }
    });
    return professionalProfiles;
};

export const processPrismicDataForFindAnAppointment = (prismicData) => {
    let c = {};
    let ch = {};
    let bookingData = [];
    prismicData.forEach((result) => {
        if (result.type === 'hub' && result.tags.includes(import.meta.env.VITE_ENVIRONMENT)) {
            const data = {};
            const hubData = result.data;

            if (hubData.is_activated) {
                data.title = hubData.title[0].text;
                data.eterno_map_title = hubData.eterno_map_title[0].text;
                data.image_url = hubData.hub_image.url;
                data.mobile_image_url = hubData.hub_mobile_image.url;
                const professionalsArray = [];
                const onlineProfessionalsDict = {};
                hubData?.professional_types?.forEach((professional_hub_type) => {
                    if (!professional_hub_type.professional_type.tags.includes(import.meta.env.VITE_ENVIRONMENT)) {
                        return;
                    }
                    const isPrescription = professional_hub_type.prescription_or_transfer === 'Prescription';

                    const professionalTypeData = prismicData.find(
                        (entry) => entry.id === professional_hub_type.professional_type.id
                    )?.data;
                    const treatmentArray = [];
                    const onlineTreatments = [];
                    professionalTypeData?.services_or_treatments?.forEach((professional_service_type) => {
                        const isOnline = professional_service_type.online;
                        const serviceTypesArr = prismicData.find(
                            (entry) => entry.id === professional_service_type.service_or_treatment.id
                        );
                        if (serviceTypesArr && !serviceTypesArr?.tags?.includes(import.meta.env.VITE_ENVIRONMENT)) {
                            return;
                        }
                        const serviceTypeData = serviceTypesArr.data;
                        const symptomsArray = [];
                        serviceTypeData.symptoms.forEach((service_symptom) => {
                            const serviceSymptomsArr = prismicData.find((ss) => ss.id === service_symptom.symptom.id);
                            if (serviceSymptomsArr) {
                                const serviceSymptomsData = serviceSymptomsArr.data;
                                symptomsArray.push({
                                    key: serviceSymptomsData.key[0].text,
                                    logo: serviceSymptomsData.logo.url,
                                    name: serviceSymptomsData.name[0].text,
                                });
                            }
                        });
                        treatmentArray.push({
                            key: serviceTypeData.key[0].text,
                            logo: serviceTypeData.logo.url,
                            name: serviceTypeData.name[0].text,
                            shortName: serviceTypeData.short_name[0].text,
                            isLocation: professional_service_type.location,
                            isOnline: isOnline,
                            isPrescription: professional_service_type.prescription,
                            isPrivateInsurance: professional_service_type.private_insurance,
                            isPublicInsruance: professional_service_type.public_insurance,
                            isSelfPayer: professional_service_type.self_payer,
                            symptoms: symptomsArray,
                        });
                        if (isOnline) {
                            onlineTreatments.push({
                                key: serviceTypeData.key[0].text,
                                logo: serviceTypeData.logo.url,
                                name: serviceTypeData.name[0].text,
                                shortName: serviceTypeData.short_name[0].text,
                                isLocation: professional_service_type.location,
                                isOnline: isOnline,
                                isPrescription: professional_service_type.prescription,
                                isPrivateInsurance: professional_service_type.private_insurance,
                                isPublicInsruance: professional_service_type.public_insurance,
                                isSelfPayer: professional_service_type.self_payer,
                                symptoms: symptomsArray,
                            });
                        }
                    });
                    professionalsArray.push({
                        isPrescription: isPrescription,
                        key: professionalTypeData.key[0].text,
                        logo: professionalTypeData.logo.url,
                        name: professionalTypeData.name[0].text,
                        treatments: treatmentArray,
                    });
                    if (onlineTreatments.length > 0) {
                        onlineProfessionalsDict[professionalTypeData.key[0].text] = {
                            isPrescription: isPrescription,
                            key: professionalTypeData.key[0].text,
                            logo: professionalTypeData.logo.url,
                            name: professionalTypeData.name[0].text,
                            treatments: onlineTreatments,
                        };
                    }
                });
                data.professionals = professionalsArray;
                data.onlineProfessional = onlineProfessionalsDict;
                const contentLanguage = c;

                contentLanguage[result.data.key[0].text] = data;
                c = contentLanguage;
            }
        } else if (result.type === 'find_an_appointment') ch = [isProcessedPrismicValues(result.data)];
        else if (result.type === 'book_an_appointment') bookingData = result.data;
    });
    console.debug('🚀 ~ file: processPrismicDataHelpers.js:403 ~ prismicData.forEach ~ ch:', ch);

    return {
        content: c,
        contentHeading: ch,
        bookingHeading: bookingData,
        professionalProfiles: createPrismicProfessionalProfile(prismicData, 'professional_profile'),
        dataSet: true,
    };
};

export const processPrismicDataForAnamnesis = (prismicData) => {
    let content = {};
    let contentSummary = {};
    let contentAccount = {};
    let contentAccountEterno = {};
    let contentProfessional = {};
    let allData = {};
    const arr = [];

    prismicData.forEach((result) => {
        if (result.type === 'health_profile_questionnaire_public_pages') {
            content = result.data;
            allData = { 'en-us': content, 'de-de': content };
        }
        if (result.type === 'anamnesis_summary_page') contentSummary = result.data;
        if (result.type === 'registration') contentAccount = result.data;
        if (result.type === 'account_page_patients') {
            const { data } = result;
            // eslint-disable-next-line no-underscore-dangle
            data.privacy_policy_link_ = prismicData.filter((entry) => entry.id === entry.privacy_policy_link?.id);
            contentAccountEterno = data;
        }
        if (result.type === 'professional_profile') {
            const { data } = result;
            data.tags = result.tags;
            arr.push(data);
            contentProfessional = arr;
        }
    });

    return {
        mainData: content,
        mainDataSummary: contentSummary,
        mainDataAccount: contentAccount,
        mainDataAccountEterno: contentAccountEterno,
        contentProfessional: contentProfessional,
        allData: allData,
        dataSet: true,
        mainDataProfessionalDoctors: contentProfessional,
    };
};

export const processPrismicDataForDoctorDetails = (prismicData, type) => {
    const mainData = prismicData.find((result) => result.type === 'page_professionals')?.data;
    return { mainData: mainData, content: createPrismicProfessionalProfile(prismicData, type), dataSet: true };
};

export const processPrismicDataForHealthProfile = (prismicData) => {
    let content = {};
    let dataArr = {};

    prismicData.forEach((result) => {
        if (result.type === 'health_overview') content = result.data;
        if (result.type === 'health_profile_questionnaire_public_pages') dataArr = result.data;
    });
    return { content: content, dataArr: dataArr, dataSet: true };
};

export const processPrismicDataForBookAppointment = (prismicData) => {
    const mainData = prismicData.find((result) => result.type === 'book_an_appointment')?.data;
    mainData.booking_summary = prismicData.find((result) => result.type === 'page_appointment_booking_summary')?.data;
    return { mainData: mainData, dataSet: true };
};
