import DatefieldQuestion from '@/components/CustomAnamnesis/QuestionTypes/DatefieldQuestion.jsx';
import DropdownQuestion from '@/components/CustomAnamnesis/QuestionTypes/DropdownQuestion.jsx';
import ListQuestion from '@/components/CustomAnamnesis/QuestionTypes/ListQuestion.jsx';
import MultiselectQuestion from '@/components/CustomAnamnesis/QuestionTypes/MultiselectQuestion.jsx';
import NumberfieldQuestion from '@/components/CustomAnamnesis/QuestionTypes/NumberfieldQuestion.jsx';
import RadioQuestion from '@/components/CustomAnamnesis/QuestionTypes/RadioQuestion.jsx';
import TextareaQuestion from '@/components/CustomAnamnesis/QuestionTypes/TextareaQuestion.jsx';
import TextfieldQuestion from '@/components/CustomAnamnesis/QuestionTypes/TextfieldQuestion.jsx';

export const QuestionComponent = {
    Dropdown: DropdownQuestion,
    Textfield: TextfieldQuestion,
    'Text Area': TextareaQuestion,
    'Number Field': NumberfieldQuestion,
    'Date Field': DatefieldQuestion,
    Radio: RadioQuestion,
    'Multi Select': MultiselectQuestion,
    List: ListQuestion,
};
export const QuestionType = {
    DROPDOWN: 'Dropdown',
    TEXTFIELD: 'Textfield',
    TEXT_AREA: 'Text Area',
    NUMBER_FIELD: 'Number Field',
    DATE_FIELD: 'Date Field',
    RADIO: 'Radio',
    MULTI_SELECT: 'Multi Select',
    LIST: 'List',
};

export const BookingStep = {
    LOCATION_SELECTION: 'location selection',
    TIMESLOT_SELECTION: 'timeslot selection',
    REGISTRATION: 'registration',
    ADDRESS: 'address',
    VERIFICATION_CODE: 'verification code',
    CONFIRM_APPOINTMENT: 'confirm appointment',
    CONSENT: 'treatment consent',
    INVALID_TIMESLOT: 'invalid timeslot',
};
