import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import calendar from '@/assets/Illustrations/calendar.svg';
import linkOnPage from '@/assets/images/linkOnPage.png';

import CancelDialogBox from '@/components/Common/CancelDialogBox.jsx';
import EternoSpinner from '@/components/EternoLogoSpinner/EternoSpinner.jsx';
import FilledButton from '@/components/FilledButton.jsx';
import Icon from '@/components/Icon/Icon.jsx';
import MyAppointmentImageSection from '@/components/MyAppointmentImageSection.jsx';
import UnderlinedButton from '@/components/UnderlinedButton.jsx';
import UnderlinedButtonWithTooltip from '@/components/UnderlinedButtonWithTooltip.jsx';

import { Context as AuthContext } from '@/context/AuthContext.js';
import { ContextProvider } from '@/context/ProjectContext.jsx';

import usePollingData from '@/hooks/usePoolingData.js';
import { useUserAllAppointments } from '@/hooks/useUserAllAppointments.js';

import {
    calenderUpdated,
    cancelAppointmentForUser,
    getAppointment,
    getCalenderForUser,
} from '@/service/Appointments/appointmentService.js';

import { isTimeSlotInvalid } from '@/utils/availabilityUtils.js';
import { dayjs } from '@/utils/dayjsSetup.js';
import { AppointmentStatus, JourneyType, fetchAvailableProfessionals } from '@/utils/helpers.js';

import { usePersistedPrismicStore, useSearchStore } from '@/store.js';

import CalenderDialog from './Calender/CalenderDialog.jsx';

const useStyles = makeStyles((theme) => ({
    mainCardHeading: {
        ...theme.typography.HL5,
        fontFamily: 'MessinaSans-SemiBold',
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
    },
    lightText: {
        fontFamily: 'MessinaSans-Regular',
    },
    innerMainClass: {
        backgroundColor: theme.palette.common.lightBrown,
        margin: '1rem 1.3rem 1.3rem 0',
        padding: '1.1rem 1.3rem',
        borderRadius: '.6rem',
        textAlign: 'left',
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            marginRight: '.6rem',
            display: 'inherit',
        },
    },
    mainClassImg: {
        marginRight: '1.3rem',
        width: '4.3rem',
        height: '4.3rem',
        [theme.breakpoints.down('xs')]: {
            width: '2.5rem',
            height: '2.5rem',
            marginBottom: '.6rem',
        },
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
    },
    linkImg: {
        width: '1rem',
        height: '1rem',
        marginLeft: '.3rem',
    },
    cancelledClass: {
        display: 'flex',
        alignItems: 'center',
    },
    cancelled: {
        backgroundColor: theme.palette.common.red,
        color: theme.palette.common.white,
        marginLeft: '.6rem',
        padding: '.3rem .6rem',
        borderRadius: '.6rem',
        fontFamily: 'MessinaSans-Regular',
        fontSize: '.9rem',
    },
    disabled: {
        opacity: 0.6,
    },
    pending: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    iconContainer: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            marginRight: '.6rem',
            display: 'inherit',
        },
    },
}));

const MyAppointmentDetailsCard = ({ dataSet, mainData, upComming, dataSetDoctos, moveAppointmentToCancelled }) => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [open, setOpen] = useState(false);
    const [openReschedule, setOpenReschedule] = useState(false);
    const [calender, setCalender] = useState(false);
    const [startDate] = useState(new Date());
    const [activeResponseData, setActiveResponseData] = useState(null);
    const [updatedCalendar, setUpdatedCalendar] = useState(null);
    const [localLoading, setLocalLoading] = useState(false);
    const [localCancelLoading, setLocalCancelLoading] = useState(false);
    const [dcCalendarId, setDcCalendarId] = useState(null);
    const [showInvalidTimeslotMessage, setShowInvalidTimeslotMessage] = useState(false);
    const {
        [i18n.language]: { mainData: bookAppointmentPrismicData },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);
    const sub_loy_key = dataSetDoctos ? upComming.sub_loc_key : null;

    const treatments = useSearchStore((state) => state.treatments);

    const languageMap = {
        en: 'en-us',
        de: 'de-de',
    };

    const {
        dateSlot,
        setCalenderCanceldData,
        setCalenderCanceldDataActive,
        updateActiveData,
        setCancelData,
        setActiveResponseDataCon,
        setMyAppointmentsData,
        setMyAppointmentsDataStatus,
    } = useContext(ContextProvider);
    const { data: updatedAppointments } = useUserAllAppointments();
    const lan = languageMap[i18n.language];
    const doctors_data = upComming?.doctor[lan];
    const doctor_location = upComming?.location[lan];

    const {
        state: { usersession },
    } = useContext(AuthContext);

    const prepareCalendarData = async () => {
        const user_id = upComming?.professional?.id;
        const insurance_type_key = upComming.data.insurance_coverage_type ?? 'public';
        const is_returning = upComming?.data?.is_returning ?? false;
        const is_online = upComming?.is_online;
        const appointment_type_keys = upComming?.data?.services;

        let treatmentsString = '';
        if (dataSetDoctos) {
            treatmentsString = appointment_type_keys.map((appointmentType) => appointmentType.key).join(',');
        }

        const formattedDate = dayjs(startDate).format('YYYY-MM-DD');

        let retrievedDcCalendarId = null;
        if (!dcCalendarId) {
            const response = await fetchAvailableProfessionals(
                upComming?.location_key,
                upComming?.professional.type_key,
                treatmentsString,
                insurance_type_key === 'selfpayer' ? 'self-payer' : insurance_type_key,
                JourneyType.PROFESSIONAL_DETAILS,
                upComming?.customer_id
            );

            if (!response?.data) {
                return;
            }

            const professional = response.data.find((prof) => prof.key === upComming?.professional?.key);

            if (!professional) {
                return;
            }

            retrievedDcCalendarId = professional.dc_calendar_ids?.[0];
            setDcCalendarId(professional.dc_calendar_ids?.[0]);
        }

        getCalenderForUser(
            user_id,
            insurance_type_key,
            is_returning,
            is_online,
            formattedDate,
            treatmentsString,
            sub_loy_key,
            dcCalendarId ?? retrievedDcCalendarId,
            upComming?.customer_id
        ).then((response) => {
            const resp = response;
            setActiveResponseData(resp);
            console.debug('active DAta for reshedule', resp);
        });
    };

    const fetchUpdatedAppointments = () => {
        if (!updatedAppointments) {
            console.debug('No updated appointments data available');
            return;
        }

        setActiveResponseDataCon(updatedAppointments);
    };

    const getDateWithMonth = (date) => {
        const d = new Date(date);
        // an application may want to use UTC and make that visible
        return new Intl.DateTimeFormat(lan, {
            dateStyle: 'full',
            timeStyle: 'short',
            hour12: false,
        }).format(d);
    };

    let res = [];
    let resp = [];
    const updateCalenderData = () => {
        const appointment_id = upComming.id;
        const { user_id } = upComming;
        const patient_id = upComming.user_id;
        const { is_for_self } = upComming.data;
        const { is_returning } = upComming.data;
        const is_insurance_coverage_public = upComming.data.insurance_coverage_type
            ? upComming.data.insurance_coverage_type === 'public'
            : false;
        const is_insurance_coverage_private = upComming.data.insurance_coverage_type
            ? upComming.data.insurance_coverage_type === 'private'
            : false;
        const is_public_insurance = dataSetDoctos ? is_insurance_coverage_public : false;
        const is_private_insurance = dataSetDoctos ? is_insurance_coverage_private : false;
        const document = upComming.data.documents;
        const symptom = upComming.data.symptoms;
        const additional_info = upComming.data.info;
        // professional
        const professional_id = upComming.professional.id;
        const professional_key = upComming.professional.key;
        const professional_type_key = upComming.professional.type_key;
        const { location_key } = upComming;
        // appointment
        const services_data = upComming.data.services;
        let treatmentsString = '';
        let treatmentsStringName = '';
        if (dataSetDoctos) {
            treatmentsString = services_data.map((t) => t.key).join(',');
            treatmentsStringName = services_data.map((t) => t.name).join(',');
            console.debug({ treatmentsString, treatmentsStringName });
        }

        const services = treatmentsStringName;
        const type_keys = treatmentsString;
        const durationValue = upComming.duration.value;
        const durationUnits = upComming.duration.units;
        // meta
        const type_id = upComming.links.schedule_type_id;
        const calender_id = upComming.links.calendar_id;
        const user_id_p = upComming.links.patient_id;
        const is_at_location = !upComming?.is_online;
        const { is_online } = upComming;
        setLocalLoading(true);

        calenderUpdated(
            usersession,
            appointment_id,
            user_id,
            patient_id,
            is_for_self,
            is_returning,
            is_public_insurance,
            is_private_insurance,
            document,
            symptom,
            additional_info,
            professional_id,
            professional_key,
            professional_type_key,
            location_key,
            services,
            type_keys,
            dateSlot,
            durationValue,
            durationUnits,
            type_id,
            calender_id,
            user_id_p,
            is_at_location,
            is_online,
            sub_loy_key,
            upComming?.customer_id
        )
            .then((response) => {
                const isSlotInvalid = isTimeSlotInvalid(response.error);

                if (isSlotInvalid) {
                    setCalender(false);
                    setOpenReschedule(true);
                    setLocalLoading(false);
                    setShowInvalidTimeslotMessage(true);
                    return;
                }
                setShowInvalidTimeslotMessage(false);
                setUpdatedCalendar(response);
                setCalenderCanceldDataActive(true);
                // cancel
                const cancelDoctor = dataSetDoctos && upComming && upComming.doctor;
                const cancelLocation = dataSetDoctos && upComming && upComming.location;

                res = response?.cancelled;
                res.doctor = cancelDoctor;
                res.location = cancelLocation;
                setCalenderCanceldData(res);
                setLocalLoading(false);
                setCalender(false);

                fetchUpdatedAppointments();
            })
            .catch((err) => {
                console.debug('Error in updating a calander is ', err);
                setLocalLoading(false);
                setCalender(false);
            });
    };

    const cancelAppointment = (confirmCancellation = false) => {
        setLocalCancelLoading(true);
        cancelAppointmentForUser(
            usersession,
            upComming.id,
            upComming.user_id,
            upComming?.customer_id,
            confirmCancellation
        )
            .then((response) => {
                // direct cancel

                setOpen(false);
                if (!response?.error) {
                    resp = response;
                    resp.doctor = upComming?.doctor;
                    resp.location = upComming?.location;

                    setCalenderCanceldDataActive(true);
                    setCalenderCanceldData(resp);
                    updateActiveData(resp);
                    setCancelData(resp);
                    moveAppointmentToCancelled(resp);
                    setOpenReschedule(false);
                    setLocalCancelLoading(false);
                }
                console.debug('response of cancel ', resp, response);
            })
            .catch((err) => {
                console.debug('error of cancel ', err);
            });
    };

    console.debug('mainData: ', mainData);

    const forData = () => {
        console.debug('myDoctorID1: data', upComming);
        const uniqueKey = dataSetDoctos && doctors_data?.key[0].text;
        setMyAppointmentsData(upComming?.doctor);
        setMyAppointmentsDataStatus(dataSetDoctos);
        history.push(`/professional/${uniqueKey}`);
    };

    const appointmentFetcher = async ([session, bookingId]) => getAppointment(session, bookingId);

    const isBooked = (data) => data?.status === AppointmentStatus.BOOKED;

    const pollingInterval = upComming.status === 'BOOKED' ? 0 : 10000;

    const { data: pollingData } = usePollingData(
        [usersession, upComming.id],
        appointmentFetcher,
        isBooked,
        pollingInterval
    );

    const data = upComming.status === 'BOOKED' ? upComming : pollingData;

    const modalTitle = useMemo(() => {
        if (showInvalidTimeslotMessage) return bookAppointmentPrismicData.invalid_timeslot_title[0].text;
        return dataSet ? mainData.reschedule_confirmation[0].text : '';
    }, [showInvalidTimeslotMessage, bookAppointmentPrismicData, dataSet, mainData]);

    const modalText = useMemo(() => {
        if (showInvalidTimeslotMessage) return bookAppointmentPrismicData.invalid_timeslot_info_text[0].text;

        return dataSet ? mainData.action_cant_be_undo[0].text : '';
    }, [showInvalidTimeslotMessage, bookAppointmentPrismicData, dataSet, mainData]);

    const modalCtaText = useMemo(() => {
        if (showInvalidTimeslotMessage) return bookAppointmentPrismicData.invalid_timeslot_cta[0].text;

        return dataSet ? mainData.reschedule_confirm_cta[0].text : '';
    }, [showInvalidTimeslotMessage, bookAppointmentPrismicData, dataSet, mainData]);

    return (
        <>
            <div className="" style={{ display: 'flex' }}>
                <div className="" style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className={classes.cancelledClass}>
                            <Typography className={classes.mainCardHeading}>
                                {dataSetDoctos &&
                                    (treatments[upComming.location_key]?.[upComming.data.services[0].key]?.name[0]
                                        .text ??
                                        upComming?.name)}
                            </Typography>
                            {upComming?.status === 'CANCELLED' && upComming?.is_cancellation_confirmed === false && (
                                <Typography className={classes.cancelled}>
                                    {dataSet ? mainData?.cancelled_label1[0].text : 'Cancelled'}
                                </Typography>
                            )}
                        </div>
                        {!matches && (
                            <Typography className={classes.shortText} style={{ marginBottom: 20 }}>
                                {dataSetDoctos && doctors_data?.display_name[0].text} |{' '}
                                {dataSetDoctos && doctors_data?.practice_name[0].text}
                            </Typography>
                        )}

                        {matches && (
                            <Typography className={classes.shortText}>
                                {dataSetDoctos && doctors_data?.display_name[0].text}
                            </Typography>
                        )}
                        {matches && (
                            <Typography className={classes.shortText} style={{ marginBottom: 5 }}>
                                {dataSetDoctos && doctors_data?.practice_name[0].text}
                            </Typography>
                        )}

                        {/* // TODO proper redirect when appointment is from another customer */}

                        {/* start */}
                        {matches && (
                            <div className={classes.displayBtn}>
                                <UnderlinedButton
                                    text={dataSet ? mainData.see_profile_cta[0].text : 'See profile'}
                                    onPress={() => {
                                        forData();
                                    }}
                                    noMarginTop
                                />
                            </div>
                        )}
                        {/* end */}
                    </div>
                    <div className={classes.innerMainClass}>
                        {data?.status === AppointmentStatus.PENDING_BOOKING ? (
                            <div className={classes.pending}>
                                <EternoSpinner />
                            </div>
                        ) : (
                            <div className={classes.iconContainer}>
                                <div className={classes.mainClassImg}>
                                    <Icon src={calendar} width="100%" height="100%" description="handwritten" />
                                </div>
                                <div>
                                    <Typography className={classes.buttoncontentText} style={{ marginBottom: 5 }}>
                                        {updatedCalendar && updateActiveData && updatedCalendar?.booked?.start
                                            ? getDateWithMonth(updatedCalendar?.booked.start)
                                                  .replace(',', ' ')
                                                  .replace(',', ' ')
                                            : doctors_data &&
                                              getDateWithMonth(upComming?.start).replace(',', ' ').replace(',', ' ')}
                                    </Typography>
                                    {upComming.is_online ? (
                                        <Typography className={classes.lightText} style={{ marginBottom: 15 }}>
                                            {dataSet && mainData.online[0].text}
                                        </Typography>
                                    ) : (
                                        <div className={classes.flex}>
                                            <Typography className={classes.lightText}>
                                                {dataSetDoctos && doctor_location?.address[0].text}
                                            </Typography>
                                            <a
                                                href={dataSetDoctos && doctor_location?.google_maps.url}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={linkOnPage} className={classes.linkImg} alt="link" />
                                            </a>
                                        </div>
                                    )}

                                    {upComming?.status === AppointmentStatus.CANCELLED &&
                                    upComming?.is_cancellation_confirmed === false ? (
                                        <div className="">
                                            <Typography className={classes.lightText} style={{ marginBottom: 15 }}>
                                                {dataSet && mainData.cancelled_externally[0].text}
                                            </Typography>
                                            <FilledButton
                                                text={dataSet && mainData.reschedule_cta[0].text}
                                                onPress={() => {
                                                    setOpenReschedule(true);
                                                }}
                                                fullWidth={matches && true}
                                                marginBottom={matches && 20}
                                            />
                                            <UnderlinedButton
                                                text={
                                                    dataSet ? mainData.acknowledge_cancellation_cta[0].text : 'cancel'
                                                }
                                                onPress={() => {
                                                    cancelAppointment(true);
                                                }}
                                                underlineColor={theme.palette.common.yellow}
                                                marginLeft={20}
                                                noMarginTop
                                                fullWidth={matches && true}
                                                noJSLeft={matches && true}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <UnderlinedButtonWithTooltip
                                                buttonText={dataSet ? mainData.reschedule_cta[0].text : 'reschedule'}
                                                onPress={() => {
                                                    setOpenReschedule(true);
                                                }}
                                                underlineColor={theme.palette.common.yellow}
                                                tooltipText={dataSet ? mainData.tooltip_text[0].text : 'tooltip'}
                                                isDisabled={!upComming?.is_mapped}
                                                isMobile={isMobile}
                                                marginRight={20}
                                            />
                                            <UnderlinedButtonWithTooltip
                                                buttonText={dataSet ? mainData.cancel_cta[0].text : 'cancel'}
                                                onPress={() => {
                                                    setOpen(true);
                                                }}
                                                underlineColor={theme.palette.common.red}
                                                tooltipText={dataSet ? mainData.tooltip_text[0].text : 'tooltip'}
                                                isDisabled={!upComming?.is_mapped}
                                                isMobile={isMobile}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {!matches && (
                    <div className="">
                        <MyAppointmentImageSection
                            dataSet={dataSet}
                            mainData={mainData}
                            upComming={upComming}
                            dataSetDoctos={dataSetDoctos}
                            doctors_data={doctors_data}
                        />
                    </div>
                )}
            </div>

            <CancelDialogBox
                open={open}
                onClose={() => {
                    console.debug('Cancellation dialog');
                    setOpen(false);
                }}
                onPress={() => {
                    cancelAppointment();
                }}
                title={dataSet ? mainData.cancellation_confirmation[0].text : 'ITS a cancel'}
                text={dataSet ? mainData.action_cant_be_undo[0].text : 'This action be undone'}
                mainButtonText={dataSet ? mainData.cancel_confirm_cta[0].text : 'YES, CANCEL'}
                mainButton
                cancelButton
                cancelButtonText={dataSet ? mainData.cancel_reject_cta[0].text : 'NO, GO BACK'}
                filledButtonColor="red"
                localCancelLoading={localCancelLoading}
            />

            <CancelDialogBox
                open={openReschedule}
                onClose={() => {
                    console.debug('reschedule_ dialog');
                    setOpenReschedule(false);
                    setShowInvalidTimeslotMessage(false);
                }}
                onPress={() => {
                    prepareCalendarData();
                    setOpenReschedule(false);
                    setCalender(true);
                }}
                title={modalTitle}
                text={modalText}
                mainButtonText={modalCtaText}
                mainButton
                cancelButton={!showInvalidTimeslotMessage}
                cancelButtonText={dataSet ? mainData.reschedule_reject_cta[0].text : 'NO, GO BACK'}
                filledButtonColor="yellow"
            />

            <CalenderDialog
                open={calender}
                onClose={() => {
                    console.debug('Cancellation dialog');
                    setCalender(false);
                }}
                activeResponseDataCalendar={activeResponseData}
                prepareCalendarData={prepareCalendarData}
                onPress={() => {
                    updateCalenderData();
                }}
                startDate={startDate}
                localLoading={localLoading}
                mainData={mainData}
                dataSet={dataSet}
            />
        </>
    );
};

export default MyAppointmentDetailsCard;
