import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '18px',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '2rem',
        marginBottom: '1rem',
        width: '100%',
    },
    headingSec: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '18px',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '1rem',
        marginBottom: '1rem',
        width: '100%',
    },
    buttoncontentText: {
        ...theme.typography.subheading,
        width: '100%',
        textAlign: 'center',
    },
    selectionContainer: {
        padding: 3,
        marginTop: 15,
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    },
    selectItemCard: {
        backgroundColor: theme.palette.common.lightGray,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'inline-block',
        marginRight: 20,
        '&:hover': {
            backgroundColor: theme.palette.common.lightGray,
            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'fit-content',
            marginRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'fit-content',
            marginRight: 0,
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 25,
            paddingRight: 25,
        },
    },
    selectItemCardBorder: {
        boxShadow: `0 0 0 1px ${theme.palette.common.mediumBrown}`,
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'fit-content',
        },
    },
    selectedCard: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'fit-content',
        },
    },
}));

const Speciality = ({ setSpecialtyType }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        state: { uploadedByMeData },
    } = useContext(HealthProfileContextMain);

    const [selectedBox, setSelectedBox] = useState(null);

    const selectItem = (item, index) => {
        setSelectedBox(index);
        setSpecialtyType(item);
    };

    const list = uploadedByMeData?.specialty_responses ?? [];

    return (
        <div>
            <Typography className={classes.headingSec}>
                {uploadedByMeData?.specialty_question__optional_[0].text ?? 'Select the type of document'}
            </Typography>
            <div className={classes.selectionContainer}>
                {list.map((item, index) => (
                    <Button
                        key={item.specialty_response_api_value}
                        onClick={() => {
                            selectItem(item.specialty_response_api_value, index);
                        }}
                        disableRipple
                        className={clsx(
                            classes.selectItemCard,
                            selectedBox === index ? classes.selectedCard : classes.selectItemCardBorder
                        )}
                        style={{
                            marginBottom: 20,
                            marginRight: matches ? 10 : null,
                            width: matches ? '100%' : null,
                            display: matches ? 'flex' : null,
                        }}
                    >
                        <Typography className={classes.buttoncontentText}>{item.specialty_response[0].text}</Typography>
                    </Button>
                ))}
            </div>
        </div>
    );
};

const TypeOfDoc = ({ setDocType, setSpecialtyType }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    //   setIsUploadButtonDisabled
    const {
        state: { uploadedByMeData },
        setIsUploadButtonDisabled,
    } = useContext(HealthProfileContextMain);

    const [selectedBox, setSelectedBox] = useState(null);
    const [openSpecialty, setOpenSpecialty] = useState(false);

    const list = uploadedByMeData?.type_of_doc_response ?? [];

    const selectItem = (item, index) => {
        if (selectedBox === index) {
            setSelectedBox(null);
        } else {
            setSelectedBox(index);
            setDocType(item);
        }
    };

    useEffect(() => {
        if (selectedBox !== null) {
            // Enable Specialty
            setOpenSpecialty(true);
            // Enable save button
            setIsUploadButtonDisabled(false);
        } else {
            // Disable Specialty
            setOpenSpecialty(false);
            // Disable save button
            setIsUploadButtonDisabled(true);
        }
    }, [selectedBox]);

    return (
        <div>
            <Typography className={classes.heading}>
                {uploadedByMeData?.type_of_doc_question[0].text ?? 'Select the type of document'}
            </Typography>
            <div className={classes.selectionContainer}>
                {list.map((item, index) => {
                    return (
                        <Button
                            key={index}
                            onClick={() => {
                                selectItem(item.type_of_doc_responses, index);
                            }}
                            disableRipple
                            className={
                                selectedBox === index
                                    ? clsx(classes.selectItemCard, classes.selectedCard)
                                    : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                            }
                            style={{
                                marginBottom: 20,
                                marginRight: matches ? 10 : null,
                                width: matches ? '100%' : null,
                                display: matches ? 'flex' : null,
                            }}
                        >
                            <Typography className={classes.buttoncontentText}>
                                {item.type_of_document[0].text}
                            </Typography>
                        </Button>
                    );
                })}
            </div>
            {openSpecialty && <Speciality setSpecialtyType={setSpecialtyType} />}
        </div>
    );
};

export default TypeOfDoc;
