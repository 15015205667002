import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    countryFlag: {
        width: 30,
        height: 20,
        border: '1px solid #d5d5d5',
    },
}));

const CountryFlag = ({ countryCode = '', style = {}, alt = '' }) => {
    const classes = useStyles();
    return (
        <img
            className={classes.countryFlag}
            src={`https://flagcdn.com/w40/${countryCode}.png`}
            alt={alt}
            style={style}
        />
    );
};

export default CountryFlag;
