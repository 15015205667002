import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';

import ImageSectionDoctorDetails from './ImageSectionDoctorDetails';
import SecondSideBar from './SecondSideBar';

const SideBarDoctorDetails = ({ doctors_data, myAppointmentsDataStatus, dataSet, mainData }) => {
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Grid container direction={matchesMD && 'row'} spacing={1}>
                <Grid item lg={12} md={12} sm={6} xs={12}>
                    <ImageSectionDoctorDetails
                        doctors_data={doctors_data}
                        myAppointmentsDataStatus={myAppointmentsDataStatus}
                        mainData={mainData}
                        dataSet={dataSet}
                    />
                </Grid>

                <Grid item lg={12} md={12} sm={6} xs={12}>
                    <SecondSideBar
                        doctors_data={doctors_data}
                        myAppointmentsDataStatus={myAppointmentsDataStatus}
                        mainData={mainData}
                        dataSet={dataSet}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default SideBarDoctorDetails;
