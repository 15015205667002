import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DownloadDocumentIcon from '@/assets/Icons/download_document.svg';

import { useUserDocumentDownload } from '@/hooks/useUserDocumentDownload.js';

import { dayjs } from '@/utils/dayjsSetup.js';
import { getDocumentTypeTranslation } from '@/utils/helpers.js';

import { useProfessionalsStore, useSearchStore } from '@/store.js';

const useStyles = makeStyles((theme) => ({
    data: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '3px solid #F5F5F5',
    },
    item: {
        width: '100%',
        padding: '0.2rem 1.5rem',
        minHeight: '4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0.5rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            width: '66%',
            alignItems: 'start',
            height: 'auto',
            padding: '1rem 0 1rem 1.5rem',
        },
        '& .MuiTypography-root': {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
    },
    icon: {
        cursor: 'pointer',
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    mobileIcon: {
        width: '1rem',
    },
    mobileDownloadButton: {
        display: 'block',
        marginRight: '1.8rem',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    currentSorting: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
    },
    dataField: {
        width: '25%',
    },
    docNameField: {
        width: '45%',
        wordBreak: 'break-all',
        marginRight: ({ displayDoctor }) => (displayDoctor ? '1.5rem' : '0'),
    },
}));

const DocumentItem = ({ document, content, isSortedField, displayDoctor = false }) => {
    const { t } = useTranslation();
    const { handleDocumentDownload } = useUserDocumentDownload();
    const classes = useStyles({ displayDoctor });

    const uploadDate = useMemo(() => dayjs(document.meta.created_at).format('DD.MM.YYYY'), [document]);

    const documentType = useMemo(
        () => getDocumentTypeTranslation(content?.type_of_doc_response, document?.type),
        [content, document]
    );

    const getDoctorNameById = (professionalId) => {
        const { professionalMappings } = useProfessionalsStore.getState();
        const { professionals } = useSearchStore.getState();

        if (!professionalMappings || !professionals) return null;

        const matchingProfessional = Object.values(professionalMappings).find(
            (professional) => professional?.user_id === professionalId
        );

        return matchingProfessional
            ? (professionals[matchingProfessional.prismic_key]?.display_name?.[0]?.text ?? '')
            : (content?.not_shared?.[0]?.text ?? t('NotShared'));
    };

    return (
        <div className={classes.data}>
            <div className={classes.item}>
                {displayDoctor ? (
                    <>
                        <Typography
                            className={clsx(classes.dataField, { [classes.currentSorting]: isSortedField('DATE') })}
                        >
                            {uploadDate}
                        </Typography>
                        <Typography
                            className={clsx(classes.dataField, { [classes.currentSorting]: isSortedField('DOCTOR') })}
                        >
                            {getDoctorNameById(document.sharing?.[0]?.shared_target?.id)}
                        </Typography>
                        <Typography
                            className={clsx(classes.docNameField, { [classes.currentSorting]: isSortedField('NAME') })}
                        >
                            {document.name}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            className={clsx(classes.docNameField, { [classes.currentSorting]: isSortedField('NAME') })}
                        >
                            {document.name}
                        </Typography>
                        <Typography
                            className={clsx(classes.dataField, { [classes.currentSorting]: isSortedField('TYPE') })}
                        >
                            {documentType}
                        </Typography>
                        <Typography
                            className={clsx(classes.dataField, { [classes.currentSorting]: isSortedField('DATE') })}
                        >
                            {uploadDate}
                        </Typography>
                    </>
                )}
                <IconButton className={classes.icon} onClick={() => handleDocumentDownload(document.id)}>
                    <img src={DownloadDocumentIcon} alt="download" />
                </IconButton>
            </div>
            {/* TODO: should be just one icon */}
            <IconButton onClick={() => handleDocumentDownload(document.id)} className={classes.mobileDownloadButton}>
                <img src={DownloadDocumentIcon} className={classes.mobileIcon} alt="download" />
            </IconButton>
        </div>
    );
};

export default DocumentItem;
