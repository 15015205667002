import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { memo, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import fileIcon from '@/assets/Illustrations/fileIcon.svg';

import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';

import FilledButton from '../../FilledButton';
import Icon from '../../Icon/Icon';

const useStyles = makeStyles((theme) => ({
    dropZoneParent: {
        background: '#F5F5F5',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1.8rem',
        paddingBottom: '0.1rem',
        marginBlock: '1em',
        borderRadius: '8px',
        border: '2px dashed #D5D5D5',
        cursor: 'pointer',
    },
    dropZoneContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dropZoneText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '18px',
    },
    dropZoneSupportedFilesText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '18px',
        color: theme.palette.common.brown,
        [theme.breakpoints.down('xs')]: { fontSize: '14px' },
    },
    shakeAnimation: {
        animation: '$shake 0.1s',
        animationIterationCount: '1',
        animationDelay: '.2s',
    },
    textHighlight: {
        textShadow: `2px 2px 9px ${theme.palette.common.yellow}`,
    },
    '@keyframes shake': {
        '10%, 90%': {
            transform: 'translate3d(-3px, 0, 0)',
        },
        '20%, 80%': {
            transform: 'translate3d(5px, 0, 0)',
        },
        '30%, 50%, 70%': {
            transform: 'translate3d(-4px, 0, 0)',
        },
        '40%, 60%': {
            transform: 'translate3d(4px, 0, 0)',
        },
    },
}));

const FileDrop = ({ onDrop }) => {
    const classes = useStyles();
    const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
        noClick: true,
        accept: {
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'application/pdf': [],
        },
        maxFiles: 1,
        minSize: 0,
        maxSize: 21000000,
        onDrop: onDrop,
    });

    const {
        state: { acceptedFileName, uploadedByMeData },
        setAcceptedFileName,
        setFileUploadSteps,
    } = useContext(HealthProfileContextMain);
    console.debug('🚀 ~ file: FileDrop.jsx:78 ~ FileDrop ~', uploadedByMeData);

    const [shakeAnimation, setShakeAnimation] = useState(false);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            // Setting Uploaded File Name In Context
            setAcceptedFileName(acceptedFiles[0].name);
            // Updating Step
            setFileUploadSteps(2);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections.length === 0) return undefined;

        setAcceptedFileName('');
        setShakeAnimation(true);

        const timeOut = setTimeout(() => {
            setShakeAnimation(false);
        }, 500);

        return () => {
            clearTimeout(timeOut);
        };
    }, [fileRejections]);

    return (
        <div {...getRootProps({ className: 'dropzone' })} className={classes.dropZoneParent}>
            <input className="input-zone" {...getInputProps()} />
            <div className={classes.dropZoneContent}>
                <Icon src={fileIcon} />
                <FilledButton
                    text={uploadedByMeData?.select_a_file[0].text || 'Select A File'}
                    onPress={open}
                    marginTop="1rem"
                />
                <p className={classes.dropZoneText}>{uploadedByMeData?.drag_and_drop_text[0].text}</p>
                <p className={clsx(classes.dropZoneSupportedFilesText, shakeAnimation ? classes.shakeAnimation : null)}>
                    {uploadedByMeData?.file_type_and_max_size[0].text || 'Only JPG, PNG and PDF. Max 20MB each'}
                </p>
                {acceptedFileName && (
                    <ul>
                        <li>{acceptedFileName}</li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default memo(FileDrop);
