import { Button, Grid, Slider, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './LifeStyleStyles';

const PrettoSlider = withStyles((theme) => ({
    root: {
        color: '#ABABAB',
        height: 2,
    },
    active: {},
    valueLabel: {},
    track: {
        height: 8,
        borderRadius: 4,
        color: theme.palette.common.yellow,
    },
    rail: {
        height: 8,
        borderRadius: 4,
        opacity: 1,
    },
}))(Slider);

const LifeStyleSports = ({ mainData, isFromHealthData }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { sports, setSports, value_, setValue_ } = useContext(ContextProviderTwo);

    const handleChange = (event) => {
        const { value } = event.target;
        if (parseInt(value) < 1 || parseInt(value) > 7) return false;
        setValue_(value);
    };

    return (
        <>
            <div
                className={classes.fixedQuestion}
                style={{
                    marginTop: isFromHealthData ? sports && (matches ? 20 : 30) : 30,
                }}
            >
                <Typography className={classes.mainHeading}>{mainData.sports_question[0].text}</Typography>

                <Grid
                    style={{
                        marginTop: 20,
                    }}
                    container
                >
                    {mainData &&
                        mainData.sports_yes_no_responses.map((parts) => (
                            <Grid lg="auto" sm="auto" xs={6}>
                                <Button
                                    onClick={() => {
                                        console.debug(parts.sports_yes_no_response_api_value);
                                        setSports(parts.sports_yes_no_response_api_value);
                                    }}
                                    disableRipple
                                    className={
                                        sports.includes(parts.sports_yes_no_response_api_value)
                                            ? clsx(classes.selectItemCard, classes.selectedCard)
                                            : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                    }
                                    style={{
                                        marginBottom: 20,
                                        width: matches && '90%',
                                        display: matches ? null : 'flex',
                                        marginRight: 15,
                                    }}
                                >
                                    <div className="">
                                        <Typography className={classes.BtncontentText}>
                                            {parts.sports_yes_no_response[0].text}
                                        </Typography>
                                    </div>
                                </Button>
                            </Grid>
                        ))}
                </Grid>
            </div>
            {/* slider  portion */}
            {sports === 'YES' && (
                <div className={classes.rangeQuestion} style={{ marginTop: matches ? 20 : null }}>
                    <Typography className={classes.mainHeading} style={{ paddingBottom: 20 }}>
                        {mainData.days_a_week_sports_subquestion[0].text}
                    </Typography>
                    <div className={classes.questionContainer}>
                        <div className={classes.answersContainer}>
                            <TextField
                                id="yourBodyChangedWeightAmount"
                                label={mainData ? mainData.enter_days_here[0].text : 'Enter days here'}
                                variant="filled"
                                autoComplete="off"
                                color="primary"
                                type="tel"
                                value={String(value_)
                                    .replace(/[^1-7.]/g, '')
                                    .replace(/(\..*?)\..*/g, '$1')
                                    .replace(/^0[^.]/, '0')}
                                onChange={handleChange}
                                classes={{
                                    root: classes.textFields,
                                }}
                                style={{ width: '100%' }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                    inputProps: {
                                        min: 1,
                                        max: 7,
                                    },
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {/* slider end */}
        </>
    );
};

export default LifeStyleSports;
