import { useContext, useEffect, useMemo } from 'react';
import { isSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';

import { WelcomeComponent } from '@/components/ConsentForms';
import Loader from '@/components/Loader';

import { Context as AuthContext } from '@/context/AuthContext';
import { useConsentFormsContext } from '@/context/ConsentFormsContext';

import useFormSteps from '@/hooks/useFormSteps';
import useFormTemplate from '@/hooks/useFormTemplate';

import { getText } from '@/utils/helpers.js';

import { usePersistedPrismicStore } from '@/store.js';

const description =
    'Please read the following documents thoroughly and fill them out. After completing the required sections, these documents will be forwarded to your practitioner immediately.';

const ConsentAllFormsWelcome = () => {
    const history = useHistory();
    const { i18n } = useTranslation();

    const { [i18n.language]: content } = usePersistedPrismicStore((state) => state.treatmentContractWelcomeData);

    const { isConsentAllForms, step, currentStepIndex } = useFormSteps();

    const {
        state: { user },
    } = useContext(AuthContext);

    const {
        setSignState,
        consentAllForms: { initialRoute, formTemplates },
    } = useConsentFormsContext();

    const { pages } = useFormSteps();

    const { loading: formTemplateLoading } = useFormTemplate({ type: isConsentAllForms ? 'form' : 'query' });

    const signatureTypeForNextForm = formTemplates[currentStepIndex]?.signatureType ?? null;

    const handleContinueWithAccount = () => {
        setSignState(signatureTypeForNextForm);

        if (!user) {
            history.push(`/login?redirectTo=${step}`);
            return false;
        }

        history.push(step);
        return false;
    };

    const handleContinueAsGuest = () => {
        setSignState(signatureTypeForNextForm);
        history.push(step);
    };

    const continueText = useMemo(() => {
        return content ? getText(content?.continue_as_guest_title) : 'You don’t have an Eterno account?';
    }, [content]);

    useEffect(() => {
        //* * Redirect back user to checkin page */
        const redirectBack = () => {
            history.replace(initialRoute || '/all-forms-code');
        };
        window.addEventListener('popstate', redirectBack);

        window.addEventListener('load', redirectBack);

        // for safari browser
        if (isSafari && !isConsentAllForms) redirectBack();

        return () => {
            window.removeEventListener('popstate', redirectBack);
            window.removeEventListener('load', redirectBack);
        };
    }, []);

    if (!content || formTemplateLoading) return <Loader />;
    return (
        <WelcomeComponent
            width="70% !important"
            icon={WelcomeIcon}
            pages={pages.map((page) => ({ ...page, active: false }))}
            title={getText(content?.welcome_title) ?? 'Data protection patient information'}
            paragraphs={[getText(content?.welcome_text) ?? description]}
            primaryBtnText={getText(content?.continue_with_account_cta) ?? 'Continue with your eterno account'}
            {...(!user && { continueText: continueText })}
            continueBtnText={getText(content?.continue_as_guest_cta) ?? 'Continue as guest'}
            onPrimaryBtnClick={handleContinueWithAccount}
            onContinueBtnClick={handleContinueAsGuest}
        />
    );
};

export default ConsentAllFormsWelcome;
