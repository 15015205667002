import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@/utils/helpers.js';

import { useSearchStore } from '@/store.js';

import { HealthInsurance } from '../Search/SearchBar';
import UnderlinedButton from '../UnderlinedButton';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0.75rem 1rem',
        background: theme.palette.common.lightGray,
        border: '1px solid #d5d5d5',
        borderRadius: '0.5rem',
        fontSize: '17px',
    },
    text: {
        display: 'inline',
        verticalAlign: 'middle',
    },
    button: {
        display: 'inline-block',
        verticalAlign: 'middle',
        padding: '0 0.2rem',
    },
}));

const NoTreatmentsMessage = ({
    selectedHealthInsurance,
    publicText,
    privateText,
    selfPayText,
    changeInsuranceText,
    availableInsurances,
}) => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const query = useQuery();
    const history = useHistory();

    let firstSentence;
    switch (selectedHealthInsurance) {
        case HealthInsurance.PUBLIC:
            firstSentence = publicText;
            break;
        case HealthInsurance.PRIVATE:
            firstSentence = privateText;
            break;
        case HealthInsurance.SELF_PAYER:
            firstSentence = selfPayText;
            break;
        default:
            firstSentence = '';
            break;
    }

    const changeInsuranceParts = changeInsuranceText.split('{insurance_placeholder}');

    const updateSelectedHealthInsurance = (value) => {
        useSearchStore.setState({ selectedHealthInsurance: value });
        query.set('insurance_type', encodeURI(value));
        history.replace({ search: query.toString() });
    };

    return (
        <div className={classes.container}>
            <span className={classes.text}>{`${firstSentence} ${changeInsuranceParts[0].trim()}`}</span>
            <div className={classes.button}>
                <UnderlinedButton
                    onPress={() => updateSelectedHealthInsurance(availableInsurances[0])}
                    text={t(availableInsurances[0])}
                    noMarginTop
                    noPresetWidth
                    fontOverride="14px"
                />
            </div>
            {availableInsurances[1] && (
                <>
                    <span className={classes.text}>{changeInsuranceParts[1].trim()}</span>
                    <div className={classes.button}>
                        <UnderlinedButton
                            onPress={() => updateSelectedHealthInsurance(availableInsurances[1])}
                            text={t(availableInsurances[1])}
                            noMarginTop
                            noPresetWidth
                            fontOverride="14px"
                        />
                    </div>
                </>
            )}
            <span className={classes.text}>{changeInsuranceParts[2].trim()}</span>
        </div>
    );
};

export default NoTreatmentsMessage;
