import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentStyles, ContractDetailsWithLogo, TranslationAlert } from '@/components/ConsentForms';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import useScrollToTop from '@/hooks/useScrollToTop';

import { languageMap } from '@/utils/helpers.js';

const defaultText = `This document can only be signed in original form in german. For your convenience, we provide you with a suggested english translation `;

const ContractDetailsPage = () => {
    /** Styles */
    const classes = ConsentStyles();
    const { i18n } = useTranslation();
    const lan = languageMap[i18n.language];

    useScrollToTop();

    /** Context */
    const { consentFormsContent: content } = useContext(ConsentFormsContext);

    return (
        <>
            {lan === languageMap.en ? (
                <TranslationAlert
                    text={
                        content && content?.heraklesContent?.translationMessage
                            ? content?.heraklesContent?.translationMessage
                            : defaultText
                    }
                    underlineText={
                        content && content?.contractDetails.herePopup ? content?.contractDetails.herePopup : `here.`
                    }
                    modalContent={content?.heraklesContent?.popupTranslation}
                />
            ) : null}
            <ContractDetailsWithLogo
                heading={
                    <Typography className={clsx(classes.shortHeading)}>
                        {content?.heraklesContent?.textProvidedHeading}
                    </Typography>
                }
                content={content?.heraklesContent?.contractDetailsText}
                logo={content?.heraklesContent?.contractLogo?.url}
                logoHeight={content?.heraklesContent?.contractLogo?.dimensions.height}
                logoWidth={content?.heraklesContent?.contractLogo?.dimensions.width}
            />
        </>
    );
};

export default ContractDetailsPage;
