import { useContext } from 'react';

import ContentDivider from '@/components/Common/ContentDivider';
import { ConfirmSummary, SummaryIntro, SummaryPersonalInfoWithAddress } from '@/components/ConsentForms';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import useScrollToTop from '@/hooks/useScrollToTop';

import ContractDetailsPage from './ContractDetailsPage';

const SummaryAndConsentPage = () => {
    useScrollToTop();

    /** Context */
    const { consentFormsContent: content, confirmSummary, handleToggle } = useContext(ConsentFormsContext);

    return (
        <>
            {/* Intro */}
            <SummaryIntro subtitle={content?.heraklesContent?.summarySubtitle} />

            {/* Divider */}
            <ContentDivider my={30} />

            {/* Personal Information */}
            <SummaryPersonalInfoWithAddress />

            {/* Divider */}
            <ContentDivider my={30} />

            {/* Contract Details Component */}
            <ContractDetailsPage />

            {/* Divider */}
            <ContentDivider my={30} />

            {/* Confirm Summary Toggle */}
            <ConfirmSummary
                heading={content?.heraklesContent?.consentTitle}
                text={content?.heraklesContent?.toggleText}
                //* * Switch Props */
                checked={confirmSummary}
                onToggle={handleToggle}
            />
        </>
    );
};

export default SummaryAndConsentPage;
